import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getAffiliate: ({ commit }) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5101AffiliateService.getAffiliate' } } })
        .then((data) => data.auth.output)
    },
    getAffiliatePolicyVersion: ({ commit }) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5101AffiliateService.getAffiliatePolicyVersion' } } })
        .then((data) => data.auth.output)
    },
    registerAffiliate: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5101AffiliateService.registerAffiliate', input } } })
        .then((data) => data.auth.output)
    },
    handleAffiliateReferral: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5101AffiliateService.handleAffiliateReferral', input } } })
        .then((data) => {})
    },
    getAffiliateReviewById: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5101AffiliateService.getAffiliateReviewById', input } } })
        .then((data) => data.auth.output)
    },
    updateAffiliateReviewById: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5101AffiliateService.updateAffiliateReviewById', input } } })
        .then((data) => data.auth.output)
    },
    // Dashboard
    getAffiliateDetails: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5202AffiliateUserItemHistoryService.getAffiliateDetails', input } } })
        .then((data) => data.auth.output)
    },
    getVendorDetails: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'M5202AffiliateUserItemHistoryService.getVendorDetails', input } } })
        .then((data) => data.auth.output)
    }
  }
}
export default ObjArrUtils.merge(Base, error, BREAD, module)
