import i18n from '@/plugins/i18n'

export default [
  {
    path: 'approvalList',
    name: 'ApprovalList',
    component: () => import('@/app/M2301Approval/PgApprovalList.vue'),
    portalGroup: 'admin.admin',
    meta: { authenticated: true, permissions: ['approval.manage'], title: i18n.t('m2301.title') }
  }
]
