<template>
  <!-- <div>
    <div
      v-bind="$attrs"
      id="g_id_onload"
      :data-client_id="client_id"
      :data-context="dataContext"
      :data-ux_mode="dataUxMode"
      data-callback="loginWithGoogle"
      :data-itp_support="itpSupport"
    />
    <div
      v-bind="$attrs"
      class="g_id_signin"
      :data-type="dataType"
      :data-shape="dataShape"
      :data-theme="dataTheme"
      :data-text="dataText"
      :data-size="dataSize"
      :data-logo_alignment="dataLogoAlignment"
      :data-width="dataWidth"
      :data-locale="convertLocale(currentLocale, 'bcp47')"
    />
  </div> -->
  <button
    class="membership-login-method"
    @click.prevent="$refs.googleLoginBtn.querySelector('div[role=button]').click()"
  >
    <div
      v-show="false"
      ref="googleLoginBtn"
    />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      x="0"
      y="0"
      viewBox="0 0 512 512"
      style="enable-background:new 0 0 512 512; margin-right: 10px;"
      xml:space="preserve"
      class=""
    ><g><path
      d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"
      style=""
      fill="#fbbb00"
      data-original="#fbbb00"
    /><path
      d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"
      style=""
      fill="#518ef8"
      data-original="#518ef8"
      class=""
    /><path
      d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"
      style=""
      fill="#28b446"
      data-original="#28b446"
      class=""
    /><path
      d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"
      style=""
      fill="#f14336"
      data-original="#f14336"
      class=""
    /></g></svg>
    使用<span class="en fw-600">Gmail</span>登入
  </button>
</template>

<script>
export default {
  name: 'AtGoogleLogin',
  /**
   * Props for modifying Google login button styles & attributes
   *
   * @link https://developers.google.com/identity/gsi/web/reference/html-reference
   */
  props: {
    dataContext: {
      type: String,
      default: 'use',
      validator: (val) => {
        return ['signin', 'signup', 'use'].includes(val)
      }
    },
    dataUxMode: {
      type: String,
      default: 'popup',
      validator: (val) => {
        return ['popup', 'redirect'].includes(val)
      }
    },
    itpSupport: {
      type: Boolean,
      default: true
    },
    dataType: {
      type: String,
      default: 'standard',
      validator: (val) => {
        return ['standard', 'icon'].includes(val)
      }
    },
    dataShape: {
      type: String,
      default: 'rectangular',
      validator: (val) => {
        return ['rectangular', 'pill', 'circle', 'square'].includes(val)
      }
    },
    dataTheme: {
      type: String,
      default: 'outline',
      validator: (val) => {
        return ['outline', 'filled_blue', 'filled_black'].includes(val)
      }
    },
    dataText: {
      type: String,
      default: 'continue_with',
      validator: (val) => {
        return ['signin_with', 'signup_with', 'continue_with', 'signin'].includes(val)
      }
    },
    dataSize: {
      type: String,
      default: 'large',
      validator: (val) => {
        return ['large', 'medium', 'small'].includes(val)
      }
    },
    dataLogoAlignment: {
      type: String,
      default: 'center',
      validator: (val) => {
        return ['left', 'center'].includes(val)
      }
    },
    dataWidth: {
      type: [String, Number],
      default: '300'
    }
  },
  data () {
    return {
      client_id: import.meta.env.VITE_APP_GOOGLE_CLIENT_ID
    }
  },
  created () {
    document.getElementById('google-jssdk')?.remove()
    window.loginWithGoogle = this.loginWithGoogle
  },
  mounted () {
    this.initializeGoogle()
  },
  methods: {
    initializeGoogle () {
      // Insert Google SDK if not found in header
      const id = 'google-jssdk'
      if (!document.getElementById(id)) {
        const fjs = document.getElementsByTagName('script')[0]
        const js = document.createElement('script')
        js.addEventListener('load', () => {
          window.google.accounts.id.initialize({
            client_id: this.client_id,
            callback: this.loginWithGoogle
          })
          window.google.accounts.id.renderButton(this.$refs.googleLoginBtn, {})
          window.google.accounts.id.prompt()
        })
        js.id = id
        js.src = 'https://accounts.google.com/gsi/client'
        fjs.parentNode.insertBefore(js, fjs)
      }
    },
    loginWithGoogle (response) {
      const decodedJwt = this.parseJwt(response.credential)
      const obj = {
        social_media: 'GOOGLE',
        user_id: decodedJwt.sub,
        access_token: response.credential
      }
      this.$emit('login:success', obj)
    },
    parseJwt (token) {
      // return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString())
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
  }
}
</script>
