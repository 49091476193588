// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// import i18n from '@/plugins/i18n'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    // PgUserReport
    report_list_pagination: {
      per_page: 10
    },
    report_list: [],
    reported_list_pagination: {
      per_page: 10
    },
    reported_list: []
  },

  getters: {
    getReportList: state => state.report_list,
    getReportListPagination: state => state.report_list_pagination,
    getReportedList: state => state.reported_list,
    getReportedListPagination: state => state.reported_list_pagination
  },

  mutations: {
    SET_REPORT_LIST_PAGINATION (state, payload) {
      state.report_list_pagination = {
        ...state.report_list_pagination,
        ...payload
      }
    },
    SET_REPORT_LIST (state, payload) {
      state.report_list = payload
    },
    SET_REPORTED_LIST_PAGINATION (state, payload) {
      state.reported_list_pagination = {
        ...state.reported_list_pagination,
        ...payload
      }
    },
    SET_REPORTED_LIST (state, payload) {
      state.reported_list = payload
    }
  },

  actions: {
    searchReportList: ({ commit, state }) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1101UserReportService.getUserReportHistory', input: { pagination: state.report_list_pagination } }
        ]
      })
        .then(data => {
          commit('SET_REPORT_LIST', data[0].output.data)
          commit('SET_REPORT_LIST_PAGINATION', data[0].output.pagination)
        })
    },
    searchReportedList: ({ commit, state }) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1101UserReportService.getUserReportedHistory', input: { pagination: state.reported_list_pagination } }
        ]
      })
        .then(data => {
          commit('SET_REPORTED_LIST', data[0].output.data)
          commit('SET_REPORTED_LIST_PAGINATION', data[0].output.pagination)
        })
    },
    getReportCaseTimeline: ({ commit }, input) => {
      return ApiUtils.postData({ data: [{ api: 'M1204ReportCaseCommentService.getReportCaseTimeline', input }] })
        .then(data => {
          return data[0].output
        })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
