<template>
  <VInput
    v-bind="{...$attrs}"
  >
    <v-row align="center">
      <v-col cols="auto">
        <AtDatePicker
          :value="dValue.start ? moment(dValue.start).format('YYYY-MM-DD') : null"
          :clear-value="moment().format('YYYY-MM-DD')"
          v-bind="AtDatePickerProps"
          @input="dValue.start = $event + ' ' + moment(dValue.start).format('HH:mm:ss')"
        />
      </v-col>
      <v-col cols="auto">
        <AtTimeStepPicker
          v-if="step15min"
          :value="moment(dValue.start).format('HH:mm')"
          v-bind="AtTimePickerProps"
          @input="dValue.start = moment(dValue.start).format('YYYY-MM-DD') + ' ' + $event + ':00'"
        />
        <AtTimePicker
          v-else
          :value="dValue.start ? moment(dValue.start).format('HH:mm') : null"
          clear-value="00:00"
          v-bind="AtTimePickerProps"
          @input="dValue.start = moment(dValue.start).format('YYYY-MM-DD') + ' ' + $event + ':00'"
        />
      </v-col>
      <v-col cols="auto">
        -
      </v-col>
      <v-col cols="auto">
        <AtTimeStepPicker
          v-if="step15min"
          :value="moment(dValue.end).format('HH:mm')"
          :min="moment(dValue.start).format('HH:mm')"
          v-bind="AtTimePickerProps"
          @input="dValue.end = moment(dValue.end).format('YYYY-MM-DD') + ' ' + $event + ':00'"
        />
        <AtTimePicker
          v-else
          :value="dValue.end ? moment(dValue.end).format('HH:mm') : null"
          v-bind="AtTimePickerProps"
          clear-value="00:00"
          @input="dValue.end = moment(dValue.end).format('YYYY-MM-DD') + ' ' + $event + ':00'"
        />
      </v-col>
      <v-col
        v-if="hideEndDate == false"
        cols="auto"
      >
        <AtDatePicker
          :value="dValue.end ? moment(dValue.end).format('YYYY-MM-DD') : null"
          :clear-value="moment().format('YYYY-MM-DD')"
          v-bind="AtDatePickerProps"
          :date-picker-props="{
            min: dValue.start
          }"
          @input="dValue.end = $event + ' ' + moment(dValue.end).format('HH:mm:ss')"
        />
      </v-col>
    </v-row>
  </VInput>
</template>

<script>
import m from 'moment'
import momentPlugin from '@/plugins/moment'
const moment = momentPlugin(m)

export default {
  components: {
    AtTimePicker: () => import('@/components/base/atoms/input/AtTimePicker/AtTimePicker.vue'),
    AtTimeStepPicker: () => import('./AtTimeStepPicker.vue'),
    AtDatePicker: () => import('@/components/base/atoms/input/AtDatePicker/AtDatePicker.vue')
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          start: null,
          end: null
        }
      }
    },
    allDay: {
      type: Boolean,
      default: false
    },
    hideEndDate: {
      type: Boolean,
      default: false
    },
    step15min: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dValue: this.value,
      AtTimePickerProps: {
        dense: true,
        outlined: true,
        clearable: false,
        hideDetails: true,
        prependIcon: null,
        readonly: this.isReadonly,
        disabled: this.isReadonly
      },
      AtDatePickerProps: {
        dense: true,
        outlined: true,
        clearable: false,
        hideDetails: true,
        prependIcon: null,
        readonly: this.isReadonly,
        disabled: this.isReadonly
      },
      moment
    }
  },
  computed: {
    startDate () {
      return this.value.start
    },
    endDate () {
      return this.value.end
    },
    isSameDay () {
      return moment(this.startDate).isSame(this.endDate, 'day')
    },
    formattedStartDate () {
      return moment(this.startDate).format('dddd, MMMM D')
    },
    formattedEndDate () {
      return moment(this.endDate).format('dddd, MMMM D')
    },
    formattedStartTime () {
      return moment(this.startDate).format('h:mm A')
    },
    formattedEndTime () {
      return moment(this.endDate).format('h:mm A')
    },
    display () {
      let init = '_sd_ ⋅ _st_ – _et_ ⋅ _ed_'

      if (this.isSameDay) {
        init = init.replace('⋅ _ed_', '')
      }
      if (this.allDay) {
        init = init.replace('_st_', '')
          .replace('_et_', '')
          .replace('⋅', '')
      }

      // if no et and ed, remove the dash
      if (this.isSameDay && this.allDay) {
        init = init.replace(' – ', '')
      }

      return init
        .replace('_sd_', this.formattedStartDate)
        .replace('_st_', this.formattedStartTime)
        .replace('_ed_', this.formattedEndDate)
        .replace('_et_', this.formattedEndTime)
    }
  },
  watch: {
    value: {
      handler (val) {
        if (!val.start) {
          val.start = moment().format('YYYY-MM-DD HH:mm:ss')
          val.end = val.end ?? val.start
        }
        this.dValue = val
      },
      deep: true
    },
    dValue: {
      handler (val) {
        if (val.start > val.end) {
          val.end = val.start
        }
        this.$emit('input', val)
        this.$emit('change', val)
      },
      deep: true
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.col-auto {
  padding-left: 8px;
  padding-right: 8px;
}
</style>
