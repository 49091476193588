import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/** Plugins, configs & utils */
import vuetify from '@/plugins/vuetify'
import i18n from '@/plugins/i18n'
import vuetifyDialog from '@/configs/vuetifyDialog'
import '@/plugins/validations'
import '@/plugins/directives'
import '@/plugins/websocket'
import '@/configs/nprogress'
import '@/configs/debug'
import Sentry from './plugins/sentry'

/** 3rd Party Packages */
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import VuePlyr from 'vue-plyr'
import VuetifyDialog from 'vuetify-dialog'

// Mixins
import locale from '@/mixins/global/locale'
import app from '@/mixins/global/app'
import user from '@/mixins/global/user'
import datetime from '@/mixins/global/datetime'
import StoreMixin from '@/mixins/global/StoreMixin'

/** Other */
import 'es6-promise/auto'

/** CSS */
import '../node_modules/@fortawesome/fontawesome-free/css/all.css'
import '../node_modules/vuetify-dialog/dist/vuetify-dialog.css'
import '@/assets/css/nprogress.css'
import '@/assets/css/main.css'
import '@/assets/css/ttc.css'
import '../node_modules/vue-plyr/dist/vue-plyr.css'
import '@/assets/css/bootstrap-val.css'

import { register } from 'swiper/element/bundle'
register()
Sentry.init(Vue)

// Plugins Registration
Vue.use(VuetifyDialog, vuetifyDialog)
Vue.use(VuePlyr)

/** Configuration */
Vue.prototype.$API_URL = import.meta.env.VITE_API_URL
window.API_URL = import.meta.env.VITE_API_URL
Vue.config.productionTip = false
Vue.config.performance = true

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)

// GLobal Mixins Registrations
Vue.mixin(locale)
Vue.mixin(app)
Vue.mixin(user)
Vue.mixin(datetime)
Vue.mixin(StoreMixin)

window.vue = new Vue({
  el: '#app',
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
})
