// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'press.public',
    path: 'subscription',
    name: 'Subscription',
    component: () => import('./press/PgMainPage.vue'),
    meta: { }
  },
  {
    portalGroup: 'press.my',
    path: 'subscription',
    name: 'MySubscription',
    component: () => import('./press/My/MySubscription.vue'),
    meta: { authenticated: true }
  },
  {
    portalGroup: 'press.my',
    path: 'subscription/:id',
    name: 'MySubscriptionDetail',
    component: () => import('./press/My/MySubscriptionDetail.vue'),
    meta: { authenticated: true }
  }

]
