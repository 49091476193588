import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
const module = {
  state: {
    inited: false,
    countries: []
  },
  getters: {
    getCountries: (state) => {
      return state.countries
    }
  },
  mutations: {
    SET_COUNTRIES (state, countries) {
      state.countries = countries
    }
  },
  actions: {
    getCountries ({ commit, state }) {
      if (state.inited) { return Promise.resolve() }
      state.inited = true
      return ApiUtils.postData({
        data: [
          { api: 'O0101CountriesService.getCountries' }
        ]
      }).then((data) => {
        commit('SET_COUNTRIES', data[0].output)
      })
    }
  }
}
export default ObjArrUtils.merge(Base, error, BREAD, module)
