// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'press.public',
    path: 'donation',
    name: 'Donation',
    component: () => import('./press/PgMainPage.vue'),
    meta: { authenticated: true }
  },
  {
    portalGroup: 'press.my',
    path: 'donation',
    name: 'MyDonationList',
    component: () => import('./press/My/MyDonation.vue'),
    meta: { authenticated: true }
  },
  {
    portalGroup: 'press.my',
    path: 'donation/create',
    name: 'CreateDonation',
    component: () => import('./press/My/CreateEditDonation.vue'),
    meta: { authenticated: true }
  },
  {
    portalGroup: 'press.my',
    path: 'donation/edit/:id',
    name: 'EditDonation',
    component: () => import('./press/My/CreateEditDonation.vue'),
    meta: { authenticated: true }
  }

]
