import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'
import DataUtils from '@/utils/DataUtils'

const module = {
  state: {
    data: null, // if null =loading, if [] = no data, if [{}] = data
    pagination: {
      current_page: 1,
      per_page: 10
    },
    stats: { },
    mainStats: {},
    query: {},
    loading: false
  },
  getters: {
    type: state => {
      return state.query._main_type[0]
    }
  },
  mutations: {
    setQuery (state, payload,init=false) {
      if(DataUtils.isSameObject(state.query, payload)) return

      state.query = payload
    }
  },
  actions: {
    async fullSearch ({ state,commit }, payload = null,init=false) {
      if (state.loading) return
      state.data = null
      state.loading = true

      if (payload) {
        commit('setQuery', payload,init)
      }

      const res = await ApiUtils.postData({
        data: {
          search: {
            api: 'C0101ProjectService.fullSearch',
            input: {
              query: state.query,
              pagination: {
                current_page: 1,
                per_page: 10
              }
            }
          }
        }
      })
      // console.log(res.search.output)
      const outputKeys = ['data', 'pagination', 'stats', 'mainStats']

      for (const key of outputKeys) {
        if (res.search.output[key]) {
          state[key] = res.search.output[key]
        }
      }
      state.loading = false
      return res.search.output
    },
    async nextPage ({ state }, payload) {
      if (state.loading) return
      state.loading = true
      const res = await ApiUtils.postData({
        data: {
          search: {
            api: 'C0101ProjectService.fullSearch',
            input: {
              query: state.query,
              pagination: {
                current_page: state.pagination.current_page + 1,
                per_page: state.pagination.per_page
              }
            }
          }
        }
      })
      state.pagination = res.search.output.pagination

      if (res.search.output.data) {
        state.data = state.data.concat(res.search.output.data)
      }
      state.loading = false
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
