// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'press.my',
    path: 'M4401SubscriptionUser/index',
    name: 'Subscription',
    component: () => import('./PgMainPage.vue'),
    meta: { debug: true }
  }
]
