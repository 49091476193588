import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    targetModel: 'M4001Donation',
    donationInfo: {},
    donationList: [],
    numberofDonation: 0

  },
  getters: {
  },
  mutations: {},
  actions: {
    async init ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            // api: 'M4001DonationService.getDonationByid',
            api: 'M4001DonationService.getAdd_press',
            input: { m0001_id: payload.id }
          }

        ]
      })
      state.donationList = res[0].output
      state.numberofDonation = res[0].output.length ?? 0
    },
    async initByid ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            // api: 'M4001DonationService.getDonationByid',
            api: 'M4001DonationService.getDonationByid',
            input: { m4001_id: payload.id }
          }

        ]
      })
      return res[0].output
    },
    async checkout ({ commit, state }, payload) {
      return await ApiUtils.postData({
        data: [
          {
            api: 'M4001DonationService.doAdd_press',
            input: payload
          }

        ]
      })
    },
    async delete ({ commit, state }, payload) {
      await ApiUtils.postData({
        data: [
          {
            api: 'M4001DonationService.doDeleteByid',
            input: payload
          }

        ]
      }).then(data => {
        state.donationList = data[0].output
      })
    },
    async save ({ commit, state }, payload) {
      return await ApiUtils.postData({
        data: [
          {
            api: 'M4001DonationService.doSave',
            input: payload
          }

        ]
      })
    },
    async duplicate ({ commit, state, dispatch }, payload) {
      await ApiUtils.postData({
        data: [
          {
            api: 'M4001DonationService.doDuplicateByid',
            input: payload
          }

        ]
      }).then((res) => {
        dispatch('init', { id: payload.m0001_id })
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
