// @ts-check

import vuetify from '@/plugins/vuetify'
import Router from 'vue-router'
import Vuex from 'vuex'

import i18n from '@/plugins/i18n'

export default {
  context: { vuetify, Router, Vuex, i18n },
  confirm: {
    actions: {
      false: { text: () => i18n.t('common.cancel') },
      true: { text: () => i18n.t('common.confirm') }
    }
  },
  prompt: {
    actions: {
      false: { text: () => i18n.t('common.cancel') },
      true: { text: () => i18n.t('common.confirm') }
    }
  },
  warning: {
    actions: [{ text: () => i18n.t('common.close') }]
  },
  error: {
    actions: [{ text: () => i18n.t('common.close') }]
  },
  info: {
    actions: [{ text: () => i18n.t('common.close') }]
  }
}
