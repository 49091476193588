import ApiUtils from '@/utils/api/ApiUtils'
import C4001Const from './C4001Const'

const StoreConst = C4001Const.storeConst
const store = {
  state: {
    init: {
      m0801_id: null,
      project_advise_question_title: null,
      project_advise_question_desc: {},
      m0601_id: 1,
      project_advise_tag: null
    },
    alert: {
      'collab.advise.fees.mainPrice': null,
      'collab.advise.fees.mainCoin': null,
      'collab.advise.fees.exchangeAmount': null,
      'collab.advise.fees.exchangeCoin': null
    }
  },
  getters: {
    getInit: state => state.init,
    getAlert: state => state.alert
  },
  mutations: {
    SET_ALERT (state, payload) {
      state.alert = payload
    }
  },
  actions: {
    async doBrowse ({ commit, state }) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.doBrowse.api, input: val },
          { api: StoreConst.ACTION.getAlert.api }
        ]
      })
      commit('SET_ROWS', res[0].output.data)
      commit('SET_PAGINATION', res[0].output.pagination)
      commit(StoreConst.PROPERTY.alert.set, res[1].output, { root: true })
      return res
    },
    async doEdit ({ state }, payload) {
      return await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doEdit.api, input: { ...payload, project_advise: state.rows.filter(row => row !== state.init) } }]
      })
    }
  }
}

export default C4001Const.getStore(store)
