<template>
  <div
    v-if="cValue"
    v-html="cValue"
  />
  <div v-else>
    <!-- no content UI -->
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  computed: {
    cValue () {
      if (this.value === 'MoBlockViewDummyData') { return '<div class="co-block"><h2 id="lv3G4j-EUG" class="co-header">1: The Birth of Incubation Platforms</h2></div><div class="co-block"><p class="co-paragraph"><i>Incubation platforms</i>, often known as startup incubators, have become an integral part of the entrepreneurial ecosystem. These platforms have evolved significantly since their inception, transforming into hubs of innovation and collaboration. In their earliest form, incubators were primarily associated with technology and biotech startups. They were typically set up by universities, government agencies, or private organizations to support early-stage companies by providing them with office space, funding, and mentorship.</p></div><div class="co-block"><img class="co-inline-image" src="https://images.unsplash.com/photo-1425082661705-1834bfd09dca?crop=entropy&amp;cs=srgb&amp;fm=jpg&amp;ixid=Mnw0MzQwNDF8MHwxfHNlYXJjaHwyfHxjdXRlfGVufDB8fHx8MTY4MTk3MDkxMw&amp;ixlib=rb-4.0.3&amp;q=85" /><div class="co-inline-image-credits"><span>by </span><a href="https://unsplash.com/@sweetmangostudios" target="_blank">Ricky  Kharawala</a><span> on </span><a href="https://unsplash.com/?utm_source=m27_ttc&utm_medium=referral" target="_blank">Unsplash</a></div><div class="co-inline-image-caption"></div></div>' }
      return this.value
    }
  }
}
</script>

<style>
.co-block {
  flex: 0 0 auto;
  width: 100%;
  margin-top: 1.5rem;
  padding: 0px;
}

.co-header {
  font-size: 18px;
  line-height: 1.2em;
  font-weight: 600;
}

.co-paragraph {
  font-size: 16px;
  color: var(--v-light-subText-base) !important;
  font-weight: 500;
  margin-bottom: 1rem;
}

.co-link {
  color: var(--v-light-primary-base);
  font-weight: 500;
  font-size: 16px;
  word-wrap: break-word;
}

.co-link-image {
  width: 120px !important;
  /* margin-top: 3rem;
  margin-bottom: 3rem; */
}

.co-link-title {
  font-size: 20px;
  line-height: 1.2em;
  font-weight: 600;
}

.co-link-description {
  font-size: 16px;
  color: var(--v-light-subText-base) !important;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.co-link-anchor {
  font-weight: 500;
  font-size: 16px;
  color: var(--v-light-primary-base);
}

.co-list {
  padding: 0 1.5rem;
  font-weight: 500;
  font-size: 16px;
  color: var(--v-light-subText-base);
  margin-top: 1.5rem;
}

.co-list-item {
  font-weight: 500;
  font-size: 16px;
  color: var(--v-light-subText-base);
}

.co-code {
  width: 100%;
  max-height: 300px;
  padding: 0.25rem;
  border: 1px solid #E8E8E8;
  border-radius: 10px;
}

.co-embed{
  width: 50%;
  aspect-ratio: 16/9;
  border-radius: 20px;
  padding: 0.25rem;
  margin: auto 25%;
}

.co-table {
  width: 100%;
  height: auto;
}

/* .co-table-row {

} */

.co-table table,
.co-table th,
.co-table td {
  border-top: 2px solid #E8E8E8;
  border-bottom: 2px solid #E8E8E8;
  /* border: 2px dashed var(--v-press-light-primary-base); */
  padding: 10px;
}

.co-table th {
  /* text-align: center; */
  color: var(--v-light-mainText-base);
}

.co-table td {
  color: var(--v-light-subText-base);
}

.co-checklist,
.co-checklist .form-check-input {
  display: flex !important;
  align-items: center;
  gap: 10px;
  margin: unset;
}

.co-checklist.form-check-input {
  box-shadow: inset 1px 5px 5px #dddddd, inset -1px -5px 10px var(--v-light-white-base);
  border: solid 1px #BEBEBE;
}

.co-checklist-checkbox.form-check-input:checked[type=checkbox] {
  background-color: #AE9D80;
  box-shadow: unset;
  border: unset;
}

/* .co-checklist-checkbox--checked {

} */

.co-checklist-text {
  font-weight: 600 !important;
  font-size: 16px;
  color: var(--v-light-mainText-base);
}

.co-warning {
  /* border-left: 4px solid #ff0000;
  padding-left: 1rem!important;
  color: #ff0000; */
  background-color: var(--v-error-base);
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  padding: 8px 20px;
  flex-wrap: wrap;
}

.co-warning i.fa-solid.fa-triangle-exclamation {
  font-size: 45px;
  color: var(--v-error-lighten5);
}

.co-warning-title {
  color: var(--v-error-lighten5);
  font-size: 20px;
  font-weight: 700;
}

.co-warning-message {
  color: var(--v-error-lighten5);
  font-size: 16px;
}

.co-quote {
  border-left: 4px solid #b2a285;
  padding-left: 1rem !important;
}

.co-quote-text {
  font-weight: 600;
  font-size: 30px;
  color: var(--v-primary-base);
  margin-bottom: 1rem;

}

.co-quote-caption {
  font-weight: 600;
  font-size: 16px;
  color: var(--v-light-subText-base);
}

.co-delimiter {
  content: "";
  width: 100%;
  border-bottom: solid 2px #E8E8E8;
}

.co-image {
  aspect-ratio: 16/9;
  object-fit: cover;
  width: 40%;
  border-radius: 20px;
  padding: 0.25rem;
  margin: auto;
}

.co-image-caption {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.co-inline-image {
  width: 40%;
  border-radius: 20px;
  padding: 0.25rem;
  margin: auto 30%;
}

.co-inline-image-credits {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
}

.co-inline-image-caption {
  margin-top: 1rem;
  font-size: 16px;
  font-weight: 400;
}
</style>
