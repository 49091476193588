export default [
  {
    portalGroup: 'master.public',
    path: 'faq',
    name: 'FAQList',
    component: () => import('./M5001FAQList.vue')
  },
  {
    // For Affiliate FAQ
    portalGroup: 'master.public',
    path: 'faq/membership.join.affiliate',
    name: 'FAQAffiliate',
    component: () => import('@/app/M5101Affiliate/PgAffiliateFAQ.vue')
  },
  {
    portalGroup: 'master.public',
    path: 'faq/:slug/',
    name: 'FAQ',
    component: () => import('./PgM5001View.vue')
  },
  {
    portalGroup: 'admin.admin',
    path: 'faq/creator',
    name: 'FAQCreator',
    component: () => import('./comps/FAQCreater.vue')
  }
]
