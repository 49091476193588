<template>
  <v-combobox
    v-model="vModel"
    :items="userTitleList"
    v-bind="$attrs"
    :menu-props="{
      closeOnContentClick: true,
      value: input !== ''
    }"
    @keydown="searchUserTitle"
  >
    <template
      v-if="isSearching"
      #append
    >
      <v-progress-circular indeterminate />
    </template>
    <template
      v-if="userTitleList.length && isSearching === false && !userTitleList.includes(input)"
      #append-item
    >
      <v-divider />
      <v-list-item
        link
        @click="pushTag"
      >
        {{ $t('components.TTCEnterpriseUserTitleField.propose', { tag: input }) }}
      </v-list-item>
    </template>
    <template
      v-else
      #no-data
    >
      <v-list-item
        v-if="isSearching === false && input !== ''"
        link
        @click="pushTag"
      >
        {{ $t('components.TTCEnterpriseUserTitleField.propose', { tag: input }) }}
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'TTCEnterpriseUserTitle',

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  data () {
    return {
      input: '',
      isSearching: false,
      userTitleList: []
    }
  },
  computed: {
    vModel: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    searchUserTitle: debounce(function (event) {
      this.isSearching = true
      this.input = event ? event.target.value : ''
      this.storeApiDispatch('IndividualProfile/getEnterpriseUserTitle', { term: this.input }, { loading: false }).then((data) => {
        this.userTitleList = data.map(item => item.enterprise_user_title)
        this.isSearching = false
      })
    }, 500),

    pushTag () {
      this.vModel = this.input
    }
  }
}
</script>
