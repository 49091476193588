<template>
  <ValidationObserver
    ref="observer"
    v-slot="{invalid,handleSubmit}"
  >
    <v-form
      ref="form"
      v-model="fromIsValid"
      @submit.prevent="handleSubmit(buttonOnClick('submit',invalid)) "
    >
      <p>
        {{ label }}
      </p>
      <v-row
        v-for="(row, i) in fields"
        :key="'form_element_'+i"
      >
        <template
          v-for="(col, fieldKey) in row"
        >
          <div
            v-if="appAllowAccess(col.authenticated ? col.authenticated : false, col.roles ? col.roles : [], col.permissions ? col.permissions : [])"
            :key="fieldKey"
            :class="col.column + ' py-1'"
          >
            <input-rules-wrapper
              :rules="col.rules"
            >
              <template #default="{ on, attrs }">
                <div v-if="col.type === 'section'">
                  <h2 :class="col.class">
                    {{ col.title }}
                  </h2>
                  <v-divider />
                </div>
                <component
                  :is="col.component"
                  v-else-if="checkRegisteredComps(col.component)"
                  v-model="fieldModel[fieldKey]"
                  v-bind="{...col.props, ...attrs}"
                  v-on="{...on}"
                  @keyup="focusNext"
                  @upload-done="uploadDone = $event"
                >
                  <template
                    v-for="slot in scopedSlotNamesOnly"
                    #[fieldSlotName(slot,fieldKey)]="scope"
                  >
                    <slot
                      :name="slot"
                      v-bind="scope"
                    />
                  </template>
                  <template
                    v-for="slot in slotNamesOnly"
                  >
                    <slot
                      :slot="fieldSlotName(slot,fieldKey)"
                      :name="slot"
                    />
                  </template>
                </component>
                <slot
                  v-else
                  :name="col.component"
                  :value="fieldModel[fieldKey]"
                  :props="{ ...attrs,...col.props}"
                  :update="(event)=>{$set(fieldModel, fieldKey, event)}"
                />
              </template>
            </input-rules-wrapper>
          </div>
        </template>
      </v-row>
      <v-card-actions>
        <ActionButton
          v-if="mergeButton('cancel').show"
          v-bind="mergeButton('cancel').props"
          @click="buttonOnClick('cancel')"
        />
        <ActionButton
          v-if="mergeButton('clear').show"
          v-bind="mergeButton('clear').props"
          @click="buttonOnClick('clear')"
        />
        <ActionButton
          v-if="mergeButton('reset').show"
          v-bind="mergeButton('reset').props"
          @click="buttonOnClick('reset')"
        />
        <v-spacer />
        <ActionButton
          v-if="mergeButton('submit').show"
          v-bind="mergeButton('submit').props"
          type="submit"
        />
      </v-card-actions>
    </v-form>
  </ValidationObserver>
</template>

<script>
import DataUtils from '@/utils/DataUtils'
import ActionButton from '@/components/base/molecules/ActionButton/ActionButton.vue'
import i18n from '@/plugins/i18n'
import InputRulesWrapper from '../InputRulesWrapper.vue'
import * as Input from '@/components/base/atoms/input'
import * as CustomInput from '@/components/base/atoms/input/indexCustom'

const AtFormUtils = {
  get compList () {
    return {
      VAutocomplete: () => import('vuetify/lib/components/VAutocomplete'),
      VCombobox: () => import('vuetify/lib/components/VCombobox'),
      VRangeSlider: () => import('vuetify/lib/components/VRangeSlider'),
      VSlider: () => import('vuetify/lib/components/VSlider'),
      VSelect: () => import('vuetify/lib/components/VSelect'),
      VTextarea: () => import('vuetify/lib/components/VTextarea'),
      ...Input,
      // add custom Component Here (For Current Project)
      ...CustomInput
    }
  },
  genField (type, key, obj, globalProps) {
    const presetList = {
      default: {
        column: 'col-md-4',
        component: 'AtTextField',
        props: {
          label: key
        }
      },
      section: {
        column: 'col-12',
        component: 'H2',
        title: key
      },

      // add preset comps here
      category: {
        column: 'col-md-12',
        component: 'TTCCategoryField',
        props: {
          label: i18n.t('components.TTCCategoryField.category'),
          outlined: true,
          items: []
        },
        async: [{
          api: 'M0601CategoryService.doBrowse',
          input: {
            category_key: 'GENERAL'
          },
          mutateTarget: 'props.items'
        }]
      },

      tags: {
        column: 'col-md-12',
        component: 'TTCTagField',
        props: {
          label: i18n.t('components.TTCTagField.tags'),
          outlined: true,
          chips: true,
          multiple: true,
          counter: 10
        }
      },

      price: {
        column: 'col-md-12',
        component: 'TTCPriceField',
        props: {
          coinACurrencyList: []
        },
        async: [{
          api: 'M1902CoinsTypeCurrencyService.GetACoinCurrency',
          mutateTarget: 'props.coinACurrencyList'
        }]
      }
    }

    let targetPreset = presetList[type]
    if (targetPreset === undefined) {
      console.warn(`AtForm Preset Not Found ${type}, use default preset`)
      targetPreset = presetList.default
    }

    const def = DataUtils.mergeObjects(targetPreset, obj)
    // apply global props
    def.props = DataUtils.mergeObjects(def.props, globalProps)
    return def
  }
}

/**
 * @function resetValidation - reset validation
 * @return void
 * 
 * @function validate - validate form, return promise with boolean
 * @return Promise<boolean>
 * 
 * @function submit - submit form, return Object with form data
 * @return Promise<Object>
 * 
 * @function reset - reset form, return Object with form data (start value)
 * @return Object
 * 
 * @function clear - clear form, return Object with form data (empty value)
 * @return Object
 * 
 * @function cancel - cancel form
 * @return void
 * 
 * emit event
 * @event input - emit form data
 * @event onSubmit
 * @event onCancel
 * @event onClear
 * @event onReset
 * @event activeInputsNumber - number of active input
 */
export default {
  components: {
    InputRulesWrapper,
    ...AtFormUtils.compList,
    ActionButton
  },
  props: {
    /**
     * AtForm submit data
     * @typedef {Row[]} value
     * @property {any} {string} - field key {@link Row}
     */
    value: {
      type: Object,
      default: () => { return {} }
    },
    /**
     * Main Definitions
     * @typedef {Row[]} def
     * 
     * Every element in def mean every row
     * @typedef {Object} Row
     * @property {Field} {string} - dynamic property key mean key name for form output
     * 
     * The field data
     * @typedef {Object} Field
     * @property {String} [column] - The class string of field. This should be in the format 'col-md-X', where X is the width of the column.
     * @property {String} [component] - The name of the component to be used for this field. This should be a string, such as 'AtTextField'.
     * @property {Boolean} [authenticated] - A boolean value indicating whether this field requires authentication to access. 
     * @property {String[]} [roles] - An array of roles that are allowed to access this field.
     * @property {String[]} [permissions] - An array of permissions that are required to access this field.
     * @property {AsyncData[]} [async] - An array of {@link AsyncData} objects that are used to mutate this field data.
     * @property {String|Function|String[]|Function[]} [rules] - The rules to be applied to this field. {@see InputRulesWrapper}
     * @property {Any} [clear] - The value to set this field to when the clear button is clicked.
     * @property {Object} [props] - An object containing additional properties for this field.
     * 
     * The AsyncData class is used to store and manage the data and input for an async API call.
     * @typedef {Object} AsyncData
     * @property {string} api - The API key used for the async call.
     * @property {object} input - The input for the async API call.
     * @property {string} mutateTarget - The nested key for mutating the data before being rendered.
     * @property {function} callback - Used to handle the response data and perform any necessary post-processing operations.
     * @example
     * {
     *    api : 'E0001DemoPostService.tempTest',
     *    input : {},
     *    mutateTarget: 'props.items',
     *    callback: () => {}
     * }
     */
    def: {
      type: Array,
      required: true
    },
    /**
     * The form title
     * @typedef {String} title
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Apply sparse properties to all input fields
     * (Some input may not have any sparse properties)
     * @typedef {Object} globalProps
     */
    globalProps: {
      type: Object,
      default: () => { return { outlined: true } }
    },
    /**
     * 
     * @typedef {(Object|Boolean)} cancelBtn
     * @property {Boolean} show -
     * @property {Object} props - 
     */
    cancelBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    /**
     * 
     * @typedef {(Object|Boolean)} clearBtn
     * @property {Boolean} show - 
     * @property {Object} props - 
     */
    clearBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    /**
     * 
     * @typedef {(Object|Boolean)} resetBtn
     * @property {Boolean} show - 
     * @property {Object} props - 
     */
    resetBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    /**
     * 
     * @typedef {(Object|Boolean)} submitBtn
     * @property {Boolean} show - 
     * @property {Object} props - 
     */
    submitBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    promptOnRouteChange: {
      type: Boolean,
      default: false
    },
    promptOnRefresh: {
      type: Boolean,
      default: false
    },
    /**
     * return undefiend when value is empty
     */
    undefinedOnEmpty: {
      type: Boolean,
      default: false
    },
    mainchange: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      /**
       * store {@link value} onMounted
       * 
       */
      initValue: {},
      /**
       * default config 4 buttons:
       * {@link cancelBtn}
       * {@link clearBtn}
       * {@link resetBtn}
       * {@link submitBtn}
       */
      defaultBtn: {
        cancel: {
          show: false,
          props: { class: 'pa-1', color: 'warning', preset: 'cancel', outlined: true },
          showPrompt: { toggleOnDirty: true, toggleOnNotDirty: true, label: this.$t('components.confirmPrompt.cancelForm') },
          callback: (fieldModel, initValue) => { return true }
        },
        clear: {
          show: true,
          props: { class: 'pa-1', color: 'red', preset: 'clear', outlined: true },
          showPrompt: { toggleOnDirty: true, toggleOnNotDirty: true, label: this.$t('components.confirmPrompt.clearForm') },
          callback: (fieldModel, initValue) => { return true }
        },
        reset: {
          show: true,
          props: { class: 'pa-1', color: 'orange', preset: 'reset', outlined: true },
          showPrompt: { toggleOnDirty: true, toggleOnNotDirty: true, label: this.$t('components.confirmPrompt.resetForm') },
          callback: (fieldModel, initValue) => { return true }
        },
        submit: {
          show: true,
          props: { class: 'pa-1', color: 'primary', preset: 'submit', outlined: false },
          showPrompt: { toggleOnDirty: false, toggleOnNotDirty: false, label: this.$t('components.confirmPrompt.submitForm') },
          callback: (fieldModel, initValue) => { return true }
        }
      },
      /**
       * data for {@link value}
       */
      fieldModel: {},
      /**
       * data for {@link def}
       */
      fields: [],
      /**
       * List Of {@link AsyncData }
       * @typedef {AsyncData[]} asyncDataList
       */
      asyncDataList: [],
      /**
       * List Output Of {@link AsyncData }
       * @typedef {any[]} outputData
       */
      outputData: [],
      /**
       * For V-form validation
       */
      fromIsValid: false,
      uploadDone: true
    }
  },
  computed: {
    slotNamesOnly () {
      return Object.keys(this.$scopedSlots).filter(slot => Object.keys(this.$slots).includes(slot))
    },
    scopedSlotNamesOnly () {
      return Object.keys(this.$scopedSlots).filter(slot => !Object.keys(this.$slots).includes(slot))
    }
  },
  watch: {
    value: {
      handler (val, oldVal) {
        if (DataUtils.isSameObjectSimple(val, oldVal)) return
        this.setValue(val)
      },
      deep: true,
      immediate: true
    },
    fieldModel: {
      handler (val, oldVal) {
        if (this.promptOnRouteChange) {
          if (!DataUtils.isSameObjectSimple(val, oldVal)) {
            this.$store.state.promptOnRouteChange = this.$t('components.confirmPrompt.cancelForm')
          } else {
            this.$store.state.promptOnRouteChange = undefined
          }
        }
        if (this.undefinedOnEmpty) {
          Object.keys(val).forEach(key => {
            // if value is '',null,[]
            if (val[key] === '' || val[key] === null || val[key] === []) {
              delete val[key]
            }
          })
        }
        const activeInputsNumber = Object.keys(val).filter(key => this.fields.find(field => field[key])).length
        this.$emit('activeInputsNumber', activeInputsNumber)
        this.$emit('input', val)
      },
      deep: true
    },
    def: {
      handler (val, oldVal) {
        if (DataUtils.isSameObject(val, oldVal)) return

        let temp = val

        // STEP: handle diff format
        if (!Array.isArray(temp)) { throw new Error('def must be an array') }

        // if like this -> [{data:'d1'},{data:'d2'}]
        if (!temp.every(row => Object.values(row).every(col => typeof col === 'object'))) {
          temp = [temp]
        }
        // if like this -> [[{data:'d1'},{data:'d2'}]]
        if (Array.isArray(temp[0]) && typeof temp[0][0] === 'object') {
          // convert + Validation
          temp = temp.map((row) => {
            const obj = {}
            if (!Array.isArray(row)) { throw new Error('input format is invalid') }
            row.forEach((element) => {
              if (typeof element !== 'object') { throw new Error('input format is invalid') }
              if (typeof element.data === 'undefined') { element.data = '_' + DataUtils.randomString(5) }
              obj[element.data] = element
            })
            return obj
          })
        }

        // final format must be [{d1:{},d2:{}}]

        const asyncDataList = []
        const inputData = []
        for (let i = 0; i < temp.length; i++) {
          const row = temp[i]
          for (const key in row) {
            row[key] = AtFormUtils.genField(row[key].type ?? 'default', key, row[key], this.globalProps)

            // handle async 
            if (typeof row[key].async !== 'undefined') {
              row[key].async.forEach(element => {
                asyncDataList.push({ ...element, mutateTarget: [i, key, ...element.mutateTarget.split('.')] })
                inputData.push({ api: element.api, input: element.input ?? null })
              })
            }
          }
        }
        this.fields = temp
        // if async data no change, use saved data
        if (DataUtils.isSameObject(asyncDataList, this.asyncDataList)) {
          for (let i = 0; i < asyncDataList.length; i++) {
            const mutateTarget = asyncDataList[i].mutateTarget
            const resData = this.outputData[i].output

            this.fields = DataUtils.setValue(this.fields, mutateTarget, resData)
          }
        } else {
          // if async data changed request agian
          this.apiDispatch(inputData).then((res) => {
            for (let i = 0; i < asyncDataList.length; i++) {
              const mutateTarget = asyncDataList[i].mutateTarget
              let resData = res[i].output

              // extra post-processing operations
              if (asyncDataList[i].callback) {
                resData = asyncDataList[i].callback(resData)
              }

              this.fields = DataUtils.setValue(this.fields, mutateTarget, resData)
            }
            this.asyncDataList = asyncDataList
            this.outputData = res
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  mounted () {
    this.setValue(this.value)
    this.setInitValue(this.value)
    // console.log('slotNamesOnly', this.slotNamesOnly)
    // console.log('scopedSlotNamesOnly', this.scopedSlotNamesOnly)
  },
  methods: {
    setValue (val) {
      this.fieldModel = DataUtils.jsonCopy(val)
    },
    setInitValue (val) {
      this.initValue = DataUtils.jsonCopy(val)
    },
    // check slot is for field
    fieldSlotName (name, key) {
      // split the slot name by the key and get the second part
      if (!this.isFieldSlot(name, key)) return
      return name.split(key + '_')[1]
    },
    isFieldSlot (name, key) {
      // most of the time, name is defined
      if (!name || !key) return false

      // check if the slot name starts with the provided key
      if (!name.startsWith(key + '_')) return false

      return true
    },
    // before Reload page
    beforeWindowUnload (e) {
      if (!this.promptOnRefresh) return
      if (this.$store.state.promptOnRouteChange) {
      // Cancel the event

        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
        return e.returnValue
      }
    },
    checkRegisteredComps (compName) {
      compName = compName.split('/')
        .map(snake => snake.split('-').map(substr => substr.charAt(0).toUpperCase() + substr.slice(1)).join(''))
        .join('/')
      // found local components
      if (compName in AtFormUtils.compList) { return true }

      // found slot components
      if (compName in this.$scopedSlots) { return false }

      console.error('Not registered components: ' + compName)
      return false
    },
    resetValidation () {
      this.$refs.observer.reset()
      this.$refs.form.resetValidation()
    },
    async validate () {
      const isValid = await this.$refs.observer.validate()
      const isValid2 = this.$refs.form.validate()
      return isValid && isValid2
    },
    async submit () {
      if (!this.uploadDone) {
        this.$dialog.notify.error(this.$t('components.fileIsUploading'))
        return
      }
      if (!await this.validate()) {
        this.$dialog.notify.error(this.$t('components.AtForm.formInvalid'))
        return
      }
      this.$emit('onSubmit', DataUtils.jsonCopy(this.fieldModel))
      return DataUtils.jsonCopy(this.fieldModel)
    },
    reset () {
      this.resetValidation()
      this.fieldModel = DataUtils.jsonCopy(this.initValue)
      this.$emit('onReset', DataUtils.jsonCopy(this.initValue))
      return DataUtils.jsonCopy(this.initValue)
    },
    clear () {
      this.resetValidation()
      this.$refs.form.reset()
      this.fields.forEach(row => {
        for (const key in row) {
          this.fieldModel[key] = row[key].clear ?? undefined
        }
      })
      this.$emit('onClear', DataUtils.jsonCopy(this.fieldModel))
      return DataUtils.jsonCopy(this.fieldModel)
    },
    cancel () {
      this.$emit('onCancel', DataUtils.jsonCopy(this.initValue))
    },
    buttonOnClick (type, payload = null) {
      const curBtn = this.mergeButton(type)

      // callback
      if (!this.btn_callbackHandler(curBtn.callback)) return

      // showPrompt
      if (!this.btn_showPromptHandler(curBtn.showPrompt)) return

      const btnActionList = {
        submit: this.submit,
        reset: this.reset,
        clear: this.clear,
        cancel: this.cancel
      }
      // if type is not in the list, throw error
      if (!(type in btnActionList)) {
        console.error('InvalidBtnFunction: ' + type)
        return
      }

      // run btn function
      btnActionList[type](this, payload)
    },
    // handle showPrompt key for button
    // return true for pass confirm prompt
    // return false for not pass confirm prompt
    btn_showPromptHandler (obj) {
      const isDirty = !DataUtils.isSameObject(this.initValue, this.fieldModel)

      // if showPrompt is not defined, return true
      if (obj === undefined) return true

      // if showPrompt is Object, check toggleOnDirty and toggleOnNotDirty
      if ((obj.toggle && isDirty) ||
        (obj.toggleOnDirty && isDirty) ||
        (obj.toggleOnNotDirty && !isDirty)) {
        return window.confirm(obj.label)
      }

      return true
    },
    // handle callback key for button
    // function callback(fieldModel, initValue)
    btn_callbackHandler (callback) {
      if (callback === undefined) return true
      return callback(DataUtils.jsonCopy(this.fieldModel), DataUtils.jsonCopy(this.initValue))
    },
    mergeButton (type) {
      const currentButton = this[type + 'Btn']
      let defaultBtn = this.defaultBtn[type]
      if (typeof currentButton === 'boolean') {
        defaultBtn.show = currentButton
      } else {
        defaultBtn = DataUtils.mergeObjects(defaultBtn, currentButton)
      }
      return defaultBtn
    },
    focusNext (keyboardEvent) {
      if ((keyboardEvent?.keyCode === 13 || keyboardEvent?.keyCode === 40) && keyboardEvent?.target?.form) {
        let currentIndex = Array.from(keyboardEvent.target.form).indexOf(keyboardEvent.currentTarget)

        while (true) {
          currentIndex++
          if (currentIndex >= keyboardEvent.target.form.length) {
            return
          }
          const nextElement = keyboardEvent.target.form[currentIndex]

          if (nextElement.tagName === 'FIELDSET') continue
          if (nextElement.tagName === 'BUTTON') continue
          nextElement.focus()
          break
        }
      }
    }
  }
}

// ! disable cause of callback cannot run `this`
// fieldsIterator (callback) {
//   if (!(callback && (typeof callback === 'function'))) throw 'Must be function'
//   this.fields.forEach(row => {
//     for (const key in row) {
//       callback(row, key)
//     }
//   }, this)
// },
</script>

<style>

</style>
