import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    showDialog: false,
    relModel: null,
    relInteraction: null
  },

  getters: {
    getShowDialog: state => state.showDialog,
    getRelModel: state => state.relModel,
    getRelInteraction: state => state.relInteraction
  },

  mutations: {
    SET_SHOW_DIALOG (state, payload) {
      state.showDialog = payload
    }
  },

  actions: {
    showInteractionDialog: ({ commit, state }, payload) => {
      const { relModel, relInteraction } = payload
      state.relModel = relModel
      state.relInteraction = relInteraction
      state.showDialog = true
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
