import BaseDataStore from '@/components/base/store/BaseDataStoreV1'

const BaseConst = {
  version: 1,
  storeName: 'M3901Guide',
  serviceName: 'M3901GuideService',
  storeConst: {
    ACTION: {
      getHint: {
        store: 'M3901Guide/getHint',
        api: 'M3901GuideService.getGuide'
      }
    }
  }
}

const M3901Const = new BaseDataStore(BaseConst)
export default M3901Const
