// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
// import ApiUtils from '@/utils/api/ApiUtils'

// TODO: NEED TIN CONFIMATION
// TODO: update `text` zh_tw

// NEED TIN CONFIRMATION, diff with other tXXXX status
// 'DRAFT','PUBLISHED','ARCHIVED','DELETED','REMOVED'
const statusMap={
  DRAFT: {
    text: '草稿',
    color: 'grey',
    topBtnList: ['moreSetting','preview', 'submitForApproval'],
    editBtnList: ['preview', 'submitForApproval','doSave'],
    canEdit: true, // got `doSave` button == canEdit,
    showTabsList: ['edit'],
  },
}


function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}


const PageConst= {
  FORM_DEF: {
    // if cannot use dont use
    // editDef: [
    //   { name: 'name', label: 'Name', type: 'text', required: true },
    //   { name: 'description', label: 'Description', type: 'textarea', required: false }
    // ]
  },
  SEARCH_DEF: {
    inputDef: [
      // {
      //   column: 'col-8',
      //   data: 'job_posting_requirement_title',
      //   type: 'text',
      //   label: '標題',
      //   props: {
      //     clearable: true
      //   }
      // },
      // {
      //   column: 'col-4',
      //   data: 'job_posting_requirement_status',
      //   type: 'autoComplete',
      //   label: '狀態',
      //   props: {
      //     items: getMapArray(statusMap),
      //     itemText: 'text',
      //     itemValue: 'value',
      //     clearable: true
      //   }
      // },
    ],
    sortDef:[
      // {
      //   text: '最近更新',
      //   value: {order_updated_at: 'desc'}
      // },
      // {
      //   text: '最早更新',
      //   value: {order_updated_at: 'asc'}
      // },
      {
        text: '最近',
        value: {order_created_at: 'desc'}
      },
      // {
      //   text: '最早',
      //   value: {order_created_at: 'asc'}
      // },
    ],
  },
  LIST_DEF: {
    // tableDef: [
    // { name: 'name', label: 'Name', align: 'left', sortable: true },
    // { name: 'description', label: 'Description', align: 'left', sortable: false }
    // ]
  },
  ACTION: {
    // doAdd: { api: 'T1001JobPostingRequirementService.doAdd_creator'},
    // getEdit: { api: 'T1001JobPostingRequirementService.getEdit_creator'},
    // doEdit: { api: 'T1001JobPostingRequirementService.doEdit_creator'},
    // doRead: { api: 'T1001JobPostingRequirementService.doRead_creator'},
    // doDelete: { api: 'T1001JobPostingRequirementService.doDelete_creator'},
    doBrowse: { api: 'M2001CoinsService.doBrowse_transactionHistory'},

  },
}

const module = {
  state: {
    PageConst,
    statusMap,
    statusMapArray: getMapArray(statusMap),

    // set default here
    searchInfo:{
    },
    // set default here
    orderBy:PageConst.SEARCH_DEF.sortDef[0].value,
  },

  getters: {

  },

  mutations: {

  },
  actions: {
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
