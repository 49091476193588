import ApiUtils from '@/utils/api/ApiUtils'
import P0101Const from './P0101Const'
// import { object } from 'dot-object'

// TODO: can i know what storeConst properties are available? (using intelisense)
const StoreConst = P0101Const.storeConst
const store = {
  state: {
    // custom state
    searchInfo: { status: 'DRAFT' },
    stats: {}
  },
  getters: {
    // custom getter
    getStats: (state) => state.stats,
    getMyProp: (state) => state.myProp
  },
  mutations: {
    // custom mutation
    SET_MY_PROP (state, payload) {
      state.myProp = payload
    },
    SET_SEARCH_STATE (state, payload) {
      state.searchState = payload
    },
    SET_COMMENTS (state, payload) {
      state.getComments = payload
    },
    SET_STATS (state, payload) {
      const stats = {}
      if (Array.isArray(payload)) {
        payload.forEach(row => {
          stats[row.name] = row.count
        })
        state.stats = stats
      }
    }
  },
  actions: {
    // override action  
    async doBrowse ({ commit, state }, payload) {
      let searchApi = {}
      if (state.searchInfo.status === 'COMMENT') {
        const val = {
          searchInfo: state.searchInfo,
          orderBy: state.orderBy,
          pagination: state.pagination
        }
        searchApi = { api: StoreConst.ACTION.doBrowseComments.api, input: val }
      } else {
        const val = {
          searchInfo: state.searchInfo,
          orderBy: state.orderBy,
          pagination: state.pagination
        }
        searchApi = { api: StoreConst.ACTION.doBrowse.api, input: val }
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.getStats.api, input: null },
          searchApi
        ]
      })
      commit('SET_STATS', res[0].output)
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
      // commit('SET_COMMENTS', res[0].output)
    },
    async doBrowsemainstate ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      console.log('val', val)

      const res = await ApiUtils.postData({
        // Do NOT use "val" for "input (Refer to Postman)"
        data: [{ api: StoreConst.ACTION.doBrowsemainstate.api, input: null }]
      })

      commit('SET_SEARCH_STATE', res[0].output)
    },
    async getStats ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.getStats.api, input: null }]
      })
      commit('SET_STATS', res[0].output)
    },
    async doBrowseComments ({ commit, state }, payload) {
      // Need to change Val (Refer to Postman)
      const val = {
        searchInfo: {},
        orderBy: []
      }
      console.log('val', val)

      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doBrowseComments.api, input: val }]
      })
      commit('SET_COMMENTS', res[0].output)
    },
    async doBrowseVersion ({ commit, state }, payload) {
      console.log('doBrowseVersion')
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      console.log('val', val)
      console.log('api', StoreConst.ACTION.doBrowseVersion.api)
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doBrowseVersion.api, input: val }]
      })
      commit('SET_ROWS', res[0].output.data)
      commit('SET_PAGINATION', res[0].output.pagination)
      console.log(res)
    },
    // custom action
    myAction ({ commit, state }, payload) {
      commit('SET_MY_PROP', payload)
    }
  }
}
export default P0101Const.getStore(store)
