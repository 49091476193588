// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'admin.public',
    path: 'E0001DemoPost/list',
    name: 'PgList',
    component: () => import('./PgList.vue'),
    meta: { debug: true }
  },
  {
    portalGroup: 'admin.admin',
    path: 'E0001DemoPost/view/:id',
    name: 'PgView',
    component: () => import('./PgView.vue'),
    meta: { debug: true }
  },
  {
    portalGroup: 'admin.admin',
    path: 'E0001DemoPost/form/:id?',
    name: 'PgForm',
    component: () => import('./PgForm.vue'),
    meta: { debug: true }
  },
  {
    portalGroup: 'admin.admin',
    path: 'E0001DemoPost/index',
    name: 'PgMultiPage',
    component: () => import('./PgMultiPage.vue'),
    meta: { debug: true }
  },
  {
    portalGroup: 'admin.admin',
    path: 'E0001DemoPost/single',
    name: 'PgSinglePage',
    component: () => import('./PgSinglePage.vue'),
    meta: { debug: true }
  },
  {
    portalGroup: 'press.public',
    path: 'E0001DemoPost/dataImport',
    name: 'E0001DemoPostDataImport',
    component: () => import('./PgDataImport.vue')

  }
]
