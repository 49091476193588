<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
    attach
    :content-class="'v-date-picker-menu'"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        attach
        prepend-icon="mdi-calendar"
        readonly
        v-bind="{
          ...{
            placeholder: '(Month, Year)',
            outlined: true
          },
          ...attrs,
          ...$attrs,
        }"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      type="month"
      no-title
      attach
      scrollable
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="menu = false"
      >
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.menu.save(date)"
      >
        {{ $t('common.ok') }}
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      date: '',
      menu: false
    }
  },
  watch: {
    value: {
      handler (val) {
        this.year = val
        this.date = val
      },
      immediate: true
    },
    date (val) {
      this.$emit('input', val)
    }
  }
}
</script>
