import { localize, localeChanged } from 'vee-validate'
import locale from '@/configs/locale'

// BUG: dirty fix for only moment.js seem like only support 2 langauge
// IF want to use more than 2 language, need to usedynamic import things
/**
    // moment lazy load
    const momentLazy = await import(`../../../node_modules/moment/dist/locale/${convertLocale(locale, 'momentLocale')}.js`)
    // Sets translation
    console.log(momentLazy.default)
 */
// import moment from 'moment'
import moment from 'moment/dist/moment';
import "moment/dist/locale/zh-hk.js"

import { loadTrans } from '@/plugins/i18n'
// import setOptions from 'vue-filepond'
import CookieUtils from '@/utils/CookieUtils'

// Available locales in different formats
/**
 * Vuetify Language Code, also rmb add import in src\plugins\vuetify.js
 * @see https://v2.vuetifyjs.com/en/features/internationalization/#getting-started
 */
const vuetify = ['en', 'zhHans', 'zhHant', 'ja']
const vee = ['en', 'zh_CN', 'zh_TW', 'ja']
/**
 * BCP47 Language Tags
 * must follow this below format, cause it is the standard format for language tags
 * @see https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
 * better UI:
 * @see https://www.techonthenet.com/js/language_tags.php
 */
const bcp47 = ['en-US', 'zh-CN', 'zh-HK', 'ja-JP']
const vuefilepond = ['en-en', 'zh-cn', 'zh-tw', 'ja-ja']

/**
 * Function For Dynamic Imports of Language Dictionary (vue-i18n)
 * Definition / Translation for language only will be load when needed
 * @param {String} locale The locale language code (in vuetify language code format)
 * @param {Object} i18n The i18n object [vue-i18n]
 * @return {Promise} Promise for imports
 */
async function lazyLoadLocale (locale, i18n) {
  // If the locale hasn't been loaded yet
  // @ts-ignore
  if ((i18n.locale !== locale && !window.loadedLocales.includes(locale)) || window.loadedLocales.length === 0) {
    // Dynamically import user defined translation
    const messages = loadTrans(locale)

    // Sets translations
    i18n.setLocaleMessage(locale, messages)
    // Records loaded locales to prevent duplicate loading
    // @ts-ignore
    window.loadedLocales.push(locale)
    // import veevalidate locale
    const imports = await import(`../../../node_modules/vee-validate/dist/locale/${convertLocale(locale, 'vee')}.json`)
    // Sets translation
    localize(convertLocale(locale, 'vee'), imports)

    // TODO: change vueFilePond locale here, stopped because (setOptions) not avilable here
    // import vueFilePond locale (global)
    // const filePondLocale = await import(/* webpackChunkName: "vuefilepond-[request]" */ 'filepond/locale/' + convertLocale(locale, 'vuefilepond').toLowerCase() + '.js')
    // setOptions(filePondLocale)

    return true
  }
  // If the locale has been loaded
  return new Promise((resolve) => resolve(false))
}

/**
 * Convert locale identifier to specified format
 * @param {String} locale The locale language code (any format that is in defined {@link vuetify const})
 * @param {String} to The target locale format to convert to
 * - Available values: **['vuetify', 'vee', 'bcp47']**
 * @return {String} The converted locale language code
 */
function convertLocale (locale, to) {
  const toArr = selectLocalePack(to)

  if (toArr !== null) {
    let result = null

    result = matchLocale(locale, vuetify, toArr)
    if (result) return result

    result = matchLocale(locale, vee, toArr)
    if (result) return result

    result = matchLocale(locale, bcp47, toArr)
    if (result) return result

    result = matchLocale(locale, vuefilepond, toArr)
    if (result) return result
  }

  return locale
}

/**
 * Selects the locale pack (defined {@link vuetify const}) to use
 * @param {String} to Target locale format / pack to choose
 * - Available values: **['vuetify', 'vee', 'bcp47']**
 * @return {Array<String> | null} Defined {@link vuetify const} language code | `null`: No match is found
 */
function selectLocalePack (to) {
  if (to === 'vuetify') return vuetify
  if (to === 'vee') return vee
  if (to === 'bcp47') return bcp47
  if (to === 'vuefilepond') return vuefilepond
  return null
}

/**
 * Match / Maps / Translate locale identifier
 * @param {String} locale Source locale languge code format to match
 * @param {Array<String>} from Source locale language code definition ({@link vuetify const}) to match with input
 * @param {Array<String>} to Target locale language code to map to
 * @return {String | null} Converted locale language code | `null`: No match
 */
function matchLocale (locale, from, to) {
  for (let i = 0; i < from.length; i++) {
    if (from[i] === locale) {
      return to[i]
    }
  }
  return null
}

/**
 * This is a mixin component to carry out locale / translation related operations
 *
 * - Methods
 * @property {Function} initLocale Initialize locale
 * @property {Function} changeLocale Function to change locale
 * @property {Function} convertLocale Function to convert locale format (eg. between zhHant & zh_HK)
 *
 * - Computed
 * @property {Function} availableLocales Get available locales in this application (Installed / Initialized)
 * @property {Function} currentLocale Get current locale (in 'vuetify' format)
 *
 * @see https://vuetifyjs.com/en/features/internationalization/
 * @see https://kazupon.github.io/vue-i18n/
 * @see https://vee-validate.logaretm.com/v3/guide/localization.html
 *
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/20
 */
export default {
  filters: {
    // main for solr search
    /**
     * Translates the given key based on the provided row object.
     * If the key exists in the row object, the corresponding value is returned.
     * If the key does not exist, it checks for localized versions of the key in the row object.
     * The localized versions are checked in the order of 'zh-HK', 'zh-CN', 'en_US', 'ja-JP'.
     * If a localized version of the key is found, the corresponding value is returned.
     * If no translation is found, undefined is returned.
     *
     * @param {string} key - The key to be translated.
     * @param {object} row - The row object containing the translation values.
     * @returns {string|undefined} - The translated value or undefined if no translation is found.
     */
    translate (key, row) {
      if (row[key]) {
        return row[key]
      }
      const localeList = ['zh-HK', 'zh-CN', 'en_US', 'ja-JP']
      for (let i = 0; i < localeList.length; i++) {
        if (row[key + '_' + localeList[i]]) {
          return row[key + '_' + localeList[i]]
        }
      }
    }
  },
  methods: {
    /**
     * Initialize Locale
     * @return {Promise} Promise for locale imports
     * @see {@link lazyLoadLocale}
     */
    initLocale () {
      // Sets vuetify locale
      this.$vuetify.lang.current = this.convertLocale(this.$root.$i18n.locale, 'vuetify')
      // Sets moment locale
      moment.locale(this.convertLocale(this.$root.$i18n.locale, 'bcp47'))
      // Load locale files
      return lazyLoadLocale(this.$root.$i18n.locale, this.$root.$i18n)
    },
    /**
     * Function to Change Locale
     * @param {String} locale The target locale to change into (any format that is in defined {@link vuetify const})
     * @param {Boolean} refresh Whether to force refresh the page [Default: `false`]
     */
    changeLocale (locale, refresh = false) {
      // Prepares locale language code into correct format
      locale = this.convertLocale(locale, 'vuetify')
      // Checks if target locale is current locale
      if (this.currentLocale !== locale) {
        /**
         * Emits locale changing event
         * @return {String} Current locale
         */
        this.$root.$emit('changingLocale', locale)
        // Load the required locale
        lazyLoadLocale(locale, this.$root.$i18n).then(() => {
          // Change vue-i18n current locale
          this.$root.$i18n.locale = locale
          // Change vuetify current locale
          this.$vuetify.lang.current = locale
          // Change moment current locale
          moment.locale(this.convertLocale(locale, 'bcp47'))
          // Sets the current locale to cookie
          CookieUtils.setCookie('locale', locale)
          // Informs vee-validate that locale changed
          localeChanged()

          if (refresh) {
            // Refresh page
            this.$router.go()
          } else {
            /**
             * Emits locale changed event
             * @return {String} Current locale
             */
            this.$root.$emit('localeChanged', locale)
          }
        })
      }
    },
    /**
     * Function to Convert Locale Format (eg. between zhHant & zh_HK)
     * @param {String} locale The source locale to change into (any format that is defined in {@link vuetify const})
     * @param {String} to The target locale format to convert to
     * - Available values: **['vuetify', 'vee', 'bcp47']**
     * @return {String} The converted locale language code
     */
    convertLocale (locale, to) {
      return convertLocale(locale, to)
    }
  },
  computed: {
    /**
     * Get Available Locales In This Application
     * @return {Array} List of available locales [vuetify {@link https://vuetifyjs.com/en/features/internationalization/ format}]
     */
    availableLocales () {
      return locale.availableLocales
    },
    /**
     * Get Current Locale
     * @return {String} Current Locale [vuetify {@link https://vuetifyjs.com/en/features/internationalization/ format}]
     */
    currentLocale () {
      return this.$root.$i18n.locale
    }
  }
}
