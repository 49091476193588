<template>
  <div
    id="AtAudio"
    class="rounded-xl"
  >
    <v-col
      align-self="center"
      class="container mx-auto"
      :style="{ 'max-width': maxWidth + 'px' }"
    >
      <v-card class="rounded-xl">
        <v-card-title class="title">
          {{ title }}
        </v-card-title>
        <v-card-text>
          <vue-plyr
            ref="plyr"
            v-bind="{
              ...options
            }"
          >
            <audio
              :src="src"
              controls
              crossorigin
            />
          </vue-plyr>
        </v-card-text>
      </v-card>
    </v-col>
  </div>
</template>

<script>
import 'vue-plyr/dist/vue-plyr.css'
export default {
  props: {
    title: {
      type: String,
      default: 'Untitled'
    },
    src: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default: () => {}
    },
    debugEvents: {
      type: Boolean,
      default: false
    },
    watchedDuration: {
      type: Number,
      default: 0
    },
    maxWidth: {
      type: Number,
      default: 700
    }
  },
  mounted () {
    // TODO Alex assigned watched duration to currentTime
    // this.$refs.plyr.player.currentTime = this.watchedDuration

    // https://github.com/sampotts/plyr#events
    const eventList = ['progress', 'playing', 'play', 'pause', 'timeupdate', 'volumechange', 'seeking', 'seeked', 'ratechange', 'ended', 'enterfullscreen', 'exitfullscreen', 'captionsenabled', 'captionsdisabled', 'languagechange', 'controlshidden', 'controlsshown', 'ready', 'loadstart', 'loadeddata', 'loadedmetadata', 'qualitychange', 'canplay', 'canplaythrough', 'stalled', 'waiting', 'emptied', 'cuechange', 'error']
    // listen all events and emit them
    eventList.forEach(event => {
      this.$refs.plyr.player.on(event, (event) => {
        this.$emit(event.type, event)
        if (this.debugEvents) {
          this.$dialog.notify.info(event.type, { position: 'bottom-right', timeout: 1000 })
          // console.log(event)
          // this.$dialog.notify.info(event.detail.plyr.media.plyr.currentTime, { position: 'bottom-right', timeout: 1000 })
        }
      })
    })
  }

}
</script>

<style scoped>
.container {
  border-radius: 10px;
}
.title {
  word-break: normal;
  text-align: center;
  font-size: 20px;
  color: #0e0e0e;
  font-weight: 700;
}
#AtAudio {
  height: inherit;
  background: #bb71f3;
  background: -moz-linear-gradient(-45deg, #bb71f3 0%, #3d4d91 100%);
  background: -webkit-linear-gradient(-45deg, #bb71f3 0%, #3d4d91 100%);
  background: linear-gradient(135deg, #bb71f3 0%, #3d4d91 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#bb71f3', endColorstr='#3d4d91', GradientType=1);
  border-radius: 10px;
  justify-content: center;
  display: flex;
}
</style>
<!--
<style scoped src="./styles.css"/>
<style scoped src="vue-plyr/dist/vue-plyr.css"/>
-->
