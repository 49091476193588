import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = ObjArrUtils.merge(Base, error, {
  state: {
    l0101: null,
    l0701: null,
    l0301: null,
    l0601: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async init ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'L0101ContentService.doRead_contentDetails',
            input: { seo_url: payload.seo_url }
          },
          {
            api: 'L0601ContentSectionContentService.doRead_contentClass',
            input: { l0701_status: 'SUCCESS', l0601_id: payload.contentId, seo_url: payload.seo_url }
          }
          // {
          //   api: 'L0801ContentSectionContentProgressService.doSave',
          //   input: { l0601_id: payload.contentId }
          // }
        ]
      })

      state.l0101 = res[0].output.l0101_content
      state.l0701 = res[0].output.l0701_content_class_enrollment
      state.l0301 = res[1].output.l0301_content_class
      state.l0601 = res[1].output.l0601_content_section_content
      return res
    },
    // async selectContent ({ commit, state }, payload) {
    //   const res = await ApiUtils.postData({
    //     data: [
    //       {
    //         api: 'L0601ContentSectionContentService.doRead_contentClass',
    //         input: { l0701_status: 'SUCCESS', l0601_id: payload.contentId, seo_url: payload.seo_url }
    //       }
    //     ]
    //   })
    //   state.l0601 = res[0].output.l0601_content_section_content[0]
    // },
    async doSave ({ state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'L0801ContentSectionContentProgressService.doSave',
            input: payload
          }
        ]
      })
      return res
    },
    async savePage ({ state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'L0801ContentSectionContentProgressService.savePage',
            input: payload
          }
        ]
      })
      return res
    },
    async doRead_contentClass ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'L0601ContentSectionContentService.doRead_contentClass',
            input: { l0701_status: 'NONE', l0601_id: payload.l0601_id, seo_url: payload.seo_url }
          }
        ]
      })

      return res[0].output.l0601_content_section_content
    },
    async getQuizResult ({ state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'L0802ContentSectionContentProgressQuizService.gradingAnswer',
            input: payload
          }
        ]
      })
      return res[0].output.quizResult
    },
  }
})

export default module
