import M1901Const from './M1901Const'

const store = {
  state: {
    coinsType: []
  },
  getters: {
    geCoinsType: state => state.coinsType
  },
  mutations: {
    SET_COINS_TYPE (state, payload) {
      state.coinsType = payload
    }
  }
}

export default M1901Const.getStore(store)
