import ApiUtils from '@/utils/api/ApiUtils'
const Base = {
  namespaced: true,
  strict: (import.meta.env.VITE_NODE_ENV !== 'production' && import.meta.env.VITE_NODE_ENV !== 'production-dev' && import.meta.env.VITE_NODE_ENV !== 'production-staging')
}
/**
 * Base error module
 * mostly for show form error hint from backend
 *
 */
const error = {
  state: {
    errors: {}
  },
  getters: {
    getErrors: (state) => state.errors
  },
  mutations: {
    SET_ERRORS (state, payload) { state.errors = payload },
    REMOVE_ERRORS (state) { state.errors = {} }
  }
}

const PageConst={
  FORM_DEF: {
    editDef: [
      { name: 'name', label: 'Name', type: 'text', required: true },
      { name: 'description', label: 'Description', type: 'textarea', required: false }
    ]
  },
  SEARCH_DEF: {
    inputDef: [
      { name: 'name', label: 'Name', type: 'text', required: false }
    ]
  },
  LIST_DEF: {
    tableDef: [
      { name: 'name', label: 'Name', align: 'left', sortable: true },
      { name: 'description', label: 'Description', align: 'left', sortable: false }
    ]
  },
  ACTION: {
    doAdd: { api: 'XXXService.doAdd'},
    getEdit: { api: 'XXXService.getEdit'},
    doEdit: { api: 'XXXService.doEdit'},
    doRead: { api: 'XXXService.doRead'},
    doDelete: { api: 'XXXService.doDelete'},
    doBrowse: { api: 'XXXService.doBrowse'},
  }
}

const doBrowseStatusEnum = {
  init: 'init',
  loading: 'loading',
  noData: 'noData',
  loaded: 'loaded',
  error: 'error',
  loading_nextPage: 'loading_nextPage',
  loaded_nextPage: 'loaded_nextPage',
  error_nextPage: 'error_nextPage'
}
const getEditStatusEnum={
  init: 'init',
  loading: 'loading',
  loaded: 'loaded',
  error: 'error'
}
const doEditStatusEnum={
  init: 'init', // have not done anything
  validationError: 'validationError', // have done something but have validation error
  dirty: 'dirty', // have done something but pass validation
  saving: 'saving', // saving to backend
  saved: 'saved', // saved to backend
  error: 'error' // error when saving to backend
}

//  TODO: seperate breadBuilder into `doBrowse_list`, `doBrowse_table` , `doREAD`
// eslint-disable-next-line no-unused-vars
function breadBuilder (name=null){
  function targetKeyName (key){
    let keyword = ''
    if(name) keyword = `${name}_`
    return `${keyword}${key}`
  }
  return {
    state: {
      // Generic
      [targetKeyName('PageConst')]:PageConst,

      // doEdit/getEdit
      [targetKeyName('row')]:{},
      // for validation use
      [targetKeyName('fnDoValidate')]:async () => {return true},
      [targetKeyName('getEditStatus')]:getEditStatusEnum.init,
      [targetKeyName('doEditStatus')]:doEditStatusEnum.init,

      // doBrowse
      [targetKeyName('doBrowseStatus')]:doBrowseStatusEnum.init,
      // Additinal data for doBrowse
      [targetKeyName('doBrowseInput')]:{},
      [targetKeyName('searchInfo')]:{},
      [targetKeyName('orderBy')]:[],
      [targetKeyName('rows')]:[],
      [targetKeyName('pagination')]:{
        current_page: 1,
        last_page: 0,
        per_page: 10,
        total: null
      },
      // doBrowse.Table Options
      [targetKeyName('tableOptions')]:{
        itemsPerPageOptions: [10, 15, 25, 50]
      }
    },
    actions:{
      async [targetKeyName('doAdd')] ({ commit, state }, payload) {
        const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.doAdd.api, input: payload }] })
        return res[0].output
      },
      async [targetKeyName('getEdit')] ({ commit, state }, payload) {
        state[targetKeyName('getEditStatus')] = getEditStatusEnum.loading
        const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.getEdit.api, input: payload }] })
        commit(targetKeyName('SET_ROW'), res[0].output)
        state[targetKeyName('getEditStatus')] = getEditStatusEnum.loaded
        return res[0].output
      },
      async [targetKeyName('doEdit')] ({ commit, state }, payload) {
        if(state[targetKeyName('fnDoValidate')] && !await state[targetKeyName('fnDoValidate')]()) return
        const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.doEdit.api, input: payload }] })
        return res[0].output
      },
      async [targetKeyName('doRead')] ({ commit, state }, payload) {
        const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.doRead.api, input: payload }] })
        commit(targetKeyName('SET_ROW'), res[0].output)
        return res[0].output
      },
      async [targetKeyName('doDelete')] ({ commit, state }, payload) {
        const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.doDelete.api, input: payload }] })
        return res[0].output
      },
      /**
       * for list / init
       *
       * @param {*} param0
       * @param {*} payload
       * @returns
       */
      async [targetKeyName('doBrowse')] ({ commit, state }, payload) {
        state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.loading
        try{
          const val = {
            ...state[targetKeyName('doBrowseInput')],
            searchInfo: state[targetKeyName('searchInfo')],
            orderBy: state[targetKeyName('orderBy')],
            pagination: { current_page: 1, per_page: state[targetKeyName('pagination')].per_page
            } }
          const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.doBrowse.api, input: val }] })
          commit(targetKeyName('SET_ROWS'), res[0].output.data)
          commit(targetKeyName('SET_PAGINATION'), res[0].output.pagination)
          state[targetKeyName('doBrowseStatus')] = res[0].output.pagination.total > 0 ? doBrowseStatusEnum.loaded : doBrowseStatusEnum.noData
          return res[0].output
        } catch (e) {
          state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.error
          throw e
        }
      },
      /**
       * for AtDataTable
       *
       * @param {*} param0
       * @param {*} payload
       * @returns
       */
      async [targetKeyName('doBrowse_AtDataTable')] ({ commit, state }, payload=null) {
        state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.loading
        try{
          let val={}
          if(payload) {
            val = {
              ...state[targetKeyName('doBrowseInput')],
              searchInfo: state[targetKeyName('searchInfo')],
              orderBy: payload.orderBy,
              pagination: payload.pagination
            }
          }else{
            val = {
              ...state[targetKeyName('doBrowseInput')],
              searchInfo: state[targetKeyName('searchInfo')],
              orderBy: state[targetKeyName('orderBy')],
              pagination: { current_page: 1, per_page: state[targetKeyName('pagination')].per_page
              }
            }
          }

          const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.doBrowse.api, input: val }] })
          commit(targetKeyName('SET_ROWS'), res[0].output.data)
          commit(targetKeyName('SET_PAGINATION'), res[0].output.pagination)
          state[targetKeyName('doBrowseStatus')] = res[0].output.pagination.total > 0 ? doBrowseStatusEnum.loaded : doBrowseStatusEnum.noData
          return res[0].output
        } catch (e) {
          state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.error
          throw e
        }
      },
      /**
       * for list
       *
       * @param {*} param0
       * @param {*} overwrite true to overwrite rows, false to append
       */
      async [targetKeyName('nextPage')] ({ commit, state }, overwrite=true) {
        state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.loading_nextPage
        try{
          const val = { searchInfo: state[targetKeyName('searchInfo')], orderBy: state[targetKeyName('orderBy')],
            pagination: { current_page: state[targetKeyName('pagination')].current_page + 1, per_page: state[targetKeyName('pagination')].per_page }
          }
          const res = await ApiUtils.postData({ data: [{ api: state[targetKeyName('PageConst')].ACTION.doBrowse.api, input: val }] })

          if (overwrite) {
            commit(targetKeyName('SET_ROWS'), res[0].output.data)
          }
          else {
            commit(targetKeyName('SET_ROWS'), [...state[targetKeyName('rows')], ...res[0].output.data])
          }
          commit(targetKeyName('SET_PAGINATION'), res[0].output.pagination)
          state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.loaded
          return res[0].output
        } catch (e) {
          state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.error_nextPage
          throw e
        }
      }
    },
    getters:{
      [targetKeyName('getRows')]: (state) => state[targetKeyName('rows')],
      [targetKeyName('getRow')]: (state) => state[targetKeyName('row')],
      [targetKeyName('getPagination')]: (state) => state[targetKeyName('pagination')],
      [targetKeyName('getSearchInfo')]: (state) => state[targetKeyName('searchInfo')],
      [targetKeyName('getOrderBy')]: (state) => state[targetKeyName('orderBy')],
      [targetKeyName('getTableOptions')]: (state) => state[targetKeyName('tableOptions')],
      [targetKeyName('getEditDef')]: (state) => state[targetKeyName('PageConst')].FORM_DEF.editDef,
      [targetKeyName('getSearchDef')]: (state) => state[targetKeyName('PageConst')].SEARCH_DEF.inputDef,
      [targetKeyName('getTableDef')]: (state) => state[targetKeyName('PageConst')].LIST_DEF.tableDef,
      [targetKeyName('hasNextPage')]: (state) => state[targetKeyName('pagination')].hasNextPage,
      [targetKeyName('selectedOrderBy')]: (state) => {
        // get Text from value
        return state[targetKeyName('PageConst')].SEARCH_DEF.sortDef.find(item => {
          return JSON.stringify(item.value) === JSON.stringify(state[targetKeyName('orderBy')])
        }).text
      },
      [targetKeyName('doBrowseInput')]: (state) => state[targetKeyName('doBrowseInput')],
    },
    mutations:{
      [targetKeyName('SET_ROWS')] (state, payload) { state[targetKeyName('rows')] = payload },
      [targetKeyName('SET_ROW')] (state, payload) { state[targetKeyName('row')] = payload },
      [targetKeyName('REMOVE_ROWS')] (state) { state[targetKeyName('rows')] = [] },
      [targetKeyName('REMOVE_ROW')] (state) { state[targetKeyName('row')] = {} },
      [targetKeyName('DELETE_ROW')] (state, payload) { state[targetKeyName('rows')] = state[targetKeyName('rows')].filter(row => row.id !== payload.id) },
      [targetKeyName('SET_PAGINATION')] (state, payload) {
        state[targetKeyName('pagination')] =payload
      },
      [targetKeyName('SET_SEARCH_INFO')] (state, payload) { state[targetKeyName('searchInfo')] = payload },
      [targetKeyName('SET_ORDER_BY')] (state, payload) { state[targetKeyName('orderBy')] = payload },
      [targetKeyName('SET_ITEM_PER_PAGE')] (state, payload) {
        state[targetKeyName('tableOptions')] = { itemsPerPageOptions: payload }
      },
      [targetKeyName('SET_FN_DO_VALIDATE')] (state, payload) { state[targetKeyName('fnDoValidate')] = payload },
      [targetKeyName('SET_DO_BROWSE_INPUT')] (state, payload) { state[targetKeyName('doBrowseInput')] = payload },
    },
  }
}

/**
 * Base BREAD module
 * mostly for admin page
 *
 * with Const implementation
 */
const BREAD = breadBuilder()

export { Base, error, BREAD,breadBuilder,doBrowseStatusEnum,getEditStatusEnum }
