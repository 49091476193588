import { mapGetters } from 'vuex'

export default {
  methods: {
    dialogConfirm () {
      this.$dialog.confirm(this.getDialogConfirm)
      this.$store.commit('Notification/SET_DIALOG_CONFIRM', null)
    },
    dialogInfo () {
      this.$dialog.info(this.getDialogInfo)
      this.$store.commit('Notification/SET_DIALOG_INFO', null)
    },
    dialogWarning () {
      this.$dialog.warning(this.getDialogWarning)
      this.$store.commit('Notification/SET_DIALOG_WARNING', null)
    },
    dialogError () {
      this.$dialog.error(this.getDialogError)
      this.$store.commit('Notification/SET_DIALOG_ERROR', null)
    },
    dialogPrompt () {
      this.$dialog.prompt(this.getDialogPrompt)
      this.$store.commit('Notification/SET_DIALOG_PROMPT', null)
    },
    notifySuccess () {
      this.$dialog.notify.success(this.getNotifySuccess.text, this.getNotifySuccess)
      this.$store.commit('Notification/SET_NOTIFY_SUCCESS', null)
    },
    notifyInfo () {
      this.$dialog.notify.info(this.getNotifyInfo.text, this.getNotifyInfo)
      this.$store.commit('Notification/SET_NOTIFY_INFO', null)
    },
    notifyWarning () {
      this.$dialog.notify.warning(this.getNotifyWarning.text, this.getNotifyWarning)
      this.$store.commit('Notification/SET_NOTIFY_WARNING', null)
    },
    notifyError () {
      this.$dialog.notify.error(this.getNotifyError.text, this.getNotifyError)
      this.$store.commit('Notification/SET_NOTIFY_ERROR', null)
    },
    toastSuccess () {
      this.$dialog.message.success(this.getToastSuccess.text, this.getToastSuccess)
      this.$store.commit('Notification/SET_TOAST_SUCCESS', null)
    },
    toastInfo () {
      this.$dialog.message.info(this.getToastInfo.text, this.getToastInfo)
      this.$store.commit('Notification/SET_TOAST_INFO', null)
    },
    toastWarning () {
      this.$dialog.message.warning(this.getToastWarning.text, this.getToastWarning)
      this.$store.commit('Notification/SET_TOAST_WARNING', null)
    },
    toastError () {
      this.$dialog.message.error(this.getToastError.text, this.getToastError)
      this.$store.commit('Notification/SET_TOAST_ERROR', null)
    }
  },
  computed: {
    ...mapGetters('Notification', [
      'getDialogConfirm',
      'getDialogInfo',
      'getDialogWarning',
      'getDialogError',
      'getDialogPrompt',
      'getNotifySuccess',
      'getNotifyInfo',
      'getNotifyWarning',
      'getNotifyError',
      'getToastSuccess',
      'getToastInfo',
      'getToastWarning',
      'getToastError'
    ])
  },
  mounted () {
    if (this.getDialogConfirm) this.dialogConfirm()
    if (this.getDialogInfo) this.dialogInfo()
    if (this.getDialogWarning) this.dialogWarning()
    if (this.getDialogError) this.dialogError()
    if (this.getDialogPrompt) this.dialogPrompt()
    if (this.getNotifySuccess) this.notifySuccess()
    if (this.getNotifyInfo) this.notifyInfo()
    if (this.getNotifyWarning) this.notifyWarning()
    if (this.getNotifyError) this.notifyError()
    if (this.getToastSuccess) this.toastSuccess()
    if (this.getToastInfo) this.toastInfo()
    if (this.getToastSuccess) this.toastSuccess()
    if (this.getToastWarning) this.toastWarning()
    if (this.getToastError) this.toastError()
  },
  watch: {
    '$store.state.Notification.dialog.confirm': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.dialogConfirm()
        }
      }
    },
    '$store.state.Notification.dialog.info': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.dialogInfo()
        }
      }
    },
    '$store.state.Notification.dialog.warning': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.dialogWarning()
        }
      }
    },
    '$store.state.Notification.dialog.error': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.dialogError()
        }
      }
    },
    '$store.state.Notification.dialog.prompt': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.dialogPrompt()
        }
      }
    },
    '$store.state.Notification.notify.success': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.notifySuccess()
        }
      }
    },
    '$store.state.Notification.notify.info': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.notifyInfo()
        }
      }
    },
    '$store.state.Notification.notify.warning': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.notifyWarning()
        }
      }
    },
    '$store.state.Notification.notify.error': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.notifyError()
        }
      }
    },
    '$store.state.Notification.toast.success': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.toastSuccess()
        }
      }
    },
    '$store.state.Notification.toast.info': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.toastInfo()
        }
      }
    },
    '$store.state.Notification.toast.warning': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.toastWarning()
        }
      }
    },
    '$store.state.Notification.toast.error': {
      deep: true,
      handler (newValue) {
        if (newValue.length) {
          this.toastError()
        }
      }
    }
  }
}
