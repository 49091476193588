import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    calendar: null,
  },
  mutations: {
  },
  actions: {
    async getCalendar ({ state }, payload) {
      state.calendar = null
      const data = await ApiUtils.postData({
        data: [{ api: 'M0001UserService.getCalendar', input: payload }]
      })
      state.calendar = data[0].output
    }
  }
}
export default ObjArrUtils.merge(Base, error, module)
