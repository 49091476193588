import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C2001ProjectInvestment',
  serviceName: 'C2001ProjectInvestmentService',
  storeConst: {
    ACTION: {
      getHint: {
        store: 'M3901Guide/getHint'
      }
    }
  },
  componentDef: {
    form: {
      viewDef: [
        {
          column: 'col-12',
          data: 'project_funding_target',
          custom: 'C0101ProjectFundingTarget',
          label: i18n.t('c0101.filter.project_funding_target'),
          rules: 'required',
          class: 'required',
          props: {
            coinACurrencyList: []
          },
          async: [{
            api: 'M1902CoinsTypeCurrencyService.GetACoinCurrency',
            mutateTarget: 'props.coinACurrencyList'
          }]
        },
        {
          column: 'col-12',
          data: {
            get (val) {
              return {
                project_investment_deadline: val.project_investment_deadline ?? null,
                project_investment_deadline_day_draft: val.project_investment_deadline_day_draft ?? null
              }
            },
            set (val, input) {
              if (input.project_investment_deadline) val.project_investment_deadline = input.project_investment_deadline
              if (input.project_investment_deadline_day_draft) val.project_investment_deadline_day_draft = input.project_investment_deadline_day_draft
              return val
            }
          },
          custom: 'C2001ProjectInvestmentDeadline'
        },
        {
          column: 'col-12',
          data: 'project_investment_package',
          custom: 'C2101List',
          label: i18n.t('c2001.filter.project_investment_package')
        },
        {
          column: 'col-12',
          data: 'project_investment_target_investor',
          custom: 'C2201List',
          label: i18n.t('c2001.filter.project_investment_target_investor')
        },
        {
          column: 'col-12',
          data: 'project_investment_use_of_funds',
          custom: 'MoBlockEditor',
          props: {
            data: 'project_investment_use_of_funds'
          },
          label: i18n.t('c2001.filter.project_investment_use_of_funds')
        },
        {
          column: 'col-12',
          data: 'project_investment_policy',
          type: 'ttc_policy',
          props: {
            policyCode: 'collab.projectInvestment'
          }
        }
      ]
    }
  }
}
const C2001Const = new BaseDataStore(BaseConst)
export default C2001Const
