import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    renderedHtml: ''
  },

  getters: {
    getRenderedHtml (state) {
      return state.renderedHtml
    }
  },

  mutations: {
    SET_RENDERED_HTML (state, payload) {
      state.renderedHtml = payload
    }
  },

  actions: {
    render: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'E0000DemoService.editorjsRender', input: payload }]
      }).then(data => {
        commit('SET_RENDERED_HTML', data[0].output)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
