// @ts-check

import NProgress from 'nprogress'

/**
 * NProgress configuration
 * @link https://github.com/rstacruz/nprogress#configuration
 */
NProgress.configure({
  showSpinner: false
})
