import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
const BaseConst = {
  version: 1,
  storeName: 'UserInvestList',
  serviceName: 'C2301ProjectInvestmentInvestService',
  componentDef: {
    table: {
      viewDef: [
        { text: '項目', value: 'c0101_title' },
        { text: '投資金額', value: 'c2301_amount' },
        {
          text: '狀態',
          value: 'c2301_status',
          type: 'chip',
          // 'PENDING','IN_PROGRESS','DEAL','SUCCESS','REJECTED','CLOSED','DELETED','REMOVED'
          items: [
            { value: 'PENDING', label: '待辦', color: 'grey' },
            { value: 'IN_PROGRESS', label: '進行中', color: 'blue' },
            { value: 'DEAL', label: '已成交', color: 'green' },
            { value: 'SUCCESS', label: '成功', color: 'green' },
            { value: 'REJECTED', label: '已拒絕', color: 'red' },
            { value: 'CLOSED', label: '已關閉', color: 'red' },
            { value: 'DELETED', label: '已刪除', color: 'red' },
            { value: 'REMOVED', label: '已移除', color: 'red' }
          ]
        },
        { text: '投資日期', value: 'c2301_created_at', type: 'date' },
        { text: '', value: 'actions' }
      ],
      optionDef: {}
    }
  }
}
const C1001Const = new BaseDataStore(BaseConst)
export default C1001Const
