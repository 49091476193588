import store from '@/store'
import ObjArrUtils from '@/utils/ObjArrUtils'

/** @module AppUtils */

/**
 * Utilities for application
 * @property {Function} sleep Pause execution
 * @property {Function} isDebugMode Get whether the application is put into debug mode
 * @property {Function} isLoggedIn Get whether the user is logged in
 * @property {Function} allowAccess Checks whether user has the rights to access resource
 * @property {Function} isFullScreen Get whether current is full screen
 * @property {Function} toggleFullScreen Toggle full screen on element
 * @property {Function} $dialog Wrapper for `vuetify-dialog` Object
 *
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/20
 */
const AppUtils = {
  /**
   * Pause execution
   * @param {Number} miliseconds Miliseconds to sleep
   * @return {Promise} Promise object that will resolved after sleep
   */
  sleep (miliseconds) {
    return new Promise(resolve => setTimeout(resolve, miliseconds))
  },
  /**
   * Get whether the application is put into debug mode
   * @return {Boolean | String} `true`: Debug mode, `false`: otherwise
   */
  isDebugMode () {
    if (!import.meta.env.VITE_DEBUG) return false
    const val = localStorage.getItem('mockProduction')
    switch (val) {
      case '0':
        return true
      case '1':
        return false
      default:
        return false
    }
  },
  /**
   * Get whether the user is logged in
   * @return {Boolean} `true`: Logged In, `false`: otherwise
   */
  isLoggedIn () {
    const user = store.getters['Auth/getUser']

    // TODO for now, this line is not in use
    if (ObjArrUtils.isEmpty(user)) return false

    if (user.role_code === 'V') return false

    // TODO: show error dialog if user is not active (u banned, etc)
    if (user.status !== 'A') return false

    return true
  },
  /**
   * Checks whether user has the rights to access resource
   * @param {Boolean} authenticated Specifies if user need to log in (authenticated)
   * @param {Array} userRoles An array of user roles to permit access
   * - [Default] `[] | null`: User role check will be skipped
   * @param {Array} userPermissions An array of user permissions to permit access
   * - [Default] `[] | null`: User permission check will be skipped
   * @return {Boolean} `true`: Access granted, `false`: otherwise
   */
  allowAccess (authenticated = false, userRoles = [], userPermissions = []) {
    // Checks if user needs to be authenticated
    if (authenticated && !this.isLoggedIn()) {
      return false
    }

    // Checks if user fulfill roles requirement
    if (!ObjArrUtils.isEmpty(userRoles)) {
      if (!userRoles.includes(store.getters['Auth/getUserRole'])) {
        return false
      }
    }

    // Checks if user has the permission
    if (!ObjArrUtils.isEmpty(userPermissions)) {
      const premissions = store.getters['Auth/getUserPermissions']
      let passFlag = false

      premissions?.forEach(permission => {
        if (userPermissions.includes(permission)) {
          passFlag = true
        }
      })

      if (!passFlag) {
        return false
      }
    }

    return true
  },
  /**
   * Get whether current is full screen
   * @return {Boolean} `true`: Fullscreen, `false`: otherwise
   */
  isFullScreen () {
    return document.fullscreen
  },
  /**
   * Toggle full screen on element
   * @param {String} element The traget element to make full screen
   * - Default: `'html'`
   */
  toggleFullScreen (element = 'html') {
    if (!this.isFullScreen()) {
      document.querySelector(element).requestFullscreen()
    } else {
      document.exitFullscreen()
    }
    store.commit('App/SET_APP_FULL_SCREEN', !this.isFullScreen())
  },
  /**
   * Wrapper for `vuetify-dialog` Object
   * @return {Function} `vuetify-dialog` Object
   * @example AppUtils.$dialog().{__function__}
   */
  // @ts-ignore
  $dialog: () => window.vue.$dialog
}

export default AppUtils
