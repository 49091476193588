import CookieUtils from '@/utils/CookieUtils'

// ******************************************** //

/**
 * Config: Default Locale
 */
const defaultLocale = 'zhHant'

// ******************************************** //

// Sets the current locale based on cookie if exist
const currentLocale = CookieUtils.getCookie('locale') ? CookieUtils.getCookie('locale') : defaultLocale

// Sets current locale to cookie
CookieUtils.setCookie('locale', currentLocale)

export default {
  currentLocale,
  availableLocales: [
    { text: 'EN', value: 'en', id: 1 },
    { text: '繁', value: 'zhHant', id: 3 }
    // { text: '简体中文', value: 'zhHans' },
    // { text: '日本語', value: 'ja' },
  ]
}
