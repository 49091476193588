export default [
  // TVP routes
  // {
  //   portalGroup: 'collab.public',
  //   path: '/project/update/:id',
  //   name: 'ProjectUpdate',
  //   component: () => import('./PgProjectUpdate.vue')
  // }
]

// write regex
// target file = routes.js
// true condition
// .vue')
// false condition
// .vue')
// regex
// \.vue'\)
