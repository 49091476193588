import FnConst from './FnSelectConst'
import ApiUtils from '@/utils/api/ApiUtils'
const store = {
  state: {
    // custom state
    searchInfo: {
      non_choice: 1
    }
  },
  getters: {
    // custom getter
  },
  mutations: {
    // custom mutation

  },
  actions: {
    async addChoice ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: FnConst.storeConst.ACTION.addChoice.api, input: payload },
          { api: FnConst.storeConst.ACTION.doBrowse.api, input: val }
        ]
      })
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
    }
  }
}
export default FnConst.getStore(store)
