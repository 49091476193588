const user = {
  id: 1,
  user_name: 'Jasper Ngai',
  user_role_name: '思想家',
  user_alias: 'Jasper',
  user_profile_pic: 'https://via.placeholder.com/300x200.png',
  bio: '一位技術愛好者和程式設計師，對藝術充滿熱情。 在演算法和創造力之間保持平衡，為編碼和藝術追求帶來獨特的風采。',
  project_role: 'Designer', // role in this project
  country: 'my'
}

const base = {
  id: 1,
  project_seo_url: 'P.NOTE: 強大的AI錄音機',
  project_title: 'P.NOTE: 強大的AI錄音機',
  project_subtitle: '無論您身在何處，都可以輕鬆捕獲會議、iPhone 通話和語音備忘錄的優質錄音。 徹底改變會議、電話以及多體驗。',
  project_img_src: 'https://via.placeholder.com/200x200.png', // project_cover
  project_creator: user, // created_by
  project_category: '科技',
  project_created_at: '2021-08-10 00:00:00',
  project_country: 'my' // no?
}

const listingObj = {
  base,
  user,
  crowdfund: {
    ...base,
    intention: ['CROWDFUND', 'INVESTMENT'],
    crowdfund_progress: '30%', // funding_progress
    crowdfund_status: 'FUNDING',
    crowdfund_raised: 60000, // funding_collected
    crowdfund_goal: 200000, // funding_target
    crowdfund_contributors: 20,
    crowdfund_daysLeft: 20, // funding_days_left
    crowdfund_deadline: '2021-08-10 00:00:00' // funding_deadline
  },
  investment: {
    ...base,
    intention: ['INVESTMENT'],
    crowdfund_progress: '30%',
    crowdfund_raised: 60000,
    crowdfund_goal: 200000,
    crowdfund_daysLeft: 20
  },
  partnerSeeking: {
    ...base,
    intention: ['PARTNERSEEKING'],
    partner_seeking_title: '高級工程設計師',
    partner_seeking_subtitle: '為我們的產品尋找優秀、專業的工程師來協助我們新鮮公司的開發和生...',
    partner_seeking_earn: 'RM 14,000 / 每月',
    partner_seeking_location: '馬來西亞'
  },
  advise: {
    ...base, // optional
    advise_id: 1,
    intention: ['ADVISE'],
    advise_seo_url: '如何在短時間內提高產品的銷售量？',
    advise_title: '如何在短時間內提高產品的銷售量？',
    advise_subtitle: '我們的產品在市場上的銷售量不高，我們該如何提高產品的銷售量？', // advise_desc
    advise_created_at: '2021-08-10 00:00:00'
  }
}
// const pagination = {
//   current_page: 1,
//   last_page: 1,
//   per_page: 10,
//   total: 10,
//   has_next_page: 0
// }



export default listingObj
