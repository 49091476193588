// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// import i18n from '@/plugins/i18n'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    // ReportCaseList
    VModel: {
      searchInfo: {
        report_case_status: ['O', 'R']
      },
      orderBy: {},
      pagination: {
        current_page: 1,
        last_page: 0,
        per_page: 10,
        total: null
      }
    },
    rel_model_options: [],
    report_case_status_options: [],
    // Report Case Detail
    users_reports_list: [],
    users_reports_list_pagination: {
      per_page: 10
    },
    report_case_categories: [],
    user_report_status_options: [],
    report_case_detail_VModel: {
      searchInfo: {
        m1201_id: null
      },
      orderBy: {},
      pagination: {
        current_page: 1,
        last_page: 0,
        per_page: 10,
        total: null
      }
    },
    rows: [],
    row: {},
    categories: [],
    showDialog: false,
    relModel: null,
    relId: null
  },

  getters: {
    getVModel: state => state.VModel,
    getRelModelOptionsKeyValue: state => {
      const obj = {}
      state.rel_model_options.forEach(o => {
        obj[o.value] = {
          text: o.text,
          icon: o.icon
        }
      })
      return obj
    },
    getReportCaseStatusOptionsKeyValue: state => {
      const obj = {}
      state.report_case_status_options.forEach(o => {
        obj[o.value] = {
          text: o.text,
          color: o.color
        }
      })
      return obj
    },
    getUsersReports: state => state.users_reports_list,
    getReportCaseCategories: state => state.report_case_categories,
    getReportCaseCategoriesKeyValue: state => {
      const obj = {}
      state.report_case_categories.forEach(c => {
        obj[c.m0601_id] = {
          translated_category_name: c.translated_category_name,
          translated_category_desc: c.translated_category_desc,
          category_color: c.category_color,
          count: c.count
        }
      })
      return obj
    },
    getUserReportContent: state => state.report_case_content,
    getUserReportStatusOptions: state => state.user_report_status_options,
    getUserReportStatusOptionsKeyValue: state => {
      const obj = {}
      state.user_report_status_options.forEach(o => {
        obj[o.value] = {
          text: o.text,
          color: o.color
        }
      })
      return obj
    },
    getReportCaseDetailVModel: state => state.report_case_detail_VModel,
    getRows: state => state.rows,
    getRow: state => state.row,
    getCategories: state => state.categories,
    getShowDialog: state => state.showDialog,
    getRelModel: state => state.relModel,
    getRelId: state => state.relId
  },

  mutations: {
    SET_VMODEL (state, payload) {
      state.VModel = payload
    },
    SET_REL_MODEL_OPTIONS (state, payload) {
      state.rel_model_options = payload
    },
    SET_REPORT_CASE_STATUS_OPTIONS (state, payload) {
      state.report_case_status_options = payload
    },
    SET_USERS_REPORTS_LIST (state, payload) {
      state.users_reports_list = payload
    },
    SET_REPORT_CASE_CATEGORIES (state, payload) {
      state.report_case_categories = payload
    },
    SET_USER_REPORT_CONTENT (state, payload) {
      if (payload?.id) {
        const index = state.users_reports_list.findIndex(r => r.id === payload.id)
        if (index >= 0) {
          state.users_reports_list.splice(index, 1, payload)
        }
      }
    },
    SET_USER_REPORT_STATUS (state, payload) {
      state.user_report_status_options = payload
    },
    SET_REPORT_CASE_DETAIL_VMODEL (state, payload) {
      state.report_case_detail_VModel = payload
    },
    SET_ROWS (state, payload) {
      state.rows = payload
    },
    SET_ROW (state, payload) {
      state.row = payload
    },
    SET_CATEGORIES (state, payload) { state.categories = payload },
    SET_SHOW_DIALOG (state, payload) { state.showDialog = payload },
    SET_REL_MODEL (state, payload) { state.relModel = payload },
    SET_REL_ID (state, payload) { state.relId = payload }
  },

  actions: {
    // For ReportCaseList page
    // Get status for filter
    getReportCaseStatusOptions: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1201ReportCaseService.getReportCaseStatusOptions' }
        ]
      })
        .then((data) => {
          commit('SET_REPORT_CASE_STATUS_OPTIONS', data[0].output)
        })
    },
    // Get relmodel for filter
    getReportCaseRelModelOptions: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1201ReportCaseService.getReportCaseRelModelOptions', input }
        ]
      }, false)
        .then(data => {
          commit('SET_REL_MODEL_OPTIONS', data[0].output)
        })
    },
    // Get all report cases
    getReportCases: ({ commit, state }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1201ReportCaseService.getReportCases', input }
        ]
      })
        .then(data => {
          commit('SET_ROWS', data[0].output.data)
          commit('SET_VMODEL', {
            ...state.VModel,
            pagination: data[0].output.pagination
          })
        })
    },

    // For ReportCaseDetail
    // get the info for the report cases
    getReportCase: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1201ReportCaseService.getReportCases', input }
        ]
      })
        .then(data => {
          commit('SET_ROW', data[0].output.data[0] ?? {})
        })
    },
    // to search the user report 
    getUserReports: ({ commit, state }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1101UserReportService.getReporterReports', input: { ...input, pagination: state.users_reports_list_pagination } }
        ]
      })
        .then(data => {
          commit('SET_USERS_REPORTS_LIST', data[0].output.data)
          commit('SET_REPORT_CASE_DETAIL_VMODEL', {
            ...state.report_case_detail_VModel,
            pagination: data[0].output.pagination
          })
        })
    },
    // to search the user report with report attachements
    searchUserReportContent: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1101UserReportService.getReporterReports', input }
        ]
      }, false)
        .then(data => {
          commit('SET_USER_REPORT_CONTENT', data[0].output.data[0])
        })
    },
    // Mark user case as READ
    markAsRead: ({ commit }, ids) => {
      return ApiUtils.postData({
        data: [
          { api: 'M1101UserReportService.markAsRead', input: { ids } }
        ]
      }, false)
    },
    // Get seoUrl for route params
    getSeoUrl: ({ commit }, input) => {
      return ApiUtils.postData({ data: [{ api: 'M4801SEOService.getSeoUrl', input }] })
        .then((data) => {
          return data[0].output
        })
    },
    // For dialog
    // Opening dialog
    showReportDialog: ({ commit, state }, payload) => {
      const { relModel, relId } = payload
      state.relModel = relModel
      state.relId = relId
      state.showDialog = true
    },
    // Submit report request
    submit: ({ commit }, input) => {
      return ApiUtils.postData({ data: [{ api: 'M1101UserReportService.createReport', input }] })
    },
    // Admin save verdict
    saveVerdiction: ({ commit }, input) => {
      return ApiUtils.postData({ data: [{ api: 'M1201ReportCaseService.saveVerdiction', input }] })
        .then((data) => {
          return data[0].output.data[0]
        })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
