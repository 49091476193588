<template>
  <v-card
    :class="'px-5 mt-3 pb-3 filter-card ' + (dense ? 'pt-8' : 'pt-5')"
  >
    <v-form>
      <v-row
        v-for="(row, i) in value"
        :key="i"
      >
        <template
          v-for="(col, j) in row"
        >
          <div
            v-if="appAllowAccess(col.authenticated ? col.authenticated : false, col.roles ? col.roles : [], col.permissions ? col.permissions : [])"
            :key="j"
            :class="col.column + ' py-1'"
          >
            <component
              :is="col.component"
              v-if="col.component != 'slot'"
              v-model="col.vModel"
              v-bind="col.props"
              :dense="dense"
              @keyup="nextInput"
            />
            <slot
              v-else
              :name="col.props.name"
              :dense="dense"
              v-bind="col.props"
            />
          </div>
        </template>
      </v-row>
      <input
        class="hide"
        @focus="searchData"
      >
    </v-form>
    <v-row class="my-1 mr-2">
      <v-btn
        v-if="search"
        class="ml-auto white--text"
        color="orange"
        @click="clearFilter"
      >
        {{ $t('common.clear') }}
      </v-btn>
      <v-btn
        v-if="reset"
        class="ml-3 white--text"
        color="pink"
        @click="resetFilter"
      >
        {{ $t('common.reset') }}
      </v-btn>
      <v-btn
        v-if="clear"
        class="ml-3"
        color="primary"
        @click="searchData"
      >
        {{ $t('common.search') }}
      </v-btn>
    </v-row>
  </v-card>
</template>

<script>
import { VAutocomplete, VCombobox, VRadio, VRangeSlider, VSlider, VSelect, VSwitch, VTextField } from 'vuetify/lib'
import { AtCheckbox, AtDatePicker, AtTimePicker } from '@/components/base/atoms/input'
import ObjArrUtils from '@/utils/ObjArrUtils'

export default {
  components: {
    VAutocomplete,
    VCombobox,
    VRadio,
    VRangeSlider,
    VSlider,
    VSelect,
    VSwitch,
    VTextField,
    AtCheckbox,
    AtDatePicker,
    AtTimePicker
  },
  props: {
    value: {
      type: Array,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    search: {
      type: Boolean,
      default: true
    },
    reset: {
      type: Boolean,
      default: true
    },
    clear: {
      type: Boolean,
      default: true
    },
    removeEmpty: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeFilter: 0,
      filterValue: {},
      originalFilter: {}
    }
  },
  watch: {
    value: {
      handler () {
        this.activeFilter = 0

        this.value.forEach(row => {
          for (const key in row) {
            if (this.appAllowAccess(row[key].authenticated ?? false, row[key].roles ?? [], row[key].permissions ?? [])) {
              this.filterValue[key] = row[key].vModel
              if (!ObjArrUtils.isEmpty(row[key].vModel)) {
                this.activeFilter++
              } else {
                if (this.removeEmpty) delete (this.filterValue[key])
              }
            }
          }
        })

        this.$emit('update', ObjArrUtils.clone(this.filterValue))
        this.$emit('active', this.activeFilter)
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {
    this.originalFilter = ObjArrUtils.clone(this.value)
  },
  methods: {
    searchData () {
      this.$emit('search', ObjArrUtils.clone(this.filterValue))
    },
    resetFilter () {
      this.$emit('input', ObjArrUtils.clone(this.originalFilter))
      this.$nextTick(() => {
        this.$emit('reset', ObjArrUtils.clone(this.filterValue))
      })
    },
    clearFilter () {
      this.value.forEach(row => {
        for (const key in row) {
          row[key].vModel = row[key].clear ?? null
        }
      })
      this.$nextTick(() => {
        this.$emit('clear', ObjArrUtils.clone(this.filterValue))
      })
    }
  }
}
</script>

<style>
  .filter-card {
    width: 100%;
    border-bottom: var(--v-primary-base) solid 3px !important;
  }
  .hide {
    width: 0;
    height: 0;
  }
</style>
