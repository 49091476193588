import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'
import C0101DemoJson from './C0101DemoJson'
// const demo = require('./C0101DemoJson').default

const module = {
  state: {
    row: {},
    overview: {},
    adviseList: {},
    adviseDetails: {},
    updateList: {},
    partnerSeeking: {},
    partnerSeekingDetails: {},
    updateDetails: {},
    dummyData: {}
  },
  getters: {},
  mutations: {},
  actions: {
    async init({ commit, state, dispatch }, payload) {
      state.row = {}

      // TODO: Remove this line after API is ready
      // state.row = C0101DemoJson.row
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C0101ProjectService.doBrowse_projectOverview', input: {
              seo_url: payload.seo_url
            }
          }
        ]
      })
      state.row = res[0].output

      console.log('state.row', state.row)
      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async getOverview({ commit, state, dispatch }, payload) {
      state.overview = {}

      // TODO: Remove this line after API is ready
      // state.overview = C0101DemoJson.overview

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C0101ProjectService.getOverview', input: {
              seo_url: payload.seo
            }
          }
        ]
      })
      state.overview = res[0].output

      // console.log('state.overview', state.overview)
      return new Promise((resolve, reject) => {
        resolve(state.overview)
      })
    },
    async getAdviseList({ commit, state, dispatch }, payload) {
      state.adviseList = {}

      // TODO: Remove this line after API is ready
      // state.adviseList = C0101DemoJson.adviseList

      // TODO: pagination
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C4001ProjectAdviseService.getAdviseList', input: {
              seo_url: payload.seo_url
            }
          }
        ]
      })
      state.adviseList = res[0].output

      console.log('state.adviseList', state.adviseList)
      return new Promise((resolve, reject) => {
        resolve(state.adviseList)
      })
    },
    async getAdviseDetails({ commit, state, dispatch }, payload) {
      state.adviseDetails = {}

      // TODO: Remove this line after API is ready
      // state.adviseDetails = C0101DemoJson.adviseDetails
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C4001ProjectAdviseService.getAdviseDetail', input: {
              id: payload.id,
              seo_url: payload.seo_url
            }
          }
        ]
      })
      state.adviseDetails = res[0].output

      // console.log('state.adviseDetails', state.adviseDetails)
      return new Promise((resolve, reject) => {
        resolve(state.adviseDetails)
      })
    },
    async getProjectUpdate({ commit, state, dispatch }, payload) {
      state.updateList = {}

      // TODO: Remove this line after API is ready
      // state.updateList = C0101DemoJson.updateList
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C0401ProjectUpdateService.doGetProjectUpdate', input: {
              seo_url: payload.seo_url
            }
          }
        ]
      })
      state.updateList = res[0].output

      console.log('state.updateList', state.updateList)
      return new Promise((resolve, reject) => {
        resolve(state.updateList)
      })
    },
    async getInvestMentPackageList({ commit, state, dispatch }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C2101ProjectInvestmentPackageService.getInvestMentPackageList', input: {
              seo_url: payload.seo
            }
          }
        ]
      })
      state.investmentPackageList = res[0].output

      // console.log('state.overview', state.overview)
      return new Promise((resolve, reject) => {
        resolve(state.investmentPackageList)
      })

    },
    async submitInvestmentForm({ commit, state, dispatch }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C2101ProjectInvestmentPackageService.doAdd', input: payload
          }
        ]
      })

      return res
    },
    async getinvestmentOverview({ commit, state, dispatch }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C2001ProjectInvestmentService.investmentOverview', input: {
              seo_url: payload.seo
            }
          }
        ]
      })
      state.investmentOverview = res[0].output

      // console.log('state.overview', state.overview)
      return new Promise((resolve, reject) => {
        resolve(state.investmentOverview)
      })

    },
    async getUpdateDetails({ commit, state, dispatch }, payload) {
      state.updateDetails = {}

      // TODO: Remove this line after API is ready
      // state.updateDetails = C0101DemoJson.updateDetails

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C0401ProjectUpdateService.doGetProjectUpdateDetail', input: {
              seo_url: payload
            }
          }
        ]
      })
      state.updateDetails = res[0].output[0]

      // console.log('state.overview', state.overview)
      return new Promise((resolve, reject) => {
        resolve(state.updateDetails)
      })
    },
    async getDummyData({ commit, state, dispatch }, payload) {
      state.dummyData = {}

      // TODO: Remove this line after API is ready
      state.dummyData = C0101DemoJson.dummyData

      // const res = await ApiUtils.postData({
      //   data: [
      //     {
      //       api: 'C0101ProjectService.getOverview', input: {
      //         seo_url: payload.seo
      //       }
      //     }
      //   ]
      // })
      // state.overview = res[0].output

      // console.log('state.overview', state.overview)
      return new Promise((resolve, reject) => {
        resolve(state.dummyData)
      })
    },
    async getPartnerSeeking({ commit, state, dispatch }, payload) {
      state.partnerSeeking = {}

      // TODO: Remove this line after API is ready
      // state.partnerSeeking = C0101DemoJson.partnerseeking

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T1001JobPostingRequirementService.getJobPostingList', input: {
              seo_url: payload.seo_url
            }
          }
        ]
      })
      state.partnerSeeking = res[0].output

      // console.log('state.overview', state.overview)
      return new Promise((resolve, reject) => {
        resolve(state.partnerSeeking)
      })
    },
    async getPartnerSeekingDetails({ commit, state, dispatch }, payload) {
      state.partnerSeekingDetails = {}

      // TODO: Remove this line after API is ready
      // state.partnerSeekingDetails = C0101DemoJson.partnerseekingDetails

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T1001JobPostingRequirementService.getJobPostingDetail', input: {
              t1001_id: payload.t1001_id
            }
          }
        ]
      })
      state.partnerSeekingDetails = res[0].output

      // console.log('state.overview', state.overview)
      return new Promise((resolve, reject) => {
        resolve(state.partnerSeekingDetails)
      })
    },
    async submitJobApplication({ commit, state, dispatch }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T1101JobPostingApplicationService.submitApplication', input: payload
          }
        ]
      })

      return res
    },

    async doAddAnswer({ state }, payload) {
      const res = await ApiUtils.postData({
        data: [{
          api: 'C4001ProjectAdviseService.doAddAnswer',
          input: payload
        }]
      })

      return res[0].output ?? {}
    }

  }
}

export default ObjArrUtils.merge(Base, error, module)
