import i18n from '@/plugins/i18n'

export default [
  {
    // ! deprecated path
    path: '/Contact',
    redirect: { name: 'ContactUs' }
  },
  {
    portalGroup: 'master.public',
    path: 'contact',
    name: 'ContactUs',
    component: () => import('@/app/M2501Enquiry/PgContactPage.vue'),
    meta: { title: i18n.t('m2501.contact.label') }
  },
  {
    portalGroup: 'admin.admin',
    path: 'ManageContactForm',
    name: 'ManageContactForm',
    component: () => import('@/app/M2501Enquiry/PgManageContactForm.vue'),
    meta: { authenticated: true, title: i18n.t('m2501.manageEnquiries.label'), permissions: ['enquiryForm.manage'] }
  }
]
