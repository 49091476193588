import i18n from '@/plugins/i18n'

export default [
  {
    path: '/meeting',
    name: 'OnlineMeetingZoomClient',
    component: () => import('./OnlineMeeting/ZoomClient/PgZoomClient.vue')
  },
  {
    portalGroup: 'all.my',
    path: 'meetings',
    name: 'M3801MyBrowse',
    component: () => import('./PgM3801List.vue'),
    meta: { title: i18n.t('m3801.sidebarTitle'), authenticated: true }
  }
]
