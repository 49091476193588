import Vue from 'vue'
import Echo from 'laravel-echo'
import $ from 'jquery'
import Pusher from 'pusher-js'


let echo = {

}

if (import.meta.env.VITE_APP_WS_BROADCASTER) {
  window.Pusher = Pusher
  const config = {
    broadcaster: import.meta.env.VITE_APP_WS_BROADCASTER,
    key: import.meta.env.VITE_APP_WS_KEY,
    cluster: import.meta.env.VITE_APP_WS_CLUSTER,
    wsHost: import.meta.env.VITE_APP_WS_HOST,
    wsPort: import.meta.env.VITE_APP_WS_PORT ,
    wssPort: import.meta.env.VITE_APP_WSS_PORT,
    forceTLS: import.meta.env.VITE_APP_WS_FORCE_TLS === 'true',
    encrypted: import.meta.env.VITE_APP_WS_ENCRYPTED === 'true',
    disableStats: import.meta.env.VITE_APP_WS_DISABLE_STATS === 'true',
    authEndpoint: import.meta.env.VITE_API_URL + 'broadcasting/auth',
  }

  echo = new Echo({
    ...config,

    authorizer: (channel, options) => {
      return {
        authorize: (socketId, _callback) => {
          $.ajaxSetup({
            crossDomain: true,
            xhrFields: {
              withCredentials: true
            }
          })
          $.post(import.meta.env.VITE_API_URL + 'broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name,
            xhrFields: {
              withCredentials: true
            }
          })
            .then(response => {
              _callback(false, response)
            })
            .catch(error => {
              console.log(error)
              _callback(true, error)
            })
        }
      }
    }
  })

  Vue.prototype.$echo = echo
}


export default echo
