import ApiUtils from '@/utils/api/ApiUtils'
import C2301Const from './C2301Const'

// TODO: can i know what storeConst properties are available? (using intelisense)
const store = {
  state: {
    // custom state
    packagesInfo: {}
  },
  getters: {
    // custom getter
    getPackagesInfo: (state) => state.packagesInfo
  },
  mutations: {
    // custom mutation
    SET_PACKAGES_INFO (state, payload) {
      state.packagesInfo = payload
    }
  },
  actions: {
    // override action  
    async getPackagesInfo ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: 'C2101ProjectInvestmentPackageService.getPackagesInfo', input: payload }
        ]
      })
      commit('SET_PACKAGES_INFO', res[0].output)
    },
    createInvestmentRequest ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [
          { api: 'C2301ProjectInvestmentInvestService.createInvestmentRequest', input: payload }
        ]
      })
    }
  }
}

export default C2301Const.getStore(store)
