import ApiUtils from '@/utils/api/ApiUtils'
import FnConst from './P0101UserConst'

// TODO: can i know what storeConst properties are available? (using intelisense)
const StoreConst = FnConst.storeConst
const store = {
  state: {
    // custom state
    searchInfo: { status: 'recommend' },
    stats: {}

  },
  getters: {
    // custom getter
    getStats: (state) => state.stats
  },
  mutations: {
    // custom mutation

    SET_STATS (state, payload) {
      state.stats = payload
    }
  },
  actions: {
    // override action  
    async doBrowse ({ commit, state }, payload) {
      console.debug('doBrowse', payload)

      const val = {
        searchInfo: state.searchInfo,
        orderBy: [],
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.getStats.api, input: null },
          { api: StoreConst.ACTION.doBrowse.api, input: val }
        ]
      })
      commit('SET_STATS', res[0].output)
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
    },
    async getStats ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.getStats.api, input: null }]
      })
      commit('SET_STATS', res[0].output)
    },
    async addUserReaction ({ commit, state }, payload) {
      // alert('addUserReaction')
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.addUserReaction.api, input: payload },
          { api: StoreConst.ACTION.getStats.api, input: null }
        ]
      })
      commit('SET_STATS', res[1].output)
      console.debug('addUserReaction', res)
    }
  }
}
export default FnConst.getStore(store)
