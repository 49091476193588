export default [{
  path: '/Example',
  component: { render: h => h('router-view')},
  meta: { debug: true },
  children: [
    {
      path: '',
      name: 'Example',
      component: () => import('@/components/examples/Example.vue'),
      meta: { debug: true }
    },
    {
      path: 'AllCompsExample',
      name: 'AllCompsExample',
      component: () => import('@/components/examples/AllCompsExample.vue'),
      meta: { debug: true }
    },
    /*
    {
      path: 'Category',
      name: 'CategoryList',
      component: () => import('@/app/M0601Category/CategoryList/PgCategoryList.vue'),
      meta: { debug: true }
    },
    {
      path: 'Category/Add',
      name: 'CategoryAdd',
      component: () => import('@/app/M0601Category/CategoryList/PgCategoryList.vue'),
      meta: { debug: true }
    },
    {
      path: 'Category/Edit/:id(\\d+)',
      name: 'CategoryEdit',
      component: () => import('@/app/M0601Category/CategoryList/PgCategoryList.vue'),
      meta: { debug: true }
    },
    */
    {
      path: 'StripeCheckoutExample',
      name: 'StripeCheckoutExample',
      component: () => import('@/components/examples/Stripe/StripeCheckoutExample.vue'),
      meta: { debug: true }
    },
    {
      path: 'ConvertToModuleCss',
      name: 'ConvertToModuleCss',
      component: () => import('@/components/examples/ConvertToModuleCss.vue'),
      meta: { debug: true }
    },
    {
      path: 'ApprovalDemo',
      name: 'ApprovalDemo',
      component: () => import('@/components/examples/TTCApprovalWrapper/TTCApprovalExample.vue'),
      meta: { debug: true }
    },
    {
      path: 'refund',
      name: 'refundExample',
      component: () => import('@/components/examples/RefundModule/exampleRefundModule.vue'),
      meta: { debug: true }
    }
  ]
}
]
