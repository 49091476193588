import FnConst from './P0201VersionConst'

// TODO: can i know what storeConst properties are available? (using intelisense)
// const StoreConst = FnConst.storeConst
const store = {
  state: {
  },
  getters: {
  },
  mutations: {

  },
  actions: {

  }
}
export default FnConst.getStore(store)
