import ApiUtils from '@/utils/api/ApiUtils'
import C1301Const from './C1301Const'

const store = {
  state: {
    tierInfo: {},
    shippingInfoCountries: null
  },
  getters: {
    getTierInfos: (state) => state.tierInfo,
    getShippingInfoCountries: (state) => state.shippingInfoCountries
  },
  mutations: {
    SET_TIER_INFOS (state, payload) {
      state.tierInfo = payload
    },
    SET_SHIPPING_INFO_COUNTRIES (state, payload) {
      state.shippingInfoCountries = payload
    }
  },
  actions: {
    async getTierInfos ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: 'C1101ProjectCrowdFundTierService.getTierInfos', input: payload }
        ]
      })
      commit('SET_TIER_INFOS', res[0].output)
      return res
    },
    async getShippingInfoCountries ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: 'C1101ProjectCrowdFundTierService.getShippingInfoCountries', input: payload }
        ]
      })
      if (res[0].output !== null) {
        commit('SET_SHIPPING_INFO_COUNTRIES', res[0].output.shippingPrice)
      }
    },
    async checkout ({ commit, state }, payload) {
      return await ApiUtils.postData({
        data: [
          { api: 'C1101ProjectCrowdFundTierService.checkout', input: payload }
        ]
      })
    }
  }
}

export default C1301Const.getStore(store)
