import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, error } from '@/store/base/module'

const store = {
  state: {
    status: 'follow',
    UserInformation: null,
    levelNumber: null,
    userList: null,
    selectLevel: 'ALL',
    per_page: 10,
    current_page: 1,
    getInterestUser: null,
    userProfile: null,
    categoryList: null,
    sizeList: null,
    serviceProduct: null,
    achuevementList: null
  },
  actions: {
    getFollowNumber: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M3601FollowsService.getFollowNumber', input: payload }]
      }).then(data => {
        state.levelNumber = [{
          user_type: 'ALL',
          count: data[0].output.reduce((acc, item) => acc + item.count, 0)
        }, ...data[0].output]
      })
    },
    doBrowse: ({ state }, payload) => {
      // state.userList = null
      return ApiUtils.postData({
        data: [{ api: 'M3601FollowsService.doBrowse', input: payload }]
      }).then(data => {
        state.userList = data[0].output.data
        return data[0].output
      })
    },
    setState: ({ state }, payload) => {
      state.status = payload
      state.selectLevel = 'ALL'
    },
    searchFollow: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M3601FollowsService.searchFollow', input: payload }]
      }).then(data => {
        state.followerList = data[0].output
      })
    },
    getUserDetail: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M3601FollowsService.getUserDetail', input: payload }]
      }).then(data => {
        state.UserInformation = data[0].output
        return data[0].output
      })
    },
    setSelectLevel: ({ state }, payload) => {
      state.selectLevel = payload
    },
    setCurrentPage: ({ state }, payload) => {
      state.current_page = payload
    },
    getInterestUser: ({ state }, payload) => {
      // state.userList = null
      return ApiUtils.postData({
        data: [{ api: 'M3601FollowsService.getInterestUser', input: payload }]
      }).then(data => {
        state.getInterestUser = data[0].output
      })
    },
    getUserProfile: ({ state }, payload) => {
      // state.userProfile = null
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getUserDetail', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output
      })
    },
    getEducation: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getUserEducation', input: payload }]
      }).then(data => {
        state.userProfile.education = data[0].output
      })
    },
    getUserJobExp: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getUserJobExp', input: payload }]
      }).then(data => {
        state.userProfile.userJobExp = data[0].output
      })
    },
    getSchool: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0020UserEducationService.searchEnterprise', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getEnterprise: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.searchEnterprise', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getlocation: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.getLocation', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getEnterpriseUserTitle: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M1604EnterpriseUserService.getEnterpriseUserTitle', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getCategory: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0601CategoryService.doBrowse', input: payload }]
      }).then(data => {
        state.categoryList = data[0].output
        return data[0].output
      })
    },
    getCurrentEnterprise: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.getCurrentEnterprise', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getCurrentJob: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.getCurrentJob' }]
      }).then(data => {
        return data[0].output
      })
    },
    searchLocation: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M1603EnterpriseLocationService.setLocation', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getEducationData: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0020UserEducationService.getEducation', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    addEducation: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0020UserEducationService.addEducation', input: payload }]
      }).then(data => {
        // state.userProfile.education.push(data[0].output)
        state.userProfile = data[0].output
        return data[0].output
      })
    },
    editEducation: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0020UserEducationService.editUserEducation', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output
        return data[0].output
      })
    },
    deleteEducation: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0020UserEducationService.deleteUserEducation', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output
        return data[0].output
      })
    },
    addJobExp: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.addJobExp', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output.userProfile
        state.UserInformation = data[0].output.userDetail
        return data[0].output
      })
    },
    editJobExp: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.editJobExp', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output.userProfile
        state.UserInformation = data[0].output.userDetail
        return data[0].output
      })
    },
    deleteJob: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.deleteUserJob', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output.userProfile
        state.UserInformation = data[0].output.userDetail
        return data[0].output
      })
    },
    getLabel: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.getLabel' }]
      }).then(data => {
        state.sizeList = data[0].output
        return data[0].output
      })
    },
    getUserJobExpDetail: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0030UserJobExpService.getUserJobExp', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    addCertification: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0040UserCertService.addCertification', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output
        return data[0].output
      })
    },
    getCertDetail: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0040UserCertService.getUserCert', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    editUserCert: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0040UserCertService.editUserCert', input: payload }]
      }).then(data => {
        // state.userProfile.LiecenseOrCertification = state.userProfile.LiecenseOrCertification.map((item) => {
        //   if (item.id !== payload.id) return item
        //   const newItem = item
        //   console.log(payload)
        //   newItem.user_cert_name = payload.cert_name
        //   newItem.user_cert_issuing_org = payload.issuing_org
        //   newItem.user_cert_issue_date = payload.issue_date
        //   return newItem
        // })
        state.userProfile = data[0].output
        return data[0].output
      })
    },
    delete_cert: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0040UserCertService.delete_cert', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output
        return data[0].output
      })
    },
    getUserSKill: ({ state }) => {
      return ApiUtils.postData({
        data: [{ api: 'M0010UserSkillsService.getUserSKill' }]
      }).then(data => {
        return data[0].output
      })
    },
    updateUserSkill: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0010UserSkillsService.updateUserSkill', input: payload }]
      }).then(data => {
        state.userProfile = data[0].output
        return data[0].output
      })
    },
    getEnterpriseMember: ({ state }, payload) => {
      // state.userList = null
      return ApiUtils.postData({
        data: [{ api: 'M1604EnterpriseUserService.getEnterpriseMember', input: payload }]
      }).then(data => {
        state.userList = data[0].output.data
        return data[0].output
      })
    },
    getUserComments: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M1501CommentService.getUserComments', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    submitUserComments: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M1501CommentService.doAdd', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    updateUserComments: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M1501CommentService.doEdit', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getUserPublish: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getUserPost', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getUserActivities: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getUserActivities', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    async getSurvice({ commit, state }, payload) {
      state.surveyData = {}

      return ApiUtils.postData({
        data: [
          {
            api: 'T2001ServiceService.getOwnService', input:payload
          }
        ]
      }).then(data => {
        state.serviceProduct = data[0]?.output?.data
        return data[0].output
      })
    },
    getEdit_profileDetail: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getEdit_profileDetail', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    doEdit_profileDetail: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.doEdit_profileDetail', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    async getAchievement({ commit, state }, payload) {
      state.achuevementList = {}

      return ApiUtils.postData({
        data: [
          {
            api: 'T2001ServiceService.getAchievement', input:payload
          }
        ]
      }).then(data => {
        state.achuevementList = data[0]?.output?.data
        return data[0].output
      })
    },
  }
}

export default ObjArrUtils.merge(Base, error, store)
