import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C2301ProjectInvestmentInvest',
  serviceName: 'C2301ProjectInvestmentInvestService',

  componentDef: {
    form: {
      /*
       type: div, row, col, form, text,file,select, button,table
       component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
       rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewDef: [
        // {
        //   type: 'section',
        //   title: i18n.t('c0101.investment.requestTips'),
        //   class: 'text-body-1'
        // },
        {
          column: 'col-12',
          custom: 'RequestText',
          class: 'text-body-1'
        },
        {
          column: 'col-6',
          data: 'name',
          type: 'text',
          label: i18n.t('c0101.form.name'),
          rules: 'required',
          class: 'required',
          labelHint: i18n.t('c0101.formHints.name')
        },
        {
          column: 'col-6',
          data: 'companyName',
          type: 'text',
          label: i18n.t('c0101.form.companyName'),
          rules: 'required',
          labelHint: i18n.t('c0101.formHints.companyName'),
          class: 'required'
        },
        {
          column: 'col-12',
          data: 'remark',
          type: 'textarea',
          label: `${i18n.t('c0101.form.introduction')} / ${i18n.t('c0101.form.remarks')}`,
          labelHint: i18n.t('c0101.formHints.remarks')
        },
        {
          column: 'col-12',
          data: 'document',
          type: 'file',
          label: i18n.t('c0101.form.documents'),
          labelHint: i18n.t('c0101.formHints.documents'),
          props: {
            maxFiles: 3
          }
        },
        // {
        //   type: 'section',
        //   title: i18n.t('c0101.investment.packageTips'),
        //   class: 'text-body-1'
        // },
        {
          column: 'col-12',
          custom: 'PackageText',
          class: 'text-body-1'
        },
        {
          column: 'col-12',
          data: 'package',
          custom: 'PackageComponent',
          class: 'text-body-1',
          props: {
            itemValue: 'id',
            multiple: true,
            delimited: ','
          }
        },
        // {
        //   type: 'section',
        //   title: i18n.t('c0101.investment.preliminaryOfferTips'),
        //   class: 'text-body-1'
        // },
        {
          column: 'col-12',
          custom: 'BudgetText',
          class: 'text-body-1'
        },
        {
          column: 'col-12',
          data: 'budget',
          type: 'textarea',
          label: `${i18n.t('c0101.form.budget')} / ${i18n.t('c0101.form.offers')}`,
          labelHint: i18n.t('c0101.formHints.budget')
        },
        {
          column: 'col-12',
          custom: 'PolicyDivider'
        },
        {
          type: 'ttc_policy',
          data: 'policyInvestment',
          props: {
            policyCode: 'collab_projectInvestment'
          }
        },
        {
          type: 'ttc_policy',
          data: 'policyInvestmentNDA',
          props: {
            policyCode: 'collab_investment.nda'
          }
        }
      ],
      optionDef: {}
    }
  }
}
const C2301Const = new BaseDataStore(BaseConst)
export default C2301Const
