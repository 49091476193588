import AppUtils from '@/utils/AppUtils'
export default {
  data () {
    return {
      baseDebug: import.meta.env.VITE_DEBUG
    }
  },
  computed: {
    // ! deprecated
    localDebug: {
      get () {
        return !this.mockProduction
      },
      set (val) {
        this.mockProduction = !val
      }
    },
    mockProduction: {
      get () {
        return !AppUtils.isDebugMode()
      },
      set (val) {
        localStorage.setItem('mockProduction', val ? 1 : 0)
      }
    }
  },
  methods: {
    // ! deprecated
    toggleDebug () {
      this.localDebug = !this.localDebug
      alert('(Reload window) MockProduction: ' + !this.localDebug)
      window.location.reload()
    },
    toggleMockProduction () {
      this.mockProduction = !this.mockProduction
      alert('(Reload window) MockProduction: ' + this.mockProduction)
      window.location.reload()
    }
  }
}
