<template>
  <!-- TODO: [Adrian] This components does not use value for any purpose as it is not planned to become a global component at the moment due to fast development needed -->
  <v-row>
    <v-col cols="9">
      <AtTextField
        v-model="amount"
        v-bind="$attrs"
      />
    </v-col>
    <v-col cols="3">
      <v-select
        v-model="time"
        outlined
        :items="items"
      />
    </v-col>
  </v-row>
</template>

<script>
import AtTextField from '../AtTextField/AtTextField.vue'
import moment from 'moment'

export default {
  name: 'AtDuration',
  components: { AtTextField },
  data () {
    return {
      time: 'd',
      amount: 30,
      items: [
        {
          text: '天',
          value: 'd'
        },
        {
          text: '個月',
          value: 'M'
        },
        {
          text: '年',
          value: 'y'
        }
      ]
    }
  },

  watch: {
    time: {
      handler (value) {
        this.$emit('input', moment().add(this.amount, value))
      },
      immediate: true
    },
    amount: {
      handler (value) {
        this.$emit('input', moment().add(value, this.time))
      },
      immediate: true
    }
  }
}
</script>
