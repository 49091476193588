import ObjArrUtils from '@/utils/ObjArrUtils'
import ApiUtils from '@/utils/api/ApiUtils'
import AppUtils from './AppUtils'

/** @module StoreUtils */

function warnNoCtx (ctx,caller) {
  if (!ctx)
    console.error(`No Vue instance context found. Please use \`this.${caller}\`(Global Mixin) instead of \`StoreUtils.${caller}\` in Vue component.`, `Caller: ${caller}`)
}

/**
 * Utilities for application
 * @property {Function} storeApiDispatch Utilities for dispatching store action
 *
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/20
 */
const StoreUtils = {
  /**
   * Utilities for dispatching store action
   *
   * @param {String} dispatchAction Store Action to be called on
   * @param {Object} data Data to be passed to action
   * @param {Object} options Options to be passed to action
   * - loading {Boolean}: [Default: `true`] Whether to show loading animation
   * - callback {Function}: [Default: Empty Function] Callback function
   * - errorDialog {Boolean}: [Default: `true`] Whether to show dialog when error happens
   * - errorTitle {String}: [Default: `common.messages.errorTitle`] Default error title
   * - errorMessage {String}: [Default: `common.messages.errorMessage`] Default error message
   * @param {Vue} ctx Vue instance
   * @returns {Promise} Store action returned promise
   */
  storeApiDispatch (dispatchAction, data = {}, options = {}, ctx = null) {
    warnNoCtx(ctx,'storeApiDispatch')
    return baseDispatch(
      function (mergedOptions) {
        const module = dispatchAction.split('/')[0]
        if (mergedOptions.clearError) ctx.$store.commit(module + '/REMOVE_ERRORS')
        return ctx.$store.dispatch(dispatchAction, data)
      }, options, ctx)
  },
  /**
   *
   * @param {Object} input
   * @param {Object} options
   * @param {Vue} ctx
   *
   * @returns {Promise}
   * @example this.apiDispatch({ api: 'E0001DemoService.action', data: { data } })
   */
  apiDispatch (input, options = {}, ctx = null) {
    warnNoCtx(ctx,'apiDispatch')
    return baseDispatch(
      function () {
        return ApiUtils.postData({ data: input })
      }, options, ctx)
  },
  /**
   *
   * @param {Vue} ctx
   * @param {String} store
   * @param {Array} dispatchActions
   * @returns
   */
  mapStoreApiDispatch (ctx,store, dispatchActions)
  {
    const dispatch = {}

    // if not array, throw error
    // TODO: support object (key-value) dispatchActions
    if (!Array.isArray(dispatchActions)) {
      console.error('dispatchActions must be an array of strings')
      return
    }

    for (const key in dispatchActions) {
      dispatch[key] = (data=undefined, options=undefined) => {
        return StoreUtils.storeApiDispatch( `${store}/${dispatchActions[key]}`, data, options, ctx)
      }
    }
    return dispatch
  }
}

/**
 *
 * @param {Function} callback
 * @param {Object} options
 * @param {Vue} ctx
 * @returns
 */
function baseDispatch (callback, options = {}, ctx) {
  const defaultOptions = {
    loading: true,
    clearError: true,
    callback: (res) => { },
    errorDialog: true,
    // throwOnError: false,
    // errorTitle: ctx.$t('common.messages.errorTitle'),
    // errorMessage: ctx.$t('common.messages.errorMessage'),
    errorTitle: '系统错误',
    errorMessage: '很抱歉，遇到了些许问题. 正在努力修复 , 请稍后再试',
    showDebugError: AppUtils.isDebugMode()
  }
  const mergedOptions = ObjArrUtils.merge(defaultOptions, options)

  const load = (mergedOptions.loading ? 1 : 0)
  ctx.$store.commit('App/ADJUST_APP_LOADING', load)

  return callback(mergedOptions)
    .then(res => {
      mergedOptions.callback(res)
      return res
    })
    .catch((err) => {
      let res

      // IF is not ApiError, throw error
      if(!(err instanceof ApiUtils.ApiError)) throw err
      try {
        res = JSON.parse(err.message)
      } catch (e) {
        ctx.$store.commit('Notification/SET_DIALOG_ERROR', {
          title: 'Framework Error',
          text: 'Backend response is not a valid JSON. Please contact the administrator.'
        })
        throw err
      }
      let message = (typeof res === 'object' && res.responseJSON)
        ? res.responseJSON.message
        : res
      const status = res.status ?? 0

      switch (true) {
        // for input form
        case status === 422:
          message = ObjArrUtils.isJsonString(res.responseJSON.message)
            ? JSON.parse(res.responseJSON.message)
            : message
          mergedOptions.errorMessage = message['422'] ?? ctx.$t('common.messages.errorInput')
          ctx.$store.commit('Notification/SET_NOTIFY_ERROR', {
            text: mergedOptions.errorMessage
          })
          break
        case status === 400:
          ctx.$dialog.notify.info('错误操作:'+message)
          break
        case status === 401 && !!localStorage.getItem('token'):
          ctx.$store.dispatch('Auth/logout')
          ctx.$router.replace({ name: 'Login',query:{next:ctx.$router.currentRoute.fullPath}})
          ctx.$dialog.notify.info(ctx.$t('utils.storeMixin.sessionExpired'))
          break
        case status === 401 :
          ctx.$router.replace({ name: 'Login',query:{next:ctx.$router.currentRoute.fullPath} })
          ctx.$dialog.notify.info(ctx.$t('utils.storeMixin.needLogin'))
          break
        case status === 403:
          ctx.$router.replace({ name: 'Home' })
          ctx.$dialog.notify.info(ctx.$t('utils.storeMixin.notAuthorized'))
          break
        case status === 404:
          ctx.$router.replace({ name: '404' })
          ctx.$dialog.notify.info(ctx.$t('utils.storeMixin.lost'))
          break
        case status === 408:
          ctx.$dialog.notify.info('数据请求超时，将自动刷新页面')
          setTimeout(() => {
            location.reload()
          }, 3000)
          break
        case res.statusText === 'abort':
          // do nothing
          return
        case status === 0: // Network error
          // TODO: network error page
          ctx.$store.commit('Notification/SET_NOTIFY_ERROR', {
            title: 'Backend Offline',
            text: ctx.$t('utils.storeMixin.backendOffline')
          })
          break
        // show debug error message
        case mergedOptions.showDebugError:
          ctx.$dialog.error({
            title: mergedOptions.errorTitle,
            text: `[${message}] \n Please check the console for more details.`,
            actions: {
              true: 'OPEN DEBUG URL'
            }
          }).then(ans => {
            if (ans) {
              const targetUrl = res.responseJSON.debug_url
              window.open(targetUrl, '_blank')
            }
          })
          console.error('Error:', res.responseJSON)
          break
        // default error message, for production
        default:
          ctx.$dialog.notify.error(mergedOptions.errorTitle+ ': ' +mergedOptions.errorMessage)
          break
      }
      throw err
    })
    .finally(() => {
      ctx.$store.commit('App/ADJUST_APP_LOADING', -load)
    })
}
export default StoreUtils
