import ApiUtils from '@/utils/api/ApiUtils'
import C0101Const from './C0101Const'
import i18n from '@/plugins/i18n'
// import C1001Const from '../C1001ProjectCrowdFund/C1001Const'
// import C2001Const from '../C2001ProjectInvestment/C2001Const'
// import C3001Const from '../C3001ProjectPartnerRequirement/C3001Const'
// import C4001Const from '../C4001ProjectAdvise/C4001Const'


// 'DRAFT','PENDING','APPROVED','AMENDMENT','REJECTED','SCHEDULED','PUBLISHED','PREPARE',
//  'FUND_FALIED', 'DEVELOPMENT', 'DELIVERED', 'ARCHIVED', 'DELETED', 'REMOVED'

// button: moresetting(gearIcon), preview, submit, save, publish
// tab: edit, update, promotion
const statusMap={
  DRAFT: {
    text: i18n.t('t3001.status.DRAFT'),
    color: 'grey',
    topBtnList: ['submit','save', 'moresetting'],
    editBtnList: ['submit','save', 'moresetting'],
    canEdit: true, // got `doSave` button == canEdit,
    showTabsList: ['edit'],
  },
  PENDING: {
    text: i18n.t('t3001.status.PENDING'),
    color: 'orange',
    topBtnList: ['moresetting','preview'],
    editBtnList: ['moresetting','preview'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  APPROVED: {
    text: i18n.t('t3001.status.APPROVED'),
    color: 'green',
    topBtnList: ['publish','preview', 'save'],
    editBtnList: ['publish','preview', 'save'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit'],
  },
  AMENDMENT: {
    text: i18n.t('t3001.status.AMENDMENT'),
    color: 'orange',
    topBtnList: ['moresetting','preview', 'save', 'submit'],
    editBtnList: ['moresetting','preview', 'save', 'submit'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit'],
  },
  // it will be `UNPUBLISHED` if creator not choose to publish when admin approved
  REJECTED: {
    text: i18n.t('t3001.status.REJECTED'),
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['moreSetting','preview'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  SCHEDULED: {
    text: i18n.t('t3001.status.SCHEDULED'),
    color: 'orange',
    topBtnList: ['save','preview'],
    editBtnList: ['save','preview'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit'],
  },
  PUBLISHED: {
    text: i18n.t('t3001.status.PUBLISHED'),
    color: 'green',
    topBtnList: ['save','preview'],
    editBtnList: ['save','preview'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments'],
  },

  PREPARE: {
    text: i18n.t('t3001.status.PREPARE'),
    color: 'green',
    topBtnList: ['save','preview'],
    editBtnList: ['save','preview'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments'],
  },

  FUND_FALIED: {
    text: i18n.t('t3001.status.FUND_FALIED'),
    color: 'green',
    topBtnList: ['save','preview'],
    editBtnList: ['save','preview'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments'],
  },

  DEVELOPMENT: {
    text: i18n.t('t3001.status.DEVELOPMENT'),
    color: 'green',
    topBtnList: ['save','preview'],
    editBtnList: ['save','preview'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments'],
  },

  ARCHIVED: {
    text: i18n.t('t3001.status.ARCHIVED'),
    color: 'green',
    topBtnList: ['preview'],
    editBtnList: ['preview'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments'],
  },

  DELIVERED: {
    text: i18n.t('t3001.status.DELIVERED'),
    color: 'green',
    topBtnList: ['save','preview'],
    editBtnList: ['save','preview'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments'],
  },

  DELETED: {
    text: i18n.t('t3001.status.DELETED'),
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  },
  REMOVED: {
    text: i18n.t('t3001.status.REMOVED'),
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  }
}

const PageConst = {
  REVIEW_DEF:{
    rel_model: 'C0101Project',
    key: 'project.project.publish',
  },
}

function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}

const StoreConst = C0101Const.storeConst
const store = {
  state: {
    forms: [],
    orderBy: { order_created_at: 'DESC' },
    tag: [],
    category: [],
    label: [],
    row: {},
    // vvvv temp vvvv
    temp: null,
    tempQuestionForm: null,
    // ^^^^ temp ^^^^
    projectlist: [],
    questionList: [],
    coinACurrencyList: [],
    industryLabel: [],
    projectAdviseTypeLabel: [],
    currencyInfo: {},
    // validation info
    // component_name => field
    validationForm: {
      C0101FormBasics: { isValid: true },
      C0105FormPolicy: { isValid: true },
      C0101FormInformation: { isValid: true },
    },
    formInit: false,
    questionAnswerList: [],
    statusMap,
    statusMapArray: getMapArray(statusMap),
    PageConst
  },
  getters: {
    getForms: state => state.forms,
    getPagination: (state) => state.pagination,
    getSearchInfo: (state) => state.searchInfo,
    getOrderBy: (state) => state.orderBys,
  },
  mutations: {
    SET_ROW_MERGE(state, payload) {
      state.row = { ...state.row, ...payload }
    },
    SET_FORMS(state, payload) {
      state.forms.push(payload)
    },
    SET_ROW(state, payload) {
      state.row = payload
    },
    SET_crowdFund_tier(state, payload) {
      if (state.row.c1001_project_crowd_fund.c1101_project_crowd_fund_tier === undefined) {
        state.row.c1001_project_crowd_fund.c1101_project_crowd_fund_tier = []
      }
      state.row.c1001_project_crowd_fund.c1101_project_crowd_fund_tier.push(payload)
    },
    SET_edit_crowdfund_tier(state, payload) {
      const index = state.row.c1001_project_crowd_fund.c1101_project_crowd_fund_tier.findIndex(tier => tier._id === payload._id)

      state.row.c1001_project_crowd_fund.c1101_project_crowd_fund_tier[index] = payload
    },
    DELETE_crowdfund_tier(state, payload) {
      const index = state.row.c1001_project_crowd_fund.c1101_project_crowd_fund_tier.findIndex(tier => tier._id === payload._id)

      state.row.c1001_project_crowd_fund.c1101_project_crowd_fund_tier.splice(index, 1)
    },
    // mutatuon used by temp
    SET_TEMP_c1001_project_crowd_fund(state, payload) {
      state.temp = null
      state.temp = payload ?? null
    },
    REVERT_TEMP_c1001_project_crowd_fund(state) {
      state.row.c1001_project_crowd_fund = state.temp
    },
    UPDATE_itemCounter(state, payload) {

      console.log(payload)

      const index = state.row.c1001_project_crowd_fund.c1201_project_crowd_fund_item.findIndex(item =>
        item._id === payload._id
      )
      if (index !== -1) {
        state.row.c1001_project_crowd_fund.c1201_project_crowd_fund_item[index]._useCount += payload.count ?? 0
      }
    },
    // payload = { property: 'property_name(state)', value: 'value' }
    SET_TEMP(state, payload) {
      state[payload.property] = payload.value
    },
    RESET_projectlist(state) {
      state.projectlist = []
    }

  },
  actions: {
    async doEdit({ state, dispatch }) {
      return await ApiUtils.postData({
        data: [{ api: 'C0101ProjectService.doEdit', input: state.row }]
      })
    },
    async doSubmit({ state, dispatch }) {
      dispatch('doEdit')
      return await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doSubmit.api, input: { id: state.row.id } }]
      })
    },
    async reviewComplete({ state, dispatch }, payload) {
      return await ApiUtils.postData({
        data: [{ api: 'C0101ProjectService.reviewComplete', input: payload }]
      })
    },
    async init_Tag({ state }) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M3901GuideService.doGet_atFormHint',
          input: 'learn.contentTag'
        }]
      })

      state.tag = data[0].output
    },
    async init_category({ state }) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M0601CategoryService.doBrowse',
          input: {
            category_key: 'GENERAL'
          }
        }]
      })

      state.category = data[0].output
    },
    async init_label({ state }) {
      const data = await ApiUtils.postData({
        data: [
          {
            api: 'M0801LabelService.doBrowse_input',
            input: {
              label_type: 'INDUSTRY_FIELD'
            }
          },
          {
            api: 'M0801LabelService.doBrowse_input',
            input: {
              label_type: 'PROJECT_ADVISE_TYPE'
            }
          }
        ]
      })

      state.industryLabel = data[0].output
      state.projectAdviseTypeLabel = data[1].output
    },
    async init_coinACurrencyList({ state }) {
      const data = await ApiUtils.postData({
        data: [{ api: "M1902CoinsTypeCurrencyService.GetACoinCurrency" }],
      })

      state.coinACurrencyList = data[0].output

    },
    async doBrowse({ state }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'C0101ProjectService.getEdit',
          input: payload
        }]
      })
      state.row = data[0].output
    },
    async doSave2({ state }, payload) {
      await ApiUtils.postData({
        data: [{
          api: 'C3001ProjectPartnerRequirementService.doSave2',
          input: payload
        }]
      }).then((data) => {
        return data[0].output
      })
    },
    async getEdit2({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'C3001ProjectPartnerRequirementService.getEdit2',
          input: payload
        }]
      }).then((data) => {
        // console.log(data[0].output)
        return data[0].output
      })
    },
    async doDelete2({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'C3001ProjectPartnerRequirementService.doDelete2',
          input: payload
        }]
      })
    },
    async doBrowseProjectList({ state }, payload) {

      const res = await ApiUtils.postData({
        data: [{
          api: 'C0101ProjectService.doBrowseProjectList',
          input: payload
        }]
      })

      if (state.projectlist.data === undefined) {
        state.projectlist = res[0].output
      }
      else {
        state.projectlist.data = state.projectlist.data.concat(res[0].output.data)
        state.projectlist.pagination = res[0].output.pagination
      }

    },
    async getQuestionList({ state }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'C4001ProjectAdviseService.getEditByCreatedBy',
          input: payload
        }]
      })

      state.questionList = data[0].output
    },
    async doEditQuestion({ state }, payload) {

      console.log(payload)
      const result = await ApiUtils.postData({
        data: [{
          api: 'C4001ProjectAdviseService.doEditQuestion',
          input: payload
        }]
      })

      return result[0].output
    },

    async doSearchUser({ state }, payload) {

      const result = await ApiUtils.postData({
        data: [{
          api: 'C0101ProjectService.doSearchUser',
          input: payload
        }]
      })

      return result[0].output
    },

    async getCurrencyExchangeRate({ state }, payload) {

      const result = await ApiUtils.postData({
        data: [{
          api: 'M2101CoinsTopupService.getCurrencyExchangeRate',
          input: payload
        }]
      })

      state.currencyInfo = result[0].output

    },
    async doAdd({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'C0101ProjectService.doAdd',
          input: payload
        }]
      })
    },
    async doAddQuestion({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'C4001ProjectAdviseService.doAdd',
          input: payload
        }]
      })
    },
    async getAnswerList({ state }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'C4101ProjectAdviseAnswerService.getAnswerList',
          input: payload
        }]
      })

      state.questionAnswerList = data[0].output
    },

    async answerInteraction({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'C4101ProjectAdviseAnswerService.answerInteraction',
          input: payload
        }]
      })
    },

    async doPublished({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'C0101ProjectService.doPublished',
          input: payload
        }]
      })
    },

    async extendCrowdFundDeadline({ state }, payload) {
      await ApiUtils.postData({
        data: [{
          api: 'C1001ProjectCrowdFundService.extendCrowdFundDeadline',
          input: payload
        }]
      })
    },
    async requestApproval({ state }, payload) {

      const res = await ApiUtils.postData({ data: [
        { api: 'C0101ProjectService.requestApproval',
          input: state.row
        }]
      })
      return res[0].output
    }
  }
}

export default C0101Const.getStore(store)
