// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
import ApiUtils from '@/utils/api/ApiUtils'

// 'DRAFT','PENDING','APPROVED','AMENDMENT','REJECTED','REVIEWING','REVIEWED','CONFLICT','PUBLISHED','UNPUBLISHED','DELETED','REMOVED'
const statusMap={
  DRAFT: {
    text: '草稿',
    color: 'grey',
    topBtnList: ['moreSetting','preview', 'submitForApproval'],
    editBtnList: ['preview', 'submitForApproval','doSave'],
    canEdit: true, // got `doSave` button == canEdit,
    showTabsList: ['edit'],
  },
  PENDING: {
    text: '待審批',
    color: 'orange',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  APPROVED: {
    text: '已審批',
    color: 'green',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  },
  AMENDMENT: {
    text: '要求修正',
    color: 'orange',
    topBtnList: ['moreSetting','preview', 'submitForApproval'],
    editBtnList: ['preview', 'submitForApproval','doSave'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit'],
  },
  // it will be `UNPUBLISHED` if creator not choose to publish when admin approved
  REJECTED: {
    text: '審批未通過',
    color: 'red',
    topBtnList: ['moreSetting','preview','submitForApproval'],
    editBtnList: ['preview','submitForApproval'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  REVIEWING: {
    text: '審查中',
    color: 'orange',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  REVIEWED: {
    text: '已審查',
    color: 'green',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  },
  CONFLICT: {
    text: '有衝突',
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  },
  PUBLISHED: {
    text: '已發佈',
    color: 'green',
    topBtnList: ['moreSetting','preview','doSave'],
    editBtnList: ['preview','doSave'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments', 'application'],
  },
  UNPUBLISHED: {
    text: '未發佈',
    color: 'grey',
    topBtnList: ['moreSetting','preview','publish','doSave'],
    editBtnList: ['preview','publish','doSave'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit'],
  },
  DELETED: {
    text: '已刪除',
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  },
  REMOVED: {
    text: '已被移除',
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  }
}


function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}


const PageConst= {
  FORM_DEF: {
    // if cannot use dont use
    // editDef: [
    //   { name: 'name', label: 'Name', type: 'text', required: true },
    //   { name: 'description', label: 'Description', type: 'textarea', required: false }
    // ]
  },
  SEARCH_DEF: {
    inputDef: [
      {
        column: 'col-8',
        data: 'content_title',
        type: 'text',
        label: '標題',
        props: {
          clearable: true
        }
      },
      {
        column: 'col-4',
        data: 'content_status',
        type: 'autoComplete',
        label: '狀態',
        props: {
          items: getMapArray(statusMap),
          itemText: 'text',
          itemValue: 'value',
          clearable: true
        }
      },
    ],
    sortDef:[
      {
        text: '最近更新',
        value: {order_updated_at: 'desc'}
      },
      {
        text: '最早更新',
        value: {order_updated_at: 'asc'}
      },
      {
        text: '標題 A-Z',
        value: {order_content_title: 'desc'}
      },
      {
        text: '標題 Z-A',
        value: {order_content_title: 'asc'}
      },
    ],
  },
  LIST_DEF: {
    // tableDef: [
    // { name: 'name', label: 'Name', align: 'left', sortable: true },
    // { name: 'description', label: 'Description', align: 'left', sortable: false }
    // ]
  },
  // REVIEW_DEF:{
  //   rel_model: 'T1001JobPostingRequirement',
  //   key: 'tank.survey',
  // },
  ACTION: {
    doBrowse: { api: 'L0101ContentService.doBrowse_creator'},
  },
}

const module = {
  state: {
    PageConst,
    statusMap,
    statusMapArray: getMapArray(statusMap),

    // set default here
    searchInfo:{
    },
    // set default here
    orderBy:PageConst.SEARCH_DEF.sortDef[0].value,
  },

  getters: {

  },

  mutations: {

  },
  actions: {
    async requestApproval ({ commit,state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: state.PageConst.ACTION.requestApproval.api,
          input: state.row
        }] })
      return res[0].output
    },
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
