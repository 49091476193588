import BaseDataStore from '@/components/base/store/BaseDataStoreV2'
import i18n from '@/plugins/i18n'
// import moment from 'moment'
const storeName = 'P0101User'
const serviceName = 'P0101ArticleService'

/*
    {
      column: 'col-6', // column size
      data: 'status', // data name
      type: 'select', // component type : text,textarea,number,checkbox,switch,radio,file,date,time,dateRange,select,autoComplete,combobox,rangeSlider,slider,ttc_category,ttc_tag,ttc_price,ttc_policy
      label: 'status', // label
      props: any // component props
    }
*/
const storeDef = {
  ACTION: {
    // base actions : doAdd, doEdit, getEdit, doBrowse, doDelete, getRead
    // custom actions
    // TODO: accept string without storeName/serviceName
    init: {
      store: storeName + '/init'
    },
    doBrowse: {
      store: storeName + '/doBrowse',
      api: serviceName + '.doBrowseWithDetailForUser'
    },
    getStats: {
      store: storeName + '/getStats',
      api: serviceName + '.getActiclsStatsForUser'
    },
    getRead: {
      store: storeName + '/getRead',
      api: serviceName + '.getReadDetailByArticleId'
    },
    addRelate: {
      store: storeName + '/addRelate',
      api: serviceName + '.addArticleReaderRelate'
    },
    addUserReaction: {
      store: storeName + '/addUserReaction',
      api: serviceName + '.addUserReaction'
    }
  },
  PROPERTY: {
    // base properties : row, rows, pagination, searchInfo, orderBy
    // custom properties
    myProp: {
      get: storeName + '/getMyProp',
      set: storeName + '/SET_MY_PROP'
    },
    stats: {
      get: storeName + '/getStats',
      set: storeName + '/SET_STATS'
    }
  }
}

const searchDef = {
  viewDef: [
    {
      column: 'col-3',
      data: 'search_title',
      type: 'text',
      label: 'Title'
    },
    {
      column: 'col-sm-3',
      data: 'category',
      type: 'ttc_category',
      props: {
        label: i18n.t('components.TTCCategoryField.category'),
        outlined: true
      }
    },
    {
      column: 'col-sm-3',
      data: 'tag',
      type: 'ttc_tag',
      props: {
        label: i18n.t('components.TTCTagField.tags'),
        outlined: true
      }
    }
  ],
  optionDef: {}
}

const listDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('samples.layout4.title'), value: 'title' },
    // use type: 'date' to format date
    { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
    // use type: 'enum' to format enum
    {
      text: 'Gender',
      value: 'gender',
      type: 'chip',
      items: [
        { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
        { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
        { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
      ]
    },
    { text: 'comments', value: 'comment_count' },
    { text: 'post_type', value: 'post_type' },
    { text: 'views', value: 'views' },
    { text: 'Custom (comments+views)', value: 'cus' },
    { text: 'Action', value: 'actions', sortable: false }
  ],
  optionDef: {}
}

const readDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
    { text: 'subtitle', value: 'article_version_subtitle' },
    { text: 'status', value: 'article_version_status' },
    { text: 'tags', value: 'tags' }
  ],
  optionDef: {}
}

const BaseConst = {
  version: 1,
  storeName,
  serviceName,
  storeConst: storeDef,
  // target def for './component'
  //  if search -> search component
  componentDef: {
    search: searchDef,
    list: listDef,
    read: readDef
  }
}
const FnConst = new BaseDataStore(BaseConst)
export default FnConst
