import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    targetModel: 'M4201Subscription',
    user_subscription: [],
    subscription_plans: [],
    status: '',
    auto_renew: 0

  },
  getters: {
  },
  mutations: {
    SET_STATUS (state, payload) {
      state.status = payload.status
      state.auto_renew = payload.auto_renew
    }

  },
  actions: {
    async init ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: 'M4201SubscriptionService.doBrowse', input: { subscription_code: 'month' } },
          { api: 'M4201SubscriptionService.doBrowse', input: { subscription_code: 'year' } }
        ]
      })

      state.subscription_plans.push(res[0].output ?? [])
      state.subscription_plans.push(res[1].output ?? [])
    },
    async initUserPlan ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M4201SubscriptionService.doGet',
            input: payload
          }

        ]
      })
      // console.log('initUserPlan')
      state.user_subscription = res[0].output
    },
    async doCheck ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M4201SubscriptionService.doCheck',
            input: payload
          }

        ]
      })
      return res[0].output
    },
    async disable_renew ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M4201SubscriptionService.disable_renew',
            input: payload
          }

        ]
      })
      return res[0].output
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
