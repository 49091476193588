import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'S0801Setting',
  serviceName: 'S0801SettingService',

  componentDef: {
    form: {
      /*
       type: div, row, col, form, text,file,select, button,table
       component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
       rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewDef: [
        // {
        //   column: 'col-12',
        //   custom: 'General'
        // },
        // {
        //   column: 'col-9',
        //   custom: 'CommissionRate'
        // },
        // {
        //   column: 'col-3',
        //   data: 'commissionRate',
        //   type: 'number',
        //   rules:
        //   [(val) => {
        //     if (val === null || val === '') {
        //       return i18n.t('s0801.category.collab.validation.valueEmpty', { _field_: i18n.t('s0801.category.collab.questionFees.feesAmount') })
        //     }
        //     return true
        //   },
        //   (val) => {
        //     if (val < 0 || val > 1) {
        //       return i18n.t('s0801.category.collab.validation.valueMoreThan1', { _field_: i18n.t('s0801.category.collab.general.commissionRate') })
        //     }
        //     return true
        //   }],
        //   props: {
        //     disabled: false
        //   }
        // },
        {
          column: 'col-12',
          custom: 'QuestionFees'
        },
        {
          column: 'col-9',
          custom: 'FeesRequired'
        },
        // TODO Alex a popout for toggle
        {
          column: 'col-3',
          data: 'collab.advise.fees_toggle',
          type: 'switch',
          class: 'd-flex justify-end mb-6',
          props: {
            disabled: false,
            childProps: {
              class: 'mt-0 pt-0'
            }
          }
        },
        {
          column: 'col-9',
          custom: 'FeesCoinType'
        },
        {
          column: 'col-3',
          data: 'collab.advise.fees_mainCoin',
          type: 'select',
          props: {
            disabled: false,
            items: [{ id: 1, name: i18n.t('components.TTCPriceField.coin') }, { id: 2, name: i18n.t('components.TTCPriceField.token') }, { id: 3, name: i18n.t('components.TTCPriceField.CAToken') }],
            itemText: 'name',
            itemValue: 'id'
          }
        },
        {
          column: 'col-9',
          custom: 'FeesAmount'
        },
        // TODO Alex suffix for this field
        {
          column: 'col-3',
          data: 'collab.advise.fees_mainPrice',
          rules:
          [(val) => {
            if (val === null || val === '') {
              return i18n.t('s0801.category.collab.validation.valueEmpty', { _field_: i18n.t('s0801.category.collab.questionFees.feesAmount') })
            }
            return true
          },
          (val) => {
            if (val < 0) {
              return i18n.t('s0801.category.collab.validation.valueNegative', { _field_: i18n.t('s0801.category.collab.questionFees.feesAmount') })
            }
            return true
          }],
          type: 'number',
          props: {
            disabled: false
          }
        },
        {
          column: 'col-9',
          custom: 'ExchangeCoinType'
        },
        {
          column: 'col-3',
          data: 'collab.advise.fees_exchangeCoin',
          type: 'select',
          props: {
            disabled: false,
            items: [{ id: 1, name: i18n.t('components.TTCPriceField.coin') }, { id: 2, name: i18n.t('components.TTCPriceField.token') }, { id: 3, name: i18n.t('components.TTCPriceField.CAToken') }],
            itemText: 'name',
            itemValue: 'id'
          }
        },
        {
          column: 'col-9',
          custom: 'ExchangeAmount'
        },
        // TODO Alex suffix for this field
        {
          column: 'col-3',
          data: 'collab.advise.fees_exchangeAmount',
          rules:
          [(val) => {
            if (val === null || val === '') {
              return i18n.t('s0801.category.collab.validation.valueEmpty', { _field_: i18n.t('s0801.category.collab.questionFees.feesAmount') })
            }
            return true
          },
          (val) => {
            if (val < 0) {
              return i18n.t('s0801.category.collab.validation.valueNegative', { _field_: i18n.t('s0801.category.collab.questionFees.exchangeAmount') })
            }
            return true
          }],
          type: 'number',
          props: {
            disabled: false
          }
        },
        {
          column: 'col-12',
          custom: 'AnswerRewards'
        },
        {
          column: 'col-9',
          custom: 'BestAnswerRewardAmount'
        },
        {
          column: 'col-3',
          data: 'collab.advise.reward_bestAnswerAmount',
          rules:
          [(val) => {
            if (val === null || val === '') {
              return i18n.t('s0801.category.collab.validation.valueEmpty', { _field_: i18n.t('s0801.category.collab.questionFees.feesAmount') })
            }
            return true
          },
          (val) => {
            if (val < 0) {
              return i18n.t('s0801.category.collab.validation.valueNegative', { _field_: i18n.t('s0801.category.collab.answerRewards.bestAnswerRewardAmount') })
            }
            return true
          }],
          type: 'number',
          props: {
            disabled: false,
            suffix: i18n.t('components.TTCPriceField.CAToken')
          }
        },
        {
          column: 'col-9',
          custom: 'MinimumRewardAmount'
        },
        {
          column: 'col-3',
          data: 'collab.advise.reward_minAmount',
          rules:
          [(val) => {
            if (val === null || val === '') {
              return i18n.t('s0801.category.collab.validation.valueEmpty', { _field_: i18n.t('s0801.category.collab.questionFees.feesAmount') })
            }
            return true
          },
          (val) => {
            if (val < 0) {
              return i18n.t('s0801.category.collab.validation.valueNegative', { _field_: i18n.t('s0801.category.collab.answerRewards.minimumRewardAmount') })
            }
            return true
          }],
          type: 'number',
          props: {
            disabled: false,
            suffix: i18n.t('components.TTCPriceField.CAToken')
          }
        }
      ],
      optionDef: {}
    }
  }
}
const S0801Const = new BaseDataStore(BaseConst)
export default S0801Const
