/*
This script defines an object called `TestUtils`, which contains a single method called `unitTest()`.

The `unitTest()` method takes three arguments:

-   `name`: a string representing the name of the unit test.
-   `tests`: an array of objects representing the unit tests. 
              Each test object should have the following properties:
    -   `name`: a string representing the name of the test.
    -   `arg`: an array of arguments to pass to the test function.
    -   `ground`: the expected output of the test function when passed the arguments in `arg`.
    -   `func`: the function to be tested, this is optional parameter 
                if not provided then it will consider `func` parameter in method signature.
-   `func`: the function to be tested, this is optional parameter , 
            if `func` is not defined inside the test objects, 
            then this will be used as function to be tested.

The method iterates through each test in the `tests` array 
and calls the test function with the arguments in `arg`. 
It then compares the output of the test function 
to the expected output in `ground` using DataUtils.isSameObjectSimple 
If they are the same, the test is considered to have passed. 
If they are different, an error message is logged to the console. 
At the end, the method logs a message to the console indicating how many tests passed out of the total number of tests.
*/
const TestUtils = {
  /**
 * Run a unit test and log the results to the console
 * @function
 * @param {string} name - The name of the unit test
 * @param {Array<{name: string, arg: any[], ground: any, func: function}>} [tests=[]] - An array of unit tests to run
 * @param {function} [func=null] - The function to be tested. If not defined in individual test objects, this will be used
 * @throws {Error} Will throw an error if `func` is not defined in individual test objects and is not passed as a parameter
 * @returns {void}
 */
  unitTest (name, tests = [], func = null) {
    let pass = 0
    function getFunc (test) {
      if (typeof test.func === 'function') return test.func
      if (typeof func === 'function') return func
      throw new Error('TestUtils - func not defined')
    }
    tests.forEach(test => {
      if (this.isSameObjectSimple(getFunc(test)(...test.arg), test.ground)) pass++
      else {
      // Gen Error Log 
        console.error('Test failed :', test.name)
        console.error('Test failed with function args:\n', JSON.stringify(test.arg, null, 2))
        console.error('Expected result:----\n', JSON.stringify(test.ground, null, 2))
        console.error('Actual result:------\n', JSON.stringify(getFunc(test)(...test.arg), null, 2))
      }
    })

    console.log(name + ' ~ All tests passed : ' + pass + '/' + tests.length)
  },
  isSameObjectSimple (obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  }
}
export default TestUtils
