import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    wallet: null
  },

  getters: {
    getWallet: state => state.wallet
  },

  mutations: {
    SET_WALLET (state, payload) {
      if (ObjArrUtils.isEmpty(payload)) {
        return
      }
      const wallet = {}
      payload.forEach(item => {
        wallet[item.coins_type_code] = item
      })
      state.wallet = wallet
    }
  },

  actions: {
    initWallet: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2001CoinsService.initWallet' }
          // { api: 'M2001CoinsService.getTransactionHistory' }
        ]
      }).then(res => {
        const data = res[0].output
        // [
        //   {
        //     "id": 8,
        //     "coin_type_id": 1,
        //     "coins_type_code": "A",
        //     "coins_type_name": "A 幣",
        //     "amount": "100050"
        //   }
        // ]
        // change to key-value pair
        // {
        //   "A": { 
        //     "id": 8,
        //     "coin_type_id": 1,
        //     "coins_type_code": "A",
        //     "coins_type_name": "A 幣",
        //     "amount": "100050"
        //   }}
        commit('SET_WALLET', data)
        // return {
        //   transactionHistory: res[1].output
        // }
      })
    },
    getCoinsTopupInfo: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M2101CoinsTopupService.getCoinsTopupInfo', input: payload }]
      }).then(res => res[0].output)
    },
    coinsTopup: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M2101CoinsTopupService.coinsTopup', input: payload }]
      }).then(res => {
        const data = res[0].output
        return data
      })
    },
    getCoinsCashoutInfo: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M2201CoinsCashoutService.getCoinsCashoutInfo', input: payload }]
      }).then(res => res[0].output)
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
