import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'
import moment from 'moment'
const BaseConst = {
  version: 1,
  storeName: 'E0001DemoPost',
  serviceName: 'E0001DemoPostService',
  storeConst: {
    ACTION: {
      // base actions : doAdd, doEdit, getEdit, doBrowse, doDelete, doRead
      // custom actions
      // TODO: accept string without storeName/serviceName
      importData: {
        store: 'E0001DemoPost/importData',
        api: 'E0001DemoPostService.importData'
      },
      myAction: {
        store: 'E0001DemoPost/myAction',
        api: 'E0001DemoPostService.myAction'
      }
    },
    PROPERTY: {
      // base properties : row, rows, pagination, searchInfo, orderBy
      // custom properties
      myProp: {
        get: 'E0001DemoPost/getMyProp',
        set: 'E0001DemoPost/SET_MY_PROP'
      }
    }
  },
  // TODO: no use cases
  PAGE_NAME: 'E0001DataMaintain',
  // target def for './component'
  //  if search -> search component
  componentDef: {
    search: {
      viewDef: [
        {
          column: 'col-sm-3',
          data: 'title',
          type: 'text',
          label: 'Search Title'
        },
        {
          column: 'col-sm-3',
          data: 'description',
          type: 'text',
          label: 'Search description'
        },
        {
          column: 'col-sm-3',
          data: {
            get (val) {
              return [val.created_at_start ?? null, val.created_at_end ?? null]
            },
            set (val, inputVal) {
              val.created_at_start = inputVal[0]
              val.created_at_end = inputVal[1]
              return val
            }
          },
          type: 'dateRange',
          label: 'Created At'
        },
        {
          column: 'col-sm-3',
          data: 'created_by',
          type: 'select',
          label: 'Created By',
          props: {
            clearable: true,
            items: [],
            'item-text': 'name_en',
            'item-value': 'id'
          },
          // get data from api
          async: [
            {
              api: 'E0001DemoPostService.getCreatedBy',
              mutateTarget: 'props.items'
            }
          ]
        }
      ],
      optionDef: {}
    },
    form: {
      /*
       type: div, row, col, form, text,file,select, button,table
       component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
       rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewDef: [
        {
          column: 'col-4',
          data: 'title',
          type: 'text',
          rules: 'required',
          label: 'title'
        },
        {
          column: 'col-12',
          data: 'radio_col',
          type: 'radio',
          label: 'radio column',
          props: {
            items: [
              { id: 1, name: '男' },
              { id: 2, name: '女' }
            ],
            itemText: 'name',
            itemValue: 'id'
          }
          // 1: 男 , 2: 女 
        },
        {
          column: 'col-6',
          data: 'select_one_col',
          type: 'select',
          label: 'select one column',
          props: {
            items: [
              { id: 1, name: 'Apple' },
              { id: 2, name: 'Orange' },
              { id: 3, name: 'Bannar' }
            ],
            itemText: 'name',
            itemValue: 'id'
          }
          // 1: Apple, 2: Orange, 3:Bannar 
        },
        {
          column: 'col-6',
          // data: 'select_multi_col',
          data: {
            get (val) {
              return val.select_multi_col
                ? val.select_multi_col.split(',').forEach(element => { return parseInt(element) })
                : []
            },
            set (val, inputVal) {
              val.select_multi_col = inputVal ? inputVal.join(',') : undefined
              return val
            }
          },
          type: 'select',
          label: 'select multi column',
          props: {
            items: [
              { id: 1, name: 'Book' },
              { id: 2, name: 'Movie' },
              { id: 3, name: 'Game' },
              { id: 9, name: 'Other' }
            ],
            itemText: 'name',
            itemValue: 'id',
            multiple: true
          }
          // 1: Book , 2: Movie, 3:Game, 9:Other  
          // save as comma div, e.g.  1,3 => means Book & Game
        },
        {
          column: 'col-6',
          data: 'check_one_col',
          type: 'checkbox',
          label: 'check one column'
          // 1:checked 0:unchecked
        },
        {
          column: 'col-6',
          data: 'check_multi_col',
          // data: {
          //   get (val) {
          //     // parse int after split
          //     return val.check_multi_col
          //       ? val.check_multi_col.split(',').forEach(element => { return parseInt(element) })
          //       : []
          //   },
          //   set (val, inputVal) {
          //     val.check_multi_col = inputVal ? inputVal.join(',') : undefined
          //     return val
          //   }
          // },
          type: 'checkbox',
          label: 'check multi column',
          props: {
            items: [
              { id: 1, name: 'Book' },
              { id: 2, name: 'Movie' },
              { id: 3, name: 'Game' },
              { id: 9, name: 'Other' }
            ],
            itemText: 'name',
            itemValue: 'id',
            multiple: true,
            delimited: ','
          }
          // 1: Book , 2: Movie, 3:Game, 9:Other 
          // save as comma div, e.g.  1,3 => means Book & Game
        },
        {
          column: 'col-6',
          data: 'if_check_col',
          type: 'checkbox',
          label: 'if check value column'
          // 1:checked 0:unchecked
        },
        {
          column: 'col-6',
          show (data) {
            return data.if_check_col
          },
          data: 'if_check_value_col',
          type: 'text',
          label: 'if check show enter textbox'
          // if_check_col ==1  show if_check_value_col , other hide if_check_value_col
        },
        {
          column: 'col-6',
          data: 'date_col',
          type: 'date',
          label: 'date column'
          // data define as `date_col` DATE NULL DEFAULT NULL
        },
        {
          column: 'col-6',
          // data: 'date_string_col', default format is YYYY-MM-DD
          // Example 1: use get/set without target
          // data: {
          //   get (val) {
          //     return val.date_string_col ? moment(val.date_string_col, 'YYYY/MM/DD').format('YYYY-MM-DD') : undefined
          //   },
          //   set (val, inputVal) {
          //     val.date_string_col = inputVal ? moment(inputVal, 'YYYY-MM-DD').format('YYYY/MM/DD') : undefined
          //     return val
          //   }
          // },
          // Example 2: use get/set with target 
          data: {
            target: 'date_string_col',
            get (val) {
              return val ? moment(val, 'YYYY/MM/DD customFormat').format('YYYY-MM-DD') : undefined
            },
            set (inputVal) {
              return inputVal ? moment(inputVal, 'YYYY-MM-DD').format('YYYY/MM/DD customFormat') : undefined
            }
          },
          type: 'date',
          label: 'data string column'
          // data define as  `date_string_col` CHAR(10) NULL DEFAULT NULL , format  yyyy/mm/dd
        },
        {
          column: 'col-6',
          data: 'time_col',
          type: 'time',
          label: 'time column'
          // data define as  `time_col` TIME NULL DEFAULT NULL
        },
        {
          column: 'col-6',
          // data: 'time_string_col',
          data: {
            target: 'time_string_col',
            get (val) {
              return val ? moment(val, 'HH:mm customFormat').format('HH:mm') : undefined
            },
            set (inputVal) {
              return inputVal ? moment(inputVal, 'HH:mm').format('HH:mm customFormat') : undefined
            }
          },
          type: 'time',
          label: 'time string column'
          // data define as  `time_string_col` CHAR(5) NULL DEFAULT NULL , format hh:mm
        },
        {
          column: 'col-12',
          data: 'description',
          custom: 'CustomTextField',
          rules: (val) => {
            if (val && !val.startsWith('Hi!')) {
              return 'Must start with `Hi!`'
            }
            return true
          },
          label: 'description'
        },
        {
          column: 'col-12',
          data: 'thumbnail',
          type: 'file',
          label: 'thumbnail',
          props: {
            accept: 'image/*',
            maxFiles: 1
          }
        },
        {
          column: 'col-12',
          data: 'thumbnail',
          custom: 'ShowFileStatus',
          label: 'ShowFileStatus'

        },
        {
          column: 'col-12',
          data: 'attachments',
          type: 'file',
          label: 'attachments',
          props: {
            accept: 'image/*'
          }
        }

      ],
      optionDef: {}
    },
    table: {
      viewDef: [
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'title' },
        // use type: 'date' to format date
        { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
        // use type: 'enum' to format enum
        {
          text: 'Gender',
          value: 'gender',
          type: 'chip',
          items: [
            { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
            { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
            { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
          ]
        },
        { text: 'comments', value: 'comment_count' },
        { text: 'post_type', value: 'post_type' },
        { text: 'views', value: 'views' },
        { text: 'Custom (comments+views)', value: 'cus' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      optionDef: {}
    },
    view: {
      viewDef: [
        { text: i18n.t('samples.layout4.title'), value: 'title' },
        { text: 'desc', value: 'description' },
        { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
        { text: 'Thumbnail', value: 'thumbnail', type: 'image', attrs: { style: 'max-width:90vw' } },
        { text: 'Attachments', value: 'attachments', type: 'files' }
      ],
      optionDef: {}
    }
  }
}
const E0001Const = new BaseDataStore(BaseConst)
export default E0001Const
