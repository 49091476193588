import Vue from 'vue'
import Router from 'vue-router'
import routes from '@/router/routes.js'
import { authorizationCheck } from '@/router/guards.js'
import store from '@/store'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes
})

// Router Configuration
router.beforeEach((to, from, next) => {
  if (store.state.promptOnRouteChange !== undefined) {
    if (window.confirm(store.state.promptOnRouteChange)) {
      store.state.promptOnRouteChange = undefined
      next()
    } else {
      next(false)
    }
  } else {
    next()
  }
})

router.beforeEach(authorizationCheck)

export default router
