// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const statusMap={
  DRAFT: {
    label: 'Draft',
    color: 'grey'
  }
}

function getStatusMapArray(){
  return Object.keys(statusMap).map(key => {
    return {
      value: key,
      text: statusMap[key].label,
      color: statusMap[key].color
    }
  })
}

const module = {
  state: {
    PageConst: {
      FORM_DEF: {
        editDef: [
          { name: 'name', label: 'Name', type: 'text', required: true },
          { name: 'description', label: 'Description', type: 'textarea', required: false }
        ]
      },
      SEARCH_DEF: {
        inputDef: [
          {
            column: 'col-12',
            data: 'title',
            type: 'text',
            label: 'Title',
            props: {
              clearable: true
            }
          },
          {
            column: 'col-4',
            data: 'status',
            type: 'autoComplete',
            label: 'Status',
            props: {
              items: getStatusMapArray(),
              itemText: 'text',
              itemValue: 'value',
              clearable: true
            }
          },
        ],
        sortDef:[
          {
            text: 'Newest',
            value: 'newest'
          },
          {
            text: 'Popular',
            value: 'popular'
          },
          {
            text: 'Published first',
            value: 'published_first'
          },
          {
            text: 'Unpublished first',
            value: 'unpublished_first'
          },
          {
            text: 'A-Z',
            value: 'a-z'
          },
          {
            text: 'Z-A',
            value: 'z-a'
          }
        ],
      },
      LIST_DEF: {
        tableDef: [
          { name: 'name', label: 'Name', align: 'left', sortable: true },
          { name: 'description', label: 'Description', align: 'left', sortable: false }
        ]
      },
      ACTION: {
        doAdd: { api: 'T3001FundingService.doAdd_customer'},
        getEdit: { api: 'T3001FundingService.getEdit_customer'},
        doEdit: { api: 'T3001FundingService.doEdit_customer'},
        doRead: { api: 'T3001FundingService.doRead_customer'},
        doDelete: { api: 'T3001FundingService.doDelete_customer'},
        doBrowse: { api: 'T3001FundingService.doBrowse_customer'},
      },
    },

    statusMap,
    rows:[
      {
        id: 1,
        title: 'Project 1',
      },
      {
        id: 2,
        title: 'Project 2',
      },
      {
        id: 3,
        title: 'Project 3',
      }
    ],
    searchInfo:{
      intentions: [],
      project_status: 'All'
    }
  },

  getters: {

  },

  mutations: {

  },
  actions: {


  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
