import ApiUtils from '@/utils/api/ApiUtils'
import M0601Const from './M0601Const'

// TODO: can i know what storeConst properties are available? (using intelisense)
const StoreConst = M0601Const.storeConst
const store = {
  state: {
    // custom state
    myProp: null,
    // null means not yet loaded, false means `fetching`, true means data is loaded
    categoryStatus: null,
    category: []
  },
  getters: {
    // custom getter
    getMyProp: (state) => state.myProp
  },
  mutations: {
    // custom mutation
    SET_MY_PROP(state, payload) {
      state.myProp = payload
    }
  },
  actions: {
    // override action
    async doBrowse({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doBrowse.api, input: val }]
      })
      commit('SET_ROWS', res[0].output.data)
      commit('SET_PAGINATION', res[0].output.pagination)
    },
    // custom action
    myAction({ commit, state }, payload) {
      commit('SET_MY_PROP', payload)
    },
    async init_category({ state }) {
      if (state.categoryStatus !== null) return
      state.categoryStatus = false
      const data = await ApiUtils.postData({
        data: [{
          api: 'M0601CategoryService.doBrowse',
          input: {
            category_key: 'GENERAL'
          }
        }]
      })

      state.categoryStatus = true
      state.category = data[0].output
    },
  }
}

export default M0601Const.getStore(store)
