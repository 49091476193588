import TestUtils from '@/utils/TestUtils'

const DataUtils = {
  /**
   * Merges two objects together.
   *
   * @param {Object} obj1 - The first object to merge. (Default)
   * @param {Object} obj2 - The second object to merge.
   * @return {Object} A new object containing the properties from both obj1 and obj2. If a property exists in both obj1 and obj2, the value from obj2 is used.
   */
  mergeObjects (obj1, obj2) {
    const result = {}

    // Add all properties from obj1
    for (const key in obj1) {
      if (key in obj1) {
        result[key] = obj1[key]
      }
    }

    // Add all properties from obj2, with priority given to obj2
    for (const key in obj2) {
      if (key in obj2) {
        if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
          // Recursively merge nested objects
          result[key] = DataUtils.mergeObjects(result[key], obj2[key])
        } else {
          result[key] = obj2[key]
        }
      }
    }

    return result
  },
  /**
 * Sorts the keys in an object or array of objects.
 *
 * @param {Object|Array} obj - The object or array of objects to sort the keys of.
 * @return {Object|Array} A new object or array with the keys sorted in ascending order.
 */
  sortObjectKeys (obj) {
    if (Array.isArray(obj)) return obj.map(DataUtils.sortObjectKeys.bind(DataUtils)) // sort keys in each object in the array

    if (typeof obj !== 'object') return obj // return non-object values as-is

    if (obj === null) return obj // return non-object values as-is

    return Object.keys(obj)
      .sort()
      .reduce((acc, key) => {
        const value = obj[key]
        return { ...acc, [key]: DataUtils.sortObjectKeys(value) } // recursively sort keys in nested objects
      }, {})
  },
  /**
  * Function to set the value of a specific key in a JavaScript object using a list of keys to navigate to the target key.
  * @param {Object|Array} data - The JavaScript object to be modified
  * @param {Array} targetKeyList - An array of keys to navigate to the target key in the object
  * @param {Any} targetValue - The value to be set for the target key
  * @returns {Object|Array} - The modified JavaScript object
  */
  setValue (data, targetKeyList, targetValue) {
    const key = targetKeyList[0]
    if (targetKeyList.length === 1) {
      data[key] = targetValue
    } else {
      data[key] = DataUtils.setValue(data[key], targetKeyList.slice(1), targetValue)
    }
    return data
  },
  isSameObject (obj1, obj2) {
    return JSON.stringify(DataUtils.sortObjectKeys(obj1)) === JSON.stringify(DataUtils.sortObjectKeys(obj2))
  },
  isSameObjectSimple (obj1, obj2) {
    return JSON.stringify(obj1) === JSON.stringify(obj2)
  },
  jsonCopy (val) {
    return JSON.parse(JSON.stringify(val))
  },
  isSameObjectTest () {
    const tests = [
      { name: 'Normal Test', arg: [{ searchInfo: {}, layout: 'grid' }, { searchInfo: {}, layout: 'grid' }], ground: true },
      { name: 'Normal Test Invert', arg: [{ searchInfo: {}, layout: 'grid' }, { searchInfo: {}, layout1: 'grid' }], ground: false },
      { name: 'Normal Test (Nested Obj)', arg: [{ searchInfo: { a: 1, b: 2 }, layout: 'grid' }, { searchInfo: { a: 1, b: 2 }, layout: 'grid' }], ground: true },
      { name: 'Normal Test (Nested Obj) Invert', arg: [{ searchInfo: { a: 1, b: 2 }, layout: 'grid' }, { searchInfo: { a: 1, b1: 2 }, layout: 'grid' }], ground: false },
      { name: 'Diffrent order', arg: [{ searchInfo: {}, layout: 'grid' }, { layout: 'grid', searchInfo: {} }], ground: true },
      { name: 'Diffrent order (Nested Obj)', arg: [{ pagination: { current_page: 1, last_page: 1 } }, { pagination: { last_page: 1, current_page: 1 } }], ground: true },
      { name: 'Diffrent order (Nested Obj in Array)', arg: [{ pagination: [{ current_page: 1, last_page: 1 }] }, { pagination: [{ last_page: 1, current_page: 1 }] }], ground: true },
      { name: 'Diffrent order (Nested Obj in Array)2', arg: [{ pagination: [{ current_page: 1, last_page: 1, pagination: [{ current_page: 1, last_page: 1 }] }] }, { pagination: [{ last_page: 1, current_page: 1, pagination: [{ last_page: 1, current_page: 1 }] }] }], ground: true }
    ]
    TestUtils.unitTest('DataUtils.js ~ isSameObjectTest ~', tests, DataUtils.isSameObject)
  },
  mergeObjectsTest () {
    const tests = [
      { name: 'Normal Test', arg: [{ searchInfo: {}, layout: 'grid' }, { searchInfo: 'a', layout: 'grid1' }], ground: { searchInfo: 'a', layout: 'grid1' } },
      { name: 'Normal Test2', arg: [{ searchInfo: {}, orderBy: { asd: 2 }, layout: 'grid', pagination: { current_page: 1, last_page: 1, per_page: 10, total: null } }, { orderBy: { asd: 1 }, pagination: { current_page: 5, last_page: 15 } }], ground: { searchInfo: {}, orderBy: { asd: 1 }, layout: 'grid', pagination: { current_page: 5, last_page: 15, per_page: 10, total: null } } },
      { name: 'Normal Test3, check array still is array', arg: [{ column: 'col-md-4', component: 'AtTextField', props: { label: 'label' } }, { column: 'col-md-4', component: 'VAutocomplete', props: { items: ['item1', 'item2', 'item3'] } }], ground: { column: 'col-md-4', component: 'VAutocomplete', props: { label: 'label', items: ['item1', 'item2', 'item3'] } } }
    ]
    TestUtils.unitTest('DataUtils.js ~ mergeObjects ~', tests, DataUtils.mergeObjects)
  },
  setValueTest () {
    const tests = [
      { name: 'Normal Test, nest2 array', arg: [[['base']], [0, 0], 'target'], ground: [['target']] },
      { name: 'Normal Test', arg: [[{ whenComponentDefined: { component: 'VAutocomplete', props: { items: ['item1', 'item2', 'item3'] } } }], [0, 'whenComponentDefined', 'props', 'items'], ['new item1', 'new item2']], ground: [{ whenComponentDefined: { component: 'VAutocomplete', props: { items: ['new item1', 'new item2'] } } }] }
    ]
    TestUtils.unitTest('DataUtils.js ~ setValue ~', tests, DataUtils.setValue)
  },
  randomString (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  }
}

// removeTest
// DataUtils.mergeObjectsTest()
// DataUtils.isSameObjectTest()
// DataUtils.setValueTest()

export default DataUtils
