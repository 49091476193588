import ApiUtils from '@/utils/api/ApiUtils'
import DataUtils from '@/utils/DataUtils'
import L0101Const from './L0101Const'
import i18n from '@/plugins/i18n'

function searchInput (input, contentType) {
  return DataUtils.mergeObjects(input, {
    cons: { content_type: contentType }
  })
}

function filterTemplate (certlist, code) {
  return certlist.find(item => item.certificate_code === code)
}

const StoreConst = L0101Const.storeConst
const store = {
  state: {
    contentSectionContentType: [
      { value: 'VIDEO', text: i18n.t('l0501.resource_type.video') },
      { value: 'ARTICLE', text: i18n.t('l0501.resource_type.article') },
      { value: 'AUDIO', text: i18n.t('l0501.resource_type.audio') },
      { value: 'RESOURCE', text: i18n.t('l0501.resource_type.resource') },
      { value: 'MEETING', text: i18n.t('l0501.resource_type.meeting') },
      { value: 'QUIZ', text: i18n.t('l0501.resource_type.quiz') }
    ],
    programs: [],
    courses: [],
    seminars: [],
    events: [],
    programsOptions: {},
    coursesOptions: {},
    seminarsOptions: {},
    eventsOptions: {},
    tab: null,
    certList: [],
    certType: [],
    certTemplate: [],
    contains_quiz: false,
    basicCertOption: [
      { value: 'no_cert', text: '無文憑' },
      { value: 'attendance', text: '出席證書' }
    ],
    gradedCertOption: [
      { value: 'graded', text: '學分文憑' }
    ],
    settings: {},
    attendance_template: [],
    passing_template: [],
    distinction_template: [],
    tag: [],
    language: [],
    category: [],
    label: [],
    content_status: 'DRAFT',
    disableSubmitReview: false,
    content_type: null // single - Program, multi - course and other
  },
  getters: {
    getPrograms (state) {
      return state.programs
    },
    getCourses (state) {
      return state.courses
    },
    getSeminars (state) {
      return state.seminars
    },
    getEvents (state) {
      return state.events
    },
    getProgramsOptions (state) {
      return state.programsOptions
    },
    getCoursesOptions (state) {
      return state.coursesOptions
    },
    getSeminarsOptions (state) {
      return state.seminarsOptions
    },
    getEventsOptions (state) {
      return state.eventsOptions
    },
    getLanguage (state) {
      return state.language
    },
    getCategory (state) {
      return state.category
    },
    getLabel (state) {
      return state.label
    }
  },
  mutations: {
    // SET_PROGRAMS (state, payload) {
    //   state.programs = payload
    // },
    // SET_COURSES (state, payload) {
    //   state.courses = payload
    // },
    // SET_SEMINARS (state, payload) {
    //   state.seminars = payload
    // },
    // SET_EVENTS (state, payload) {
    //   state.events = payload
    // },
    SET_PROGRAMS_OPTIONS (state, payload) {
      state.programsOptions = payload
    },
    SET_COURSES_OPTIONS (state, payload) {
      state.coursesOptions = payload
    },
    SET_SEMINARS_OPTIONS (state, payload) {
      state.seminarsOptions = payload
    },
    SET_EVENTS_OPTIONS (state, payload) {
      state.eventsOptions = payload
    },
    SET_TAB (state, payload) {
      state.tab = payload
    },
    SET_CONTAINS_QUIZ (state, payload) {
      if (payload.key !== '') {
        state.row.l0301_content_class.find(item => item.key === payload.key).contains_quiz = payload.contains_quiz

        state.contains_quiz = payload
      }
    },
    SET_PREVIEWS (state, payload) {
      state.previews[payload.index] = payload.preview
    },
    SET_CONTENT_STATUS (state, payload) {
      state.content_status = payload
    },
    SET_CONTENT_TYPE (state, payload) {
      if (payload === 'PROGRAM') {
        state.content_type = 'single'
      } else {
        state.content_type = 'multi'
      }
    }
  },
  actions: {
    async doEdit ({ commit, state, dispatch }) {
      const data = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doEdit.api, input: state.row }]
      })

      return await dispatch('getEdit', { seo_url: data[0].output.seo_url })
    },

    async doAdd ({ state }) {
      return await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doAdd.api, input: state.row }]
      })
    },

    // async doDelete ({ state }) {
    //   return await ApiUtils.postData({
    //     data: [{ api: StoreConst.ACTION.doDelete.api, input: state.row }]
    //   })
    // },

    async getReview ({ commit }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.getReview.api, input: payload }]
      })
      return res[0].output
    },

    async doReview (_, payload) {
      return await ApiUtils.postData({ data: [{ api: StoreConst.ACTION.doReview.api, input: payload }] })
    },

    async doSubmit ({ state }) {
      return await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doSubmit.api, input: state.row }]
      })
    },

    search: ({ state, commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'L0101ContentService.searchContent', input: searchInput(input, 'PROGRAM') },
          { api: 'L0101ContentService.searchContent', input: searchInput(input, 'COURSE') },
          { api: 'L0101ContentService.searchContent', input: searchInput(input, 'SEMINAR') },
          { api: 'L0101ContentService.searchContent', input: searchInput(input, 'EVENT') }
        ]
      }).then(data => {
        commit('SET_PROGRAMS', data[0].output.data)
        commit('SET_PROGRAMS_OPTIONS', data[0].output)

        commit('SET_COURSES', data[1].output.data)
        commit('SET_COURSES_OPTIONS', data[1].output)

        commit('SET_SEMINARS', data[2].output.data)
        commit('SET_SEMINARS_OPTIONS', data[2].output)

        commit('SET_EVENTS', data[3].output.data)
        commit('SET_EVENTS_OPTIONS', data[3].output)
      })
    },

    read: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'L0101ContentService.readContent', input: payload }
        ]
      }).then(data => {
        commit('SET_ROW', data[0].output)
        return data
      })
    },

    reviewComplete: (ctx, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'L0101ContentService.reviewComplete', input: payload }]
      })
    },

    async doSeachCalendar (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'L0101ContentService.doSearchCalendar', input: payload }] })
      return data[0].output
    },

    async doBrowse_Discussion (_, payload) {
      const data = await ApiUtils.postData({
        data: [{ api: 'L0501ContentSectionService.doBrowse_Discussion', input: payload }]
      })

      return data[0].output
    },

    async doBrowse_Rating (_, payload) {
      const data = await ApiUtils.postData({
        data: [{ api: 'L0301ContentClassService.doBrowse_Rating', input: payload }]
      })

      return data[0].output
    },

    async init_cert ({ state }) {
      const data = await ApiUtils.postData({
        data: [{ api: 'L0101ContentService.doBrowse_cert', input: { certificate_key: 'learn.content' } }]
      })

      state.certList = data[0].output
    },
    // certType
    async doBrowse_certList ({ state }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M4902CertificateTemplateService.doBrowse',
          input: {
            certificate_key: 'learn.content',
            getTemplate: false
          }

        }]
      })

      state.certType = data[0].output
    },
    // certTemplate
    async doBrowse_certTemplate ({ state }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M4902CertificateTemplateService.doBrowse',
          input: {
            certificate_key: 'learn.content'
          }

        }]
      })

      const certList = data[0].output

      state.attendance_template = [filterTemplate(certList, 'learn.attendance')]
      state.passing_template = [filterTemplate(certList, 'learn.credit')]
      state.distinction_template = [filterTemplate(certList, 'learn.distinction')]

      return data[0].output
    },
    async doBrowse_setting ({ state }) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'L0101ContentService.get_Setting'
        }]
      })

      state.settings = data[0].output ?? {}
    },
    async init_Tag ({ state }) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M3901GuideService.doGet_atFormHint',
          input: 'learn.contentTag'
        }]
      })

      state.tag = data[0].output
    },
    async init_language ({ state }) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'O0201LocalesService.getLangauges'
        }]
      })

      state.language = data[0].output
    },
    async init_category ({ state }) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M0601CategoryService.doBrowse',
          input: {
            category_key: 'GENERAL'
          }
        }]
      })

      state.category = data[0].output
    },
    async init_label ({ state }) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M0801LabelService.doBrowse_input',
          input: {
            label_type: 'CONTENT_LEVEL'
          }
        }]
      })

      state.label = data[0].output
    },
    async submitReviewFinal ({ state }, payload) {
      return await ApiUtils.postData({
        data: [{ api: 'L0101ContentService.submitReviewFinal', input: payload }]
      })
    },
    async updateStatusAdmin ({ state }, payload) {
      return await ApiUtils.postData({
        data: [{ api: 'L0101ContentService.updateStatusAdmin', input: payload }]
      })
    },
    async checkPendingApproval ({ state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: 'L0101ContentService.checkPendingApproval', input: payload }]
      })

      state.disableSubmitReview = res[0].output
    },
    async doPublish_content ({ state, dispatch }, payload) {
      const data = await ApiUtils.postData({
        data: [{ api: 'L0101ContentService.doPublish_content', input: payload }]
      })

      return await dispatch('getEdit', { seo_url: data[0].output.seo_url })
    },
    async doDelete ({ state }, payload) {
      return await ApiUtils.postData({
        data: [{ api: 'L0101ContentService.doDelete', input: payload }]
      })
    },
    async doDeleteContent ({ state, dispatch }, payload) {
      const data = await ApiUtils.postData({
        data: [{ api: 'L0101ContentService.doDeleteContent', input: payload }]
      })

      return await dispatch('getEdit', { seo_url: data[0].output.seo_url })
    }
  }
}

export default L0101Const.getStore(store)
