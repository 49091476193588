import ApiUtils from '@/utils/api/ApiUtils'
import C4101Const from './C4101Const'

const StoreConst = C4101Const.storeConst
const store = {
  state: {
    project_advise_answer_answered: null
  },
  getters: {
    getProjectAdviseAnswerAnswered: state => state.project_advise_answer_answered
  },
  mutations: {
    SET_PROJECT_ADVISE_ANSWER_ANSWERED (state, payload) {
      state.project_advise_answer_answered = payload
    }
  },
  actions: {
    async upvote ({ dispatch }, payload) {
      await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.upvote.api, input: payload }]
      })
      await dispatch('doBrowse')
    },
    async downvote ({ dispatch }, payload) {
      await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.downvote.api, input: payload }]
      })
      await dispatch('doBrowse')
    },
    async markAsBestAnswer ({ dispatch }, payload) {
      await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.markAsBestAnswer.api, input: payload }]
      })
      await dispatch('doBrowse')
    },
    async reward ({ dispatch }, payload) {
      await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.reward.api, input: payload }]
      })
      await dispatch('doBrowse')
    },
    async getProjectAdviseAnswerAnswered ({ state, commit }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.getProjectAdviseAnswerAnswered.api, input: payload }]
      })
      commit('SET_PROJECT_ADVISE_ANSWER_ANSWERED', res[0].output)
    }
  }
}

export default C4101Const.getStore(store)
