<template>
  <div>
    <slot :display="display">
      <span>{{ display }} </span>
    </slot>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    value: {
      type: [Array, Object],
      default: () => []
    },
    allDay: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    startDate () {
      if (this.value.start) {
        return this.value.start
      }
      return this.value[0]
    },
    endDate () {
      if (this.value.end) {
        return this.value.end
      }
      return this.value[1]
    },
    isSameDay () {
      return moment(this.startDate).isSame(this.endDate, 'day')
    },
    formattedStartDate () {
      return moment(this.startDate).format('dddd, MMMM D')
    },
    formattedEndDate () {
      return moment(this.endDate).format('dddd, MMMM D')
    },
    formattedStartTime () {
      return moment(this.startDate).format('h:mm A')
    },
    formattedEndTime () {
      return moment(this.endDate).format('h:mm A')
    },
    display () {
      let init = '_sd_ ⋅ _st_ – _et_ ⋅ _ed_'

      if (this.isSameDay) {
        init = init.replace('⋅ _ed_', '')
      }
      if (this.allDay) {
        init = init.replace('_st_', '')
          .replace('_et_', '')
          .replace('⋅', '')
      }

      // if no et and ed, remove the dash
      if (this.isSameDay && this.allDay) {
        init = init.replace(' – ', '')
      }

      return init
        .replace('_sd_', this.formattedStartDate)
        .replace('_st_', this.formattedStartTime)
        .replace('_ed_', this.formattedEndDate)
        .replace('_et_', this.formattedEndTime)
    }
  },
  methods: {
  }
}
</script>
