import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C1201ProjectCrowdFundItem',
  serviceName: 'C1201ProjectCrowdFundItem',
  componentDef: {
    form: {
      viewDef: [
        {
          column: 'col-12',
          data: 'project_crowd_fund_item_title',
          type: 'text',
          label: i18n.t('c1201.filter.project_crowd_fund_item_title')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_item_cover',
          type: 'file',
          props: {
            accept: 'image/*'
          },
          label: i18n.t('c1201.filter.project_crowd_fund_item_cover')
        }
      ]
    }
  }
}
const C1201Const = new BaseDataStore(BaseConst)
export default C1201Const
