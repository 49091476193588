import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C4101ProjectAdviseAnswer',
  serviceName: 'C4101ProjectAdviseAnswerService',
  storeConst: {
    ACTION: {
      upvote: {
        store: 'C4101ProjectAdviseAnswer/upvote',
        api: 'C4101ProjectAdviseAnswerService.upvote'
      },
      downvote: {
        store: 'C4101ProjectAdviseAnswer/downvote',
        api: 'C4101ProjectAdviseAnswerService.downvote'
      },
      markAsBestAnswer: {
        store: 'C4101ProjectAdviseAnswer/markAsBestAnswer',
        api: 'C4101ProjectAdviseAnswerService.markAsBestAnswer'
      },
      getProjectAdviseAnswerAnswered: {
        store: 'C4101ProjectAdviseAnswer/getProjectAdviseAnswerAnswered',
        api: 'C4101ProjectAdviseAnswerService.getProjectAdviseAnswerAnswered'
      },
      reward: {
        store: 'C4101ProjectAdviseAnswer/reward',
        api: 'C4101ProjectAdviseAnswerService.reward'
      },
    },
    PROPERTY: {
      project_advise_answer_answered: {
        get: 'C4101ProjectAdviseAnswer/getProjectAdviseAnswerAnswered',
        set: 'C4101ProjectAdviseAnswer/SET_PROJECT_ADVISE_ANSWER_ANSWERED'
      }
    }
  },
  componentDef: {
    form: {
      viewDef: [
        {
          column: 'col-12',
          data: 'project_advise_answer_answer',
          custom: 'MoBlockEditor',
          props: {
            data: 'project_advise_answer_answer'
          },
          label: i18n.t('c4101.filter.project_advise_answer_answer')
        }
      ]
    }
  }
}

const C4101Const = new BaseDataStore(BaseConst)
export default C4101Const
