<template>
  <div>
    <v-row v-if="unresolvedComments.length || resolvedComments.length || permission.addComment">
      <v-spacer />
      <!-- unresolved  btn -->
      <button
        v-if="unresolvedComments.length || permission.addComment"
        class="toggle-unresolved-btn mr-2"
        title="Unresolved Comment(s)"
        :active="showUnresolved"
        @click="showUnresolved = !showUnresolved"
      >
        {{ unresolvedComments.length ? unresolvedComments.length : '' }}
        <i
          v-if="!showUnresolved"
          class="v-icon mdi mdi-message-outline"
        />
        <i
          v-else
          class="v-icon mdi mdi-message"
        />
      </button>
      <!-- resolved  btn -->
      <button
        v-if="resolvedComments.length"
        class="toggle-resolved-btn mr-2"
        title="Resolved Comment(s)"
        :active="showResolved"
        @click="showResolved = !showResolved"
      >
        {{ resolvedComments.length ? resolvedComments.length : '' }}
        <i
          v-if="!showResolved"
          class="v-icon mdi mdi-message-check-outline"
        />
        <i
          v-else
          class="v-icon mdi mdi-message-check"
        />
      </button>
    </v-row>
    <div
      v-if="showResolved || showUnresolved"
      class="section row"
      :class="showResolved ? 'section--resolved' : ''"
    >
      <div
        v-for="c in c_value"
        :key="c.id"
        class="section-item col-12"
      >
        <comment-item
          class="comment-item"
          :comment="c"
          :show-input="showUnresolved"
          :current-user-id="currentUserId"
          :users-data="usersData"
          @resolveComment="$emit('resolveComment',$event)"
          @replyComment="$emit('replyComment',$event)"
          @editComment="$emit('editComment', $event)"
          @deleteComment="$emit('deleteComment',$event)"
        />
      </div>
      <div
        v-if="showUnresolved && permission.addComment"
        class="row"
      >
        <input
          v-model="inputComment"
          class="section-input col"
          placeholder="Add Comment  Here ..."
          @keydown="enterToSend"
        >
        <!-- send button -->
        <button
          class="section-send col-1"
          title="Send"
          :disabled="!inputComment"
          @click="sendComment()"
        >
          <i
            aria-hidden="true"
            class="v-icon  mdi mdi-send "
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CommentItem from './CommentItem.vue'

export default {
  components: {
    CommentItem
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    show: {
      type: String,
      default: 'hide',
      validator: function (value) {
        return ['resolved', 'unresolved', 'hide'].indexOf(value) !== -1
      }
    }
  },
  data () {
    return {
      d_showInput: false,
      inputComment: '',
      d_show: ''
    }
  },
  computed: {
    showUnresolved: {
      get () { return this.d_show === 'unresolved' },
      set (val) { this.d_show = val ? 'unresolved' : 'hide' }
    },
    showResolved: {
      get () { return this.d_show === 'resolved' },
      set (val) { this.d_show = val ? 'resolved' : 'hide' }
    },
    c_value () {
      switch (this.d_show) {
        case 'resolved':
          return this.resolvedComments
        case 'unresolved':
          return this.unresolvedComments
        default:
          return this.value
      }
    },
    resolvedComments () {
      return this.value.filter(c => c.resolved)
    },
    unresolvedComments () {
      return this.value.filter(c => !c.resolved)
    }
  },
  created () {
    this.d_show = this.show
  },
  destroyed () {
  },

  methods: {
    sendComment () {
      this.$emit('sendComment', { content: this.inputComment })
      this.inputComment = ''
    },
    enterToSend (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        this.sendComment()
      }
    }
  }
}
</script>
