import BaseDataStore from '@/components/base/store/BaseDataStoreV1'

const BaseConst = {
  version: 1,
  storeName: 'C3001ProjectPartnerRequirement',
  serviceName: 'C3001ProjectPartnerRequirementService',
  storeConst: {
    ACTION: {
      getHint: {
        store: 'M3901Guide/getHint'
      }
    }
  },
  componentDef: {
    form: {
      viewDef: [
        {
          column: 'col-12',
          data: 'project_investment_policy',
          type: 'ttc_policy',
          props: {
            policyCode: 'collab.projectPartnerRequirement'
          }
        }
      ]
    }
  }
}
const C3001Const = new BaseDataStore(BaseConst)
export default C3001Const
