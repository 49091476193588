import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
const module = {
  state: {
    cities: []
  },
  getters: {
    getCities: (state) => {
      return state.cities
    }
  },
  mutations: {
    SET_CITIES (state, cities) {
      state.cities = cities
    }
  },
  actions: {
    getCities ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [
          {
            api: 'O0103CitiesService.getCities',
            input: payload
          }
        ]
      }).then((data) => {
        commit('SET_CITIES', data[0].output)
      })
    }
  }
}
export default ObjArrUtils.merge(Base, error, BREAD, module)
