<template>
  <!-- Simple Checkbox -->
  <v-row v-if="!checkboxes.length">
    <v-col>
      <VCheckbox
        v-model="dValue"
        v-bind="{...$attrs}"
        v-on="$listeners"
      />
    </v-col>
  </v-row>

  <!-- Checkbox Group -->
  <div v-else>
    <v-input
      v-bind="{...$attrs}"
      hide-details
    />
    <div>
      <v-row
        v-for="(row, i) in checkboxes"
        :key="i"
      >
        <v-col
          v-for="col in row"
          :key="col[itemValue]"
        >
          <VCheckbox
            v-model="dValue"
            v-bind="{...$attrs}"
            :label="typeof col === 'object' ? col[itemText] : col"
            :value="typeof col === 'object' ? col[itemValue]: col"
            hide-details
            v-on="$listeners"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
/**
  * Extend VCheckbox
  * @displayName AtCheckbox
  */
export default {
  props: {
    /**
     * Can be an array of objects or array of strings.
     * When using objects, will look for a text, and value.
     * This can be changed using the **item-text**, and **item-value** props.
     */
    items: {
      type: Array,
      default: () => []
    },
    /**
     * Set property of **items**'s text value
     */
    itemText: {
      type: String,
      default: 'text'
    },
    /**
     * Set property of **item**'s value - **must be primitive**.
     */
    itemValue: {
      type: String,
      default: 'value'
    },
    /**
     * The input's value
     */
    value: {
      type: [Boolean, Array, String],
      default () {
        if (this.commaDelimited) return ''
        return !this.items.length ? false : []
      }
    },
    /**
     * Number of items per row.
     * Can be a number or an object with breakpoint keys that return boolean. 
     * {@link https://vuetifyjs.com/en/features/breakpoints/#breakpoint-service-object}
     * @example
     * ```js
     * 1 // 1 item per row
     * { sm: 2, md: 3, lg: 4 } // 2 items per row on sm, 3 on md, 4 on lg,  other breakpoints will use 1
     * { smAndUp: 2 } // 1 item per row on xs, 2 items per row on sm and up
     * ```
     */
    itemsPerRow: {
      type: [Number, Object],
      default: 1
    },
    delimited: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      dValue: undefined
    }
  },
  computed: {
    checkboxes () {
      return this.items.reduce((resultArray, item, index) => {
        function getPerChunk (ctx) {
          if (typeof ctx.itemsPerRow === 'number') return ctx.itemsPerRow
          for (const key in ctx.itemsPerRow) {
            if (ctx.$vuetify.breakpoint[key] === undefined) {
              console.error(`Invalid breakpoint key: ${key}`)
            }
            if (typeof ctx.$vuetify.breakpoint[key] !== 'boolean') {
              console.error(`Breakpoint key must return a boolean: ${key}`)
            }
            if (ctx.$vuetify.breakpoint[key]) return ctx.itemsPerRow[key]
          }
          return 1
        }
        const perChunk = getPerChunk(this)

        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])
    },
    multiMode: {
      get () {
        return this.items.length > 1
      }
    }
  },
  watch: {
    value: {
      handler (val, oldVal) {
        if (val === oldVal) return
        let o = val
        if (this.multiMode && this.delimited) {
          if (typeof val === 'string') {
            // parse int, if NaN, set to original value
            o = o
              ? o.split(this.delimited).map((v) => parseInt(v) || v)
              : []
          }
          o = o === undefined ? [] : o
        }
        this.dValue = o
      },
      immediate: true
    },
    dValue: {
      handler (val, oldVal) {
        if (val === oldVal) return
        let o = val
        if (this.multiMode && this.delimited) {
          // warn if any element in val(array) contain delimiter
          if (Array.isArray(val)) {
            val.forEach((v) => {
              if (v.toString().includes(this.delimited)) {
                console.error(`AtCheckbox: value "${v}" contains delimiter "${this.delimited}" \n` +
                'Will cause unexpected behavior.')
              }
            })
          }
          o = o ? o.join(this.delimited) : o
          // if o is empty array, set to empty string
          o = o === '' ? undefined : o
        }
        this.$emit('input', o)
      },
      immediate: true
    }
  }
}
</script>
