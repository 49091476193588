import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

// a
const module = {
  state: {
    numApproval: 0,
    numReports: 0,
    numEnquiries: 0,
    numLecturerApplication: 0
  },

  getters: {
    getNumApproval (state) {
      return state.numApproval
    },
    getNumReports (state) {
      return state.numReports
    },
    getNumEnquiries (state) {
      return state.numEnquiries
    },
    getLecturerApplication (state) {
      return state.numLecturerApplication
    }
  },

  mutations: {
    SET_NUMBER_APPROVAL (state, payload) {
      state.numApproval = payload
    },
    SET_NUM_REPORTS (state, payload) {
      state.numReports = payload
    },
    SET_NUM_ENQUIRIES (state, payload) {
      state.numEnquiries = payload
    },
    SET_LECTURER_APPLICATION (state, payload) {
      state.numLecturerApplication = payload
    }
  },

  actions: {
    init: ({ commit }) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.getNumApprovalCases' },
          { api: 'M1201ReportCaseService.getNumReportCases' },
          { api: 'M2501EnquiryService.getNumEnquiries' },
          { api: 'ZZ0101LecturerApplicationService.getLecturerApplication' }
        ]
      })
        .then((data) => {
          commit('SET_NUMBER_APPROVAL', data[0].output)
          commit('SET_NUM_REPORTS', data[1].output)
          commit('SET_NUM_ENQUIRIES', data[2].output)
          commit('SET_LECTURER_APPLICATION', data[3].output)
        })
    },
    doBrowse_statistic: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'S0801SettingService.daily_Statistics', input: payload }]
      }).then(data => {
        return data[0].output
      })
    }
  }
}
export default ObjArrUtils.merge(Base, module)
