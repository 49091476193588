// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// import i18n from '@/plugins/i18n'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async doSearch (_, payload) {
      const input = {
        pagination: {
          current_page: payload.currentPage ?? 1,
          per_page: payload.perPage ?? 3
        },
        filter: {
          comment_rel_model: payload.relInfo.model,
          comment_rel_id: payload.relInfo.id,
          parent_id: payload.parentId ?? undefined
        },
        orderBy: {
          order_created_at: payload.createdAt ?? 'DESC',
          order_comment_likes: payload.commentLikes ?? undefined
        }
      }
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.doSearch', input }] })
      return data[0].output
    },
    async doAdd (_, payload) {
      const input = {
        parent_id: payload.parentId, // 'numeric', 
        comment_rel_model: payload.relInfo.model, // 'required|string',
        comment_rel_id: payload.relInfo.id, // 'required|numeric',
        text: payload.text, // 'string',
        files: payload.files // 'array',
      }
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.doAdd', input }] })
      return data[0].output
    },
    async doReact (_, payload) {
      const input = {
        id: payload.id, // 'required|numeric',                                       
        reactionType: payload.reactionType // 'in:L,D,UV,DV' // L:like, D:dislike, UV:upvote, DV:downvote
      }
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.doReact', input }] })
      return data[0].output
    },
    async doEdit (_, payload) {
      const input = {
        id: payload.id, // 'required|numeric',
        text: payload.text, // 'string',
        files: payload.files // 'array',
      }
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.doEdit', input }] })
      return data[0].output
    },
    // 3 use cases:
    // 1. user can delete own comment
    // 2. content owner can hide/unhide any comment from their content
    // 3. admin can remove any comment
    async doDelete (_, payload) {
      const input = {
        id: payload.id, // 'required|numeric'
        remark: payload.remark // 'string'
      }
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.doDelete', input }] })
      return data[0].output
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
