import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
const module = {
  state: {
    states: []
  },
  getters: {
    getStates: (state) => {
      return state.states
    }
  },
  mutations: {
    SET_STATES (state, states) {
      state.states = states
    }
  },
  actions: {
    getStates ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [
          {
            api: 'O0102StatesService.getStates',
            input: {
              o0101_id: payload
            }
          }
        ]
      }).then((data) => {
        commit('SET_STATES', data[0].output)
      })
    }
  }
}
export default ObjArrUtils.merge(Base, error, BREAD, module)
