<template>
  <v-app id="app">
    <router-view
      v-if="viewShow"
      :key="refreshKey"
    />
    <LottieLoader
      :show="showLoader"
      :animation-data="require('@/assets/json/lottie/loaders/open-book-loader.json')"
      overlay-color="#21212155"
      overlay
      center
      fullscreen
    />
    <UserInteractionDialog />
    <ReportDialog />
    <DebugOverlay />
  </v-app>
</template>

<script>
import { LottieLoader } from '@/components/base'

import notification from '@/mixins/notification'
import websocket from '@/mixins/websocket'
import { mapGetters } from 'vuex'
import { ReportDialog } from '@/app/M1201ReportCase/index'
import DebugOverlay from './components/debug/DebugOverlay.vue'
import UserInteractionDialog from './components/TTC/TTCInteraction/UserInteractionDialog.vue'

export default {
  name: 'App',
  components: {
    LottieLoader,
    ReportDialog,
    DebugOverlay,
    UserInteractionDialog
  },
  mixins: [notification, websocket],
  data () {
    return {
      refreshKey: Date.now(),
      viewShow: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'Auth/getUser'
    }),
    showLoader () {
      return !!this.$store.getters['App/getAppLoading'] > 0
    }
  },
  // watch `user.locale_code`
  watch: {
    'user.locale_code' (val) {
      this.changeLocale(val)
      this.refreshKey = Date.now()
    },
    'user.id' (val, oldVal) {
      if (val !== oldVal) return
      this.refreshKey = Date.now()
    }
  },
  created () {
    this.initLocale().then((changed) => {
      this.viewShow = true
      if (changed) this.refreshKey = Date.now()
    })
  },
  mounted () {
    this.$root.$on('localeChanged', () => {
      this.refreshKey = Date.now()
    })
  }
}
</script>

<style>
</style>
