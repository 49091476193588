import moment from 'moment'
import 'moment-timezone'

const systemTimezone = import.meta.env.VITE_APP_API_SYSTEM_TIMEZONE

const DateTimeUtils = {
  convertToDatabaseTime (input) {
    return moment(input).format('YYYY-MM-DD HH:mm:ss')
  },
  convertToUTC (input) {
    return moment(input).utc().format()
  },
  convertTimeZoneToUTC (input, timezone) {
    return moment.tz(input, timezone).utc().format()
  },
  convertUTCToLocal (input) {
    return moment.utc(input).local().format()
  },
  convertUTCToTimeZone (input, timezone) {
    return moment.utc(input).tz(timezone).format()
  },
  convertToTimeZone (input, timezone = systemTimezone) {
    return moment(input).tz(timezone).format()
  },
  convertTimeZoneToLocal (input, timezone) {
    return moment.tz(input, timezone).local().format()
  },
  convertTimeZoneToTimeZone (input, timezoneSource, timezoneDest) {
    return moment.tz(input, timezoneSource).tz(timezoneDest).format()
  },
  formatDisplay (input, format = '') {
    return moment(input).format(format)
  },
  dateDisplay (input, dayFirst = true) {
    return dayFirst ? moment(input).format('DD/MM/YYYY') : moment(input).format('YYYY/MM/DD')
  },
  dateWeekDisplay (input, dayFirst = true) {
    return dayFirst ? moment(input).format('DD/MM/YYYY (dddd)') : moment(input).format('YYYY/MM/DD (dddd)')
  },
  dateTimeDisplay (input, dayFirst = true) {
    return dayFirst ? moment(input).format('DD/MM/YYYY hh:mm A') : moment(input).format('YYYY/MM/DD hh:mm A')
  },
  dateTimeFormatDisplay (input, format = 'DD/MM/YYYY hh:mm A') {
    return moment(input).format(format)
  },
  dateTimeDisplayHK (input, dayFirst = true) {
    return dayFirst ? moment(input).format('LL hh:mm A') : moment(input).format('LL hh:mm A')
  },
  dateDisplayHK (input, week = false) {
    return week ? moment(input).format('LL （dddd）') : moment(input).format('LL')
  },
  timeDisplayHK (input) {
    return moment(input).format('hh:mm A')
  },
  dateTimeRangeDisplay (start, end) {
    start = this.convertTimeZoneToLocal(start, systemTimezone)
    end = this.convertTimeZoneToLocal(end, systemTimezone)
    if (moment(start).isSame(end, 'day')) {
      return this.dateTimeDisplay(start) + ' - ' + this.formatDisplay(end, 'hh:mm A')
    }
    return this.dateTimeDisplay(start) + ' - ' + this.dateTimeDisplay(end)
  },
  weekDisplay (input) {
    return moment(input).format('dddd')
  },
  monthDisplay (input) {
    return moment().month(input - 1).format('MMMM')
  },
  timeDisplay (input, timezone = '', hour12 = true) {
    return hour12 ? moment(input).tz(timezone).format('hh:mm A') : moment(input).tz(timezone).format('HH:mm')
  },
  relativeTimeDisplay (input) {
    let output = moment(input).fromNow() // 1.11 sec
    // make output dont have decimal point
    const outputArr = output.split(' ')
    if (outputArr.length > 1) {
      if (outputArr[0].indexOf('.') > -1) {
        outputArr[0] = outputArr[0].split('.')[0]
        output = outputArr.join(' ')
      }
    }

    return output
  },
  infoDisplay (input) {
    return moment(input).format('LLL dddd') + ' [ ' + moment(input).fromNow() + ' ]'
  },
  infoTimeDisplay (input) {
    return moment(input).format('hh:mm A') + ' [ ' + moment(input).fromNow() + ' ]'
  },
  addBracket (input, zh = true) {
    return input ? zh ? '（' + input + '）' : '(' + input + ')' : input
  },
  /**
   * Converts a duration in minutes to a formatted string representation.
   * @param {moment.duration} duration The duration to format.
   * @returns {string} The formatted string representation of the duration.
   *
   * @example
   * DateTimeUtils.formatDuration(moment.duration(70, 'minutes')) // 1 hour 10 minutes
   */
  formatDuration (duration) {
    if (!moment.isDuration(duration)) {
      throw new Error('duration must be a moment duration object')
    }
    const dur = duration

    const tmp = {
      y: dur.years() === 1 ? dur.years() : undefined,
      yy: dur.years() !== 1 ? dur.years() : undefined,
      M: dur.months() === 1 ? dur.months() : undefined,
      MM: dur.months() !== 1 ? dur.months() : undefined,
      w: dur.weeks() === 1 ? dur.weeks() : undefined,
      ww: dur.weeks() !== 1 ? dur.weeks() : undefined,
      d: dur.days() === 1 ? dur.days() : undefined,
      dd: dur.days() !== 1 ? dur.days() : undefined,
      h: dur.hours() === 1 ? dur.hours() : undefined,
      hh: dur.hours() !== 1 ? dur.hours() : undefined,
      m: dur.minutes() === 1 ? dur.minutes() : undefined,
      mm: dur.minutes() !== 1 ? dur.minutes() : undefined,
      s: dur.seconds() === 1 ? dur.seconds() : undefined,
      ss: dur.seconds() !== 1 ? dur.seconds() : undefined
    }
    const str = dur.localeData()._relativeTime
    const keys = Object.keys(tmp)
    const values = Object.values(tmp)
    const result = []
    for (let i = 0; i < keys.length; i++) {
      if (values[i]) {
        result.push(str[keys[i]].replace(/%d/g, values[i]))
      }
    }
    return result.join(' ')
  },
  // give two date, return the formatDuration between them
  formatDurationBetween (start, end) {
    const duration = moment.duration(moment(end).diff(moment(start)))
    return this.formatDuration(duration)
  }
}
export default DateTimeUtils
