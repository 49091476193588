import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'ZZ0201PromotionRequest',
  serviceName: 'ZZ0201PromotionRequestService',
  componentDef: {
    table: {
      viewDef: [
        { text: i18n.t('zz0201.table.projectTitle'), value: 'title' },
        {
          text: i18n.t('zz0201.table.status.title'),
          value: 'status',
          type: 'chip',
          items: [
            { value: 'PENDING', label: i18n.t('zz0201.table.status.pending'), color: 'warning' },
            { value: 'APPROVED', label: i18n.t('zz0201.table.status.approved'), color: 'primary' },
            { value: 'REJECTED', label: i18n.t('zz0201.table.status.rejected'), color: 'error' }
          ]
        },
        { text: i18n.t('zz0201.table.time'), value: 'time', type: 'relativeDate' },
        { text: i18n.t('zz0201.table.action'), value: 'actions', sortable: false }
      ],
      optionDef: {}
    }
  }
}
const ZZ0201Const = new BaseDataStore(BaseConst)
export default ZZ0201Const
