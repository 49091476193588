import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    popularProjects: [],
    popularPartnerSeeking: [],
    popularAdvise: [],
    recentlyBrowse: [],
    intro: [],
    list: null,
    row: null
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async init ({ state }) {
      const res = await ApiUtils.postData({
        data: {
          popularProjects: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'collab.home.popularProjects' },
          popularPartnerSeeking: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'collab.home.popularPartnerSeeking' },
          popularAdvise: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'collab.home.popularAdvise' },
          recentlyBrowse: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'collab.home.recentlyBrowse' },
          intro: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'collab.home.intro' },
        }
      })
      for (const key in res) {
        state[key] = res[key].output
      }
    },
    getProjectList: ({ state }) => {
      return ApiUtils.postData({
        data: [{
          api: 'C0101ProjectService.getProjectList'
        }]
      }).then(data => {
        state.list = data[0].output
      })
    },
    async getEdit({ commit, state, dispatch }, payload) {
      state.row = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.getEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output[0]

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async doEdit({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.doEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
