import ObjArrUtils from '@/utils/ObjArrUtils'
import ApiUtils from '@/utils/api/ApiUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    projectCalendar_doSearch: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'C0101ProjectService.projectCalendar_doSearch', input: payload }]
      }).then(res => {
        state.rows = res[0].output
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
