// @ts-check

/** @module StringUtils */

/**
 * Utilities for string related operations
 * @property {Function} countCharacter Count the occurance of character in a string
 * @property {Function} getPixelSize Transform size (number) into pixels
 * 
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/20
 */
const StringUtils = {
  /**
   * Count the occurance of character in a string
   * @param {String} str The source string to be check against
   * @param {String} char The character to be counted
   * @return {Number} Character occurance in the `String`
   */
  countCharacter (str, char) {
    return str.split(char).length - 1
  },
  /**
     * Transform size (number) into pixels
     * @param {Number | any} size
     * - `Number`: size to convert into pixles
     * - `any`: Will be ignored for processing
     * @return {String | any} CSS renderable size string
     */
  getPixelSize (size) {
    // @ts-ignore
    // eslint-disable-next-line valid-typeof
    return typeof size === 'number' ? `${size}px` : size
  }
}
export default StringUtils
