export default [
  {
    portalGroup: 'admin.admin',
    path: 'affiliate/:id(\\d+)/review',
    name: 'AffiliateReview',
    component: () => import('@/app/M5101Affiliate/PgAffiliateReview.vue'),
    meta: { authenticated: true }
  },
  {
    portalGroup: 'master.my',
    path: 'affiliate/dashboard',
    name: 'PgAffiliateDashboard',
    component: () => import('@/app/M5101Affiliate/Dashboard/PgAffiliateDashboard.vue'),
    meta: { authenticated: true }
  },
  {
    portalGroup: 'master.my',
    path: 'vendor/dashboard',
    name: 'PgVendorDashboard',
    component: () => import('@/app/M5101Affiliate/Dashboard/PgVendorDashboard.vue'),
    meta: { authenticated: true }
  }
]
