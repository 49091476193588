import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C1301ProjectCrowdFundPledge',
  serviceName: 'C1301ProjectCrowdFundPledgeService',

  //  if search -> search component
  componentDef: {
    form: {
      /*
       type: div, row, col, form, text,file,select, button,table
       component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
       rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewDef: [
        {
          column: 'col-12',
          custom: 'ShippingForm'
        },
        {
          column: 'col-12',
          data: 'name',
          type: 'text',
          rules: 'required',
          label: i18n.t('c0101.form.name'),
          class: 'required',
          // Ah Huat done changes for label to appear above the field
          topLabel: true
        },
        {
          column: 'col-12',
          data: 'phone',
          custom: 'Phone',
          rules: ['required',
            (val) => {
              if (val.length < 10) {
                return i18n.t('components.AtTelInput.minimum10')
              }
              return true
            }],
          class: 'required',
          label: i18n.t('c0101.form.phone')
        },
        {
          column: 'col-12',
          data: 'address1',
          type: 'textarea',
          label: i18n.t('c0101.form.address'),
          rules: 'required',
          class: 'required',
          topLabel: true
        },
        {
          column: 'col-12',
          data: 'address2',
          type: 'textarea',
          label: i18n.t('c0101.form.address2'),
          topLabel: true
        },
        {
          column: 'col-6',
          custom: 'Country',
          label: i18n.t('c0101.form.country'),
          data: 'country',
          topLabel: true,
          props: {
            itemText: 'name',
            itemValue: 'id'
          }
        },
        {
          column: 'col-6',
          custom: 'State',
          data: 'state',
          label: i18n.t('c0101.form.state'),
          topLabel: true,
          props: {
            itemText: 'name',
            itemValue: 'id'
          }
        },
        {
          column: 'col-6',
          custom: 'City',
          data: 'city',
          label: i18n.t('c0101.form.city'),
          topLabel: true,
          props: {
            itemText: 'name',
            itemValue: 'id'
          }
        },
        {
          column: 'col-6',
          data: 'postcode',
          type: 'text',
          label: i18n.t('c0101.form.postCode'),
          topLabel: true
        }
      ],
      optionDef: {}
    }
  }
}
const C1301Const = new BaseDataStore(BaseConst)
export default C1301Const
