// @ts-check

import locale from '@/configs/locale.js'

// Import locales pack for veutify (add / remove desired locales)

// @ts-ignore
import zhHant from 'vuetify/src/locale/zh-Hant.ts'
import { PortalUtils } from '@/utils/PortalUtils/index.js'

export default {
  /**
   * Defines icon settings in vuetify  
   * Currently installed fonts: [mdi, FontAwesome6]
   * @see https://vuetifyjs.com/en/features/icon-fonts/
   */
  icons: {
    iconfont: 'mdi'
  },
  /**
   * Defines i18n settings in vuetify
   * @see https://vuetifyjs.com/en/features/internationalization/
   */
  lang: {
    locales: { zhHant },
    current: locale.currentLocale
  },
  /**
   * Defines theme related settings in vuetify
   * @see https://vuetifyjs.com/en/features/theme/
   */
  theme: {
    options: { customProperties: true },
    themes: PortalUtils.themes
  }
}
