<template>
  <ValidationObserver
    ref="observer"
  >
    <v-form
      ref="form"
      v-model="fromIsValid"
      @submit.prevent="buttonOnClick('submit')"
    >
      <slot name="label">
        <p>
          {{ label }}
        </p>
      </slot>
      <v-alert
        v-if="debug"
        type="info"
        outlined
      >
        <div class="text-h6">
          Debug
        </div>
        focusIndex ::{{ focusIndex }}
        <br>
        fieldModel ::{{ fieldModel }}
        <br>
        initValue ::{{ initValue }}
      </v-alert>
      <v-row
        v-for="(y, j) in dDef"
        :key="`form_element_${j}`"
      >
        <template
          v-for="(x, i) in y"
        >
          <div
            v-if="(x.show?x.show(fieldModel,x):true) && appAllowAccess(x.authenticated ? x.authenticated : false, x.roles ? x.roles : [], x.permissions ? x.permissions : [])"
            :key="`form_element_${j}_${i}`"
            :class="x.column + ' py-1'"
          >
            <input-rules-wrapper
              :rules="x.rules"
              :value="tempData[`${j}_${i}`]"
              v-bind="x"
            >
              <template #default="{ attrs,validateInput }">
                <div v-if="x.type === 'section'">
                  <h2 :class="x.class">
                    {{ x.title }}
                  </h2>
                  <v-divider />
                </div>
                <div v-else>
                  <!-- TODO: not mobile friendly -->
                  <slot
                    :name="x.data+'_top'"
                    v-bind="x"
                  >
                    <div
                      v-if="useTopLabel(x)"
                      :class="'text-subtitle-1 text-medium-emphasis ' + x.class"
                    >
                      <label>
                        {{ x.props.label }}
                      </label>
                      <v-tooltip right>
                        <template #activator="tt">
                          <v-icon
                            v-bind="tt.attrs"
                            style="vertical-align: middle"
                            v-on="tt.on"
                          >
                            mdi-help-circle
                          </v-icon>
                        </template>
                        <span>{{ x.labelHint }}</span>
                      </v-tooltip>
                    </div>
                  </slot>
                  <component
                    :is="x.component"
                    v-if="checkRegisteredComps(x.component)"
                    v-bind="{
                      ...x.props,
                      label: useTopLabel(x)? undefined :x.props? x.props.label : undefined,
                      ...attrs,
                      value: tempData[`${j}_${i}`]
                    }"
                    v-on="{
                      input: (val) => {
                        updateTempData(
                          {
                            key:`${j}_${i}`,
                            val,validateInput
                          }
                        )
                      },
                      focus: ()=>{focusIndex = `${j}_${i}`},
                      blur: ()=>{
                        focusIndex = null
                        emit('blur', {...x, value: tempData[`${j}_${i}`]});
                      },
                      keyup: (e) => { focusNext(e) },
                      isUploading: (e) => {
                        isUploading[`${j}_${i}`] = e
                      }
                    }"
                  >
                    <template
                      v-for="slot in scopedSlotNamesOnly"
                      #[fieldSlotName(slot,x.data)]="scope"
                    >
                      <template v-if="isFieldSlot(slot,x.data)">
                        <slot
                          :name="slot"
                          v-bind="scope"
                        />
                      </template>
                    </template>
                    <template
                      v-for="slot in slotNamesOnly"
                    >
                      <template v-if="isFieldSlot(slot,x.data)">
                        <slot
                          :name="slot"
                        />
                      </template>
                    </template>
                  </component>
                  <slot
                    v-else
                    :name="x.component"
                    :row="fieldModel"
                    :attrs="{
                      ...x.props,
                      label: x.labelHint? undefined :x.props? x.props.label : undefined,
                      ...attrs,
                      value: tempData[`${j}_${i}`]
                    }"
                    :on="{
                      input: (val) => {
                        validateInput(val)
                        let isChanged= JSON.stringify(val) !== JSON.stringify(tempData[`${j}_${i}`])
                        $set(tempData, `${j}_${i}`, val)
                        if(isChanged){
                          $nextTick(() => {
                            emit('change',fieldModel);
                          })
                        }
                      },
                      focus: ()=>{focusIndex = `${j}_${i}`},
                      blur: ()=>{
                        focusIndex = null
                        emit('blur', {...x, value: tempData[`${j}_${i}`]});
                      },
                      keyup: (e) => { focusNext(e) },
                      isUploading: (e) => { isUploading[`${j}_${i}`] = e }
                    }"
                  />
                </div>

                <v-alert
                  v-if="debug"
                  dense
                  type="info"
                >
                  <div class="text-h6">
                    Debug: {{ `${j}_${i}` }}
                  </div>
                  tempData ::{{ tempData[`${j}_${i}`] }}
                  <br>
                  data::{{ x.data }}
                </v-alert>
              </template>
            </input-rules-wrapper>
          </div>
        </template>
      </v-row>
      <slot name="actions">
        <v-card-actions>
          <ActionButton
            v-if="mergeButton('cancel').show"
            v-bind="mergeButton('cancel').props"
            @click="buttonOnClick('cancel')"
          />
          <ActionButton
            v-if="mergeButton('clear').show"
            v-bind="mergeButton('clear').props"
            @click="buttonOnClick('clear')"
          />
          <ActionButton
            v-if="mergeButton('reset').show"
            v-bind="mergeButton('reset').props"
            @click="buttonOnClick('reset')"
          />
          <v-spacer />
          <ActionButton
            v-if="mergeButton('submit').show"
            v-bind="mergeButton('submit').props"
            type="submit"
          />
        </v-card-actions>
      </slot>
    </v-form>
  </ValidationObserver>
</template>

<script>
// Doc : https://repos001.wizlink.local/m27_ttc/-/wikis/Frontend/Components/AtFormV2
import DataUtils from '@/utils/DataUtils'
import ActionButton from '@/components/base/molecules/ActionButton/ActionButton.vue'
import i18n from '@/plugins/i18n'
import InputRulesWrapper from '../InputRulesWrapper.vue'
import ObjArrUtils from '@/utils/ObjArrUtils'
import * as Input from '@/components/base/atoms/input'
import * as CustomInput from '@/components/base/atoms/input/indexCustom'
import DebugMixin from '@/mixins/DebugMixin'

import { debounce } from 'lodash'
const debounceTime = 0


const AtFormUtils = {
  get compList () {
    return {
      VAutocomplete: () => import('vuetify/lib/components/VAutocomplete'),
      VCombobox: () => import('vuetify/lib/components/VCombobox'),
      VRangeSlider: () => import('vuetify/lib/components/VRangeSlider'),
      VSlider: () => import('vuetify/lib/components/VSlider'),
      VSelect: () => import('vuetify/lib/components/VSelect'),
      VTextarea: () => import('vuetify/lib/components/VTextarea'),
      ...Input,
      // add custom Component Here (For Current Project)
      ...CustomInput
    }
  },
  basePresetList (key) {
    return {
      default: {
        column: 'col-md-4',
        component: 'AtTextField',
        label: key
      },
      section: {
        column: 'col-12',
        component: 'H2',
        label: key
      },
      // input
      text: {
        column: 'col-md-4',
        component: 'AtTextField',
        label: key
      },
      textarea: {
        column: 'col-md-4',
        component: 'VTextarea',
        label: key
      },
      number: {
        column: 'col-md-4',
        component: 'AtTextField',
        label: key,
        props: {
          type: 'number'
        }
      },
      checkbox: {
        column: 'col-md-4',
        component: 'AtCheckbox',
        label: key
      },
      switch: {
        column: 'col-md-4',
        component: 'AtSwitch',
        label: key
      },
      radio: {
        column: 'col-md-4',
        component: 'AtRadio',
        label: key
      },
      file: {
        column: 'col-md-4',
        component: 'AtFilePicker',
        label: key
      },
      date: {
        column: 'col-md-4',
        component: 'AtDatePicker',
        label: key
      },
      time: {
        column: 'col-md-4',
        component: 'AtTimePicker',
        label: key
      },
      dateRange: {
        column: 'col-md-4',
        component: 'AtDateRangePicker',
        label: key
      },
      select: {
        column: 'col-md-4',
        component: 'VSelect',
        label: key
      },
      autoComplete: {
        column: 'col-md-4',
        component: 'VAutocomplete',
        label: key
      },
      combobox: {
        column: 'col-md-4',
        component: 'VCombobox',
        label: key
      },
      rangeSlider: {
        column: 'col-md-4',
        component: 'VRangeSlider',
        label: key
      },
      slider: {
        column: 'col-md-4',
        component: 'VSlider',
        label: key
      },
      translations: {
        column: 'col-md-4',
        component: 'AtTranslationTabs',
        label: key
      }
    }
  },
  // add preset comps here (For Current Project)
  get customPresetList () {
    return {
      ttc_category: {
        column: 'col-md-12',
        component: 'TTCCategoryField',
        props: {
          label: i18n.t('components.TTCCategoryField.category'),
          outlined: true,
          items: []
        },
        async: [{
          api: 'M0601CategoryService.doBrowse',
          input: {
            category_key: 'GENERAL'
          },
          mutateTarget: 'props.items'
        }]
      },
      ttc_year_picker: {
        column: 'col-md-12',
        component: 'AtYearPicker'
      },
      ttc_tag: {
        column: 'col-md-12',
        component: 'TTCTagField',
        props: {
          label: i18n.t('components.TTCTagField.tags'),
          outlined: true,
          chips: true,
          multiple: true,
          counter: 10
        }
      },
      ttc_degree_tag: {
        column: 'col-md-12',
        component: 'TTCDegreeTagField',
        props: {
          label: i18n.t('components.TTCTagField.tags'),
          outlined: true
        }
      },
      ttc_field_tag: {
        column: 'col-md-12',
        component: 'TTCFieldOfStudy',
        props: {
          label: i18n.t('components.TTCTagField.tags'),
          outlined: true
        }
      },
      ttc_school: {
        column: 'col-md-12',
        component: 'TTCSchoolField',
        props: {
          label: i18n.t('components.TTCTagField.tags'),
          outlined: true
        }
      },
      ttc_enterprise: {
        column: 'col-md-12',
        component: 'TTCEnterpriseField',
        props: {
          label: i18n.t('components.TTCTagField.tags'),
          outlined: true
        }
      },
      ttc_enterprise_user_title: {
        column: 'col-md-12',
        component: 'TTCEnterpriseUserTitle',
        props: {
          label: 'userTitle',
          outlined: true
        }
      },
      ttc_skill_tag: {
        column: 'col-md-12',
        component: 'TTCSkillTagField',
        props: {
          label: i18n.t('components.TTCTagField.tags'),
          outlined: true,
          chips: true,
          multiple: true,
          counter: 10
        }
      },
      ttc_price: {
        column: 'col-md-12',
        component: 'TTCPriceField',
        props: {
          coinACurrencyList: []
        },
        async: [{
          api: 'M1902CoinsTypeCurrencyService.GetACoinCurrency',
          mutateTarget: 'props.coinACurrencyList'
        }]
      },
      ttc_policy: {
        column: 'col-md-12',
        component: 'TTCPolicyCheckbox',
        class: 'required'
      },
      ttc_currency: {
        column: 'col-md-12',
        component: 'TTCCurrencyField',
        props: {
          currencyList: []
        },
        async: [{
          api: 'M1902CoinsTypeCurrencyService.GetACoinCurrency',
          mutateTarget: 'props.currencyList'
        }]
      }
    }
  },
  genField (key, obj, globalProps) {
    // [type,component,custom]
    // handle `label`
    if (obj.label) {
      obj.props = obj.props ?? {}
      obj.props.label = obj.label
      delete obj.label
    }
    if (obj.component) {
      return obj
    }
    if (obj.custom) {
      return { ...obj, component: obj.custom }
    }
    const type = obj.preset ?? obj.type ?? 'default'
    const presetList = {
      ...this.basePresetList(key),
      ...this.customPresetList
    }

    let targetPreset = presetList[type]
    if (targetPreset === undefined) {
      console.warn(`AtForm Preset Not Found ${type}, use default preset`)
      targetPreset = presetList.default
    }

    const def = DataUtils.mergeObjects(targetPreset, obj)

    // apply global props
    def.props = DataUtils.mergeObjects(def.props, globalProps)
    return def
  },
  get compNameList () {
    return Object.keys(this.compList)
  },
  get presetList () {
    return {
      ...Object.keys(this.basePresetList('_')),
      ...Object.keys(this.customPresetList)
    }
  }
}

/**
 * @function resetValidation - reset validation
 * @return void
 *
 * @function validate - validate form, return promise with boolean
 * @return Promise<boolean>
 *
 * @function submit - submit form, return Object with form data
 * @return Promise<Object>
 *
 * @function reset - reset form, return Object with form data (start value)
 * @return Object
 *
 * @function clear - clear form, return Object with form data (empty value)
 * @return Object
 *
 * @function cancel - cancel form
 * @return void
 *
 * emit event
 * @event input - emit form data
 * @event onSubmit
 * @event onCancel
 * @event onClear
 * @event onReset
 * @event activeInputsNumber - number of active input
 */
export default {
  components: {
    InputRulesWrapper,
    ...AtFormUtils.compList,
    ActionButton
  },
  mixins: [DebugMixin],
  props: {
    /**
     * AtForm submit data
     * @typedef {Row[]} value
     * @property {any} {string} - field key {@link Row}
     */
    value: {
      type: Object,
      default: () => { return {} }
    },
    /**
     * Main Definitions
     * @typedef {Row[]} def
     *
     * Every element in def mean every row
     * @typedef {Object} Row
     * @property {Field} {string} - dynamic property key mean key name for form output
     *
     * The field data
     * @typedef {Object} Field
     * @property {String} [column] - The class string of field. This should be in the format 'col-md-X', where X is the width of the column.
     * @property {String} [component] - The name of the component to be used for this field. This should be a string, such as 'AtTextField'.
     * @property {String} [label] - The label to be used for this field. This should be a string, such as 'First Name'.
     * @property {String} [custom] - The name of the custom component to be used for this field. This should be a string, such as 'AtTextField'.
     * @property {Boolean} [authenticated] - A boolean value indicating whether this field requires authentication to access.
     * @property {String[]} [roles] - An array of roles that are allowed to access this field.
     * @property {String|Function|Object} [data] - Actual data
     * @property {String[]} [permissions] - An array of permissions that are required to access this field.
     * @property {AsyncData[]} [async] - An array of {@link AsyncData} objects that are used to mutate this field data.
     * @property {String|Function|String[]|Function[]} [rules] - The rules to be applied to this field. {@see InputRulesWrapper}
     * @property {string} [vid] - Identifier used for target/cross-field based rules. Must be unique. If undefined and `data` is string, use `data` as default (@see InputRulesWrapper)
     * @property {Any} [clear] - The value to set this field to when the clear button is clicked.
     * @property {Object} [props] - An object containing additional properties for this field.
     *
     * The AsyncData class is used to store and manage the data and input for an async API call.
     * @typedef {Object} AsyncData
     * @property {string} api - The API key used for the async call.
     * @property {object} input - The input for the async API call.
     * @property {string} mutateTarget - The nested key for mutating the data before being rendered.
     * @property {function} callback - Used to handle the response data and perform any necessary post-processing operations.
     * @example
     * {
     *    api : 'E0001DemoPostService.tempTest',
     *    input : {},
     *    mutateTarget: 'props.items',
     *    callback: () => {}
     * }
     */
    def: {
      type: Array,
      required: true
    },
    /**
     * The form title
     * @typedef {String} title
     */
    label: {
      type: String,
      default: ''
    },
    /**
     * Apply sparse properties to all input fields
     * (Some input may not have any sparse properties)
     * @typedef {Object} globalProps
     */
    globalProps: {
      type: Object,
      default: () => { return { outlined: true } }
    },
    /**
     *
     * @typedef {(Object|Boolean)} cancelBtn
     * @property {Boolean} show -
     * @property {Object} props -
     */
    cancelBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    /**
     *
     * @typedef {(Object|Boolean)} clearBtn
     * @property {Boolean} show -
     * @property {Object} props -
     */
    clearBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    /**
     *
     * @typedef {(Object|Boolean)} resetBtn
     * @property {Boolean} show -
     * @property {Object} props -
     */
    resetBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    /**
     *
     * @typedef {(Object|Boolean)} submitBtn
     * @property {Boolean} show -
     * @property {Object} props -
     */
    submitBtn: {
      type: [Object, Boolean],
      default: () => ({})
    },
    promptOnRouteChange: {
      type: Boolean,
      default: false
    },
    promptOnRefresh: {
      type: Boolean,
      default: false
    },
    /**
     * return undefined when value is empty
     *
     * BUG: cannot remove target key
     */
    undefinedOnEmpty: {
      type: Boolean,
      default: false
    },
    /**
     * show debug info
     */
    debug: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      /**
       * store {@link value} onMounted
       *
       */
      initValue: {},
      /**
       * default config 4 buttons:
       * {@link cancelBtn}
       * {@link clearBtn}
       * {@link resetBtn}
       * {@link submitBtn}
       */
      defaultBtn: {
        cancel: {
          show: false,
          props: { class: 'pa-1', color: 'warning', preset: 'cancel', outlined: true },
          showPrompt: { toggleOnDirty: true, toggleOnNotDirty: true, label: this.$t('components.confirmPrompt.cancelForm') },
          callback: (fieldModel, initValue) => { return true }
        },
        clear: {
          show: true,
          props: { class: 'pa-1', color: 'red', preset: 'clear', outlined: true },
          showPrompt: { toggleOnDirty: true, toggleOnNotDirty: true, label: this.$t('components.confirmPrompt.clearForm') },
          callback: (fieldModel, initValue) => { return true }
        },
        reset: {
          show: true,
          props: { class: 'pa-1', color: 'orange', preset: 'reset', outlined: true },
          showPrompt: { toggleOnDirty: true, toggleOnNotDirty: true, label: this.$t('components.confirmPrompt.resetForm') },
          callback: (fieldModel, initValue) => { return true }
        },
        submit: {
          show: true,
          props: { class: 'pa-1', color: 'primary', preset: 'submit', outlined: false },
          showPrompt: { toggleOnDirty: false, toggleOnNotDirty: false, label: this.$t('components.confirmPrompt.submitForm') },
          callback: (fieldModel, initValue) => { return true }
        }
      },
      /**
       * data for {@link value}
       */
      fieldModel: {},
      /**
       * data for {@link def}
       */
      dDef: [],
      /**
       * List Of {@link AsyncData }
       * @typedef {AsyncData[]} asyncDataList
       */
      asyncDataList: [],
      /**
       * List Output Of {@link AsyncData }
       * @typedef {any[]} outputData
       */
      outputData: [],
      /**
       * For V-form validation
       */
      fromIsValid: false,
      isUploading: {},
      colDataList: {},
      tempData: {},
      focusIndex: null
    }
  },
  computed: {
    slotNamesOnly () {
      return Object.keys(this.$scopedSlots).filter(slot => Object.keys(this.$slots).includes(slot))
    },
    scopedSlotNamesOnly () {
      return Object.keys(this.$scopedSlots).filter(slot => !Object.keys(this.$slots).includes(slot))
    }
  },
  watch: {
    value: {
      handler (val, oldVal) {
        if (DataUtils.isSameObjectSimple(val, oldVal)) return
        this.fieldModel = ObjArrUtils.clone(val)
      },
      deep: true
    },
    tempData: {
      handler (val, oldVal) {
        // console.log('🚀 ~ file: AtFormV2.vue:441 ~ tempDataVal:', JSON.stringify(val))
        // console.log('🚀 ~ file: AtFormV2.vue:441 ~ tempDataOldVal:', JSON.stringify(oldVal))
        // console.log('🚀 ~ file: AtFormV2.vue:441 ~ isSameObjectSimple:', DataUtils.isSameObjectSimple(val, oldVal))
        // // TODO: cannot use isSameObjectSimple
        // if (DataUtils.isSameObjectSimple(val, oldVal)) return
        // NOTE: data -> set
        // TODO: performance (check with key value change only) -> cannot because of isSameObjectSimple wont work

        let output = ObjArrUtils.clone(this.value)

        function _a (key) {
          // data -> string,function
          let data = this.colDataList[key]

          const tmpData = ObjArrUtils.clone(data)
          if (!data) return

          // if data is string
          if (typeof data === 'string') {
            const dataName = data
            data = {
              set: (val, inputVal) => {
                val[dataName] = inputVal
                return val
              }
            }
          }
          if (typeof data === 'function') {
            data = { set: data }
          }
          if (!(typeof data === 'object')) throw new Error('data must be object or string or function')
          if (tmpData.target) {
            data = {
              set: (val, inputVal) => {
                val[tmpData.target] = tmpData.set(inputVal)
                return val
              }
            }
          }
          if (!data.set) throw new Error('Internal Error: data must have set function')

          output = data.set(output, val[key])
        }
        for (const key in val) {
          _a.call(this, key)
        }
        _a.call(this, this.focusIndex)

        this.fieldModel = { ...this.fieldModel, ...output }
        // this.fieldModel = ObjArrUtils.merge(this.fieldModel, output)
        // this.emit('input', output)
      },
      deep: true
    },
    fieldModel: {
      handler (val, oldVal) {
        // console.log('🚀 ~ file: AtFormV2.vue:441 ~ fieldModel.val:', JSON.stringify(val))
        // console.log('🚀 ~ file: AtFormV2.vue:441 ~ fieldModel.oldVal:', JSON.stringify(oldVal))
        // console.log('🚀 ~ file: AtFormV2.vue:441 ~ isSameObjectSimple:', DataUtils.isSameObjectSimple(val, oldVal))
        if (this.promptOnRouteChange) {
          if (!DataUtils.isSameObjectSimple(val, oldVal)) {
            this.$store.state.promptOnRouteChange = this.$t('components.confirmPrompt.cancelForm')
          } else {
            this.$store.state.promptOnRouteChange = undefined
          }
        }
        if (this.undefinedOnEmpty) {
          Object.keys(val).forEach(key => {
            // if value is '',null,[]
            if (val[key] === '' || val[key] === null) {
              val[key]= undefined
            }
          })
        }

        if (DataUtils.isSameObjectSimple(val, oldVal)) return

        const d = {}
        for (const key in this.colDataList) {
          // data -> string,function
          let data = this.colDataList[key]
          const tmpData = ObjArrUtils.clone(data)
          if (!data) continue

          // if data is string
          if (typeof data === 'string') {
            const dataName = data
            data = {
              get: (val) => val[dataName]
            }
          }
          if (typeof data === 'function') {
            continue
            // data = { get: ObjArrUtils.clone(data) }
          }
          if (!(typeof data === 'object')) throw new Error('data must be object or string or function')
          if (tmpData.target) {
            data = { get: (val) => tmpData.get(val[tmpData.target]) }
          }
          if (!data.get) throw new Error('Internal Error: data must have get function')

          d[key] = data.get(ObjArrUtils.clone(val))
        }
        // console.log('🚀fieldModel.d:', JSON.stringify(d))
        this.tempData = ObjArrUtils.merge(this.tempData, d)

        // Bug: activeInputsNumber not correct, should be same with fields not fieldModel
        const activeInputsNumber = Object.keys(val).filter(key => this.dDef.find(field => field[key])).length
        this.emit('activeInputsNumber', activeInputsNumber)
        this.emit('input', ObjArrUtils.clone(val))
      },
      deep: true
    },
    def: {
      handler (val, oldVal) {
        if (DataUtils.isSameObject(val, oldVal)) return
        let temp = ObjArrUtils.clone(val)

        // STEP: handle diff format
        if (!Array.isArray(temp)) { throw new Error('def must be an array') }
        // check layer 1 is object
        // TODO: format condition
        if (!temp.every(row => Array.isArray(row)) && temp.every(row => typeof row === 'object')) {
          // check layer 2
          // if input is [{d1:{},d2:{}}]
          if (temp.every(row => Object.values(row).every(col => typeof col === 'object'))) {
            // convert to final format
            temp.forEach((row, i) => {
              temp[i] = Object.values(row)
              temp[i].forEach((col, j) => {
                temp[i][j] = { ...col, ...{ data: Object.keys(row)[j] } }
              })
            })
          } else {
            // if input is [{data:'d1'},{data:'d2'}]
            // convert to final format
            temp = [temp]
          }
        }

        // final format must be [[{data:'d1'},{data:'d2'}]]
        // array -> array -> object.data validation
        // if (!temp.every(row => Array.isArray(row))) { throw new Error('def must be an array of array') }
        if (!temp.every(row => row.every(col => typeof col === 'object'))) { throw new Error('def must be an array of array of object') }
        // if (!temp.every(row => row.every(col => col.data))) { throw new Error('def must be an array of array of object with data') }

        const asyncDataList = []
        const inputData = []
        this.colDataList = {}
        for (let i = 0; i < temp.length; i++) {
          const row = temp[i]
          for (let j = 0; j < row.length; j++) {
            let col = row[j]

            const dataKey = col.data ?? i + '_' + j
            col = AtFormUtils.genField(dataKey, col, this.globalProps)
            col.href = `${i}_${j}`

            // handle `async`
            if (typeof col.async !== 'undefined') {
              col.async.forEach(element => {
                asyncDataList.push({ ...element, mutateTarget: [i, j, ...element.mutateTarget.split('.')] })
                inputData.push({ api: element.api, input: element.input ?? null })
              })
            }

            // handle `data`
            if (col.data) {
              this.colDataList[`${i}_${j}`] = col.data
            }
            row[j] = col
          }
        }

        this.dDef = temp
        // if async data no change, use saved data
        if (DataUtils.isSameObject(asyncDataList, this.asyncDataList)) {
          for (let i = 0; i < asyncDataList.length; i++) {
            const mutateTarget = asyncDataList[i].mutateTarget
            const resData = this.outputData[i].output

            this.dDef = DataUtils.setValue(this.dDef, mutateTarget, resData)
          }
        } else {
          // if async data changed request agian
          this.apiDispatch(inputData).then((res) => {
            for (let i = 0; i < asyncDataList.length; i++) {
              const mutateTarget = asyncDataList[i].mutateTarget
              let resData = res[i].output

              // extra post-processing operations
              if (asyncDataList[i].callback) {
                resData = asyncDataList[i].callback(resData)
              }

              this.dDef = DataUtils.setValue(this.dDef, mutateTarget, resData)
            }
            this.asyncDataList = asyncDataList
            this.outputData = res
          })
        }
      },
      deep: true,
      immediate: true
    }
  },
  created () {
    window.addEventListener('beforeunload', this.beforeWindowUnload)
  },

  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  mounted () {
    this.initValue = DataUtils.jsonCopy(this.value)
    this.fieldModel = ObjArrUtils.clone(this.value)
    // console.log('slotNamesOnly', this.slotNamesOnly)
    // console.log('scopedSlotNamesOnly', this.scopedSlotNamesOnly)
  },
  methods: {
    updateTempData({key,val,validateInput}){
      this.test(this,{validateInput,val,key})
    },
    test: debounce((ctx,{validateInput,val,key}) => {
      validateInput(val)
      ctx.$set(ctx.tempData, key, val)
    }, debounceTime),
    // check slot is for field
    fieldSlotName (name, key) {
      // split the slot name by the key and get the second part
      if (!this.isFieldSlot(name, key)) return
      return name.split(key + '_')[1]
    },
    isFieldSlot (name, key) {
      // most of the time, name is defined
      if (!name || !key) return false

      // check if the slot name starts with the provided key
      if (!name.startsWith(key + '_')) return false

      return true
    },
    /**
     * determine use top label or not
     */
    useTopLabel (x) {
      if (x.labelHint) return true
      if (x.topLabel) return true
      return false
    },
    // before Reload page
    beforeWindowUnload (e) {
      if (!this.promptOnRefresh) return
      if (this.$store.state.promptOnRouteChange) {
      // Cancel the event

        e.preventDefault()
        // Chrome requires returnValue to be set
        e.returnValue = ''
        return e.returnValue
      }
    },
    checkRegisteredComps (compName) {
      try{
        // if compName is Object, is direct component
        if (typeof compName === 'object' && !!compName.__file)
          return true

        compName = compName.split('/')
          .map(snake => snake.split('-').map(substr => substr.charAt(0)?.toUpperCase() + substr.slice(1)).join(''))
          .join('/')
        // found local components
        if (compName in AtFormUtils.compList) { return true }

        // found slot components
        if (compName in this.$scopedSlots) { return false }

        console.error('Not registered components: ' , compName)
        return false
      } catch (e) {
        console.error('Not registered components: ' , compName)
        return false
      }


    },
    resetValidation () {
      this.$refs.observer.reset()
      this.$refs.form.resetValidation()
    },
    async validate () {
      // handle isUploading
      let isUploadingFlag = false // if true, cannot submit
      if (Object.values(this.isUploading).some(e => e)) {
        isUploadingFlag = true
      }
      if (isUploadingFlag) {
        this.$dialog.notify.error(this.$t('components.fileIsUploading'))
      }
      const isValid = await this.$refs.observer.validate()
      const isValid2 = this.$refs.form.validate()

      if (!isValid || !isValid2) {
        this.$dialog.notify.error(this.$t('components.AtForm.formInvalid'))
      }

      return isValid && isValid2 && !isUploadingFlag
    },
    async submit () {
      if (!await this.validate()) {
        return
      }
      this.emit('onSubmit', DataUtils.jsonCopy(this.fieldModel))
      return DataUtils.jsonCopy(this.fieldModel)
    },
    reset () {
      this.resetValidation()
      this.fieldModel = DataUtils.jsonCopy(this.initValue)
      this.emit('onReset', DataUtils.jsonCopy(this.initValue))
      return DataUtils.jsonCopy(this.initValue)
    },
    clear () {
      this.resetValidation()
      this.$refs.form.reset()

      this.fieldModel = {}
      this.dDef.forEach(row => {
        for (const key in row) {
          // deprecated
          row[key].ref = row[key].ref ?? row[key].href
          this.$refs[row[key].ref]?.reset?.()
          // new, refer TwoWayBindingMixin.js
          this.$refs[row[key].ref]?.clear?.()
        }
      })
      this.emit('onClear', DataUtils.jsonCopy(this.fieldModel))
      return DataUtils.jsonCopy(this.fieldModel)
    },

    cancel () {
      this.emit('onCancel', DataUtils.jsonCopy(this.initValue))
    },
    buttonOnClick (type, payload = null) {
      const curBtn = this.mergeButton(type)

      // callback
      if (!this.btn_callbackHandler(curBtn.callback)) return

      // showPrompt
      if (!this.btn_showPromptHandler(curBtn.showPrompt)) return

      const btnActionList = {
        submit: this.submit,
        reset: this.reset,
        clear: this.clear,
        cancel: this.cancel
      }
      // if type is not in the list, throw error
      if (!(type in btnActionList)) {
        console.error('InvalidBtnFunction: ' + type)
        return
      }

      // run btn function
      btnActionList[type](this, payload)
    },
    // handle showPrompt key for button
    // return true for pass confirm prompt
    // return false for not pass confirm prompt
    btn_showPromptHandler (obj) {
      const isDirty = !DataUtils.isSameObject(this.initValue, this.fieldModel)

      // if showPrompt is not defined, return true
      if (obj === undefined) return true

      // if showPrompt is Object, check toggleOnDirty and toggleOnNotDirty
      if ((obj.toggle && isDirty) ||
        (obj.toggleOnDirty && isDirty) ||
        (obj.toggleOnNotDirty && !isDirty)) {
        return window.confirm(obj.label)
      }

      return true
    },
    // handle callback key for button
    // function callback(fieldModel, initValue)
    btn_callbackHandler (callback) {
      if (callback === undefined) return true
      return callback(DataUtils.jsonCopy(this.fieldModel), DataUtils.jsonCopy(this.initValue))
    },
    mergeButton (type) {
      const currentButton = this[type + 'Btn']
      let defaultBtn = this.defaultBtn[type]

      if (typeof currentButton === 'boolean') {
        defaultBtn.show = currentButton
      } else {
        defaultBtn = DataUtils.mergeObjects(defaultBtn, currentButton)
      }
      return defaultBtn
    },
    focusNext (keyboardEvent) {
      if ((keyboardEvent?.keyCode === 13 || keyboardEvent?.keyCode === 40) && keyboardEvent?.target?.form) {
        let currentIndex = Array.from(keyboardEvent.target.form).indexOf(keyboardEvent.currentTarget)

        while (true) {
          currentIndex++
          if (currentIndex >= keyboardEvent.target.form.length) {
            return
          }
          const nextElement = keyboardEvent.target.form[currentIndex]

          if (nextElement.tagName === 'FIELDSET') continue
          if (nextElement.tagName === 'BUTTON') continue
          nextElement.focus()
          break
        }
      }
    }
  }
}
</script>
