import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const statusMap={
  type:{
    // 'INVITE','APPLY'
    INVITE: {
      text: '邀請',
      color: 'grey'
    },
    APPLY: {
      text: '申請',
      color: 'grey'
    }
  },
  status:{
    PENDING: {
      text: '待處理',
      color: 'orange'
    },
    IN_PROGRESS: {
      text: '面試中',
      color: 'blue'
    },
    DEAL: {
      text: '成功',
      color: 'green'
    },
    ACTIVE: {
      text: '工作開始',
      color: 'lime'
    },
    INACTIVE: {
      text: '工作結束',
      color: 'grey'
    },
    REJECTED: {
      text: '拒絕',
      color: 'red'
    },
    CLOSED: {
      text: '結束',
      color: 'black'
    },
    DELETED: {
      text: '已刪除',
      color: 'maroon'
    },
    REMOVED: {
      text: '被移除',
      color: 'purple'
    }
  }

}

function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}


const PageConst =  {
  FORM_DEF: {
    editDef: [

    ]
  },
  SEARCH_DEF: {
    inputDef: [
    ],
  },
  LIST_DEF: {
    tableDef:[
    ]
  },
  ACTION: {
    // doAdd: { api: 'T2201ServiceApplicationService.customer_doAdd'},
    // getEdit: { api: 'T2201ServiceApplicationService.customer_getEdit'},
    // doEdit: { api: 'T2201ServiceApplicationService.customer_doEdit'},
    // doRead: { api: 'T2201ServiceApplicationService.customer_doRead'},
    // doDelete: { api: 'T2201ServiceApplicationService.customer_doDelete'},
    doBrowse: { api: 'T1101JobPostingApplicationService.collab_creator_doBrowse'},
    doAddPolicy: { api: 'T1101JobPostingApplicationService.addPolicy' },
    getPolicy: { api: 'T1101JobPostingApplicationService.getPolicy' },
    updateStatus: { api: 'T1101JobPostingApplicationService.updateStatus' },
  },
}

const module = {
  state: {
    PageConst,

    statusMap: getMapArray(statusMap.status),
    typeMap: getMapArray(statusMap.type),

    // set default here
    searchInfo:{
    },
    // set default here
    orderBy:{}
  },

  getters: {

  },

  mutations: {

  },
  actions: {
    async projectPartner_goToChatroom ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [{ api: 'T1101JobPostingApplicationService.projectPartner_goToChatroom', input: payload }]
      }).then((res) => {
        return res[0].output
      })
    },

    async application_history({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [{ api: 'T1102JobPostingApplicationStatusService.doBrowse', input: payload }]
      }).then((res) => {
        return res[0].output
      })
    },

    async doAddPolicy({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: state.PageConst.ACTION.doAddPolicy.api,
            input: payload
          }]
      })
      return res[0].output
    },

    async getPolicy({ commit, state }, payload) {

      const res = await ApiUtils.postData({
        data: [
          {
            api: state.PageConst.ACTION.getPolicy.api,
            input: payload
          }]
      })

      return res[0].output

    },

    async updateStatus({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: state.PageConst.ACTION.updateStatus.api,
            input: payload
          }]
      })
      return res[0].output
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
