import ApiUtils from '@/utils/api/ApiUtils'
import Const from './Const'

// TODO: can i know what storeConst properties are available? (using intelisense)
const StoreConst = Const.storeConst
const store = {
  state: {
    // custom state
    myProp: null
  },
  getters: {
    // custom getter
    getMyProp: (state) => state.myProp
  },
  mutations: {
    // custom mutation
    SET_MY_PROP (state, payload) {
      state.myProp = payload
    }
  },
  actions: {
    // override action
    async doBrowse ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doBrowse.api, input: val }]
      })
      commit('SET_ROWS', res[0].output.data)
      commit('SET_PAGINATION', res[0].output.pagination)
    },
    // custom action
    myAction ({ commit, state }, payload) {
      commit('SET_MY_PROP', payload)
    },
    async importData ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.importData.api, input: payload }]
      })
      alert(res)
    },
    async testApiError (_, payload) {
      return ApiUtils.postData({
        data: [{ api: 'E0000DemoService.testApiError', input: payload }]
      })
    }
  }
}

export default Const.getStore(store)
