function getNotificationType (base) {
  return `App\\Api\\${base}\\${base}Notification`
}

export default {
  data () { return {} },
  watch: {
    'user.id': {
      handler: function (val, oldVal) {
        if (val === undefined) return
        if (val !== oldVal && oldVal !== undefined) {
          console.log(`SOCKET - leave App.Api.M0001User.M0001UserModel.'${oldVal}`)
          this.$echo.leave(`private-App.Api.M0001User.M0001UserModel.'${oldVal}`)
        }
        this.$echo.private('App.Api.M0001User.M0001UserModel.' + val)
          .subscribed(() => {
            console.log('subscribed to private Channel: ' + val)
          })
          .notification((payload) => {
            // if debug mode is on, show dialog
            if (import.meta.env.DEBUG) {
              this.$dialog.notify.info(JSON.stringify(payload, null, 2), {
                position: 'bottom-right',
                timeout: 5000
              })
            }
            console.log('notification:' + JSON.stringify(payload))
            switch (payload.type) {
              // TODO: need remove this
              case getNotificationType('M0901Chatroom'):
                // console.log('NewMessage:' + JSON.stringify(payload.output))
                switch (payload.output.type) {
                  case 'newMessage':
                  case 'deleteMessage':
                    this.storeApiDispatch('M0901Chatroom/incomingMsg', payload.output.data, { loading: false })
                    break
                  // case 'newRoom':
                  //   this.$store.commit('M0901Chatroom/NEW_ROOM', payload.output.data)
                  //   break
                  default:
                    console.log('Unknown M0901ChatroomNotification type:' + payload.output.type + ' :' + JSON.stringify(payload.output))
                    break
                }
                break
              default:
                // console.log('Unknown type:' + payload.type + ' :' + JSON.stringify(payload))
                break
            }
          })
          .error(error => {
            console.log('Private Channel Connect error:' + JSON.stringify(error))
          })
      },
      immediate: true,
      deep: true
    }
  }
}
