import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'collab.public',
    path: 'investment/package/:c2001_id',
    name: 'C2301ProjectInvestmentPackageSelected',
    component: () => import('./PgC2301ProjectInvestmentPackageSelected.vue'),
    meta: { title: i18n.t('c0101.investment.investmentPage') }
  }
]
