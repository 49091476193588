import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    fundingDetails: {}
  },
  getters: {},
  mutations: {},
  actions: {
    async getFundingDetails({ commit, state, dispatch }, payload) {
      state.fundingDetails = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T3001FundingService.doRead_fundingDetails', input: {
              id: payload.id,
              seo: payload.seo
            }
          }
        ]
      })
      state.fundingDetails = res[0].output

      console.log('state.fundingDetails', state.fundingDetails)
      return new Promise((resolve, reject) => {
        resolve(state.fundingDetails)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
