<template>
  <!-- https://vee-validate.logaretm.com/v3/api/validation-provider.html#props -->
  <ValidationProvider
    ref="provider"
    v-slot="{ errors,validate }"
    :rules="rules ? veeValidateRules : undefined"
    :name="$attrs.props?$attrs.props.label || undefined:undefined"
    :vid="getVid"
  >
    <slot
      v-bind="{
        attrs:{
          rules:rules? vuetifyInputRules: undefined,
          errorMessages:errors,
          value

        },
        validateInput:validate
      }"
    />
  </ValidationProvider>
</template>
<script>

import AvailableRules from '@/plugins/validations'
// ? component must extend VInput 1st
export default {
  props: {
    rules: {
      type: [String, Array, Function],
      default: undefined
    },
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: undefined
    }
  },
  computed: {
    veeValidateRules () {
      let rules = this.rules
      if (!rules) return undefined
      if (typeof rules === 'string') rules = [rules]
      if (!Array.isArray(rules)) return undefined
      const targetRules = []
      rules.forEach(rule => {
        if (typeof rule === 'string') {
          // check if rule is available,
          // if not, console.error and keep going
          const tmpRule = rule.split(':')[0]
          if (!(tmpRule in AvailableRules)) console.error(reportNotAvailableRules(rule))
          targetRules.push(rule)
        }
      })
      return targetRules.join('|')
    },
    vuetifyInputRules () {
      let rules = this.rules
      if (!rules) return undefined
      if (typeof rules === 'function') rules = [rules]
      if (!Array.isArray(rules)) return undefined
      const targetRules = []
      rules.forEach(rule => {
        if (typeof rule === 'function') targetRules.push(rule)
      })
      return targetRules
    },
    getVid () {
      if (this.$attrs.vid) return this.$attrs.vid
      if (typeof this.$attrs.data === 'string') return this.$attrs.data
      return undefined
    }
  },
  watch: {
    value (val) {
      this.$refs.provider.syncValue(val)
    }
  }
}
function reportNotAvailableRules (ruleName) {
  return 'Uncaught (in promise) Error: ' +
        'No such validator \'' + ruleName + '\' exists.\n' +
        'Available Rules: https://vee-validate.logaretm.com/v3/guide/rules.html#rules \n' +
        'Custom Rules: \\client\\src\\plugins\\validations.js'
}
</script>

<style>

</style>
