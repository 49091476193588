<template>
  <div
    v-if="show"
    :class="{ 'fullscreen': fullscreen, 'div': overlay || center }"
    :style="overlay ? overlayBackground : ''"
  >
    <div
      :class="{ 'fullscreen-loader': fullscreen } "
      :style="loaderAdjustment"
    >
      <slot name="loader">
        <!-- Default Loader -->
        {{ defaultLoader = true }}
        <v-progress-circular
          indeterminate
          color="primary"
        />
      </slot>
    </div>
  </div>
</template>

<script>
// @ts-check

import StringUtils from '@/utils/StringUtils'

/**
 * This is a component to display loader with options which includes:  
 * - Overlayer
 * - Center Loader
 * - Fullscreen Display  
 * 
 * `slot#loader`: Import custom component / template as loader
 * 
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/18
 */
export default {
  name: 'Loader',
  props: {
    /**
     * [Required] Whether to show loader
     */
    show: {
      type: Boolean,
      required: true
    },
    /**
     * Whether to show overlay  
     * Overlay color can be customized by `overlayColor` props
     * @default false
     */
    overlay: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to center loader  
     * This will automatically center the loader with respect to parent element  
     * Additional adjustment / correction can be customized by 
     * `centerTopAdjust` & `centerLeftAdjust` props
     * @default false
     */
    center: {
      type: Boolean,
      default: false
    },
    /**
     * Whether to set loader to fullscreen  
     * This will sets the loader to high z-index  
     * If `overlay` option is enabled, it will also be set to high z-index (blocking)
     * @default false
     */
    fullscreen: {
      type: Boolean,
      default: false
    },
    /**
     * Sets the overlay color (alpha allowed)
     * @default #212121CC
     */
    overlayColor: {
      type: String,
      default: '#212121CC',
      required: false
    },
    /**
     * Adjust the loader vertical position  
     * (Only valid when `center` props is set to `true`)
     * @default 0px
     */
    centerTopAdjust: {
      type: [String, Number],
      default: '0px',
      required: false
    },
    /**
     * Adjust the loader horizontal position  
     * (Only valid when `center` props is set to `true`)
     * @default 0px
     */
    centerLeftAdjust: {
      type: [String, Number],
      default: '0px',
      required: false
    }
  },
  data: () => ({
    // Settings for default loader if none is specified through slots
    defaultLoader: false,
    defaultCenterTopAdjust: '-14px',
    defaultCenterLeftAdjust: '-14px'
  }),
  computed: {
    /**
     * Sets the overlay color
     * @return {Object} (Styles properties)
     */
    overlayBackground () {
      return {
        backgroundColor: this.overlayColor
      }
    },
    /**
     * Sets the loader position adjustment if `center` props is set to `true`
     * @return {Object} (Styles properties)
     */
    loaderAdjustment () {
      return {
        position: 'relative',
        top: this.center ? `calc(50% + ${this.defaultLoader ? this.defaultCenterTopAdjust : StringUtils.getPixelSize(this.centerTopAdjust)})` : '',
        left: this.center ? `calc(50% + ${this.defaultLoader ? this.defaultCenterLeftAdjust : StringUtils.getPixelSize(this.centerLeftAdjust)})` : ''
      }
    }
  }
}
</script>

<!-- Styling For Loader -->
<style scoped>
.div {
  width: 100%;
  height: 100%;
}

.fullscreen {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.fullscreen-loader {
  position: absolute;
  z-index: 9999;
}
</style>
