import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    introBanner: null,
    typeSection: null,
    statsSection: null,
    linkPotential: null,
    advantage: null,
    partnership: null,
    ratingSection: null,
    outro: null,
    content: null,
    row: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async init ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: {
          introBanner: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.introBanner' },
          typeSection: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.typeSection' },
          statsSection: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.statsSection' },
          linkPotential: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.linkPotential' },
          advantage: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.advantage' },
          partnership: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.partnership' },
          ratingSection: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.ratingSection' },
          outro: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory.landing.outro' },
          content: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'advisory._.content' },
        }
      })
      for (const key in res) {
        state[key] = res[key].output
      }
    },
    async getEdit({ commit, state, dispatch }, payload) {
      state.row = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.getEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output[0]

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async doEdit({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.doEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
