import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from '@/configs/locale.js'

// add more languages here
// cannot set dynamic string inside import.meta.glob due to vite limitation
function getImportMeta (_locale) {
  switch (_locale) {
    case 'en':
      return import.meta.globEager('../assets/locales/en/*.json')
    case 'zhHant':
      return import.meta.globEager('../assets/locales/zhHant/*.json')
    default:
      throw new Error('Unknown locale')
  }
}

export function loadTrans (_locale) {
  const req = getImportMeta(_locale)

  let translations = {}

  Object.keys(req).forEach((file) => {
    const name = file.split('/').pop().replace('.json', '')
    translations[name] = req[file]
  })

  // Translation Files From Other Paths
  const others = {
  }

  translations = Object.assign(translations, others)
  return JSON.parse(JSON.stringify(translations))
}

// singleton
let i18n = null
Vue.use(VueI18n)
window.loadedLocales = []
const messages = {}
messages[locale.currentLocale] = { ...loadTrans(locale.currentLocale) }
i18n = new VueI18n({
  locale: locale.currentLocale,
  messages,
  silentFallbackWarn: true,
  missing: (locale, key) => {
    // if (import.meta.env.VITE_NODE_ENV === 'development') {
    //   console.warn(`[i18n] Key '${key}' not found in '${locale}' locale!`)
    // }
  }
})

export default i18n
