// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {

  },

  getters: {

  },

  mutations: {

  },
  actions: {
    submitForm ({ commit }, input) {
      return ApiUtils.postData({
        data: [
          { api: 'ZZ0101LecturerApplicationService.submitForm', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    },
    getLists ({ commit }, input) {
      return ApiUtils.postData({
        data: [
          { api: 'ZZ0101LecturerApplicationService.getLists', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    },
    updateStatus ({ commit }, input) {
      return ApiUtils.postData({
        data: [
          { api: 'ZZ0101LecturerApplicationService.updateStatus', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
