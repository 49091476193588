import Vue from 'vue'
import AppUtils from '@/utils/AppUtils'

function commentNode (el, vnode) {
  const comment = document.createComment(' ')
  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined
  })
  vnode.elm = comment
  vnode.text = ' '
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  vnode.data.directives = undefined

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

Vue.directive('debug', {
  /**
   *
   * @param {HTMLElement} el
   * @returns
   */
  bind (el, binding, vnode) {
    if (!AppUtils.isDebugMode() || (!binding.value&& binding.value !== undefined)) {
      commentNode(el, vnode)
      return
    }
    el.style.padding = '12px'
    el.style.backgroundColor = '#00f2'
    el.style.border = '1px solid #00f9'
    el.style.borderRadius = '5px'
    el.setAttribute('title','`v-debug`| This is a debug element, will be hidden in production mode | double click to hide the debug element')
    // TODO: Add a button to hide the debug element
    // el.addEventListener('dblclick', () => {
    //   el.style.display = 'none'
    // })
  }
})

Vue.directive('auth', {
  bind (el, binding, vnode) {
    if (!AppUtils.isLoggedIn() && (binding.value === undefined || binding.value === true)) commentNode(el, vnode)
    if (AppUtils.isLoggedIn() && binding.value === false) commentNode(el, vnode)
  }
})

Vue.directive('roles', {
  bind (el, binding, vnode) {
    if (!AppUtils.allowAccess(false, binding.value)) commentNode(el, vnode)
  }
})

Vue.directive('permissions', {
  bind (el, binding, vnode) {
    if (!AppUtils.allowAccess(false, [], binding.value)) commentNode(el, vnode)
  }
})

// -- Becky Specific -- //

Vue.directive('phase2', {
  bind (el, binding, vnode) {
    if (!import.meta.env.PHASE2) commentNode(el, vnode)
  }
})

// https://v2.vuejs.org/v2/guide/custom-directive#Function-Shorthand
Vue.directive('highlight-braces', function (el, binding, vnode, oldVnode) {
  // Get the value of the directive
  let text = binding.value

  if (text && vnode.children && vnode.children.length > 0) {
    console.warn('v-highlight-braces:The directive is used as a modifier. Child element will be ignored.', el)
  }
  if (!text) {
    // Get the text content of the element if no value is provided
    text = vnode.children[0].text
    if (vnode.children.length > 1) { console.warn('v-highlight-braces:Multiple child elements found. Only the first child element will be used.', el) }
  }
  if (!text) console.warn('v-highlight-braces:No text content found. Use v-highlight-braces="\'text\'" or add text content inside the element.', el)
  // Check if the text contains '{' and '}'
  if (text && text.includes('{') && text.includes('}')) {
    // If it does, split the text into parts using '{' and '}'
    const parts = text.split(/({.*?})/)

    // Create a new array to hold the modified parts
    const highlightedParts = []

    // Loop through each part
    parts.forEach(part => {
      // If the part starts and ends with '{' and '}', respectively
      if (part.startsWith('{') && part.endsWith('}')) {
        // Remove the braces and wrap the part in a span with Vuetify's primary color class and inherit font style
        const highlightedText = part.substring(1, part.length - 1)
        highlightedParts.push(`<span style="font-family: inherit;color: var(--v-primary-base);">${highlightedText}</span>`)
      } else {
        // Otherwise, keep the part as it is
        highlightedParts.push(part)
      }
    })

    // Set the innerHTML of the element to the modified parts
    el.innerHTML = highlightedParts.join('')
  }
})

Vue.directive('bold-braces', function (el, binding, vnode, oldVnode) {
  // Get the value of the directive
  let text = binding.value

  if (text && vnode.children && vnode.children.length > 0) {
    console.warn('v-bold-braces:The directive is used as a modifier. Child element will be ignored.', el)
  }
  if (!text) {
    // Get the text content of the element if no value is provided
    text = vnode.children[0].text
    if (vnode.children.length > 1) { console.warn('v-bold-braces:Multiple child elements found. Only the first child element will be used.', el) }
  }
  if (!text) console.warn('v-bold-braces:No text content found. Use v-bold-braces="\'text\'" or add text content inside the element.', el)
  // Check if the text contains '{' and '}'
  if (text && text.includes('{') && text.includes('}')) {
    // If it does, split the text into parts using '{' and '}'
    const parts = text.split(/({.*?})/)

    // Create a new array to hold the modified parts
    const boldedParts = []

    // Loop through each part
    parts.forEach(part => {
      // If the part starts and ends with '{' and '}', respectively
      if (part.startsWith('{') && part.endsWith('}')) {
        // Remove the braces and wrap the part in a span with Vuetify's primary color class and inherit font style
        const boldedText = part.substring(1, part.length - 1)
        boldedParts.push(`<span style="font-family: inherit;font-weight: bold;">${boldedText}</span>`)
      } else {
        // Otherwise, keep the part as it is
        boldedParts.push(part)
      }
    })

    // Set the innerHTML of the element to the modified parts
    el.innerHTML = boldedParts.join('')
  }
})


