import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C2101ProjectInvestmentPackage',
  serviceName: 'C2101ProjectInvestmentPackageService',
  componentDef: {
    form: {
      viewDef: [
        {
          column: 'col-12',
          data: 'project_investment_package_title',
          type: 'text',
          label: i18n.t('c2101.filter.project_investment_package_title')
        },
        {
          column: 'col-12',
          data: 'project_investment_package_desc',
          type: 'textarea',
          label: i18n.t('c2101.filter.project_investment_package_desc')
        },
        {
          column: 'col-12',
          data: 'project_investment_package_cover',
          type: 'file',
          props: {
            accept: 'image/*'
          },
          label: i18n.t('c2101.filter.project_investment_package_cover')
        },
        {
          column: 'col-12',
          data: 'project_investment_package_end_datetime',
          type: 'date',
          label: i18n.t('c2101.filter.project_investment_package_end_datetime')
        },
        {
          column: 'col-12',
          data: 'project_investment_package_slots',
          type: 'number',
          label: i18n.t('c2101.filter.project_investment_package_slots')
        }
      ]
    }
  }
}
const C2101Const = new BaseDataStore(BaseConst)
export default C2101Const
