<template>
  <v-checkbox
    v-model="dValue"
    :rules="cRules"
    :label="sentence"
    v-bind="$attrs"
  >
    <template #label>
      <p class="mb-0">
        {{ sentence }}
      </p>
      <div v-if="policies">
        <span
          v-for="(policy, index) in policies"
          :key="index"
        >
          <router-link
            :color="'Primary'"
            :to="policy.to"
          >
            {{ policy.routeLabel }}
          </router-link>
          <template v-if="index !== policies.length - 1">,</template>
          <template v-if="index === policies.length - 2 && policies.length > 1"> 以及 </template>
        </span>
      </div>
      <router-link
        v-else
        :color="'Primary'"
        :to="to"
      >
        {{ routeLabel }}
      </router-link>
    </template>
  </v-checkbox>
</template>
<script>
import i18n from '@/plugins/i18n'
export default {
  props: {
    sentence: {
      type: String,
      default: i18n.t('components.AtTCCheckbox.sentence')
    },
    routeLabel: {
      type: String,
      default: i18n.t('components.AtTCCheckbox.termsAndConditions')
    },
    to: {
      type: [String, Object],
      default: () => {
        return {
          name: 'terms-and-conditions'
        }
      }
    },
    removeDefaultRules: {
      type: Boolean,
      default: false
    },
    policies: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data () {
    return {
      dValue: false
    }
  },
  computed: {
    cRules () {
      const r = this.$attrs.rules ?? []
      return this.removeDefaultRules
        ? [...r]
        : [
            v => { return !!v || this.$t('components.AtTCCheckbox.rules.must_agree') },
            ...r
          ]
    }
  },
  watch: {
    dValue: {
      handler: function (val) {
        this.$emit('input', val)
      },
      immediate: true
    }
  },
  methods: {
  }
}
</script>
<style scoped>
.v-input--selection-controls {
  margin: 0;
  padding: 0;
}
</style>
