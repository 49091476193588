import i18n from '@/plugins/i18n'

export default [
  /**
   * ! DEPRECATED route
   */
  {
    path:'/content/bought',
    redirect: {name:'BoughtContent'},
    portalGroup: 'learn.public'
  },
  // learn.public
  {
    path: 'content/:seo',
    name: 'ContentDetails',
    component: () => import('./public/ContentDetails/PgContentDetails.vue'),
    portalGroup: 'learn.public'
  },
  {
    path: 'content/:seo/:l0601_id',
    name: 'ContentClass',
    component: () => import('./public/ContentClass/PgContentClass.vue'),
    portalGroup: 'learn.public'
  },
  // {
  //   path: 'content/:id',
  //   name: 'ContentDetails',
  //   component: () => import('./User/ContentDetails/PgContentDetails.vue'),
  //   portalGroup: 'learn.public',
  //   meta: { title: i18n.t('l0101.edit.content.title') }
  // },

  /**
   * Content Creator Routes
   */
  {
    portalGroup: 'learn.public',
    path: 'my',
    component: { render: h => h('router-view',{style:{maxWidth:'1440px'}}) },
    children: [
      {
        path: 'content',
        name: 'PgL0101MultiPage',
        component: () => import('./My/PgL0101MultiPage.vue'),
        meta: { title: i18n.t('l0101.title.PgL0101MultiPage'), authenticated: true }
      },
      {
        path: 'content/create',
        name: 'PgL0101Create',
        component: () => import('./My/PgL0101Create.vue'),
        meta: { title: i18n.t('l0101.title.PgL0101Create'), authenticated: true }
      },
      {
        path: 'content/:seoUrl/edit',
        name: 'PgL0101Form',
        component: () => import('./My/PgL0101Form.vue'),
        meta: { title: i18n.t('l0101.edit.title'), authenticated: true }
      },
      {
        path: 'content/:seoUrl/edit2',
        name: 'PgL0101Form2',
        component: () => import('./My/comps/PgL0101Form/main/main.vue'),
        meta: { title: i18n.t('l0101.edit.title'), authenticated: true }
      },
      {
        path: 'content/:l0101_seo_url/classList',
        name: 'ContentClassListQuizScore',
        component: () => import('./My/QuizScorePages/PgStudentScoreL0301List.vue'),
        meta: { title: i18n.t('l0301.scorePage.title'), authenticated: true }
      },
      {
        path: 'content/class/:l0301_seo_url/contentSectionContentList',
        name: 'ContentSectionContentListQuizScore',
        component: () => import('./My/QuizScorePages/PgStudentScoreL0601List.vue'),
        meta: { title: i18n.t('l0601.scorePage.title'), authenticated: true }
      },
      {
        path: 'content/contentSectionContent/:l0601_id/latestScoreAttemptList',
        name: 'LatestScoreAttemptList',
        component: () => import('./My/QuizScorePages/PgStudentScoreL0802List.vue'),
        meta: { title: 'Student Quiz Scores', authenticated: true }
      },
      {
        path: '/content/:l0101_id/section/:l0601_id/attendance',
        name: 'ContentSectionContentManage',
        component: () => import('@/app/L0801ContentSectionContentProgress/ContentSectionContentManage/PgContentSectionContentManage.vue'),
        meta: { title: i18n.t('l0401.ContentClassSessionManage.PgContentSectionContentManage.title'), authenticated: true }
      },
      {
        path: '/content/:l0101_id/section/:l0601_id/attendance/share/:encrypted_content_section_content_attendance_manage_token',
        name: 'ContentSectionContentManageShare',
        component: () => import('@/app/L0801ContentSectionContentProgress/ContentSectionContentManage/PgContentSectionContentManage.vue'),
        meta: { title: i18n.t('l0401.ContentClassSessionManage.PgContentSectionContentManageShare.title') }
      }
    ]
  },
  /**
   * Consumer/Customer Routes
   */
  {
    portalGroup: 'learn.public',
    path: 'my',
    component: { render: h => h('router-view') },
    children: [

      {
        path: 'boughtContent',
        name: 'BoughtContent',
        component: () => import('./public/BoughtContent/PgBoughtContent.vue'),
        meta: { title: '我的學習', authenticated: true }
      },
      {
        // Once user scan the QR code generated in ContentSectionContentManage or ContentSectionContentManageShare then they will arrive at this page
        path: '/content/:l0101_id/section/:l0601_id/checkIn/:encrypted_content_section_content_identification_token',
        name: 'ContentSectionContentCheckIn',
        component: () => import('@/app/L0801ContentSectionContentProgress/ContentSectionContentCheckIn/PgContentSectionContentCheckIn.vue'),
        meta: { title: i18n.t('l0401.ContentClassSessionCheckIn.PgContentClassSessionCheckIn.title'), authenticated: true }
      },
    ]
  },
  // {
  //   path: 'Contents/:id/preview',
  //   name: 'ContentPreview',
  //   component: () => import('./Scholar/ContentPreview/PgContentPreview.vue'),
  //   portalGroup: 'learn.my',
  //   meta: { authenticated: true, permissions: ['content.read'] }
  // },
  // admin routes
  {
    portalGroup: 'admin.admin',
    path: 'content/:id/review',
    name: 'ContentReview',
    component: () => import('./Admin/PgL0101Review.vue'),
    meta: { title: i18n.t('l0101.title.PgL0101Review'), authenticated: true }
  },
  // {
  //   path: 'Contents/:id/review',
  //   name: 'ContentReview',
  //   component: () => import('./Admin/ContentReview/PgContentReview.vue'),
  //   portalGroup: 'learn.my',
  //   meta: { authenticated: true, permissions: ['content.read', 'approval.review'] }
  // },

]
