// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'admin.admin',
    path: 'M0601Category/form/:id?',
    name: 'PgM0601Form',
    component: () => import('./PgM0601Form.vue')
  },
  {
    portalGroup: 'admin.admin',
    path: 'M0601Category/index',
    name: '類別列表',
    component: () => import('./PgM0601MultiPage.vue')
  }
]
