import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'all.public',
    path: 'my/chatroom',
    name: 'M0901Chatroom',
    component: () => import('@/app/M0901Chatroom/PgChatroomList.vue'),
    meta: { title: i18n.t('m0901.ChatRoom.title'), authenticated: true }
  }
]
