import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C0101Project',
  serviceName: 'C0101ProjectService',
  storeConst: {
    ACTION: {
      doSubmit: {
        store: 'C0101Project/doSubmit',
        api: 'C0101ProjectService.doSubmit'
      },
      getHint: {
        store: 'M3901Guide/getHint'
      }
    },
    PROPERTY: {
      forms: {
        get: 'C0101Project/getForms',
        set: 'C0101Project/SET_FORMS'
      }
    }
  },
  componentDef: {
    search: {
      viewDef: [
        {
          column: 'col-3',
          data: 'project_intention',
          type: 'checkbox',
          props: {
            items: [
              { value: 'crowd_fund', text: i18n.t('c1001.filter.crowd_fund') },
              { value: 'investment', text: i18n.t('c2001.filter.investment') },
              { value: 'partner_requirement', text: i18n.t('c3001.filter.partner_requirement') },
              { value: 'advise', text: i18n.t('c4001.filter.advise') }
            ]
          },
          label: i18n.t('c0101.edit.steps.intentions')
        },
        {
          column: 'col-3',
          data: 'project_status',
          type: 'select',
          props: {
            items: null
          },
          async: [
            {
              api: 'C0101ProjectService.getProjectStatusList',
              mutateTarget: 'props.items'
            }
          ],
          label: i18n.t('c0101.filter.project_status.label')
        }
      ],
      optionDef: {}
    },
    form: {
      viewDef: [
        // Basics
        [
          {
            column: 'col-12',
            data: 'project_title',
            type: 'text',
            rules: 'required',
            label: i18n.t('c0101.filter.project_title'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_subtitle',
            type: 'text',
            rules: 'required',
            label: i18n.t('c0101.filter.project_subtitle'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_cover',
            type: 'file',
            props: {
              accept: 'image/*'
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_cover'),
            class: 'required'
          },
          {
            column: 'col-6',
            data: 'm0601_id',
            type: 'ttc_category',
            rules: 'required',
            label: i18n.t('c0101.filter.m0601_id'),
            class: 'required'
          },
          {
            column: 'col-6',
            data: 'project_tag',
            type: 'ttc_tag',
            rules: 'required',
            label: i18n.t('c0101.filter.project_tag'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_video',
            type: 'file',
            props: {
              accept: 'video/*'
            },
            label: i18n.t('c0101.filter.project_video')
          }
        ],
        // Intentions
        [
          {
            column: 'col-12',
            // get set here
            data: 'project_intention',
            custom: 'ProjectIntention',
            rules: 'required',
            class: 'required'
          }
        ],
        // Information
        [
          {
            column: 'col-12',
            data: 'project_desc',
            custom: 'MoBlockEditor',
            props: {
              data: 'project_desc'
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_desc'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_timeline',
            custom: 'MoBlockEditor',
            props: {
              data: 'project_timeline'
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_timeline'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_market_analyze',
            custom: 'MoBlockEditor',
            props: {
              data: 'project_market_analyze'
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_market_analyze'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_risk_challenge',
            custom: 'MoBlockEditor',
            props: {
              data: 'project_risk_challenge'
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_risk_challenge'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_financial_forecast',
            custom: 'MoBlockEditor',
            props: {
              data: 'project_financial_forecast'
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_financial_forecast'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_faq',
            custom: 'ExpansionPanels',
            props: {
              headerProps: {
                label: '疑問'
              },
              contentProps: {
                label: '解答'
              }
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_faq'),
            class: 'required'
          },
          {
            column: 'col-12',
            data: 'project_policy',
            custom: 'ExpansionPanels',
            props: {
              headerProps: {
                label: '政策標題'
              },
              contentProps: {
                label: '政策內容'
              }
            },
            rules: 'required',
            label: i18n.t('c0101.filter.project_policy'),
            class: 'required'
          }
        ],
        // People
        [
          {
            column: 'col-12',
            data: 'project_member',
            custom: 'ProjectMember',
            label: i18n.t('c0101.filter.project_member')
          },
          {
            column: 'col-12',
            data: 'project_certification',
            custom: 'DraggableCard',
            label: i18n.t('c0101.filter.project_certification')
          },
          {
            column: 'col-12',
            data: 'project_sponsor',
            custom: 'DraggableCard',
            label: i18n.t('c0101.filter.project_sponsor')
          }
        ]
      ],
      optionDef: {}
    },
    table: {
      viewDef: [
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'title' },
        // use type: 'date' to format date
        { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
        // use type: 'enum' to format enum
        {
          text: 'Gender',
          value: 'gender',
          type: 'chip',
          items: [
            { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
            { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
            { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
          ]
        },
        { text: 'comments', value: 'comment_count' },
        { text: 'post_type', value: 'post_type' },
        { text: 'views', value: 'views' },
        { text: 'Custom (comments+views)', value: 'cus' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      optionDef: {}
    },
    view: {
      viewDef: [
        { text: i18n.t('samples.layout4.title'), value: 'title' },
        { text: 'desc', value: 'description' },
        { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
        { text: 'Thumbnail', value: 'thumbnail', type: 'image' },
        { text: 'Attachments', value: 'attachments', type: 'files' }
      ],
      optionDef: {}
    }
  }
}
const C0101Const = new BaseDataStore(BaseConst)
export default C0101Const
