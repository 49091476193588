<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    transition="dialog-bottom-transition"
  >
    <template #activator="{ on, attrs }">
      <slot
        name="activator"
        :action="onOpen"
        v-bind="attrs"
        v-on="on"
      >
        <v-btn
          outlined
          color="primary"
          @click="onOpen"
        >
          <v-icon> mdi-file-outline </v-icon> {{ $t('common.open') }}
        </v-btn>
      </slot>
    </template>
    <!-- Header toolbar -->
    <transition name="fade">
      <div
        v-if="getValue[current] && showToolbar && showHeaderByContentType(getValue[current])"
        :style="{ color: textColor, backgroundColor: toolbarColor }"
        class="media-viewer-header d-flex align-center"
      >
        <div class="flex--1">
          <slot
            name="infoContainer"
            :text-color="textColor"
            :item="getValue[current]"
          >
            <div
              class="d-flex"
              style="color: white;"
            >
              <span class="text-truncate text-h5">
                {{ getValue[current].name }}
              </span>
              <span
                v-if="getValue[current]['content-length'] !== undefined"
                class="flex-shrink-0 ml-2 text-h5"
              >
                ({{ getValue[current]['content-length'] | prettyBytes }})
              </span>
            </div>
          </slot>
        </div>
        <div class="flex--0">
          <slot
            name="closeButton"
            :action="onClose"
          >
            <v-btn
              icon
              color="primary"
              x-large
              @click="onClose"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </slot>
        </div>
      </div>
    </transition>
    <!-- Media Body -->
    <div
      class="content-style"
      :style="{ backgroundColor: backgroundHexa }"
      @click="showToolbar = !showToolbar"
    >
      <CarouselWrapper
        :key="carouselWrapperKey"
        v-model="getValue"
        class="carousel-wrapper"
        v-bind="$attrs"
        hide-document-name
        :height="$vuetify.breakpoint.height"
        :current-item.sync="current"
        :show-arrows="!$vuetify.breakpoint.mobile"
        v-on="$listeners"
      />
    </div>
    <!-- Footer toolbar -->
    <transition name="fade">
      <div
        v-if="getValue[current] && showToolbar && showFooterByContentType(getValue[current])"
        class="media-viewer-footer"
        :style="{ backgroundColor: toolbarColor }"
      >
        <v-row class="mh-78px overflow-hidden">
          <v-col
            cols="4"
            offset="4"
            class="text-center"
            style="color: white;"
          >
            <span
              class="text-h5"
              :style="{ color: textColor }"
            >
              ({{ current + 1 }}/{{ getValue.length }})
            </span>
          </v-col>
          <v-col
            cols="4"
            class="text-right"
          >
            <slot
              v-if="showDownloadButton"
              name="downloadButton"
              :action="download"
            >
              <v-btn
                color="primary"
                icon
                @click="download"
              >
                <v-icon size="50">
                  mdi-download
                </v-icon>
              </v-btn>
            </slot>
          <!-- TODO: Download all -->
            <!-- <v-btn
              color="primary"
              @click="downloadAll"
            >
              {{ $t('components.mediaViewer.downloadAll') }}
            </v-btn> -->
          </v-col>
        </v-row>
      </div>
    </transition>
  </v-dialog>
</template>

<script>
import CarouselWrapper from '../CarouselWrapper/CarouselWrapper.vue'
import ColorUtils from '@/utils/ColorUtils'
import jquery from 'jquery'
export default {
  name: 'MediaViewer',
  components: {
    CarouselWrapper
  },
  filters: {
    prettyBytes (num) {
      // jacked from: https://github.com/sindresorhus/pretty-bytes
      // convert string to number
      if (typeof num !== 'number') num = Number(num)

      if (typeof num !== 'number' || isNaN(num)) {
        throw new TypeError('Expected a number')
      }

      const neg = num < 0
      const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      if (neg) {
        num = -num
      }

      if (num < 1) {
        return (neg ? '-' : '') + num + ' B'
      }

      const exponent = Math.min(Math.floor(Math.log(num) / Math.log(1024)), units.length - 1)
      num = (num / Math.pow(1024, exponent)).toFixed(2) * 1
      const unit = units[exponent]

      return (neg ? '-' : '') + num + ' ' + unit
    }

  },
  props: {
    value: {
      type: Array,
      default: () => { return [] }
    },
    show: {
      type: Boolean,
      default: undefined
    },
    // Current item index that need to be displayed
    currentItem: {
      type: Number,
      default: undefined
    },
    backgroundHexa: {
      type: String,
      default: '#000000cc'
    },
    toolbarColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.7)'
    },
    // dont show download button on blacklist
    // input choice ['video', 'audio', 'document','image','youtube','null']
    downloadBlacklist: {
      type: Array,
      default: () => {
        return ['youtube', 'null']
      }
    }
  },
  data () {
    return {
      showToolbar: true,
      carouselWrapperKey: null,
      d_show: false,
      d_curent: 0
    }
  },
  computed: {
    getValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    showDialog: {
      get () {
        return this.show ?? this.d_show
      },
      set (val) {
        this.d_show = val
        this.$emit('update:show', val)
      }
    },
    current: {
      get () {
        return this.currentItem ?? this.d_curent
      },
      set (val) {
        this.d_curent = val
        this.$emit('update:currentItem', val)
      }
    },
    textColor () {
      return ColorUtils.hexa(this.backgroundHexa)
        .contrast()
        .name()
    },
    showDownloadButton () {
      const item = this.value[this.current]
      const target = item?.mimetype
      if (target === undefined || target === null) return false
      for (let i = 0; i < this.downloadBlacklist.length; i++) {
        if (target.indexOf(this.downloadBlacklist[i]) !== -1) {
          return false
        }
      }
      return true
    }
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler () {
        this.carouselWrapperKey = (new Date()).getMilliseconds()
      }
    }
  },
  methods: {
    onOpen () {
      this.showDialog = true
    },
    onClose () {
      this.showDialog = false
    },
    download () {
      const index = this.d_curent
      const a = jquery('<a>')
        .attr('href', this.getValue[index].src)
        .attr('target', 'download')
        .appendTo('body')
      a[0].click()
      a.remove()
    },
    showHeaderByContentType (media) {
      if (!media?.mimetype) {
        return true
      } else if (media.mimetype?.startsWith('youtube')) {
        return false
      }
      return true
    },
    showFooterByContentType (media) {
      if (!media?.mimetype) {
        return true
      } else if (
        media.mimetype?.startsWith('video') ||
        media.mimetype?.startsWith('audio') ||
        media.mimetype?.startsWith('youtube')
      ) {
        return false
      }
      return true
    },
    downloadAll () {
      console.log('TODO:')
    }
  }
}
</script>

<style scoped>
.media-viewer-header,
.media-viewer-footer {
  position: absolute;
  left: 0;
  width: 100vw;
  height: 78px;
  padding: 1rem;
  z-index: 1;
}
.media-viewer-header {
  top: 0;
}
.media-viewer-footer {
  bottom: 0;
}

/* Make fullscreen */
.content-style {
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
}

/* CSS to make the title & close button fit in one line */
.flex--0 {
  flex: 0 0 auto;
}
.flex--1 {
  flex: 1 1 52px;
  overflow: hidden;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.carousel-wrapper >>> .slide-item-wrapper > iframe {
  height: calc(100vh - 78px - 78px);
}
</style>
