import i18n from '@/plugins/i18n'

export default [
  {
    path: 'tag',
    name: 'TagList',
    component: () => import('@/app/M0701Tag/TagList/PgTagList.vue'),
    portalGroup: 'admin.admin',
    meta: { title: i18n.t('m0701Tag.title'), permissions: ['tag.browse'] }
  }
]
