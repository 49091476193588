<template>
  <div>
    <p class="font-weight-bold text-h6 mb-1">
      {{ $t('individualProfile.skill') }}
    </p>
    <p>{{ $t('individualProfile.skillText') }}</p>
    <div v-if="!showCombobox">
      <v-btn
        color="primary"
        @click="showCombobox = true"
      >
        {{ $t('individualProfile.addskill') }}
      </v-btn>
    </div>
    <v-combobox
      v-else
      v-model="selectSkill"
      v-permissions="['tag.search']"
      :items="skillTagList"
      close-on-content-click
      attach
      :menu-props="{
        closeOnContentClick: true
      }"
      outlined
      @keydown="searchTag"
    >
      <template #item="{item}">
        <v-list-item
          v-if="!skillList.includes(item)"
          @click="pushSearch(item)"
        >
          {{ item }}
        </v-list-item>
        <v-list-item
          v-else-if="skillList.includes(item)"
          @click="removeSkill(item)"
        >
          {{ item }} <v-icon class="ml-2 fa-solid fa-check" />
        </v-list-item>
      </template>
      <template
        v-if="isSearching"
        #append
      >
        <v-progress-circular indeterminate />
      </template>
      <template
        v-if="skillTagList.length && isSearching === false && !skillTagList.includes(inputSkill)"
        #append-item
      >
        <v-divider />
        <v-list-item
          link
          @click="pushTag"
        >
          {{ $t('components.TTCSkill.propose', { tag: inputSkill }) }}
        </v-list-item>
      </template>

      <template
        v-else
        #no-data
      >
        <v-list-item
          v-if="isSearching === false && inputSkill !== '' && !skillList.includes(inputSkill)"
          link
          @click="pushTag()"
        >
          {{ $t('components.TTCSkill.propose', { tag: inputSkill }) }}
        </v-list-item>
        <v-list-item v-if="skillList.includes(inputSkill)">
          already exits
        </v-list-item>
      </template>
    </v-combobox>
    <draggable
      tag="ul"
      :list="skillList"
      class="list-group"
      handle=".handle"
    >
      <v-list
        v-for="(skill, index) in skillList"
        :key="skill"
        class="skill-list"
        v-bind="$attrs"
      >
        <div class="d-flex justify-space-between">
          <div class="d-flex">
            <v-list-item-action>
              <v-btn icon>
                <v-icon
                  color="grey lighten-1"
                  class="fa-solid fa-x"
                  @click="removeSkill(skill)"
                />
              </v-btn>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ skill }}</v-list-item-title>
            </v-list-item-content>
          </div>
          <v-list-item-action>
            <v-btn icon>
              <v-icon
                color="grey lighten-1"
                class="fa-solid fa-bars handle"
                @remove="skillList.splice(index,1)"
              />
            </v-btn>
          </v-list-item-action>
        </div>
      </v-list>
    </draggable>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import draggable from 'vuedraggable'

export default {
  name: 'TTCSkillTagField',

  components: { draggable },

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      inputSkill: '',
      isSearching: false,
      skillTagList: [],
      selectSkill: null,
      skillList: [],
      dragging: false,
      showCombobox: false
    }
  },

  computed: {
  },
  watch: {
    value: {
      handler (val) {
        this.skillList = val
      },
      deep: true
    },
    skillList: {
      handler (val, oldVal) {
        // // if (val === oldVal) return
        this.$emit('input', val)
      },
      deep: true
    }
  },

  mounted () {
  },
  destroyed () {
    this.skillList = []
    this.$emit('input', [])
    this.$emit('value', [])
  },

  methods: {
    searchTag: debounce(function (event) {
      this.isSearching = true
      this.inputSkill = event ? event.target.value : ''
      this.storeApiDispatch('M0701Tag/searchSkillTag', { skillTag: this.inputSkill }, { loading: false }).then((data) => {
        this.skillTagList = data.map(item => item.tag_name)
        this.isSearching = false
      })
    }, 500),
    pushSearch (item) {
      this.skillList.push(item)
      this.selectSkill = ''
      this.showCombobox = false
    },
    // addGetList (item) {
    //   this.testList.push(item)
    // },
    removeAt (idx) {
      this.skillList.splice(idx, 1)
    },
    removeSkill (item) {
      this.skillList = this.skillList.filter(skill => skill !== item)
      this.showCombobox = false
    },
    pushTag () {
      this.skillList.push(this.inputSkill)
      this.showCombobox = false
    }
  }
}
</script>

<style scoped>
.skill-list {
  border: 0px
}
.skill-list:not(:last-child){
  border-bottom: solid 2px #E1E1E1 !important
}
</style>
