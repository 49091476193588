<template>
  <v-dialog
    v-model="showDialog"
    :width="dialogWidth"
    scrollable
  >
    <v-card v-if="showDialog">
      <slot name="title">
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="px-2 text-h5 font-weight-bold">
              {{ $t('userInteraction.noLogin.title') }}
            </span>
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </slot>
      <v-card-title />
      <v-card-subtitle>
        <slot name="subtitle">
          <div class="text-subtitle-1">
            {{ $t('userInteraction.noLogin.subtitle', { model: $t('m1201.ReportDialog.rel_models.' + relModel), type: $t('userInteraction.type.' + relInteraction) }) }}
          </div>
        </slot>
      </v-card-subtitle>
      <v-card-actions>
        <v-col cols="6">
          <v-btn
            color="primary"
            block
            @click="goToLoginPage();showDialog = false"
          >
            {{ $t('userInteraction.noLogin.goLogin') }}
          </v-btn>
        </v-col>
        <v-col cols="6">
          <v-btn
            color="secondary"
            block
            @click="showDialog = false"
          >
            {{ $t('common.cancel') }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  computed: {
    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100vw'
        case 'sm': return '85vw'
        case 'md': return '70vw'
        case 'lg': return '55vw'
        case 'xl': return '40vw'
        default: return '40vw'
      }
    },
    showDialog: {
      get () {
        return this.$store.getters['UserInteraction/getShowDialog']
      },
      set (val) {
        this.$store.commit('UserInteraction/SET_SHOW_DIALOG', val)
      }
    },
    relModel () {
      return this.$store.getters['UserInteraction/getRelModel']
    },
    relInteraction () {
      return this.$store.getters['UserInteraction/getRelInteraction']
    }
  }
}
</script>
