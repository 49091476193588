<template>
  <div>
    <label>{{ label }}</label>
    <div v-if="policies">
      <!-- <div v-if="have_subscript.length !== 0">
        <p>{{ have_subscript.m0001.username }}已在{{ have_subscript.created_at }}同意
          <span v-for="(route, index) in policies" :key="index">
            {{ route.to.params.name }}
            <template v-if="index !== policies.length - 1">,</template>
            <template v-if="index === policies.length - 2 && policies.length > 1"> 以及 </template>
          </span>
        </p>
      </div> -->
      <AtTCCheckbox
        v-bind="$attrs"
        :policies="policies"
        @input="$emit('input', $event)"
      />
    </div>
  </div>
</template>

<script>
import { AtTCCheckbox } from '@/components/base/atoms/input/AtTCCheckbox'
import i18n from '@/plugins/i18n'

export default {
  name: 'TTCPolicyCheckbox',
  components: { AtTCCheckbox },
  props: {
    label: {
      type: String,
      default: i18n.t('components.TTCPolicyCheckbox.label')
    },
    policyCode: {
      type: [String, Array],
      default: null
    }
  },
  data () {
    return {
      routeLabel: null,
      to: {},
      policies: [],
      policyCodes: [],
      have_subscript: {
        'id': 1,
        'created_at': '2024-05-29',
        'm0001': {
          'username': 'kong'
        }
      }
    }
  },
  mounted () {
    this.loadPolicies();
  },
  methods: {
    async loadPolicies() {
      if (typeof this.policyCode === 'string'){
        this.policyCodes.push(this.policyCode)
      } else {
        for (let index = 0; index < this.policyCode.length; index++) {
          this.policyCodes.push(this.policyCode[index])
        }
      }
      try {
        const dataArray = await this.storeApiDispatch('S0901Policy/doReadCheckbox', { codes: this.policyCodes });
        this.policies = dataArray[0].map(data => ({
          routeLabel: data.policy_name,
          to: {
            name: 'S0901View',
            params: {
              slug: data.policy_seo_url,
              name: data.policy_name,
              version: data.s0902_id,
            },
          },
        }));
      } catch (error) {
        console.error('Error loading policies:', error);
      }
    },
  }
}
</script>
