import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    introBanner: [],
    categories: [],
    contentCounter: [],
    featuredAuthor: [],
    partnership: [],
    suggestion: [],
    row: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async init ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: {
          introBanner: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'press.landing.introBanner' },
          categories: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'press.landing.categories' },
          contentCounter: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'press.landing.contentCounter' },
          featuredAuthor: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'press.landing.featuredAuthor' },
          partnership: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'press.landing.partnership' },
          suggestion: { api: 'M4601StaffPicksService.getStaffPickItems', input: 'press.landing.suggestion' }
        }
      })
      for (const key in res) {
        state[key] = res[key].output
      }
    },
    async getEdit({ commit, state, dispatch }, payload) {
      state.row = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.getEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output[0]

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async doEdit({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.doEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
