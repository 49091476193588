import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C1101ProjectCrowdFundTier',
  serviceName: 'C1101ProjectCrowdFundTierService',
  componentDef: {
    form: {
      viewDef: [
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_title',
          type: 'text',
          label: i18n.t('c1101.filter.project_crowd_fund_tier_title')
        },
        {
          column: 'col-12',
          data: {
            get (val) {
              return {
                coins: val.project_crowd_fund_tier_main_price,
                tokens: val.project_crowd_fund_tier_alternative_price_max
              }
            },
            set (val, input) {
              val.project_crowd_fund_tier_main_price = input.coins
              val.project_crowd_fund_tier_alternative_price_max = input.tokens
              return val
            }
          },
          type: 'ttc_price',
          label: i18n.t('c1101.filter.project_crowd_fund_tier_price')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_cover',
          type: 'file',
          props: {
            accept: 'image/*'
          },
          label: i18n.t('c1101.filter.project_crowd_fund_tier_cover')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_desc',
          type: 'textarea',
          label: i18n.t('c1101.filter.project_crowd_fund_tier_desc')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_item',
          custom: 'ProjectCrowdFundTierItem'
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_type',
          type: 'radio',
          props: {
            items: [
              { value: 'PHYSICAL', text: i18n.t('c1101.filter.project_crowd_fund_tier_type.PHYSICAL') },
              { value: 'NON_PHYSICAL', text: i18n.t('c1101.filter.project_crowd_fund_tier_type.NON_PHYSICAL') },
              { value: 'MIXED', text: i18n.t('c1101.filter.project_crowd_fund_tier_type.MIXED') }
            ]
          },
          label: i18n.t('c1101.filter.project_crowd_fund_tier_type.label')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_ship_option',
          type: 'radio',
          props: {
            items: [
              { value: 'ANYWHERE', text: i18n.t('c1101.filter.project_crowd_fund_tier_ship_option.ANYWHERE') },
              { value: 'SELECTED', text: i18n.t('c1101.filter.project_crowd_fund_tier_ship_option.SELECTED') },
              { value: 'PICKUP', text: i18n.t('c1101.filter.project_crowd_fund_tier_ship_option.PICKUP') },
              { value: 'NO', text: i18n.t('c1101.filter.project_crowd_fund_tier_ship_option.NO') }
            ]
          },
          label: i18n.t('c1101.filter.project_crowd_fund_tier_ship_option.label')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_estimated_delivery',
          type: 'date',
          label: i18n.t('c1101.filter.project_crowd_fund_tier_estimated_delivery')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_slots',
          type: 'number',
          label: i18n.t('c1101.filter.project_crowd_fund_tier_slots')
        },
        {
          column: 'col-12',
          data: 'project_crowd_fund_tier_end_datetime',
          type: 'date',
          label: i18n.t('c1101.filter.project_crowd_fund_tier_end_datetime')
        }
      ]
    }
  }
}
const C1101Const = new BaseDataStore(BaseConst)
export default C1101Const
