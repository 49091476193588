import AppUtils from '@/utils/AppUtils'
import { mapGetters } from 'vuex'

export default {
  methods: {
  },
  computed: {
    isUserLoggedIn: AppUtils.isLoggedIn,
    ...mapGetters({
      user: 'Auth/getUser'
    })
  }
}
