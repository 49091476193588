<template>
  <div>
    <Comment
      :comment="comment"
      :show-actions="['resolve', 'edit', 'delete']"
      :current-user-id="currentUserId"
      :users-data="usersData"
      @resolveComment="resolveComment()"
      @editComment="editComment(comment.id, null, $event)"
      @deleteComment="deleteComment(comment.id)"
    />

    <div
      v-for="r in comment.reply"
      :key="r.id"
      class="reply col-12"
    >
      <Comment
        :comment="r"
        :show-actions="['edit', 'delete']"
        :current-user-id="currentUserId"
        :users-data="usersData"
        @editComment="editComment(comment.id, r.id, $event)"
        @deleteComment="deleteComment(comment.id, r.id)"
      />
    </div>

    <div
      v-if="showInput"
      class="row reply-container"
    >
      <input
        v-model="inputVmodel"
        class="reply-input col"
        placeholder="Add Reply Here ..."
        @keydown="enterToReply"
      >
      <button
        class="reply-send col-1"
        :disabled="!inputVmodel"
        @click="replyComment"
      >
        <i
          aria-hidden="true"
          class="v-icon  mdi mdi-send "
        />
      </button>
    </div>
  </div>
</template>

<script>
import Comment from './Comment.vue'
export default {
  name: 'CommentItem',
  components: { Comment },
  props: {
    comment: {
      type: Object,
      required: true
    },
    showInput: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inputVmodel: ''
    }
  },
  methods: {
    resolveComment () {
      this.$emit('resolveComment', { commentId: this.comment.id, resolved: !this.comment.resolved })
    },
    editComment (commentId, replyId, content) {
      this.$emit('editComment', { commentId, replyId, content })
    },
    deleteComment (commentId, replyId) {
      this.$emit('deleteComment', { commentId, replyId })
    },
    replyComment () {
      this.$emit('replyComment', { commentId: this.comment.id, content: this.inputVmodel })
      this.inputVmodel = ''
    },
    enterToReply (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        this.replyComment()
      }
    }
  }
}
</script>

<style>
</style>
