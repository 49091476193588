<script>
import DateTimeUtils from '@/utils/DateTimeUtils'
import { VChip, VTooltip, VDialog, VCard, VCardText, VCardActions, VBtn, VSpacer } from 'vuetify/lib'
import MediaViewer from '@/components/base/molecules/MediaViewer/MediaViewer.vue'



export default {
  filters: {
    formatDate (value) {
      if (!value) return ''
      return DateTimeUtils.dateTimeDisplayHK(value)
    },
    formatRelativeDate (value) {
      if (!value) return ''
      return DateTimeUtils.relativeTimeDisplay(value)
    }
  },
  props: {
    type: {
      type: String,
      default: 'default',
      validator: function (value) {
        if ([
          'ttc_currency',
          'ttc_price',
          'default',
          'email',
          'longtext',
          'text',
          'date',
          'relativeDate',
          'url',
          'chip',
          'files',
          'image',
          'custom'
        ].indexOf(value) !== -1) {
          return true
        } else {
          console.error(`DWarp type: ${value} is not supported`)
          return false
        }
      }
    }
  },
  methods: {
    formatDate: function (value) {
      if (!value) return ''
      return DateTimeUtils.dateTimeDisplayHK(value)
    },
    formatRelativeDate: function (value) {
      if (!value) return ''
      return DateTimeUtils.relativeTimeDisplay(value)
    }
  },
  render (h) {
    const context = this
    const value = context.$attrs.value
    // attrs for dynamic comps
    const attrs = context.$attrs.attrs
    let items = context.$attrs.items

    if (value === undefined) return h('div', '-')

    if (value === null) return h('div', '-')

    switch (context.type) {
      case 'ttc_currency':{
        return h('div', { style: { color: 'black' } }, `USD ${value.amount}`)
      }
      case 'ttc_price':{
        return h('div', { style: { color: 'black' } }, `${value.coins} Coins, ${value.tokens} Tokens`)
      }
      case 'default':
        return h('div', { style: { color: 'black' } }, value)
      case 'email': {
        const mailtoHref = `mailto:${value}`
        return h('a', { attrs: { href: mailtoHref } }, value)
      }
      case 'longtext': {
        // check if string is longer than current container width
        // if yes, make it clickable , show dialog, text wrap, dialog width 500px
        // if no, show text
        const minLongTextLength = 20
        const isLongText = value.length > minLongTextLength
        const dialog = h(VDialog, {
          ref: 'dialog',
          props: {
            width: 500,
            minWidth: 400,
            maxWidth: 600
          },
          scopedSlots: {
            activator: function (d) {
              const _on = d.on
              const _attrs = d.attrs
              return h('div', {
                class: 'canHover',
                attrs: _attrs,
                on: _on
              }, isLongText ? `${value.substring(0, minLongTextLength)}...` : value)
            }
          }
        }, [
          h(VCard, {
            class: 'pa-3',
            style: {
              overflowY: 'auto',
              whiteSpace: 'pre-wrap'
            }
          }, [
            h(VCardText, [value]), h(VCardActions, [
              h(VSpacer),
              h(VBtn, {
                props: { color: 'primary', outlined: true },
                on: { click: () => { context.$refs.dialog.isActive = false } }
              }, [context.$t('common.close')])
            ])
          ])
        ])
        return h('div', { style: { color: 'black' } }, [isLongText ? dialog : value])
      }
      case 'date': {
        const formattedDate = context.$options.filters.formatDate(value)
        return h('div', { style: { color: 'black' } }, formattedDate)
      }
      case 'relativeDate': {
        return h(VTooltip, {
          attrs: {
            bottom: true
          },
          scopedSlots: {
            activator: function (d) {
              const _on = d.on
              const _attrs = d.attrs
              return h('div', {
                class: 'canHover',
                attrs: _attrs,
                on: _on
              }, context.$options.filters.formatRelativeDate(value))
            }
          }
        }, context.$options.filters.formatDate(value))
      }
      case 'url':
        return h('a', { attrs: { href: value, target: '_blank', ...attrs } }, value)
      case 'chip': {
        // if item is array, change to correct format
        if (Array.isArray(items)) {
          // items= [ { value: 'value', label: 'label', color: 'color' } ]
          const newItems = {}
          items.forEach((item) => {
            newItems[item.value] = item
          })
          items = newItems
        }
        if (items[value] === undefined) {
          console.error(`DWarp chip: ${value} is undefined`)
          return h('div', '-')
        }
        // items= { 'value': { label: 'label', color: 'color' } }
        const chipColor = items[value].color ?? 'grey'
        const chipLabel = items[value].label ?? items[value].text ?? value
        return h(VChip, { props: { color: chipColor, small: true, ...attrs }, style: { color: 'white' } }, chipLabel)
      }
      case 'files':
        // check is array
        if (!Array.isArray(value)) { console.error('DWarp: value is not array'); return h('div', value) }
        // check is empty
        if (value.length === 0) { return h('div', '-') }
        return h(MediaViewer, { attrs: { value } })
      case 'image': {
        let targetVal = {}
        // accept array of objects or object
        // if array use 1st object
        if (Array.isArray(value)) {
          if (value.length === 0) { console.error('DWarp: value is empty array'); return h('div', value) }
          targetVal = value[0]
        } else {
          targetVal = value
        }
        // if not object
        if (typeof targetVal !== 'object') { console.error('DWarp: value is not object'); return h('div', value) }
        return h('img', { attrs: { src: targetVal.src, alt: context.$attrs.label, contain: true, ...attrs } })
      } case 'custom':
        return h(context.$attrs.component, { props: { value, ...attrs } })
      case 'text':
        return h('div', value)
      default:
        console.error('DWarp: Unknown type', context.type)
        return h('div', value)
    }
  }
}
</script>

<style>
.canHover:hover {
  color: grey;
  text-decoration: underline;
  width: fit-content;
}
</style>
