import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'
// eslint-disable-next-line no-unused-vars
import ApiUtils from '@/utils/api/ApiUtils'


const module = {
  // `app` prefix is required to avoid name conflicts with other modules'
  // and to make it easier to identify the module in the store.
  state: {

  },

  getters: {
  },
  mutations: {
  },
  actions: {

  }
}

export default ObjArrUtils.merge(Base, module)
