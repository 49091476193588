import DataUtils from '@/utils/DataUtils'

// accept old a
export default {
  props: {
    /**
     * The definition for the DataTable cols 
     * @type {Array}
     */
    // eslint-disable-next-line vue/require-default-prop
    tableDef: {
      type: Array,
      default: () => { return [] }
    },
    /**
     * The definition for the edit/add form
     * @type {Array}
     */
    // eslint-disable-next-line vue/require-default-prop
    formDef: {
      type: Array,
      default: () => { return [] }
    },
    /**
     * The definition for the search field
     * @type {Array}
     */
    // eslint-disable-next-line vue/require-default-prop
    searchDef: {
      type: Array,
      default: () => { return [] }
    }
  },
  methods: {
    onDefUpdate () {
      this.$emit('genDefs', { tableDef: this.tableDefData, formDef: this.formDefData, searchDef: this.searchDefData })
    }
  },
  computed: {
    isFormDefEmpty () {
      return this.formDefData.length === 0
    },
    isSearchDefEmpty () {
      return this.searchDefData.length === 0
    }
  },
  watch: {
    tableDef: {
      handler (val, oldVal) {
        if (DataUtils.isSameObject(val, oldVal)) return
        this.tableDefData = val
        this.onDefUpdate()
      },
      deep: true,
      immediate: true
    },
    formDef: {
      handler (val, oldVal) {
        if (DataUtils.isSameObject(val, oldVal)) return
        this.formDefData = val
        this.onDefUpdate()
      },
      deep: true,
      immediate: true
    },
    searchDef: {
      handler (val, oldVal) {
        if (DataUtils.isSameObject(val, oldVal)) return
        this.searchDefData = val
        this.onDefUpdate()
      },
      deep: true,
      immediate: true
    }
  }
}
