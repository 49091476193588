<template>
  <v-dialog
    ref="menu"
    v-model="menu"
    width="unset"
    :fullscreen="$vuetify.breakpoint.xs"
  >
    <template #activator="{ on, attrs }">
      <VTextField
        :value="showString"
        clearable
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="{...attrs,...$attrs}"
        :disabled="disabled"
        v-on="on"
        @click:clear="clear()"
      />
    </template>
    <VCard>
      <VCardTitle>
        <VTextField
          :value="showString"
          outlined
          readonly
          prepend-icon="mdi-calendar"
          :disabled="disabled"
          hide-details
        />
      </VCardTitle>
      <VCard-text>
        <v-tabs v-model="tab">
          <v-tab>Since</v-tab>
          <v-tab-item>
            <VDatePicker
              v-model="since"
              no-title
            />
          </v-tab-item>
          <v-tab>Before</v-tab>
          <v-tab-item>
            <VDatePicker
              v-model="before"
              no-title
            />
          </v-tab-item>
          <v-tab>In Range</v-tab>
          <v-tab-item>
            <VDatePicker
              v-model="inRangeStart"
              range
              no-title
              :max="endData"
            />
            <VDatePicker
              v-model="inRangeEnd"
              :disabled="startData == null"
              no-title
              range
              :min="startData"
            />
          </v-tab-item>
        </v-tabs>
      </VCard-text>
      <VCardActions>
        <v-btn
          text
          color="primary"
          @click="cancel()"
        >
          {{ $t('common.cancel') }}
        </v-btn>
        <v-btn
          text
          color="primary"
          @click="clear()"
        >
          {{ $t('common.clear') }}
        </v-btn>
        <v-spacer />

        <v-btn
          color="primary"
          @click="menu = false"
        >
          {{ $t('common.ok') }}
        </v-btn>
      </VCardActions>
    </VCard>
  </v-dialog>
</template>

<script>
import DataUtils from '@/utils/DataUtils'
export default {
  props: {
    label: {
      type: String,
      default: null
    },
    value: {
      type: Array,
      default: () => [null, null]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false,
      clearVal: [null, null],
      startData: this.start,
      endData: this.end,
      tab: 0
    }
  },
  computed: {
    showString () {
      if (this.startData != null && this.endData != null) { return this.startData + ' <-> ' + this.endData }
      if (this.startData != null) { return 'Since: ' + this.startData }
      if (this.endData != null) { return 'Before: ' + this.endData }
      return ''
    },
    since: {
      get () {
        return this.startData
      },
      set (val) {
        this.startData = val
        this.endData = null
      }
    },
    before: {
      get () {
        return this.endData
      },
      set (val) {
        this.startData = null
        this.endData = val
      }
    },
    inRangeStart: {
      get () {
        const arr = []
        if (this.startData != null) arr.push(this.startData)
        if (this.endData != null) arr.push(this.endData)
        return arr
      },
      set (val) {
        this.startData = val.at(-1) ?? null
      }
    },
    inRangeEnd: {
      get () {
        return [...[this.startData], ...[this.endData]]
      },
      set (val) {
        this.endData = val[0] ?? null
      }
    }
  },
  watch: {
    value (newValue) {
      if (newValue === undefined) { return this.$emit('input', [null, null]) }
      this.startData = newValue[0]
      this.endData = newValue[1]
    },
    startData (newValue) {
      const a = JSON.parse(JSON.stringify(this.value))
      a[0] = newValue
      this.$emit('input', a)
    },
    endData (newValue) {
      const a = JSON.parse(JSON.stringify(this.value))
      a[1] = newValue
      this.$emit('input', a)
    },
    tab (v) {
      this.clear()
    },
    menu (v) {
      // if open menu, save current value
      if (v) {
        this.clearVal = DataUtils.jsonCopy(this.value)
      }
    }
  },
  methods: {
    clear () {
      this.$emit('input', [null, null])
    },
    cancel () {
      this.$emit('input', this.clearVal)
      this.menu = false
    }
  }
}
</script>

<style>
</style>
