import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    searchTerm: '',
    filter: {},
    project: { }
  },
  getters: {
    getProject: (state) => state.project,
    getSearchTerm: (state) => state.searchTerm,
    getProjectItems: (state) => state.project.items,
    isLastPage: (state) => state.project.pagination.current_page >= state.project.pagination.last_page,
    getFilter: (state) => state.filter
  },
  mutations: {
    SET_SEARCH_TERM (state, payload) { state.searchTerm = payload },
    SET_FILTER (state, payload) { state.filter = payload },
    SET_PROJECT (state, payload) { state.project = payload },
    NEXT_PAGE (state) { state.project.pagination.current_page = state.project.pagination.current_page + 1 },
    INIT_PROJECT (state) {
      state.project = {
        filter: {},
        items: [],
        pagination: {
          current_page: 1,
          last_page: 5,
          per_page: 10
        }
      }
    }
  },
  actions: {
    async doSearch ({ commit, state }, [searchTermUpdated, filterUpdated]) {
      if (searchTermUpdated) {
        commit('INIT_PROJECT')
        state.filter = {}
        state.project.filter.search = state.searchTerm
      } else if (filterUpdated) {
        commit('INIT_PROJECT')
        state.project.filter = { search: state.searchTerm, ...state.filter }
      } else {
        commit('NEXT_PAGE')
      }
      const res = await ApiUtils.postData({
        data: {
          project: {
            api: 'C0101ProjectService.searchTerm',
            input: { filter: state.project.filter, pagination: state.project.pagination }
          }
        }
      })
      state.project.pagination = res.project.output.pagination
      state.project.items = [...state.project.items, ...res.project.output.data]
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
