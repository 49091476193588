export default [
  {
    path: '/404',
    name: '404',
    component: () => import('./404.vue')
  },
  {
    path: '/portalError',
    name: 'portalError',
    meta: { debug: true },
    component: () => import('./PortalError.vue')
  }
]
