import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
const BaseConst = {
  version: 1,
  storeName: 'AdminInvestorList',
  serviceName: 'C2301ProjectInvestmentInvestService',
  componentDef: {
    table: {
      viewDef: [
      ],
      optionDef: {}
    }
  }
}
const C1001Const = new BaseDataStore(BaseConst)
export default C1001Const
