import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'learn.public',
    path: 'cart',
    name: 'CartPage',
    component: () => import('./PgCart.vue'),
    meta: { title: i18n.t('m2701.cartPage.title'), authenticated: true }
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2',
    name: 'CartPagev2',
    component: () => import('./PgCartv2.vue')

  },
  {
    portalGroup: 'all.public',
    path: 'thank-you',
    name: 'ThankYouPage',
    component: () => import('./PgThankYou.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/debug',
    meta: { debug: true },
    name: 'cartDev',
    component: () => import('./PgcartDev.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/donation',
    name: 'CheckoutDonation',
    component: () => import('./MainCartComp/DonationMain.vue')
  },
  {
    portalGroup: 'collab.public',
    path: 'cartv2/crowdfund',
    name: 'CheckoutCrowdfund',
    component: () => import('./MainCartComp/Crowdfund.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/productdetail',
    name: 'CheckoutProductdetail',
    component: () => import('./MainCartComp/ProductDetail.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/contentclass',
    name: 'CheckoutContentclass',
    component: () => import('./MainCartComp/ContentClass.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/subscription',
    name: 'CheckoutSubscription',
    component: () => import('./MainCartComp/SubscriptionCart.vue')
  },
  {
    portalGroup: 'collab.public',
    path: 'cartv2/question',
    name: 'CheckoutQuestion',
    component: () => import('./MainCartComp/QuestionCart.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/UserUpgrade',
    name: 'UserUpgradeCheckoutdev',
    component: () => import('./MainCartComp/UserUpgradeCheckout.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/surveyData',
    name: 'SurveyDataCheckoutdev',
    component: () => import('./MainCartComp/SurveyDataCheckout.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/ProductCheckout',
    name: 'ProductCheckout',
    component: () => import('./MainCartComp/ProductCheckout.vue')
  },
  {
    portalGroup: 'press.public',
    path: 'cartv2/ServiceCheckout',
    name: 'ServiceCheckoutdev',
    component: () => import('./MainCartComp/ServiceCheckout.vue')
  },
  {
    portalGroup: 'all.public',
    path: 'invoice/:id',
    name: 'invoice',
    component: () => import('./PgInvoice.vue')
  },

]
