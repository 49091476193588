import BaseDataStore from '@/components/base/store/BaseDataStoreV2'
// import moment from 'moment'
const storeName = 'P0101ChoiceArticleSelect'
const serviceName = 'P0101ArticleService'

/*
    {
      column: 'col-6', // column size
      data: 'status', // data name
      type: 'select', // component type : text,textarea,number,checkbox,switch,radio,file,date,time,dateRange,select,autoComplete,combobox,rangeSlider,slider,ttc_category,ttc_tag,ttc_price,ttc_policy
      label: 'status', // label
      props: any // component props
    }
*/
const storeDef = {
  ACTION: {
    // base actions : doAdd, doEdit, getEdit, doBrowse, doDelete, getRead
    // custom actions
    // TODO: accept string without storeName/serviceName
    doBrowse: {
      store: storeName + '/doBrowse',
      api: serviceName + '.doBrowseForChoice'
    },
    addChoice: {
      store: storeName + '/addChoice',
      api: serviceName + '.addChoice'
    }
  },
  PROPERTY: {
    // base properties : row, rows, pagination, searchInfo, orderBy
    // custom properties

  }
}

const tableDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: 'Title', value: 'article_version_title' },
    { text: 'Author', value: 'author' },
    { text: 'Date', value: 'updated_at' },
    { text: 'Action', value: 'actions', sortable: false }
  ],
  optionDef: {}
}

const BaseConst = {
  version: 1,
  storeName,
  serviceName,
  storeConst: storeDef,
  componentDef: {
    table: tableDef

  }
}
const FnConst = new BaseDataStore(BaseConst)
export default FnConst
