import jQuery from 'jquery'
import ApiGuards from '@/utils/api/ApiGuards'
import NProgress from 'nprogress'

class ApiError extends Error {
  constructor (message) {
    super(message)
    this.name = 'ApiError'
  }

  getObject() {
    return JSON.parse(this.message)
  }
}


function _error (XMLHttpRequest, textStatus, err, resolve, reject, req) {
  NProgress.done()
  _removeRequest(_requests, req)
  ApiGuards.errorGuard(XMLHttpRequest, textStatus, err, resolve, reject)
  reject(new ApiError(JSON.stringify(XMLHttpRequest)))
}

function _success (ctx, textStatus, XMLHttpRequest, resolve, reject, req) {
  NProgress.done()
  _removeRequest(_requests, req)
  ApiGuards.successGuard(ctx, textStatus, XMLHttpRequest, resolve, reject)
  resolve(ctx.data)
}

function _removeRequest (requestArr, request) {
  const index = requestArr.indexOf(request)
  if (index > -1) {
    requestArr.splice(index, 1)
  }
}

let _requests = []

export default {
  ApiError,
  auth: (ctx, loading = true) => {
    return new Promise((resolve, reject) => {
      // Sets the url and prepare the data
      const url = window.API_URL + 'auth.api'
      const json = JSON.stringify(ctx, null, 2)

      // Pre-send Processing / Guard
      ApiGuards.preSendGuard(url, ctx, resolve, reject)

      // If nprogress loading is enabled
      if (loading) NProgress.start()

      // AJAX Request
      const req = jQuery.ajax({
        url,
        type: 'POST',
        data: json,
        xhrFields: {
          withCredentials: true
        },
        error: (XMLHttpRequest, textStatus, err) => {
          _error(XMLHttpRequest, textStatus, err, resolve, reject, req)
        },
        success: (ctx, textStatus, XMLHttpRequest) => {
          _success(ctx, textStatus, XMLHttpRequest, resolve, reject, req)
        }
      })
      _requests.push(req)
    })
  },
  getData: (api, params, loading = true) => {
    return new Promise((resolve, reject) => {
      // Sets the url and prepare the data
      let url = window.API_URL + 'data.api?api=' + api
      if (params) {
        params.forEach(function (val, key) {
          url = url + key + '=' + val
        })
      }

      // Pre-send Processing / Guard
      ApiGuards.preSendGuard(url, {}, resolve, reject)

      // If nprogress loading is enabled
      if (loading) NProgress.start()

      // AJAX Request
      const req = jQuery.ajax({
        url,
        dataType: 'jsonp',
        xhrFields: {
          withCredentials: true
        },
        error: (XMLHttpRequest, textStatus, err) => {
          _error(XMLHttpRequest, textStatus, err, resolve, reject, req)
        },
        success: (res, textStatus, XMLHttpRequest) => {
          const ctx = res.data[''].output
          _success(ctx, textStatus, XMLHttpRequest, resolve, reject, req)
        }
      })
      _requests.push(req)
    })
  },
  postData: (ctx, loading = true) => {
    return new Promise((resolve, reject) => {
      // Sets the url and prepare the data
      const url = window.API_URL + 'data.api'
      const json = JSON.stringify(ctx, null, 2)

      // Pre-send Processing / Guard
      ApiGuards.preSendGuard(url, ctx, resolve, reject)

      // If nprogress loading is enabled
      if (loading) NProgress.start()

      // AJAX Request
      console.debug(json)
      const req = jQuery.ajax({
        url,
        type: 'POST',
        data: json,
        xhrFields: {
          withCredentials: true
        },
        error: (XMLHttpRequest, textStatus, err) => {
          console.debug('XMLHttpRequest', XMLHttpRequest)
          console.debug(json)
          _error(XMLHttpRequest, textStatus, err, resolve, reject, req)
        },
        success: (ctx, textStatus, XMLHttpRequest) => {
          _success(ctx, textStatus, XMLHttpRequest, resolve, reject, req)
          console.debug(JSON.stringify(ctx, null, '\t'))
        }
      })
      _requests.push(req)
    })
  },
  upload: (ctx, loading = true) => {
    console.log(ctx)
    return new Promise((resolve, reject) => {
      // Sets the url and prepare the data
      const url = window.API_URL + 'upload.api'
      const formData = new FormData()
      formData.append('file', ctx, ctx.name)

      // AJAX Request
      const req = jQuery.ajax({
        url,
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false
      }).then(response => {
        const reader = new FileReader()
        reader.onload = () => resolve({
          ...response,
          url: reader.result
        })
        reader.onerror = reject
        reader.readAsDataURL(ctx)
      })
      _requests.push(req)
    })
  },
  download: (ctx) => {
    console.log(ctx)
    return new Promise((resolve, reject) => {
      // Sets the url
      const url = window.API_URL + 'download.api'

      // Sets a hidden send form element
      const sendForm = document.createElement('form')
      sendForm.action = url
      sendForm.method = 'POST'
      sendForm.style.visibility = 'hidden'
      sendForm.target = 'download'
      document.body.appendChild(sendForm)

      // Sets form data and send to download
      const fld1 = document.createElement('input')
      fld1.name = 'data'
      fld1.type = 'hidden'
      fld1.value = JSON.stringify(ctx)
      sendForm.appendChild(fld1)
      sendForm.submit()
    })
  },
  abortAll: () => {
    _requests.forEach((request) => {
      request.abort()
    })
    _requests = []
  }
}
