<template>
  <Loader
    :center-top-adjust="centerTopAdjust"
    :center-left-adjust="centerLeftAdjust"
    v-bind="$attrs"
  >
    <template #loader>
      <Lottie
        v-bind="$attrs"
        v-on="$listeners"
      />
    </template>
  </Loader>
</template>

<script>
// @ts-check

import Loader from '@/components/base/atoms/Loader/Loader.vue'
import Lottie from '@/components/base/atoms/Lottie/Lottie.vue'

/**
 * This is a wrapper component to display lottie animation as loader   
 * - Props options follows `Loader.vue` and `Lottie.vue` component
 * - Emits `animControl` event (Lottie Animation Object)  
 * 
 * To use, simply pass in `path` or `animationData` props of Lottie JSON
 * 
 * @external '@/components/base/Loader'
 * @external '@/components/base/atoms/Lottie'
 * 
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/20
 */
export default {
  name: 'LottieLoader',
  components: {
    Loader,
    Lottie
  },
  props: {
    centerTopAdjust: {
      type: [String, Number],
      default: '-100px',
      required: false
    },
    centerLeftAdjust: {
      type: [String, Number],
      default: '-100px',
      required: false
    }
  }
}
</script>
