import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'
const storeName = 'P0101Article'
const serviceName = 'P0101ArticleService'
// import moment from 'moment'
const listDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('samples.layout4.title'), value: 'title' },
    // use type: 'date' to format date
    { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
    // use type: 'enum' to format enum
    {
      text: 'Gender',
      value: 'gender',
      type: 'chip',
      items: [
        { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
        { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
        { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
      ]
    },
    { text: 'comments', value: 'comment_count' },
    { text: 'post_type', value: 'post_type' },
    { text: 'views', value: 'views' },
    { text: 'Custom (comments+views)', value: 'cus' },
    { text: 'Action', value: 'actions', sortable: false }
  ],
  optionDef: {}
}
const BaseConst = {
  version: 1,
  storeName: 'P0101Article',
  serviceName: 'P0101ArticleService',
  storeConst: {
    ACTION: {
      // base actions : doAdd, doEdit, getEdit, doBrowse, doDelete, doRead
      // custom actions
      // TODO: accept string without storeName/serviceName
      // doBrowse is to render data in the table
      init: {
        store: storeName + '/init'
      },
      doBrowse: {
        store: storeName + '/doBrowse',
        api: serviceName + '.doBrowseWithDetail'
      },
      doBrowseComments: {
        store: storeName + '/doBrowseComments',
        api: serviceName + '.doBrowseComments'
      },
      doBrowseVersion: {
        store: storeName + '/doBrowseVersion',
        api: 'P0201ArticleVersionService.doBrowse'
      },
      doBrowseIncomeStatistic: {
        store: storeName + '/doBrowseIncomeStatistic',
        api: serviceName + '.doBrowseIncomeStatistic'
      },
      doApprove: {
        store: storeName + '/doApprove',
        api: serviceName + '.doApprove'
      },
      getEdit: {
        store: storeName + '/getEdit',
        api: serviceName + '.getEditDetailByVersionId'
      },
      getRead: {
        store: storeName + '/getRead',
        api: serviceName + '.getReadDetailByVersionId'
      },
      doEdit: {
        store: storeName + '/doEdit',
        api: serviceName + '.doSaveDetail'
      },
      getStats: {
        store: storeName + '/getStats',
        api: serviceName + '.getMyActiclsStats'
      },
      // doBrowsemainstate is to get Status (DRAFT, PUBLISHED....)
      doBrowsemainstate: {
        store: 'P0101Article/doBrowsemainstate',
        api: 'P0101ArticleService.getMyActiclsStats'
      },
      myAction: {
        store: 'P0101Article/myAction',
        api: 'P0101ArticleService.myAction'
      }
    },
    PROPERTY: {
      // base properties : row, rows, pagination, searchInfo, orderBy
      // Main state
      stats: {
        get: 'P0101Article/getStats',
        set: 'P0101Article/SET_STATS'
      },
      // Comments
      getComments: {
        get: 'P0101Article/getComments',
        set: 'P0101Article/SET_COMMENTS'
      },
      // Comments
      getVersion: {
        get: 'P0101Article/getVersion',
        set: 'P0101Article/SET_Version'
      },
      myProp: {
        get: 'P0101Article/getMyProp',
        set: 'P0101Article/SET_MY_PROP'
      }
    }
  },
  // DELETE: no use cases
  PAGE_NAME: 'P0101DataMaintain',
  // target def for './component'
  //  if search -> search component
  componentDef: {
    list: listDef,
    search: {
      viewDef: [
        {
          column: 'col-sm-3',
          data: 'title',
          type: 'text',
          label: 'Search Title'
        },
        {
          column: 'col-sm-3',
          data: 'description',
          type: 'text',
          label: 'Search description'
        },
        {
          column: 'col-sm-3',
          data: {
            get (val) {
              return [val.created_at_start ?? null, val.created_at_end ?? null]
            },
            set (val, inputVal) {
              val.created_at_start = inputVal[0]
              val.created_at_end = inputVal[1]
              return val
            }
          },
          type: 'dateRange',
          label: 'Created At'
        },
        {
          column: 'col-sm-3',
          data: 'created_by',
          type: 'select',
          label: 'Created By',
          props: {
            clearable: true,
            items: [],
            'item-text': 'name_en',
            'item-value': 'id'
          },
          // get data from api
          async: [
            {
              // api: 'E0001DemoPostService.getCreatedBy',
              api: 'P0101ArticleService.doBrowseWithDetail',
              mutateTarget: 'props.items'
            }
          ]
        }
      ],
      optionDef: {}
    },
    form: {
      /*
       type: div, row, col, form, text,file,select, button,table
       component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
       rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewEdit: [
        {
          column: 'col-sm-12',
          data: 'article_version_title',
          type: 'text',
          rules: 'required',
          label: 'Article Title',
          labelHint: 'Article Title'
        },
        {
          column: 'col-sm-12',
          data: 'article_version_subtitle',
          type: 'text',
          rules: 'required',
          label: 'Subtitle',
          labelHint: 'Article Title'
        },
        {
          column: 'col-sm-12',
          data: '',
          type: 'file',
          rules: 'required',
          label: 'Featured Image',
          labelHint: 'Featured Image',
          props: {
            accept: 'image/*',
            maxFiles: 1
          }
        },
        {
          column: 'col-sm-12',
          data: '',
          type: 'textarea',
          label: 'Article',
          labelHint: 'Article'
        }
      ],
      viewSettings: [
        {
          column: 'col-sm-11',
          data: 'm0601_id',
          type: 'ttc_category',
          rules: 'required',
          label: 'Category',
          labelHint: 'Category',
          props: {
            label: i18n.t('components.TTCCategoryField.category'),
            outlined: true
          }
        },
        {
          column: 'col-sm-1',
          custom: 'CustomButton',
          date: 'category'
        },
        {
          column: 'col-sm-11',
          data: 'tags',
          type: 'ttc_tag',
          rules: 'required',
          label: 'Tags(Max 5)',
          labelHint: 'Tags(Max 5)',
          props: {
            label: i18n.t('components.TTCTagField.tags'),
            outlined: true,
            chips: true,
            multiple: true,
            counter: 5
          }
        },
        {
          column: 'col-sm-1',
          custom: 'CustomButton',
          date: 'tags'
        },
        {
          column: 'col-sm-11',
          data: 'article_is_paid',
          type: 'checkbox',
          props: {
            items: [
              { id: 1, name: 'Make as Premium Article' }
            ],
            itemText: 'name',
            itemValue: 'id'
          },
          label: 'Premium Article',
          labelHint: 'Premium Article'
        },
        {
          column: 'col-sm-1',
          custom: 'CustomButton',
          date: ''
        }
      ],
      viewSEO: [
        {
          column: 'col-sm-11',
          data: 'article_seo_title',
          type: 'text',
          label: 'SEO Title',
          labelHint: 'SEO Title'
        },
        {
          column: 'col-sm-1',
          custom: 'CustomButton',
          date: 'article_seo_title'
        },
        {
          column: 'col-sm-11',
          data: 'article_seo_desc',
          type: 'text',
          label: 'SEO Description',
          labelHint: 'SEO Description'
        },
        {
          column: 'col-sm-1',
          custom: 'CustomButton',
          date: 'article_seo_desc'
        }
      ],
      optionDef: {}
    },
    Revisions: {
      viewDef: [
        { text: 'Revision', value: 'article_version_remark', sortable: false },
        { text: 'ReMarks', value: 'article_version_status', sortable: false },
        { text: 'Date', value: 'created_at', type: 'relativeDate', sortable: false },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      optionDef: {}
    },
    table: {
      viewDef: [
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'title' },
        // use type: 'date' to format date
        { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
        // use type: 'enum' to format enum
        {
          text: 'Gender',
          value: 'gender',
          type: 'chip',
          items: [
            { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
            { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
            { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
          ]
        },
        { text: 'comments', value: 'comment_count' },
        { text: 'post_type', value: 'post_type' },
        { text: 'views', value: 'views' },
        { text: 'Custom (comments+views)', value: 'cus' },
        { text: 'Action', value: 'actions', sortable: false }
      ],
      optionDef: {}
    },
    userDraft: {
      viewDef: [
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' }
      ],
      optionDef: {}
    },
    userPublished: {
      viewDef: [
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' }
      ],
      optionDef: {}
    },
    userComments: {
      viewDef: [
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' }
      ],
      optionDef: {}
    },
    userArchived: {
      viewDef: [
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'id', value: 'id' },
        { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
        { text: 'subtitle', value: 'article_version_subtitle' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' },
        { text: 'status', value: 'article_version_status' },
        { text: 'tags', value: 'tags' }
      ],
      optionDef: {}
    },
    view: {
      viewDef: [
        { text: i18n.t('samples.layout4.title'), value: 'title' },
        { text: 'desc', value: 'description' },
        { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
        { text: 'Thumbnail', value: 'thumbnail', type: 'image' },
        { text: 'Attachments', value: 'attachments', type: 'files' }
      ],
      optionDef: {}
    },
    draftSearch: {
      viewDef: [
        {
          article_version_status: {
            column: 'col-sm-3',
            component: 'v-select',
            vModel: null,
            props: {
              label: 'Status',
              items: ['', 'DRAFT', 'Pending Approval', 'Approved', 'Amendment Required'],
              itemValue: 'id',
              itemText: 'translated_category_name',
              autoSelectFirst: true
            },
            data: 'article_version_status'
          },
          m0601_id: {
            column: 'col-sm-4',
            type: 'category'
          },
          onpromotion: {
            column: 'col-sm-4',
            component: 'AtCheckbox',
            data: 'article_is_paid',
            props: {
              label: '',
              items: ['On promotion'],
              itemsPerRow: { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 },
              multiple: false
            }
          }
        }
      ],
      optionDef: {}
    },
    publishedSearch: {
      viewDef: [
        {
          m0601_id: {
            column: 'col-sm-4',
            type: 'category'
          },
          onpromotion: {
            column: 'col-sm-4',
            component: 'AtCheckbox',
            data: 'article_is_paid',
            props: {
              label: '',
              items: ['On promotion'],
              itemsPerRow: { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 },
              multiple: false
            }
          },
          hasdraft: {
            column: 'col-sm-4',
            component: 'AtCheckbox',
            data: 'article_version_status',
            props: {
              label: '',
              items: ['Has Draft/Unpublished Changes'],
              itemsPerRow: { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 },
              multiple: true
            }
          }
        }
      ],
      optionDef: {}
    },
    commentsSearch: {
      viewDef: [
        {
          response_status: {
            column: 'col-sm-6',
            component: 'v-select',
            vModel: null,
            props: {
              label: 'Response Status',
              items: ['', "I haven't Responded", 'Responded', 'New Responses'],
              itemValue: 'id',
              itemText: 'translated_category_name',
              autoSelectFirst: true
            }
          },
          user_follower_count: {
            column: 'col-sm-6',
            component: 'v-select',
            vModel: null,
            props: {
              label: 'User Follower Count',
              items: ['', '>=100', '>=500', '>=1k', '>=5k'],
              itemValue: 'id',
              itemText: 'translated_category_name',
              autoSelectFirst: true
            }
          }
        }
      ],
      optionDef: {}
    },
    archivedSearch: {
      viewDef: [
        {
          article_version_status: {
            column: 'col-sm-4',
            component: 'v-select',
            vModel: null,
            props: {
              label: 'Status',
              items: ['', 'Rejected', 'Removed', 'Deleted', 'Pending Appeal'],
              itemValue: 'id',
              itemText: 'translated_category_name',
              autoSelectFirst: true
            }
          },
          appealavailable: {
            column: 'col-sm-4',
            component: 'AtCheckbox',
            data: 'article_version_status',
            props: {
              label: '',
              items: ['Appeal Available'],
              itemsPerRow: { xs: 1, sm: 2, md: 3, lg: 3, xl: 3 },
              multiple: true
            }
          },
          m0601_id: {
            column: 'col-sm-4',
            type: 'category'
          }
        }
      ],
      optionDef: {}
    }
  }
}

const P0101Const = new BaseDataStore(BaseConst)
export default P0101Const
