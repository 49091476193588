import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const statusMap={
  DRAFT: {
    label: 'Draft',
    color: 'grey'
  }
}

function getStatusMapArray(){
  return Object.keys(statusMap).map(key => {
    return {
      value: key,
      text: statusMap[key].label,
      color: statusMap[key].color
    }
  })
}

const PageConst = {
  FORM_DEF: {
    editDef: [
      { name: 'name', label: 'Name', type: 'text', required: true },
      { name: 'description', label: 'Description', type: 'textarea', required: false }
    ]
  },
  SEARCH_DEF: {
    inputDef: [
      {
        column: 'col-12',
        data: 'title',
        type: 'text',
        label: 'Title',
        props: {
          clearable: true
        }
      },
      {
        column: 'col-4',
        data: 'status',
        type: 'autoComplete',
        label: 'Status',
        props: {
          items: getStatusMapArray(),
          itemText: 'text',
          itemValue: 'value',
          clearable: true
        }
      },
    ],
    sortDef:[
      {
        text: '最新',
        value: {order_created_at: 'DESC'}
      },
      {
        text: '最舊',
        value: {order_created_at: 'ASC'}
      }
    ],
  },
  LIST_DEF: {
    tableDef: [
    ]
  },
  ACTION: {
    // doBrowse: { api: 'M3501BookmarksService.doBrowse_user_bookmarks' },
  },
}
const module = {
  state: {
    PageConst ,

    statusMap,
    rows:[
      {
        id: 1,
        title: 'Project 1',
      },
      {
        id: 2,
        title: 'Project 2',
      },
      {
        id: 3,
        title: 'Project 3',
      }
    ],
    searchInfo:{
    },
    portal: {
    },
    // set default here
    orderBy:PageConst.SEARCH_DEF.sortDef[0].value,
  },

  getters: {
    getPortal (state) {
      return state.portal ?? null
    }
  },

  mutations: {
    SET_PORTAL (state, payload) {
      state.portal = payload
    },
  },
  actions: {
    // JJ did
    async doBrowse ({ commit, state }, payload) {
      const doBrowseStatusEnum = {
        init: 'init',
        loading: 'loading',
        noData: 'noData',
        loaded: 'loaded',
        error: 'error',
        loading_nextPage: 'loading_nextPage',
        loaded_nextPage: 'loaded_nextPage',
        error_nextPage: 'error_nextPage'
      }
      state.doBrowseStatus = doBrowseStatusEnum.loading
      try{
        const val = {
          portal: state.portal,
          searchInfo: state.searchInfo,
          orderBy: state.orderBy,
          pagination: { current_page: 1, per_page: state.pagination.per_page }
        }
        const res = await ApiUtils.postData({ data: [{ api: 'M3501BookmarksService.doBrowse_user_bookmarks', input: val }] })
        state.rows = res[0].output.data
        state.pagination = res[0].output.pagination
        state.doBrowseStatus = res[0].output.pagination.total > 0 ? doBrowseStatusEnum.loaded : doBrowseStatusEnum.noData
      } catch (e) {
        // state[targetKeyName('doBrowseStatus')] = doBrowseStatusEnum.error
        // throw e
      }
    },
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
