import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
// import i18n from '@/plugins/i18n'
// import moment from 'moment'
const BaseConst = {
  version: 1,
  storeName: 'M0601Category',
  serviceName: 'M0601CategoryService',
  storeConst: {
    ACTION: {
      doBrowse: {
        store: 'M0601Category/doBrowse',
        api: 'M0601CategoryService.admin_doBrowse'
      },
      getEdit: {
        store: 'M0601Category/getEdit',
        api: 'M0601CategoryService.admin_getEdit'
      }
    }
  },
  // target def for './component'
  //  if search -> search component
  componentDef: {
    search: {
      viewDef: [
        {
          column: 'col-sm-3',
          data: 'category_seo_url',
          type: 'text',
          label: '關鍵字'
        },
        {
          column: 'col-sm-3',
          data: 'category_status',
          type: 'select',
          label: '狀態',
          props: {
            items: [
              { value: 'A', text: '啟用' },
              { value: 'H', text: '隱藏' },
              { value: 'I', text: '無效' }
            ]
          }
        }
      ],
      optionDef: {}
    },
    form: {
      /*
        type: div, row, col, form, text,file,select, button,table
        component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
        rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewDef: [
        // {
        //   column: 'col-md-6',
        //   type: 'translations',
        //   data: 'translations',
        //   label: '翻譯',
        //   props: {
        //     languages: [
        //       { id: 1, name: 'English', code: 'en-US' },
        //       { id: 2, name: 'Chinese Simplified', code: 'zh-CN' },
        //       { id: 3, name: 'Chinese Traditional', code: 'zh-HK' },
        //       { id: 4, name: 'Japanese', code: 'ja-JP' }
        //     ]
        //   }
        // }

      ],
      optionDef: {}
    },
    table: {
      viewDef: [
        // { value: 'id', text: 'id' },
        // { value: 'category_seo_url', text: '關鍵字' },
        { value: 'category_name', text: '名字' },
        {
          value: 'category_key',
          text: '種類',
          type: 'chip',
          items: [
            // general use
            // report use (report user)
            { value: 'GENERAL', label: '通用', color: 'green' },
            { value: 'REPORT', label: '舉報', color: 'blue' }
          ]
        },
        {
          value: 'category_status',
          text: '狀態',
          type: 'chip',
          items: [
            { value: 'A', label: '啟用', color: 'green' },
            { value: 'H', label: '隱藏', color: 'grey' },
            { value: 'I', label: '無效', color: 'red' }
          ]
        },
        { value: 'actions', text: '' }

      ],
      optionDef: {}
    },
    view: {
      viewDef: [
        // { text: i18n.t('samples.layout4.title'), value: 'title' },
        // { text: 'desc', value: 'description' },
        // { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
        // { text: 'Thumbnail', value: 'thumbnail', type: 'image', attrs: { style: 'max-width:90vw' } },
        // { text: 'Attachments', value: 'attachments', type: 'files' }

      ],
      optionDef: {}
    }
  }
}
const M0601Const = new BaseDataStore(BaseConst)
export default M0601Const
