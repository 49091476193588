import ApiUtils from '@/utils/api/ApiUtils'
import quizScoreConst from './QuizScoreConst'

const store = {
  state: {
    l0301_list: [],
    content_title: null,

    l0601_list: [],
    l0301_info: [],

    l0802_latest_list: [],
    l0601_info: [],

    l0802_all_quiz_attempts_list: []
  },

  mutations: {
    SET_CLASS_LIST (state, payload) {
      state.l0301_list = payload
    },
    SET_CONTENT_TITLE (state, payload) {
      state.content_title = payload
    },
    SET_CONTENT_SECTION_CONTENT_LIST (state, payload) {
      state.l0601_list = payload
    },
    SET_CLASS_INFO (state, payload) {
      state.l0301_info = payload
    },
    SET_LATEST_PROGRESS_QUIZ (state, payload) {
      state.l0802_latest_list = payload
    },
    SET_CONTENT_SECTION_CONTENT_INFO (state, payload) {
      state.l0601_info = payload
    },
    SET_ALL_QUIZ_ATTEMPTS_LIST (state, payload) {
      state.l0802_all_quiz_attempts_list = payload
    }
  },

  actions: {
    async getAllClassInfo ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0301ContentClassService.getAllClassInfo',
          input: payload

        }]
      }).then((data) => {
        commit('SET_PAGINATION', data[0].output[0].pagination)
        commit('SET_CLASS_LIST', data[0].output[0].data)
        commit('SET_CONTENT_TITLE', data[0].output[1])
      }).catch((e) => {
        return null
      })
    },
    async getL0601AndL0801ForQuizScore ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0601ContentSectionContentService.getL0601AndL0801ForQuizScore',
          input: payload

        }]
      }).then((data) => {
        commit('SET_PAGINATION', data[0].output[0].pagination)
        commit('SET_CONTENT_SECTION_CONTENT_LIST', data[0].output[0].data)
        commit('SET_CLASS_INFO', data[0].output[1])
      }).catch((e) => {
        return null
      })
    },
    async getScoreLatestAttempts ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0802ContentSectionContentProgressQuizService.getScoreLatestAttempts',
          input: payload

        }]
      }).then((data) => {
        commit('SET_PAGINATION', data[0].output[0].pagination)
        commit('SET_LATEST_PROGRESS_QUIZ', data[0].output[0].data)
        commit('SET_CONTENT_SECTION_CONTENT_INFO', data[0].output[1])
      }).catch((e) => {
        return null
      })
    },
    async getScoreAllAttempts ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0802ContentSectionContentProgressQuizService.getScoreAllAttempts',
          input: payload

        }]
      }).then((data) => {
        commit('SET_PAGINATION', data[0].output.pagination)
        commit('SET_ALL_QUIZ_ATTEMPTS_LIST', data[0].output.data)
      }).catch((e) => {
        return null
      })
    },
    async doSave ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0801ContentSectionContentProgressService.doSave',
          input: payload

        }]
      }).catch((e) => {
        return null
      })
    },
    async doUpdate_passingPercentage ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0601ContentSectionContentService.doUpdate_passingPercentage',
          input: payload

        }]
      }).catch((e) => {
        return null
      })
    },
    async doUpdate_totalScore ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0601ContentSectionContentService.doUpdate_totalScore',
          input: payload

        }]
      }).catch((e) => {
        return null
      })
    },
    async voidQuizAttempt ({ state, commit }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: 'L0801ContentSectionContentProgressService.voidQuizAttempt',
          input: payload

        }]
      }).catch((e) => {
        return null
      })
    },
    async getData_goToClassOrContentList ({ state, commit }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'L0101ContentService.getData_goToClassOrContentList',
          input: payload

        }]
      }).catch((e) => {
        return null
      })

      return data[0].output[0]
    }
  }
}

export default quizScoreConst.getStore(store)
