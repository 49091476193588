<template>
  <v-card class="full-width">
    <v-data-table
      :headers="_cols"
      :items="rows"
      :options.sync="_options"
      :server-items-length="_total"
      v-bind="{...defaultProps,...$attrs}"
      v-on="$listeners"
    >
      <template
        v-for="header in _colHeaderSlots"
        #[`header.${header.value}`]="{ item }"
      >
        <slot
          :name="'header_'+header.value"
          :item="item"
        >
          {{ header.text }}
        </slot>
      </template>
      <template
        v-for="header in _colItemSlots"
        #[`item.${header.value}`]="{ item }"
      >
        <slot
          :name="'item_'+header.value"
          :item="item"
        >
          <div
            v-if="!header.type"
            :key="'item_'+header.value"
          >
            {{ item[header.value] }}
          </div>
          <d-warp
            v-else
            :key="'item_'+header.value"
            :value="item[header.value]"
            v-bind="header"
          />
        </slot>
      </template>
      <template #expanded-item="data">
        <td :colspan="data.headers.length">
          <slot
            name="expanded_item"
            v-bind="data"
          />
        </td>
      </template>
      <template #no-results>
        <slot name="no-results"/>
      </template>
      <template #no-data>
        <slot name="no-data"/>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import DWarp from '../DWarp/DWarp.vue'
import AppUtils from '@/utils/AppUtils'
import DataUtils from '@/utils/DataUtils'
export default {
  components: {
    DWarp
  },
  props: {
    // Vuetify Datatable format
    // [
    //   {value: 'min_data' }
    //   { text: 'id', value: 'id' },
    //   { text: 'title', value: 'title' },
    //   { text: 'admin', value: 'admin_only', roles: ['admin'] },
    //   { text: 'Action', value: 'actions', sortable: false }
    // ]
    cols: {
      type: Array,
      required: true
    },
    /**
     * Actual Data
     */
    rows: {
      type: Array,
      default: () => { return [] }
    },
    /**
     * DataTable Definetions
     */
    value: {
      type: Object,
      default: () => {
        return {
          orderBy: {},
          pagination: {
            current_page: 1,
            last_page: 0,
            per_page: 10,
            total: null
          }
        }
      }
    },
    defaultProps: {
      type: Object,
      default: () => {
        return {
          footerProps: {
            showFirstLastPage: true,
            itemsPerPageOptions: [10, 20, 30, 40, 50]
          }
        }
      }
    },
    /**
     * When the user clicks on ay action, scroll to the top of the document
     */
    scrollTop: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      oldOpt: {}
    }
  },
  computed: {
    _total () {
      if (this.value.pagination !== undefined && this.value.pagination.total !== undefined) return this.value.pagination.total
      return 0
    },
    _cols () {
      // auth, role, permission
      let result = this.cols.filter(element => AppUtils.allowAccess(element.authenticated ?? false, element.roles ?? [], element.permissions ?? []))

      //  `show` key
      for (let i = 0; i < result.length; i++) {
        const element = result[i]

        // if show is not defined, show it
        if (!('show' in element)) {
          result[i]._show = true
          continue
        }
        // if show is boolean, show it
        if (typeof element.show === 'boolean' && element.show) {
          result[i]._show = true
          continue
        }
        // if show is function, if return true, show it
        if (typeof element.show === 'function') {
          if (element.show(this)) {
            result[i]._show = true
            continue
          }
        }

        result[i]._show = false
      }

      // filter out _show
      result= result.filter(c => c._show)


      for (let i = 0; i < result.length; i++) {
        const element = result[i]
        // check min required key
        if (!('value' in element)) {
          console.error(`AtDataTable.vue ~ Computed ~ _cols[${i}] ~ value is not defined`)
          continue
        }
        const defaultHeader = {
          text: element.value
        }

        result[i] = DataUtils.mergeObjects(defaultHeader, element)
      }

      return result
    },
    _colItemSlots () {
      return this._cols.filter(c => !c.useDefaultItemSlot)
    },
    _colHeaderSlots () {
      return this._cols.filter(c => !c.useDefaultHeaderSlot)
    },
    /**
     * options
     * {
     *  page: number,
     *  itemsPerPage: number,
     *  sortBy: string[],
     *  sortDesc: boolean[],
     *  groupBy: string[],
     *  groupDesc: boolean[],
     *  multiSort: boolean,
     *  mustSort: boolean
     *}
     */
    _options: {
      get () {
        return utils.base2Options(this.value)
      },
      set (val) {
        if (JSON.stringify(val) === JSON.stringify(this.oldOpt)) return

        const baseOpt = utils.options2Base(val, this._total)

        const resetPage = utils.checkResetPage(val, this.oldOpt)

        if (resetPage) baseOpt.pagination.current_page = 1

        if (this.scrollTop) document.body.scrollTop = document.documentElement.scrollTop = 0

        this.$emit('input', baseOpt)
        this.$emit('search', baseOpt, this)
        this.oldOpt = val
      }
    }
  }
}

const utils = {
  orderKeyPrefix: 'order_',
  /**
   * convert vuetify datatable options to base(OLE)
   * https://vuetifyjs.com/en/api/v-data-table/#props
   *
   * options
   * {
   *  page: number,
   *  itemsPerPage: number,
   *  sortBy: string[],
   *  sortDesc: boolean[],
   *  groupBy: string[],
   *  groupDesc: boolean[],
   *  multiSort: boolean,
   *  mustSort: boolean
   *}
   *
   */
  options2Base (options, total) {
    const {
      page, itemsPerPage, sortBy, sortDesc
      // ,groupBy, groupDesc, multiSort, mustSort
    } = options
    const _orderBy = {}
    sortBy.forEach((item, index) => {
      _orderBy[utils.orderKeyPrefix + item] = sortDesc[index] ? 'desc' : 'asc'
    })

    return {
      orderBy: _orderBy,
      pagination: {
        current_page: page,
        per_page: itemsPerPage,
        total
      }
    }
  },
  checkResetPage (newOpt, oldOpt) {
    if (!newOpt && !oldOpt) return false
    const filterObjKey = function (obj, keys) {
      if (obj == null) return {}
      return Object.fromEntries(
        Object.entries(obj).filter(([key]) => keys.includes(key))
      )
    }
    // effect key list
    const effectKeyList = ['itemsPerPage', 'sortDesc', 'sortBy']

    const newFOpt = filterObjKey(newOpt, effectKeyList)
    const oldFOpt = filterObjKey(oldOpt, effectKeyList)

    return !(JSON.stringify(newFOpt) === JSON.stringify(oldFOpt))
  },
  base2Options: (baseOpt) => {
    const { orderBy, pagination } = baseOpt
    const vuetifyOptions = { sortBy: [], sortDesc: [] }

    if (orderBy) {
      for (const [key, value] of Object.entries(orderBy)) {
        vuetifyOptions.sortBy.push(key.replace(utils.orderKeyPrefix, ''))
        vuetifyOptions.sortDesc.push(value === 'desc')
      }
    }

    if (pagination) {
      vuetifyOptions.page = pagination.current_page ?? 1
      vuetifyOptions.itemsPerPage = pagination.per_page
    }

    return vuetifyOptions
  }
}
</script>

<style scoped>
</style>
