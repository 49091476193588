export default [
  {
    portalGroup: 'master.public',
    path: 'policy',
    name: 'S0901PolicyList',
    component: () => import('./PolicyList.vue')
  },
  {
    portalGroup: 'admin.admin',
    path: 'policy/edit',
    // path: 'policy/',
    name: 'S0901Edit',
    component: () => import('./Edit.vue')
  },
  {
    portalGroup: 'master.admin',
    path: 'policy/add',
    // path: 'policy/',
    name: 'S0901Add',
    component: () => import('./AddPolicy.vue')
  },
  {
    portalGroup: 'master.public',
    path: 'policy/:slug/:version?',
    name: 'S0901View',
    component: () => import('./PgS0901View.vue')
  },
  {
    portalGroup: 'admin.admin',
    path: 'policy/creator',
    name: 'PolicyCreator',
    component: () => import('./PolicyCreater.vue')
  },
]
