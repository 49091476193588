import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
// import i18n from '@/plugins/i18n'


const module = {
  state: {
    /**
     * Required to display UI and trigger tions according to the user using the chat
     * (ex: messages position on the right, etc.)
     */
    currentUserId: null,
    currentRoomId: null,
    totalUnreadCount: 0,
    rooms: []
  },
  getters: {
    getCurrentUserId (state) {
      return state.currentUserId
    },
    getTotalUnreadCount (state) {
      return state.totalUnreadCount
    },
    getRooms (state) {
      return state.rooms
    },
    getCurrentRoomId (state) {
      return state.currentRoomId
    },
    getCurrentRoom (state) {
      if (!state.currentRoomId) {
        return {
          id: null,
          chatroom_type: null,
          messages: []
        }
      }
      return state.rooms.find(room => room.id === state.currentRoomId)
    }
  },

  mutations: {
    SET_CURRENT_USER_ID (state, payload) {
      state.currentUserId = payload
    },
    SET_TOTAL_UNREAD_COUNT (state, payload) {
      state.totalUnreadCount = payload
    },
    SET_ROOMS (state, payload) {
      const data = payload[0]
      const currentRoomId = payload[1]

      // if pass room id thru route then display that room first
      if (currentRoomId) {
        // Find the index of the object with id based on currentRoomId
        const index = data.findIndex(data => data.id === currentRoomId)

        // If the object with the correct id is found, move it to the front
        if (index !== -1) {
          const movedItem = data.splice(index, 1)[0]
          data.unshift(movedItem)
        }
      }

      state.rooms = data.map(room => {
        room.messages = room.messages
          ? room.messages.map(
            message => {
              message.distributed = true
              return message
            }
          )
          : []
        return room
      })
    },
    SET_CURRENT_ROOM (state, payload) {
      state.currentRoomId = payload
    },
    UPDATE_ROOM (state, payload) {
      const roomIndex = state.rooms.findIndex(room => room.id === payload.id)
      if (roomIndex === -1) {
        console.log('room not found')
        return
      }
      state.rooms[roomIndex] = {
        ...state.rooms[roomIndex],
        ...payload
      }
      state.rooms = [...state.rooms]
    },
    SET_MESSAGES (state, payload) {
      const roomIndex = state.rooms.findIndex(room => room.id === state.currentRoomId)
      if (roomIndex !== -1) {
        state.rooms[roomIndex].messages = payload
      }
    },
    SET_MESSAGES_UNSHIFT (state, payload) {
      const roomIndex = state.rooms.findIndex(room => room.id === state.currentRoomId)
      if (roomIndex !== -1) {
        state.rooms[roomIndex].messages = [...payload, ...state.rooms[roomIndex].messages]
      }
    },
    EDIT_MESSAGE (state, payload) {
      const roomIndex = state.rooms.findIndex(room => room.id === payload.m0901_id)
      if (roomIndex === -1) {
        console.log('room not found')
        return
      }
      let msgIndex = state.rooms[roomIndex].messages.findIndex(message => message.id === payload.id)
      if (msgIndex === -1) {
        msgIndex = state.rooms[roomIndex].messages.findIndex(message => message.localId === payload.localId)
      }
      if (msgIndex === -1) {
        console.log('msg not found' + payload.localId)
        return
      }
      state.rooms[roomIndex].messages[msgIndex] = {
        ...state.rooms[roomIndex].messages[msgIndex],
        ...payload
      }
      state.rooms[roomIndex].messages = [...state.rooms[roomIndex].messages]
    },
    SET_MESSAGES_PUSH (state, payload) {
      const roomIndex = state.rooms.findIndex(room => room.id === payload.m0901_id)
      if (roomIndex === -1) {
        console.log('room not found')
        return
      }
      state.rooms[roomIndex].messages = [...state.rooms[roomIndex].messages, payload]
    },
    // handler for incoming message using websocket
    INCOMING_MESSAGE (state, payload) {
      const roomIndex = state.rooms.findIndex(room => room.id === payload.m0901_id)
      if (roomIndex === -1) {
        console.log('room not found')
        return
      }
      let msgIndex = -1
      msgIndex = state.rooms[roomIndex].messages.findIndex(message => message.id === payload.id)

      if (msgIndex === -1) {
        // push new message
        state.rooms[roomIndex].messages = [...state.rooms[roomIndex].messages, payload]
      } else {
        // update message
        state.rooms[roomIndex].messages[msgIndex] = {
          ...state.rooms[roomIndex].messages[msgIndex],
          ...payload
        }
        state.rooms[roomIndex].messages = [...state.rooms[roomIndex].messages]
      }
      // update room
      state.rooms[roomIndex] = {
        ...state.rooms[roomIndex],
        unread_messages: state.rooms[roomIndex].unread_messages + 1,
        last_message_content: payload.chat_message,
        last_message_created_at: payload.created_at,
        last_message_created_by: payload.created_by
      }
      state.totalUnreadCount++
      state.rooms = [...state.rooms]
    }
  },

  actions: {
    init: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M0901ChatroomService.browse',input:payload},
          { api: 'M1002ChatRecipientService.getTotalUnreadCount' }
        ]
      }).then(data => {
        commit('SET_ROOMS', [data[0].output])
        commit('SET_TOTAL_UNREAD_COUNT', data[1].output)
      })
    },
    // moreChatroom: ({ state, commit }, payload) => {},
    // filterChatroom: ({ state, commit }, payload) => {},

    browseMessages: ({ state, commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M1001ChatService.browse', input: payload }]
      }).then(data => {
        payload.offset
          ? commit('SET_MESSAGES_UNSHIFT', data[0].output)
          : commit('SET_MESSAGES', data[0].output)
        return data[0].output
      })
    },

    readMessages: ({ state, commit }, _) => {
      const payload = { m0901_id: state.currentRoomId }
      const currentUnreadCount = state.rooms.find(room => room.id === state.currentRoomId).unread_messages
      if (currentUnreadCount === 0) return
      return ApiUtils.postData({ data: [{ api: 'M1002ChatRecipientService.markAsRead', input: payload }] })
        .then(data => {
          commit('UPDATE_ROOM', { id: state.currentRoomId, unread_messages: 0 })
          commit('SET_TOTAL_UNREAD_COUNT', state.totalUnreadCount - currentUnreadCount)
        })
    },
    sendMessage: ({ state, commit, dispatch }, payload) => {
      return ApiUtils.postData({
        data: [{
          api: 'M1001ChatService.doSave',
          input: payload
        }]
      }).then(data => {
        const chat = {
          ...data[0].output[0],
          localId: payload.localId,
          distributed: true
        }
        commit('EDIT_MESSAGE', chat)
      }).catch(error => {
        console.log(error)
        const chat = {
          ...payload,
          failure: true,
          distributed: true
        }
        commit('EDIT_MESSAGE', chat)
      })
    },
    editMessage: ({ state, commit, dispatch }, payload) => {
      return ApiUtils.postData({
        data: [{
          api: 'M1001ChatService.doSave',
          input: payload
        }]
      })
      // .then(data => {
      //   const chat = data[0].output
      //   // commit('EDIT_MESSAGE', chat)
      // })
    },
    deleteMsg: async ({ state, commit }, msg) => {
      await ApiUtils.postData({
        data: [{
          api: 'M1001ChatService.deleteMsg', input: msg
        }]
      })
      const out = { ...msg, deleted_at: new Date() }
      commit('EDIT_MESSAGE', out)
    },
    incomingMsg: ({ state, commit }, payload) => {
      const roomIndex = state.rooms.findIndex(room => room.id === payload.m0901_id)
      if (roomIndex === -1) {
        console.log('room not found')
        return
      }
      let msgIndex = -1
      msgIndex = state.rooms[roomIndex].messages.findIndex(message => message.id === payload.id)
      if (msgIndex === -1) {
        commit('SET_MESSAGES_PUSH', payload)
      } else {
        commit('EDIT_MESSAGE', payload)
      }
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
