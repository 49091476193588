<template>
  <div v-if="info.updated_at" class="review-notification-header">
    <v-alert
      v-model="isOpen"
      dismissible
      :icon="false"
      :color="matchConditions(info.approval_status, 'color')"
    >
      <div
        class="header-container"
      >
        <v-col
          cols="auto"
        >
          <v-icon
            :size="80"
            style="color: white"
          >
            {{ matchConditions(info.approval_status, 'icon') }}
          </v-icon>
        </v-col>
        <v-col
          style="color: white"
          class="title-container"
        >
          <div
            class="title-notification"
          >
            {{ matchConditions(info.approval_status, 'title') }}
            <v-chip v-if="info.approval_status === 'REJECTED'">
              {{ info.categoryName }}
            </v-chip>
          </div>
          <div class="time-notification">
            {{ info.updated_at | displayDate(info.approval_status, info.adminName) }}
          </div>
        </v-col>
      </div>
      <div
        v-if="info.approval_verdict !== '' || info.approval_status !== 'APPROVED'"
        style="color: white"
      >
        <v-row
          v-if="info.approval_status !== 'PENDING' && info.approval_verdict !== ''"
          class="content-notification"
        >
          <v-col class="mx-4">
            <h3>
              {{ $t('m2301.notification.adminComment') + ' :' }}
            </h3>
          </v-col>
        </v-row>
        <v-row
          class="content-notification"
        >
          <v-col class="mb-4 mx-4 pt-0">
            {{ info.approval_verdict !== null || info.approval_status !== 'PENDING' ? info.approval_verdict : $t('m2301.notification.pendingMessage') }}
          </v-col>
        </v-row>
      </div>
      <VCard v-debug>
        <!-- <VCardTitle>
          TODO: make debug btn for dev
        </VCardTitle> -->
        <VCardActions>
          <!-- <VBtn >TODO: GOTO admin review | {{ info.routeName }}</VBtn> -->
          <!-- <VBtn>PENDING</VBtn>
          <VBtn>APPROVE</VBtn>
          <VBtn>AMENDMENT</VBtn>
          <VBtn>REJECT</VBtn> -->
          <VBtn :to="{name:'ApprovalList',query:{portal:'admin'}}">Admin ApprovalList Page</VBtn>
          <VBtn @click="init()"> Refresh</VBtn>
        </VCardActions>
      </VCard>
      <template #close>
        <div
          class="close-container"
        >
          <v-icon
            style="color: white"
            @click="isOpen = false"
          >
            mdi-close-circle
          </v-icon>
        </div>
      </template>
    </v-alert>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'
import moment from 'moment'
export default {
  filters: {
    displayDate (date, status, adminName) {
      if (status === 'PENDING') return i18n.t('m2301.notification.submittedOn') + ' ' + moment(date).format('LL (dd) LT')
      if (status !== 'PENDING') return moment(date).format('LL (dd) LT') + ' ' + i18n.t('m2301.notification.by') + ' ' + adminName
    }
  },

  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      condition: [
        { PENDING: { icon: 'mdi-clock-time-three-outline', title: this.$t('m2301.notification.title.pendingReview'), color: 'secondary' } },
        { APPROVED: { icon: 'mdi-check-circle-outline', title: this.$t('m2301.notification.title.approved'), color: 'success' } },
        { AMENDMENT: { icon: 'mdi-alert-outline', title: this.$t('m2301.notification.title.amendmentRequired'), color: 'warning' } },
        { REJECTED: { icon: 'mdi-close-octagon-outline', title: this.$t('m2301.notification.title.rejected'), color: 'error' } }
      ],
      isOpen: true
    }
  },
  computed: {
    ...mapGetters('M2301Approval', {
      info: 'getNotification'
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    init(){
      this.storeApiDispatch('M2301Approval/retrieveNotification', this.value)
    },
    matchConditions (status, key) {
      const matchingCondition = this.condition.find(item => status in item)

      if (matchingCondition) {
        if (key === 'icon') return matchingCondition[status].icon
        if (key === 'title') return matchingCondition[status].title
        if (key === 'color') return matchingCondition[status].color
      }
    }
  }
}
</script>
<style scoped>
.title-notification{
  font-size: 36px;
}
.time-notification{
  font-size: 14px;
}
.content-notification{
  font-size: 16px;
}
.header-container{
  display:flex;
  flex-direction: row;
  margin-bottom: 25px;
}
.title-container{
  display: flex;
  flex-direction: column;
  justify-items: center;
}
.close-container{
  display: flex;
  align-self: flex-start;
}
/* .review-notification-header{
  margin: 40px;
} */
</style>
