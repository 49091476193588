import ApiUtils from '@/utils/api/ApiUtils'
import ZZ0201Const from './ZZ0201Const'

// TODO: can i know what storeConst properties are available? (using intelisense)
const store = {
  state: {
    // custom state
    time: '',
    requestStatus: '',
    promotionRequests: []
  },
  getters: {
    // custom getter
    getTime: (state) => state.time,
    getRequestStatus: (state) => state.requestStatus,
    getPromotionRequests: (state) => state.promotionRequests
  },
  mutations: {
    // custom mutation
    SET_TIME (state, payload) {
      state.time = payload
    },
    SET_REQUEST_STATUS (state, payload) {
      state.requestStatus = payload
    },
    SET_PROMOTIONS_REQUESTS (state, payload) {
      state.promotionRequests = payload
    }
  },
  actions: {
    async createPromotionRequest ({ commit }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: ZZ0201Const.serviceName + '.createPromotionRequest', input: payload }
        ]
      })
      commit('SET_TIME', res[0].output.updated_at)
      commit('SET_REQUEST_STATUS', res[0].output.promotion_request_status)
      return res
    },
    async getPromotionTimeAndStatus ({ commit }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: ZZ0201Const.serviceName + '.getPromotionTimeAndStatus', input: payload }
        ]
      })
      if (res[0].output === null) return
      commit('SET_TIME', res[0].output.updated_at)
      commit('SET_REQUEST_STATUS', res[0].output.promotion_request_status)
    },
    async updateProjectPromotionStatus ({ commit }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: ZZ0201Const.serviceName + '.updateProjectPromotionStatus', input: payload }
        ]
      })
      commit('SET_TIME', res[0].output.updated_at)
      commit('SET_REQUEST_STATUS', res[0].output.promotion_request_status)
      return res
    },
    async getProjectPromotionRequest ({ commit, state }, payload) {
      const val = {
        pagination: payload.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: ZZ0201Const.serviceName + '.getProjectPromotionRequest', input: val }
        ]
      })
      commit('SET_PROMOTIONS_REQUESTS', res[0].output.data)
      commit('SET_PAGINATION', res[0].output.pagination)
    },
    async adminUpdateProjectPromotionStatus ({ commit }, payload) {
      return await ApiUtils.postData({
        data: [
          { api: ZZ0201Const.serviceName + '.adminUpdateProjectPromotionStatus', input: payload }
        ]
      })
    }
  }
}

export default ZZ0201Const.getStore(store)
