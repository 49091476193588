import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const statusMapUpdate = {
  ON_SCHEDULE: {
    text: '步入正軌',
    color: '#DCEDC8'
  },
  AHEAD: {
    text: '嚮前',
    color: '#8BC34A'
  },
  MILESTONE: {
    text: '里程碑',
    color: 'green'
  },
  DELAY: {
    text: '延遲',
    color: 'orange'
  },
  CLOSE: {
    text: '關閉',
    color: '#FF3D00'
  },
  CANCELLED: {
    text: '取消',
    color: '#FF6E40'
  }
}

const statusMapStatus = {
  DRAFT: {
    text: '草稿',
    color: 'gray'
  },
  PUBLISHED: {
    text: '已發佈',
    color: 'blue'
  },
  ARCHIVED: {
    text: '入檔',
    color: 'purple'
  }
}

const statusMap = {
  ...statusMapUpdate,
  ...statusMapStatus
}

function getStatusMapArray(){
  return Object.keys(statusMapUpdate).map(key => {
    return {
      value: key,
      ...statusMapUpdate[key],
    }
  })
}

const module = {
  state: {
    formDialog: false,
    mode: 'PO', // [PO,public],
    pagination: { current_page: 0, per_page: 5 },
    statusMap,
    statusMapArray: getStatusMapArray(),
  },
  getters: {
    getFormDialog: state => state.formDialog,
    getMode: state => state.mode
  },
  mutations: {
    SET_FORM_DIALOG (state, val) { state.formDialog = val },
    SET_MODE (state, val) { state.mode = val }
  },
  actions: {
    async doAdd ({ state }) {
      console.log(state)
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C0401ProjectUpdateService.doAdd',
            input: state.row
          }
        ]
      })
      return res
    },
    async doEdit ({ commit, state }) {
      console.log(state)
      await ApiUtils.postData({
        data: [{ api: 'C0401ProjectUpdateService.doEdit', input: state.row }]
      })
    },
    async doRead ({ commit, state }) {
      const res = await ApiUtils.postData({
        data: [{ api: 'C0401ProjectUpdateService.doRead', input: { id: state.row.id } }]
      })
      commit('SET_ROW', res[0].output)
    },
    async doDelete ({ commit, state }) {
      await ApiUtils.postData({
        data: [{
          api: 'C0401ProjectUpdateService.doDelete',
          input: state.row
        }]
      })
    },
    async doBrowse ({ commit, state }) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C0401ProjectUpdateService.doBrowse',
            input: {
              pagination: state.pagination,
              searchInfo: state.searchInfo
            }
          }
        ]
      })
      state.pagination = res[0].output.pagination
      if (state.pagination.current_page > 1) {
        state.rows = [...state.rows, ...res[0].output.data]
      } else {
        state.rows = []
        state.rows = res[0].output.data
      }
      console.log(state.rows)
    }
  }
}

const target = ObjArrUtils.merge(Base, error, BREAD, module)
export default target
