import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C3101ProjectPartnerRequirementApplication',
  serviceName: 'C3101ProjectPartnerRequirementApplicationService',

  componentDef: {
    form: {
      /*
       type: div, row, col, form, text,file,select, button,table
       component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
       rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewDef: [
        {
          column: 'col-12',
          custom: 'RequestText',
          class: 'text-body-1'
        },
        {
          column: 'col-6',
          data: 'name',
          type: 'text',
          label: i18n.t('c0101.form.name'),
          rules: 'required',
          class: 'required',
          labelHint: i18n.t('c0101.formHints.name')
        },
        {
          column: 'col-6',
          data: 'website',
          type: 'text',
          label: i18n.t('c0101.form.website'),
          labelHint: i18n.t('c0101.formHints.website')
        },
        {
          column: 'col-12',
          data: 'introduction',
          type: 'textarea',
          label: `${i18n.t('c0101.form.introduction')} / ${i18n.t('c0101.form.remarks')}`,
          labelHint: i18n.t('c0101.formHints.introduction')
        },
        {
          column: 'col-6',
          data: 'resume',
          type: 'file',
          label: `${i18n.t('c0101.form.resume')} / ${i18n.t('c0101.form.cv')}`,
          labelHint: i18n.t('c0101.formHints.resume'),
          props: {
            maxFiles: 3
          }
        },
        {
          column: 'col-6',
          data: 'documents',
          type: 'file',
          label: `${i18n.t('c0101.form.videos')} / ${i18n.t('c0101.form.documents')}`,
          labelHint: i18n.t('c0101.formHints.documents'),
          props: {
            maxFiles: 3
          }
        },
        {
          column: 'col-12',
          custom: 'PolicyDivider'
        },
        {
          type: 'ttc_policy',
          data: 'policyPartnership',
          props: {
            policyCode: 'collab_projectPartnerRequirement'
          }
        },
        {
          type: 'ttc_policy',
          data: 'policyPartnershipNDA',
          props: {
            policyCode: 'collab_partnership.nda'
          }
        }
      ],
      optionDef: {}
    }
  }
}
const C3101Const = new BaseDataStore(BaseConst)
export default C3101Const
