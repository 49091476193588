import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    targetModel: 'L0301ContentClass',
    cartItems: [],
    userMayLikeContents: [],
    totalPrice: 0,
    textFieldValue: ''
  },
  getters: {
    isInCart: (state) => (relId) => {
      // console.log(state.cartItems)
      // console.log(relId)

      const a = state.cartItems.find(
        (item) =>
          item.relModel === state.targetModel &&
          item.relId === relId
      )
      return !!a
    },
    count: (state) => {
      return state.rows.filter(
        (item) => item.shopping_cart_rel_model === state.targetModel
      ).length
    }
  },
  mutations: {},
  actions: {
    async init({ commit, state }) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M2701ShoppingCartService.doBrowse',
            input: { shopping_cart_status: 'A', rel_model: state.targetModel }
          },
          { api: 'L0101ContentService.doBrowse_recommend_userMayLike' }
          // { api: 'M1902CoinsTypeCurrencyService.GetACoinCurrency' }
        ]
      })
      state.cartItems = res[0].output
      state.userMayLikeContents = res[1].output
    },
    // Manually remove cart items from frontend
    async doDelete({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [
          {
            api: 'M2701ShoppingCartService.doDelete',
            input: {
              rel_model: state.targetModel,
              rel_id: payload.rel_id
            }
          }
        ]
      }).then(data => {
        state.cartItems = data[0].output
      })
    },
    async doAdd({ commit, state }, payload) {
      // res = await ApiUtils.postData({
      //   data: [{ api: 'M2701ShoppingCartService.doAdd', input: { rel_model: state.targetModel, ...payload } }]
      // }).then(data => {

      // })

      return ApiUtils.postData({
        data: [{ api: 'M2701ShoppingCartService.doAdd', input: { rel_model: state.targetModel, ...payload } }]
      }).then(data => {
        ApiUtils.postData({
          data: [
            {
              api: 'M2701ShoppingCartService.doBrowse',
              input: { shopping_cart_status: 'A', rel_model: 'L0301ContentClass' }
            }
          ]
        }).then(data => {
          state.cartItems = data[0].output.sort((a, b) => a.relId - b.relId)
          state.textFieldValue = ''
        })
        return data[0].output
      })

      // TODO: add
    },
    async doApply({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: 'M3101PromoCodeService.doApply', input: payload }]
      })

      state.cartItems = res[0].output

      return res
    },
    async checkout({ commit, state }, payload) {
      return await ApiUtils.postData({
        data: [{ api: 'M2701ShoppingCartService.checkout', input: payload }]
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
