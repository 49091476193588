<template>
  <v-dialog
    v-model="showDialog"
    :fullscreen="$vuetify.breakpoint.mobile"
    :width="dialogWidth"
    persistent
  >
    <template #activator="{ on, attrs }">
      <slot
        name="verdict_activator"
        v-bind="{
          attrs,
          on
        }"
      >
        <v-btn
          v-bind="{ ...attrs, ...getActivatorButtonProps }"
          v-on="on"
        >
          {{ getActivatorButtonProps.buttonText }}
        </v-btn>
      </slot>
    </template>
    <v-card>
      <v-col
        cols="12"
        class="pt-5 text-h5"
      >
        {{ $t('components.AtReportVerdictDialog.form_title') }}
      </v-col>
      <v-col cols="12">
        <AtForm
          v-model="getValue"
          cancel-btn
          :def="formDef"
          @onSubmit="submit"
          @onCancel="onFormCancel"
        >
          <template #AtDuration="{ props, update }">
            <AtDuration
              v-bind="props"
              @input="update"
            />
          </template>
        </AtForm>
      </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
import { AtForm, AtDuration } from '@/components/base'
export default {
  components: {
    AtForm,
    AtDuration
  },
  /**
  * @prop {Object} value - The object that holds the form's data. Default: {}
  * @prop {Object} activatorButtonProps - The properties of the button that activates the dialog box. Default: {}
  * @prop {Boolean} violated - A flag to indicate if the report violated a rule, set to true to shows only the violated categories in selectbox. Default: true
  */
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    activatorButtonProps: {
      type: Object,
      default: () => {}
    },
    violated: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showDialog: false
    }
  },
  computed: {
    getValue: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    getActivatorButtonProps () {
      const defaultProps = {
        color: 'primary',
        buttonText: this.$t('components.AtReportVerdictDialog.activator_button')
      }

      return { ...defaultProps, ...this.activatorButtonProps }
    },
    formDef () {
      const def = [
        {
          m0601_id: {
            column: 'col-12',
            component: 'v-select',
            vModel: null,
            props: {
              label: this.$t('m1201.ReportCaseDetail.PgReportCaseDetail.formDef.m0601_id.label'),
              items: [],
              itemValue: 'id',
              itemText: 'translated_category_name',
              autoSelectFirst: true
            },
            async: [
              {
                api: 'M0601CategoryService.getReportCategories',
                mutateTarget: 'props.items',
                callback: payload => {
                  payload.unshift({
                    id: null,
                    translated_category_name: this.$t('m1201.not_against')
                  })
                  return payload
                }
              }
            ]
          }
        },
        {
          report_action_code: {
            column: 'col-12',
            component: 'v-select',
            vModel: null,
            props: {
              label: this.$t('m1201.ReportCaseDetail.PgReportCaseDetail.formDef.report_action.label'),
              items: [],
              itemValue: 'report_action_code',
              // TODO: [Adrian] `report_action_name` after database changed
              itemText: 'report_action_code',
              autoSelectFirst: true
            },
            async: [
              {
                api: 'M1401ReportActionService.getReportActions',
                input: {
                  report_action_rel_model: this.getValue.report_case_rel_model
                },
                mutateTarget: 'props.items'
              }
            ]
          }
        },
        {
          report_case_verdict: {
            column: 'col-12',
            component: 'v-textarea',
            vModel: null,
            props: {
              label: this.$t('m1201.ReportCaseDetail.PgReportCaseDetail.formDef.report_case_verdict.label'),
              required: true
            }
          }
        }
      ]

      if (this.violated) {
        delete def[0].m0601_id.async[0].callback
        def[0].m0601_id.rules = 'required'
      }

      let actionCode = ''
      if (this.getValue.report_action_code !== undefined) actionCode = this.getValue.report_action_code

      if (actionCode.includes('freeze')) {
        def.splice(2, 0, {
          user_status_end_date: {
            column: 'col-12',
            component: 'AtDuration',
            props: {
              label: this.$t('m1201.ReportCaseDetail.PgReportCaseDetail.formDef.user_status_end_date.label')
            }
          }
        })
      }

      return def
    },
    dialogWidth () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '100vw'
        case 'sm': return '85vw'
        case 'md': return '70vw'
        case 'lg': return '55vw'
        case 'xl': return '40vw'
        default: return '40vw'
      }
    }
  },
  methods: {
    submit (data) {
      this.storeApiDispatch('M1201ReportCase/saveVerdiction', data)
        .then(reportCase => {
          this.$emit('submitted', reportCase)
          this.showDialog = false
          this.getValue = {}
        })
    },
    onFormCancel () {
      this.showDialog = false
    }
  }
}
</script>
