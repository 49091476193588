import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    jobs: {}
  },
  getters: {},
  mutations: {},
  actions: {
    async getJobPostingRequirement({ commit, state, dispatch }, payload) {
      state.jobs = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T1001JobPostingRequirementService.doRead_JobPostingRequirement', input: {
              id: payload.id,
              seo: payload.seo
            }
          }
        ]
      })
      console.log(res)
      state.jobs = res[0].output

      console.log('state.jobs', state.jobs)
      return new Promise((resolve, reject) => {
        resolve(state.jobs)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
