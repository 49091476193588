<template>
  <div>
    <v-tooltip
      v-bind="tooltipProps"
    >
      <template #activator="{ on, attrs }">
        <v-btn
          :color="buttonProps.color"
          :icon="iconMode"
          :dark="dark"
          v-bind="{...attrs,...$attrs}"
          v-on="{...on,...$listeners}"
        >
          <v-icon v-if="iconShow">
            {{ buttonProps.icon }}
          </v-icon>
          <div
            v-if="!iconMode"
            class="ml-1"
          >
            {{ buttonProps.label }}
          </div>
          <v-badge
            v-if="badgeContent"
            inline
            :content="badgeContent"
          />
        </v-btn>
      </template>
      <span>{{buttonProps.tooltipValue?buttonProps.tooltipValue: buttonProps.label }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import ActionButtonPreset from './ActionButtonPreset.json'
/**
 * ActionButton component
 * {@link defaultChild}
 * @prop {String} preset - The preset button to use, options include 'view', 'edit', 'delete', etc. {@link ActionButtonPreset}
 * @prop {Boolean} iconMode - Whether to display the button in icon mode.
 * @prop {Boolean} iconShow - Whether to show the icon in the button.
 * @prop {Boolean} dark - Whether to use the dark mode styling.
 * @prop {String} color - The color of the button.
 * @prop {String} icon - The icon to use, in the format "mdi-iconname".
 * @prop {String} label - The label to display on the button.
 * @prop {Object} tooltipProps - Properties for the tooltip, defaults to { bottom: true }.
 */
export default {
  components: {
  },
  props: {
    preset: {
      type: String,
      default: 'view'
    },
    iconMode: {
      type: Boolean,
      default: false
    },
    iconShow: {
      type: Boolean,
      default: true
    },
    dark: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    tooltipValue: {
      type: String,
      default: null
    },
    tooltipProps: {
      type: Object,
      default: () => { return { bottom: true } }
    },
    badgeContent: {
      type: String,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    buttonProps () {
      let presetProps = {}

      const set = ActionButtonPreset[this.preset]
      if (!set) throw new Error('ActionButton.vue ~ [' + this.preset + '] preset button Not Found')
      presetProps = { ...set, label: this.$t(set.transKey) }
      delete presetProps.transKey

      if (this.color) presetProps.color = this.color
      if (this.icon) presetProps.icon = this.icon
      if (this.label) presetProps.label = this.label
      if (this.outlined) presetProps.outlined = this.outlined
      if (this.show) presetProps.show = this.show

      return presetProps
    },
    actionList () {
      return Object.keys(ActionButtonPreset)
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
