export default [
  {
    path: '/Auth',
    component: () => import('@/router/template/TTCTemplate.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('./Login/PgLogin.vue'),
        meta: { notLogged: true, hideHeader: true }
      },
      {
        // NOTE: do not change this path, it is used by social media login
        path: '/login/:social_media',
        name: 'SocialMediaLogin',
        component: () => import('./Login/PgLogin.vue'),
        meta: { notLogged: true, hideHeader: true }
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('./Register/PgRegister.vue'),
        meta: { notLogged: true, hideHeader: true }
      },
      {
        path: 'resetpassword',
        name: 'ResetPassword',
        component: () => import('./ResetPassword/ResetPassword.vue'),
        meta: { notLogged: true, hideHeader: true }
      }
    ]
  },
]
