export default {
  props: {
    /**
     * Override this, depending on the use case
     * for example, if the value is an object, then the default value should be an empty object
     */
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      dValue: undefined,
      holdEmitInput: false
    }
  },
  watch: {
    value: {
      handler (val, oldVal) {
        if (val === oldVal) return
        this.dValue = val
      },
      // immediate: true,
      deep: true
    },
    dValue: {
      handler (val, oldVal) {
        if (val === oldVal) return
        if (this.holdEmitInput) return
        this.$emit('input', val)
      },
      // immediate: true,
      deep: true
    }
  }
}
