// import i18n from '@/plugins/i18n'

export default [
  // {
  //   path: 'wallet',
  //   name: 'wallet',
  //   component: () => import('@/app/M2001Coins/PgWallet.vue'),
  //   meta: { authenticated: true, permissions: ['wallet.viewOwn'] }
  // },
  {
    path: '/my/toStripe',
    name: 'M2001CoinsToStripe',
    component: () => import('@/app/M2001Coins/PgToStripe.vue'),
    portalGroup: 'master.public',
    meta: { authenticated: true }
  },
  {
    path: '/my/topup',
    name: 'topup',
    component: () => import('@/app/M2001Coins/PgTopup.vue'),
    portalGroup: 'master.public',
    meta: { authenticated: true }
  },
  {
    path: '/my/cashout',
    name: 'cashout',
    component: () => import('@/app/M2001Coins/PgCashout.vue'),
    portalGroup: 'master.public',
    meta: { authenticated: true }
  },
  {
    path: '/my/CashFlowHistory',
    name: 'PgCashFlowHistory',
    component: () => import('@/app/M2001Coins/comps/PgCashFlowHistory.vue'),
    portalGroup: 'master.public',
    meta: { authenticated: true }
  }
]
