import BaseDataStore from '@/components/base/store/BaseDataStoreV2'
import i18n from '@/plugins/i18n'
// import moment from 'moment'
const storeName = 'P0101Admin'
const serviceName = 'P0101ArticleService'

/*
    {
      column: 'col-6', // column size
      data: 'status', // data name
      type: 'select', // component type : text,textarea,number,checkbox,switch,radio,file,date,time,dateRange,select,autoComplete,combobox,rangeSlider,slider,ttc_category,ttc_tag,ttc_price,ttc_policy
      label: 'status', // label
      props: any // component props
    }
*/
const storeDef = {
  ACTION: {
    // base actions : doAdd, doEdit, getEdit, doBrowse, doDelete, doRead
    // custom actions
    // TODO: accept string without storeName/serviceName
    init: {
      store: storeName + '/init'
    },
    doBrowse: {
      store: storeName + '/doBrowse',
      api: serviceName + '.doBrowseWithDetail'
    },
    doBrowseComments: {
      store: storeName + '/doBrowseComments',
      api: serviceName + '.doBrowseComments'
    },
    doBrowseVersion: {
      store: storeName + '/doBrowseVersion',
      api: serviceName + '.doBrowseVersion'
    },
    doBrowseReport: {
      store: storeName + '/doBrowseReport',
      api: serviceName + '.doBrowseReport'
    },
    doApprove: {
      store: storeName + '/doApprove',
      api: serviceName + '.doApprove'
    },
    getEdit: {
      store: storeName + '/getEdit',
      api: serviceName + '.getEditDetailByVersionId'
    },
    getRead: {
      store: storeName + '/getRead',
      api: serviceName + '.getReadDetailByVersionId'
    },
    doEdit: {
      store: storeName + '/doEdit',
      api: serviceName + '.doSaveDetail'
    },
    getStats: {
      store: storeName + '/getStats',
      api: serviceName + '.getActiclsStats'
    }

  },
  PROPERTY: {
    // base properties : row, rows, pagination, searchInfo, orderBy
    // custom properties
    myProp: {
      get: storeName + '/getMyProp',
      set: storeName + '/SET_MY_PROP'
    },
    stats: {
      get: storeName + '/getStats',
      set: storeName + '/SET_STATS'
    }
  }
}

const searchDef = {
  viewDef: [
    {
      column: 'col-3',
      data: 'status',
      type: 'select',
      label: 'status',
      props: {
        items: [
          { id: 'DRAFT', name: 'DRAFT' },
          { id: 'PUBLISHED', name: 'PUBLISHED' },
          { id: 'ARCHIVED', name: 'ARCHIVED' }
        ],
        itemText: 'name',
        itemValue: 'id'
      }
    },
    {
      column: 'col-sm-3',
      data: 'category',
      type: 'ttc_category',
      props: {
        label: i18n.t('components.TTCCategoryField.category'),
        outlined: true
      }
    },
    {
      column: 'col-sm-3',
      data: 'tag',
      type: 'ttc_tag',
      props: {
        label: i18n.t('components.TTCTagField.tags'),
        outlined: true
      }
    }
  ],
  optionDef: {}
}

const contentDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'article_version_title',
      type: 'text',
      rules: 'required',
      label: 'Title',
      labelHint: 'Article Title'
    },
    {
      column: 'col-12',
      data: 'article_version_subtitle',
      type: 'text',
      rules: 'required',
      label: 'Subtitle',
      labelHint: 'Article Title'
    },
    {
      column: 'col-4',
      data: 'article_version_cover',
      type: 'file',
      rules: 'required',
      label: 'Featured Image',
      labelHint: 'Featured Image',
      props: {
        accept: 'image/*',
        maxFiles: 1
      }
    },
    {
      column: 'col-12',
      data: 'article_version_content',
      custom: 'MoBlockEditor',
      label: 'Article Contents MoBlockEditor',
      labelHint: 'Contents'
    }
  ],
  optionDef: {}
}
const commentsDef = {
  viewDef: [],
  optionDef: {}
}
const seoDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'article_seo_url',
      type: 'text',
      label: 'Article Seo Url',
      labelHint: 'Article Seo Url'
    },
    {
      column: 'col-12',
      data: 'article_seo_title',
      type: 'text',
      label: 'Article Seo Title',
      labelHint: 'Article Seo Title'
    },
    {
      column: 'col-12',
      data: 'article_seo_desc',
      type: 'text',
      label: 'Article Seo Desc',
      labelHint: 'Article Seo Desc'
    },
    {
      column: 'col-12',
      data: 'article_seo_keyword',
      type: 'text',
      label: 'Article Seo Keyword',
      labelHint: 'Article Seo Keyword'
    }
  ],
  optionDef: {}
}
const promotionDef = {
  viewDef: [],
  optionDef: {}
}
const statisticsDef = {
  viewDef: [],
  optionDef: {}
}
const revisionsDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'article_version_title',
      type: 'text',
      label: 'Article Version Title',
      labelHint: 'Article Version Title'
    },
    {
      column: 'col-12',
      data: 'article_version_subtitle',
      type: 'text',
      label: 'Article Version Subtitle',
      labelHint: 'Article Version Subtitle'
    },
    {
      column: 'col-12',
      data: 'article_version_status',
      type: 'text',
      label: 'Article Version Status',
      labelHint: 'Article Version Status'
    },
    {
      column: 'col-12',
      data: 'updated_at',
      type: 'text',
      label: 'Updated At',
      labelHint: 'Updated At'
    }
  ],
  optionDef: {}
}
const settingDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'm0601_id',
      type: 'ttc_category',
      props: {
        label: i18n.t('components.TTCCategoryField.category'),
        outlined: true
      }
    },
    {
      column: 'col-12',
      data: 'tags',
      type: 'ttc_tag',
      props: {
        label: i18n.t('components.TTCTagField.tags'),
        outlined: true
      }
    },
    {
      column: 'col-6',
      data: 'article_schedule_datetime',
      type: 'date',
      label: 'Publish Schedule'
    }
  ],
  optionDef: {}
}

const listDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('samples.layout4.title'), value: 'title' },
    // use type: 'date' to format date
    { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
    // use type: 'enum' to format enum
    {
      text: 'Gender',
      value: 'gender',
      type: 'chip',
      items: [
        { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
        { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
        { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
      ]
    },
    { text: 'comments', value: 'comment_count' },
    { text: 'post_type', value: 'post_type' },
    { text: 'views', value: 'views' },
    { text: 'Custom (comments+views)', value: 'cus' },
    { text: 'Action', value: 'actions', sortable: false }
  ],
  optionDef: {}
}
const reportDef = {
  viewDef: [
    { text: 'Sale Month', value: 'sale_month' },
    { text: 'Sale Type', value: 'sale_type' },
    { text: 'Amount', value: 'amount' }
  ],
  optionDef: {}
}
const tableDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
    { text: 'subtitle', value: 'article_version_subtitle' },
    { text: 'status', value: 'article_version_status' },
    { text: 'tags', value: 'tags' },
    { text: 'Action', value: 'actions', sortable: false }

  ],
  optionDef: {}
}

const readDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('samples.layout4.title'), value: 'article_version_title' },
    { text: 'subtitle', value: 'article_version_subtitle' },
    { text: 'status', value: 'article_version_status' },
    { text: 'tags', value: 'tags' }
  ],
  optionDef: {}
}

const BaseConst = {
  version: 1,
  storeName,
  serviceName,
  storeConst: storeDef,
  // target def for './component'
  //  if search -> search component
  componentDef: {
    search: searchDef,
    table: tableDef,
    list: listDef,
    read: readDef,
    report: reportDef,
    edit: {
      content: contentDef,
      comments: commentsDef,
      seo: seoDef,
      promotion: promotionDef,
      statistics: statisticsDef,
      revisions: revisionsDef,
      setting: settingDef
    }

  }
}
const FnConst = new BaseDataStore(BaseConst)
export default FnConst
