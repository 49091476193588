import Vue from 'vue'
import Vuex from 'vuex'

import ObjArrUtils from '@/utils/ObjArrUtils'

import App from '@/store/modules/AppStore'
import Notification from '@/store/modules/NotificationStore'

Vue.use(Vuex)

/**
 * Auto import stores from all modules
 * input: @/app/../{$1}Store.js
 */
const autoImportStore = {}
/**
 * map for checking duplicate store name
 * {
 *  {$1}: @/app/../{$1}Store.js
 * }
 */
const autoImportStoreMap = {}

/**
 * Auto import stores from all modu
 * @example @/app/../{$1}Store.js
 * ! multi nested is allowed
 * ? limit to only @/app/{$1}/{$1}Store.js ?
 */
let req = import.meta.globEager('../app/**/*Store.js', { eager: true, import: 'default' })
importReq(req)
req = import.meta.globEager('../components/**/*Store.js', { eager: true, import: 'default' })
importReq(req)

function importReq (localReq) {
  // console.log('localReq', localReq)
  Object.keys(localReq).forEach((file) => {
    // split by '/' and get the last element
    // console.log('file', file)
    const name = file.split('/').pop().replace('Store.js', '')

    if (autoImportStore[name] !== undefined) {
      throw new Error(`Duplicate '${name}Store.js' detected, please use diffrent Store name \n 1. '${file}'\n 2. '${autoImportStoreMap[name]}`)
    }
    autoImportStore[name] = localReq[file]
    autoImportStoreMap[name] = file
    // console.log('autoImportStore', autoImportStore)
    // console.log('autoImportStoreMap', autoImportStoreMap)
    // check if the store is object and contains [state, getters, mutations, actions]
    if (!(typeof autoImportStore[name] === 'object')) {
      throw new Error(`'${name}Store.js' must be an object`)
    }
    if (!autoImportStore[name].state) {
      throw new Error(`'${name}Store.js' must contains 'state'`)
    }
    if (!autoImportStore[name].getters) {
      throw new Error(`'${name}Store.js' must contains 'getters'`)
    }
    if (!autoImportStore[name].mutations) {
      throw new Error(`'${name}Store.js' must contains 'mutations'`)
    }
    if (!autoImportStore[name].actions) {
      throw new Error(`'${name}Store.js' must contains 'actions'`)
    }
  })
}

// console.log('req.keys()', req.keys())
// console.log(autoImportStore)

const store = new Vuex.Store({
  modules: {
    // base
    App,
    Notification,

    // auto import
    ...autoImportStore
  }
})

store.subscribeAction({
  error: (action, state, error) => {
    const module = action.type.split('/')[0]

    const res = JSON.parse(error.message)
    const message = (typeof res === 'object' && res.responseJSON) ? (ObjArrUtils.isJsonString(res.responseJSON.message) ? JSON.parse(res.responseJSON.message) : res.responseJSON.message) : res

    store.commit(module + '/SET_ERRORS', message)
  }
})

// Copy store's initial state
const initialStateCopy = JSON.parse(JSON.stringify(store.state))

// Function for reset state to initial state
Vue.prototype.$resetState = () => {
  const initialState = JSON.parse(JSON.stringify(initialStateCopy))
  initialState.App.appLoading = store.state.App.appLoading
  initialState.Auth.init = store.state.Auth.init
  store.replaceState(initialState)
}

export default store
