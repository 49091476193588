import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'collab.public',
    path: 'C3101ProjectPartnerRequirementSelected/position/:projectTitle&:partnerRequirementInfo',
    name: 'C3101ProjectPartnerRequirementSelected',
    component: () => import('./PgApplyPartnership.vue'),
    meta: { title: i18n.t('c0101.partnership.partnershipPage') }
  }
]
