import BaseDataStore from '@/components/base/store/BaseDataStoreV2'
// import moment from 'moment'
const storeName = 'P0201Version'
const serviceName = 'P0201ArticleVersionService'

/*
    {
      column: 'col-6', // column size
      data: 'status', // data name
      type: 'select', // component type : text,textarea,number,checkbox,switch,radio,file,date,time,dateRange,select,autoComplete,combobox,rangeSlider,slider,ttc_category,ttc_tag,ttc_price,ttc_policy
      label: 'status', // label
      props: any // component props
    }
*/
const storeDef = {
  ACTION: {

  },
  PROPERTY: {

  }
}

const listDef = {
  viewDef: [
  ],
  optionDef: {}
}

const BaseConst = {
  version: 1,
  storeName,
  serviceName,
  storeConst: storeDef,
  // target def for './component'
  //  if search -> search component
  componentDef: {
    list: listDef

  }
}
const FnConst = new BaseDataStore(BaseConst)
export default FnConst
