// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    categories: []
  },

  getters: {
    getEnquiryCategories (state) {
      return state.categories
    }
  },

  mutations: {
    SET_ENQUIRY_CATEGORIES (state, payload) {
      state.categories = payload
    }
  },

  actions: {
    createEnquiry ({ commit }, input) {
      return ApiUtils.postData({
        data: [
          { api: 'M2501EnquiryService.createEnquiry', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    },
    searchEnquiryList ({ commit }, input) {
      return ApiUtils.postData({
        data: [
          { api: 'M2501EnquiryService.searchEnquiryList', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    },
    updateEnquiry ({ commit }, input) {
      return ApiUtils.postData({
        data: [
          { api: 'M2501EnquiryService.updateEnquiry', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    },
    enquiryCategories ({ commit }) {
      return ApiUtils.postData({
        data: [
          { api: 'M2501EnquiryService.getContentUsEnquiry' }
        ]
      }).then((data) => {
        commit('SET_ENQUIRY_CATEGORIES', data[0].output)
      })
    },
    getStaffPick: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M4601StaffPicksService.getStaffPickFAQ' }]
      }).then(data => {
        return data[0].output
      })
    },
    getContactInformation: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'S0801SettingService.contactInformation' }]
      }).then(data => {
        return data[0].output
      })
    },
    getUserDetail: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M2501EnquiryService.getUserName' }]
      }).then(data => {
        return data[0].output
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
