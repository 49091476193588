import Vue from 'vue'
import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import i18n from '@/plugins/i18n'
import { Base, error } from '@/store/base/module'

const initApis = {
  wallet: { api: 'M2001CoinsService.initWallet' },
  chatroom: { api: 'M1002ChatRecipientService.getTotalUnreadCount' },
  role: { api: 'M0101RoleService.getRoles' },
  reportCategory: { api: 'M0601CategoryService.getReportCategories' },
  unreadNotification :{ api: 'M0001UserService.getUnreadNotificationCount' },
  footerPolicy :{ api: 'S0901PolicyService.getFooterPolicy' },
  links :{ api: 'S0801SettingService.getLinks' }
}

function initCommits (commit, data) {
  commit('M2001Coins/SET_WALLET', data.wallet.output, { root: true })
  commit('M0901Chatroom/SET_TOTAL_UNREAD_COUNT', data.chatroom.output, { root: true })
  commit('M0001User/SET_ROLE_ITEMS', data.role.output, { root: true })
  commit('M1201ReportCase/SET_CATEGORIES', data.reportCategory.output, { root: true })
  commit('UserNotification/SET_UNREAD_NOTIFICATION', data.unreadNotification.output, { root: true })
  commit('S0901Policy/SET_FOOTER_POLICY', data.footerPolicy.output, { root: true })
  commit('S0801Setting/SET_LINKS', data.links.output, { root: true })
}

const setting = {
  /**
 * false -> For cache init data in local dev, speed up reload time for dev,
 * may cause unexpected behavior if you developing auth related feature
 * true -> Disable cache init data in local dev
 */
  disableUserDataCache: true
}

const module = {
  state: {
    init: null,
    logged: !!localStorage.getItem('logged'),
    user: {}
  },

  getters: {
    getInit (state) {
      return state.init
    },

    getLogged (state) {
      return state.logged
    },

    getUser (state) {
      return state.user
    },

    getUserRole (state) {
      return state.user.role_code ?? null
    },

    getUserPermissions (state) {
      return state.user.permissions ?? null
    }
  },

  mutations: {
    INIT (state) {
      if (state.logged && ObjArrUtils.isEmpty(state.user)) {
        localStorage.removeItem('logged')
        this.commit('Notification/SET_NOTIFY_WARNING', {
          text: i18n.t('auth.sessionTimeout')
        })
      }
      state.init = true
    },
    LOGIN (state, payload) {
      state.user = payload
      localStorage.setItem('logged', '1')
    },
    LOGOUT (state) {
      state.user = {}
      localStorage.removeItem('logged')
    }
  },

  actions: {
    login: ({ commit }, authInfo) => {
      return ApiUtils.auth({
        data:
        {
          user: { api: 'AuthService.userLogin', input: authInfo },
          ...initApis
        }
      })
        .then((data) => {
          if (data.user.output) {
            commit('LOGIN', data.user.output)
            initCommits(commit, data)
          }
          localStorage.removeItem('init')
          return data.user.output
        })
    },
    preRegister: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.preRegister', input } } })
        .then((data) => {
        })
    },
    snsPreRegister: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.snsPreRegister', input } } })
        .then((data) => {
        })
    },
    register: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.register', input } } })
        .then((data) => {
        })
    },
    resendMagicLink: ({ commit }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.resendMagicLink', input } } })
    },
    logout: ({ commit }) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.userLogout' } } })
        .then((data) => {
          localStorage.removeItem('init')
          Vue.prototype.$resetState()
          commit('LOGIN', data.auth.output)
        })
    },
    init: async ({ commit, state }) => {
      let data
      // if is debug mode, remove init cache
      let debugCacheJSON = localStorage.getItem('init')
      debugCacheJSON = setting.disableUserDataCache ? null : debugCacheJSON
      // debugCacheJSON = false
      const debugCache = JSON.parse(debugCacheJSON)
      if (
        import.meta.env.VITE_NODE_ENV === 'development' &&
        debugCacheJSON &&
        debugCache.user.output.role_code !== 'V'
      ) {
        console.log('Use init cache (local dev only), may cause unexpected behavior if you developing auth related feature, Please set `setting.disableUserDataCache` to true in AuthStore.js')
        data = debugCache
      } else {
        data = await ApiUtils.auth({
          data:
          {
            user: { api: 'AuthService.getCurrentUser' },
            ...initApis
          }
        }).catch((error) => {
          state.init = false
          throw error
        })
      }
      try {
        // if data not object
        if (typeof data !== 'object') {
          throw new Error('init data is not object')
        }

        if (data.user.output) {
          commit('LOGIN', data.user.output)
          initCommits(commit, data)
        }
        commit('INIT')
        localStorage.setItem('init', JSON.stringify(data))
        return data?.user.output
      } catch (error) {
        localStorage.removeItem('init')
        // refresh page
        window.location.reload()
      }
    },
    snsLogin: ({ commit, state }, input) => {
      return ApiUtils.auth({
        data: {
          auth: { api: 'AuthService.snsLogin', input },
          ...initApis
        }
      })
        .then((data) => {
          if (data.auth.output?.user) {
            commit('LOGIN', data.auth.output.user)
            initCommits(commit, data)
          }
          return data.auth.output
        })
    },
    verifiedLogin: ({ commit, state }, input) => {
      return ApiUtils.auth({
        data: {
          auth: { api: 'AuthService.verifiedLogin', input },
          ...initApis
        }
      })
        .then((data) => {
          commit('LOGIN', data.auth.output)
          initCommits(commit, data)
          return data.auth.output
        })
    },
    setUserLocale: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.setUserLocale', input } } })
        .then((data) => {
          return data.auth.output
        })
    },
    getRegisterationInfo: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.getRegisterationInfo' } } })
        .then((data) => {
          return data.auth.output
        })
    },
    verifyUserAlias: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.verifyUserAlias', input } } })
    },
    verifyReferralCode: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.verifyReferralCode', input } } })
    },
    verifyUserMobile: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.verifyUserMobile', input } } })
    },
    verifyUserInfo: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.verifyUserInfo', input } } })
        .then((data) => {
          return data.auth.output
        })
    },
    getEnterpriseUserByName: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'M0001UserService.getEnterpriseUserByName', input } } })
        .then((data) => {
          return data.auth.output
        })
    },
    requestResetPassword: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.requestResetPassword', input } } })
        .then((data) => {
          return data.auth.output
        })
    },
    verifyResetPassword: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.verifyResetPassword', input } } })
        .then((data) => {
          return data.auth.output
        })
    },
    resetPassword: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.resetPassword', input } } })
        .then((data) => {
          return data.auth.output
        })
    },
    verifyHCaptcha: ({ commit, state }, input) => {
      return ApiUtils.auth({ data: { auth: { api: 'AuthService.verifyHCaptcha', input } } })
        .then((data) => {
          return data.auth.output
        })
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
