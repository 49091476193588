// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
import ApiUtils from '@/utils/api/ApiUtils'
import i18n from '@/plugins/i18n'

// TODO: NEED TIN CONFIMATION
const statusMap={
  DRAFT: {
    text: i18n.t('t3001.status.DRAFT'),
    color: 'grey',
    topBtnList: ['moreSetting','preview', 'submitForApproval'],
    editBtnList: ['preview', 'submitForApproval','doSave'],
    canEdit: true, // got `doSave` button == canEdit,
    showTabsList: ['edit'],
  },
  PENDING: {
    text: i18n.t('t3001.status.PENDING'),
    color: 'orange',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  AMENDMENT: {
    text: i18n.t('t3001.status.AMENDMENT'),
    color: 'orange',
    topBtnList: ['moreSetting','preview', 'submitForApproval'],
    editBtnList: ['preview', 'submitForApproval','doSave'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit'],
  },
  // BUG: NO APPROVED Status, maybe similar to `UNPUBLISHED` because after admin approved,
  // it will be `UNPUBLISHED` if creator not choose to publish when admin approved
  REJECTED: {
    text: i18n.t('t3001.status.REJECTED'),
    color: 'red',
    topBtnList: ['moreSetting','preview','submitForApproval'],
    editBtnList: ['preview','submitForApproval'],
    showReviewNotification: true,
    showTabsList: ['edit'],
  },
  AVAILABLE: {
    text: i18n.t('t3001.status.PUBLISHED'),
    color: 'green',
    topBtnList: ['moreSetting','preview','doSave'],
    editBtnList: ['preview','doSave'],
    showReviewNotification: true,
    canEdit: true, // ['title']
    showTabsList: ['edit','comments','application'],
  },
  UNAVAILABLE: {
    text: i18n.t('t3001.status.UNPUBLISHED'),
    color: 'grey',
    topBtnList: ['moreSetting','preview','doSave'],
    editBtnList: ['preview','doSave'],
    showReviewNotification: true,
    canEdit: true,
    showTabsList: ['edit','comments','application'],
  },
  DELETED: {
    text: i18n.t('t3001.status.DELETED'),
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  },
  REMOVED: {
    text: i18n.t('t3001.status.REMOVED'),
    color: 'red',
    topBtnList: ['moreSetting','preview'],
    editBtnList: ['preview'],
    showTabsList: ['edit'],
  }
}


function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}


const PageConst= {
  FORM_DEF: {
    // if cannot use dont use
    // editDef: [
    //   { name: 'name', label: 'Name', type: 'text', required: true },
    //   { name: 'description', label: 'Description', type: 'textarea', required: false }
    // ]
  },
  SEARCH_DEF: {
    inputDef: [
      {
        column: 'col-8',
        data: 'service_title',
        type: 'text',
        label: '標題',
        props: {
          clearable: true
        }
      },
      {
        column: 'col-4',
        data: 'service_status',
        type: 'autoComplete',
        label: '狀態',
        props: {
          items: getMapArray(statusMap),
          itemText: 'text',
          itemValue: 'value',
          clearable: true
        }
      },
    ],
    sortDef:[
      {
        text: '最近更新',
        value: {order_updated_at: 'desc'}
      },
      {
        text: '最早更新',
        value: {order_updated_at: 'asc'}
      },
    ],
  },
  LIST_DEF: {
    // tableDef: [
    // { name: 'name', label: 'Name', align: 'left', sortable: true },
    // { name: 'description', label: 'Description', align: 'left', sortable: false }
    // ]
  },
  REVIEW_DEF:{
    rel_model: 'T2001Service',
    key: 'tank.service',
  },
  ACTION: {
    doAdd: { api: 'T2001ServiceService.doAdd_creator'},
    getEdit: { api: 'T2001ServiceService.getEdit_creator'},
    doEdit: { api: 'T2001ServiceService.doEdit_creator'},
    doRead: { api: 'T2001ServiceService.doRead_creator'},
    doDelete: { api: 'T2001ServiceService.doDelete_creator'},
    doBrowse: { api: 'T2001ServiceService.doBrowse_creator'},
    requestApproval: { api: 'T2001ServiceService.requestApproval'}
  },
}

const module = {
  state: {
    PageConst,
    statusMap,
    statusMapArray: getMapArray(statusMap),

    // set default here
    searchInfo:{
    },
    // set default here
    orderBy:PageConst.SEARCH_DEF.sortDef[0].value,
  },

  getters: {

  },

  mutations: {

  },
  actions: {
    async doEdit ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2001ServiceService.doEdit_creator',
          input: payload
        }] })
      state.row = res[0].output
      return res[0].output
    },
    async GetACoinCurrency ({ commit,state }, payload) {
      const res = await ApiUtils.postData({ data: [{ api: 'M1902CoinsTypeCurrencyService.GetACoinCurrency' }] })
      return res[0].output[0]
    },
    async requestApproval ({ commit,state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: state.PageConst.ACTION.requestApproval.api,
          input: state.row
        }] })
      return res[0].output
    },
    async toggleServiceAvailable ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2001ServiceService.toggleServiceAvailable',
          input: {
            row: state.row,
            isAvaliable: payload
          }
        }] })
      state.row.service_status = res[0].output.service_status
      return res[0].output
    },
    async toggleServicePackageAvailable ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2101ServicePackageService.toggleServicePackageAvailable',
          input: {
            row: payload.row,
            isAvaliable: payload.status
          }
        }] })
      state.row.service_packages[payload.index].service_package_status = res[0].output.service_package_status
      return res[0].output
    },
    async updateServicePackageSort ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2101ServicePackageService.updateServicePackageSort',
          input: state.row.service_packages
        }] })
      return res[0].output
    },
    async doAdd_package ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2101ServicePackageService.doAdd_package',
          input: state.row
        }] })
      state.row.service_packages.push(res[0].output)
      return res[0].output
    },
    async doDelete_package ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2101ServicePackageService.doDelete_package',
          input: {
            newRow: state.row.service_packages,
            deleteRowId: payload
          }
        }] })
      return res[0].output
    },
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
