import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
import i18n from '@/plugins/i18n'

const statusMap={
  PENDING: {
    text: i18n.t('t2201.status.PENDING'),
    color: 'grey',
  },
  IN_PROGRESS: {
    text: i18n.t('t2201.status.IN_PROGRESS'),
    color: 'orange',
  },
  PAYMENT: {
    text: i18n.t('t2201.status.PAYMENT'),
    color: 'purple',
  },
  FAILED: {
    text: i18n.t('t2201.status.FAILED'),
    color: 'red',
  },
  ACTIVE: {
    text: i18n.t('t2201.status.ACTIVE'),
    color: 'green',
  },
  INACTIVE: {
    text: i18n.t('t2201.status.INACTIVE'),
    color: 'grey',
  },
  REJECTED: {
    text: i18n.t('t2201.status.REJECTED'),
    color: 'red',
  },
  CLOSED: {
    text: i18n.t('t2201.status.CLOSED'),
    color: 'red',
  },
  DELETED: {
    text: i18n.t('t2201.status.DELETED'),
    color: 'red',
  },
  REMOVED: {
    text: i18n.t('t2201.status.REMOVED'),
    color: 'red',
  }
}

function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}

const PageConst =  {
  // FORM_DEF: {
  //   editDef: [
  //     { name: 'name', label: 'Name', type: 'text', required: true },
  //     { name: 'description', label: 'Description', type: 'textarea', required: false }
  //   ]
  // },
  // SEARCH_DEF: {
  //   inputDef: [
  //   ],
  // },
  // LIST_DEF: {
  //   tableDef:[
  //   ]
  // },
  ACTION: {
    doAdd: { api: 'T2201ServiceApplicationService.customer_doAdd'},
    getEdit: { api: 'T2201ServiceApplicationService.customer_getEdit'},
    doEdit: { api: 'T2201ServiceApplicationService.customer_doEdit'},
    doRead: { api: 'T2201ServiceApplicationService.customer_doRead'},
    doDelete: { api: 'T2201ServiceApplicationService.customer_doDelete'},
    doBrowse: { api: 'T2201ServiceApplicationService.customer_doBrowse'},
  },
}

const module = {
  state: {
    PageConst,

    statusMapArray:getMapArray(statusMap),

    // set default here
    searchInfo:{
    },
    // set default here
    orderBy:{}
  },

  getters: {

  },

  mutations: {

  },
  actions: {
    goToChatroom ({ commit }, payload) {
      return ApiUtils.postData({
        data: [{ api: 'T2201ServiceApplicationService.goToChatroom', input: payload }]
      }).then((res) => {
        return res[0].output
      })
    }

  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
