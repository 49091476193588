import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    productDetails: {},
    userRefundList: [],
    vendorRefundList: [],
    pagination: {},
    totalRefundPending: 0
  },

  getters: {
    getProductDetails: state => state.productDetails
  },

  mutations: {
    SET_PRODUCT_DETAILS (state, payload) {
      state.productDetails = payload
    },
    SET_USER_REFUND_LIST (state, payload) {
      state.userRefundList = payload
    },
    SET_VENDOR_REFUND_LIST (state, payload) {
      state.vendorRefundList = payload
    },
    SET_PAGINATION (state, payload) {
      state.pagination = payload
    }
  },

  actions: {
    getProductDetailsRefund: async ({ commit, state }, payload) => {
      return await ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.getProductDetailsRefund', input: payload }
        ]
      }).then(data => {
        commit('SET_PRODUCT_DETAILS', data[0].output[0])
      })
    },
    getProductDetailsReview: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.getProductDetailsReview', input: payload }
        ]
      }).then(data => {
        commit('SET_PRODUCT_DETAILS', data[0].output[0])
        return data[0].output[0]
      })
    },
    createRefundRequest: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.createRefundRequest', input: payload }
        ]
      }).then(data => {
        return data[0].output
      })
    },
    updateSalesOrderItem: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.updateRefund', input: payload }
        ]
      }).then(data => {
        return data[0].output
      })
    },
    getRefundCategories: ({ commit, state }) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.getRefundCategories' }
        ]
      }).then(data => {
        return data[0].output
      })
    },
    getUserRefundList: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.getUserRefundList', input: payload }
        ]
      }).then(data => {
        commit('SET_PAGINATION', data[0].output.pagination)
        commit('SET_USER_REFUND_LIST', data[0].output.data)
      })
    },
    adminModerateUserRefund: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.adminModerateUserRefund', input: payload }
        ]
      })
    },
    getVendorRefundList: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.getVendorRefundList', input: payload }
        ]
      }).then(data => {
        commit('SET_PAGINATION', data[0].output.pagination)
        commit('SET_VENDOR_REFUND_LIST', data[0].output.data)
      })
    },
    getAdditionalDataForReview: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.getAdditionalDataForReview', input: payload }
        ]
      }).then(data => {
        return data[0].output[0]
      })
    },
    createChatroomForRefund: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.createChatroomForRefund', input: payload }
        ]
      }).then(data => {
        return data[0].output
      })
    },
    countPendingRefund: ({ commit, state }, payload) => {
      return ApiUtils.postData({
        data: [
          { api: 'M3003RefundService.countPendingRefund', input: payload }
        ]
      }).then(data => {
        state.totalRefundPending = data[0].output[0].Pending_Number
        return data[0].output[0].Pending_Number
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
