<template>
  <button
    class="membership-login-method"
    @click.prevent="loginWithFacebook"
  >
    <template v-if="inited">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        width="22"
        height="22"
        viewBox="0 0 512 512"
        style="enable-background:new 0 0 512 512"
        xml:space="preserve"
      >
        <g>
          <path
            d="M448 0H64C28.704 0 0 28.704 0 64v384c0 35.296 28.704 64 64 64h384c35.296 0 64-28.704 64-64V64c0-35.296-28.704-64-64-64z"
            fill="#1976d2"
          />
          <path
            d="M432 256h-80v-64c0-17.664 14.336-16 32-16h32V96h-64c-53.024 0-96 42.976-96 96v64h-64v80h64v176h96V336h48l32-80z"
            fill="#fafafa"
          />
        </g>
      </svg>
      使用<span class="en fw-600">Facebook</span>登入
    </template>
    <template v-else>
      <v-progress-circular
        color="blue"
        indeterminate
        size="25"
      />
    </template>
  </button>
</template>

<script>
export default {
  props: {
    spinnerBackgroundStyle: {
      type: Object,
      default: () => ({
        background: '#4267b2',
        borderRadius: '5px',
        color: 'white',
        height: '40px',
        width: '300px'
      })
    },
    maxRows: {
      type: Number,
      default: 1
    },
    size: {
      type: String,
      default: 'large',
      validator: (val) => {
        return ['large', 'medium', 'small'].includes(val)
      }
    },
    dataWidth: {
      type: String,
      default: '300'
    },
    buttonType: {
      type: String,
      default: 'continue_with',
      validator: (val) => {
        return ['continue_with', 'login_with'].includes(val)
      }
    },
    useContinueAs: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      inited: false
    }
  },
  computed: {
    sdkLocale () {
      return this.convertLocale(this.currentLocale, 'bcp47').replace('-', '_')
    }
  },
  created () {
    const elements = document.getElementsByTagName('script')
    elements.forEach(el => {
      if (el.src?.startsWith('https://connect.facebook.net/')) {
        el.remove()
      }
    })
    delete window.FB
    window.loginWithFacebook = this.loginWithFacebook
  },
  mounted () {
    this.initializeFacebookSDK()
  },
  methods: {
    initializeFacebookSDK () {
      window.fbAsyncInit = () => {
        window.FB.Event.subscribe('xfbml.render', this.finishedRendering)
        window.FB.init({
          appId: import.meta.env.VITE_APP_FACEBOOK_CLIENT_ID,
          cookie: true,
          xfbml: true,
          version: import.meta.env.VITE_APP_FACEBOOK_API_VERSION
        })
        window.FB.AppEvents.logPageView()
      }
      // Insert Facebook SDK if not found in header
      const id = 'facebook-jssdk'
      if (!document.getElementById(id)) {
        const fjs = document.getElementsByTagName('script')[0]
        const js = document.createElement('script')
        js.id = id
        if (import.meta.env.NODE_ENV === 'development') {
          js.src = `https://connect.facebook.net/${this.sdkLocale}/sdk/debug.js`
        } else {
          js.src = `https://connect.facebook.net/${this.sdkLocale}/sdk.js`
        }
        fjs.parentNode.insertBefore(js, fjs)
      }
    },
    // Remove spinner while Facebook SDK had been initialized
    finishedRendering () {
      this.inited = true
    },
    loginWithFacebook () {
      window.FB.login(response => {
        if (response.authResponse) {
          const obj = {
            social_media: 'FACEBOOK',
            user_id: response.authResponse.userID,
            access_token: response.authResponse.accessToken
          }
          this.$emit('login:success', obj)
        } else {
          this.$emit('login:cancel')
        }
      })
    },
    logout () {
      window.FB.logout(response => {
        //
      })
    }
  }
}
</script>
<style scoped src="bootstrap/dist/css/bootstrap.min.css"></style>
<style scoped src="@/assets/css/style.css"></style>

<style scoped>
.full-height {
  height: 100%
}
</style>
