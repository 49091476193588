import i18n from '@/plugins/i18n'

export default [
  {
    path: 'my/dashboard',
    name: 'UserDashboard',
    component: () => import('./UserDashboard/PgUserDashboard.vue'),
    portalGroup: 'all.public',
  },
  {
    path: 'user/@:alias',
    name: 'UserDetail',
    component: () => import('@/app/M0001User/UserProfileLayout.vue'),
    portalGroup: 'master.public',
    redirect: { name: 'UserDetailIntro' },
    children: [
      {
        path: 'intro',
        name: 'UserDetailIntro',
        component: () => import('@/app/M0001User/comps/PgIntro.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introIndividual') }
      },
      {
        path: 'follower',
        name: 'UserDetailFollower',
        component: () => import('@/app/M0001User/comps/PgFollower.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introFollower') }
      },
      {
        path: 'members',
        name: 'UserDetailMembers',
        component: () => import('@/app/M0001User/comps/PgEnterpriseMember.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introMembers') }
      },
      {
        path: 'publish',
        name: 'UserDetailPublish',
        component: () => import('@/app/M0001User/comps/PgPublish.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introPublish') }
      },
      {
        path: 'activities',
        name: 'UserDetailActivities',
        component: () => import('@/app/M0001User/comps/PgActivities.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introActivities') }
      },
      {
        path: 'services',
        name: 'UserDetailServices',
        component: () => import('@/app/M0001User/comps/PgServices.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introServices') }
      },
      {
        path: 'recommendations',
        name: 'UserDetailRecommendations',
        component: () => import('@/app/M0001User/comps/PgRecommendation.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introRecommendations') }
      },
      {
        path: 'achievements',
        name: 'UserDetailAchievements',
        component: () => import('@/app/M0001User/comps/PgAchievement.vue'),
        meta: { title: i18n.t('individualProfile.navigation.introAchievements') }
      },
      {
        path: 'calendar',
        name: 'UserCalendar',
        component: () => import('@/app/M0001User/Calendar/comps/Calendar.vue'),
        meta: { title: '日曆'}
      },
      {
        path: '*',
        redirect: { name: 'UserDetailIntro' }
      }
    ]
  },
  // {
  //   path: 'my/setting',
  //   name: 'UserSetting',
  //   // component: () => import('./UserUpgrade/PgUserUpgrade.vue'),
  //   // meta: { authenticated: true, hideHeader: true,title:"用戶升級" },
  //   portalGroup: 'master.public',
  // },
  {
    path: 'my/calendar',
    redirect: { name: 'UserCalendar', params: { alias: 'me'} },
  },
  {
    path: 'my/bookmark',
    name: 'UserBookmark',
    component: () => import('../M3501Bookmark/user/PgList.vue'),
    portalGroup: 'all.public',
  },
  {
    path: 'userupgrade',
    name: 'UserUpgrade',
    component: () => import('./UserUpgrade/PgUserUpgrade.vue'),
    meta: { authenticated: true, hideHeader: true,title:"用戶升級" },
    portalGroup: 'master.public',
  },
  {
    path: 'my/setting',
    name: 'UserSetting',
    component: () => import('../M0001User/UserSetting/PgUserSetting.vue'),
    meta: { authenticated: true,title:"用戶設置" },
    portalGroup: 'master.public',
  },
  {
    path: '/admin/userupgrade/:id/review',
    name: 'UserUpgradeReview',
    component: () => import('./UserUpgrade/PgAdminReviewUserUpgrade.vue'),
    meta: { authenticated: true, hideHeader: true,title:"用戶升級審核" },
  },
  {
    path: 'UserUpgradeCheckout',
    name: 'UserUpgradeCheckout',
    component: () => import('./UserUpgrade/PgUserUpgradeCheckout.vue'),
    meta: { authenticated: true,title:"用戶升級" },
    portalGroup: 'master.public',
  },
  {
    path: 'user',
    name: 'UserList',
    component: () => import('@/app/M0001User/PgUserList.vue'),
    portalGroup: 'admin.admin',
    meta: {
      title: i18n.t('m0001.userList'),
      authenticated: true
      // permissions: ['user.manage']
    }
  },
  {
    path: 'EnterpriseIntro',
    name: 'EnterpriseIntro',
    component: () => import('./PgEnterpriseIntro.vue'),
    meta: { authenticated: false,title:"企業介紹" },
    portalGroup: 'master.public',
  },
]
