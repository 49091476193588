import i18n from '@/plugins/i18n'

export default [
  {
    path: 'Dashboard',
    name: 'AdminDashboard',
    component: () => import('./Admin/PgAdminDashboard.vue'),
    portalGroup: 'admin.admin',
    meta: {
      title: i18n.t('dashboard.adminDashboard.label'),
      authenticated: true,
      permissions: ['admin.dashboard']
    }
  }
]
