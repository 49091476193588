import Vue from 'vue/dist/vue.js'

export default class InlineComment {
  // static get isInline () { return false }

  // static get toolbox () {
  //   return {
  //     title: 'Comment Section',
  //     icon: ''
  //   }
  // }

  // tune block settings
  static get isTune () { return true }

  constructor ({ data, config, api, block }) {
    this.data = data || { comments: [] }
    this.block = block
    this.config = config || undefined
    this.wrapper = null
    this.vueComponent = null
  }

  render () {
    // hide the button
    return document.createElement('div')
    // return {
    //   // TODO: add icon
    //   icon: '<svg>...</svg>',
    //   label: 'Add Comment',
    //   closeOnActivate: true,
    //   onActivate: () => {
    //     // add comment key into data
    //     console.log('🚀 ~ file: Test.js:167 ~ CommentSectionPlugin ~ render ~ this.data:', this.data)
    //     if (this.data === undefined) {
    //       this.data = { comments: [] }
    //       this.addVueComponent()
    //     }
    //     // console.log('data.comments initialized to: ' + this.data.comments)
    //     this.showInput = true
    //     this.rerenderVueComponent()
    //   }
    // }
  }

  save (blockContent) {
    // console.log('🚀 ~ file: Test.js:167 ~ CommentSectionPlugin ~ render ~ this.data:', this.data)
    return this.data
  }

  wrap (blockContent) {
    const myWrapper = document.createElement('div')
    myWrapper.classList.add('my-wrapper')

    myWrapper.appendChild(blockContent)

    this.wrapper = myWrapper
    this.addVueComponent()

    return myWrapper
  }

  addVueComponent (show = 'hide') {
    const globalData = {
      permission: Permissions[this.config.mode],
      // [edit, comment, view]
      mode: this.config.mode,
      // type: [String, Number],
      currentUserId: this.config.currentUserId,
      // type: Array,
      usersData: this.config.usersData
    }
    Vue.mixin({
      data () {
        return globalData
      }
    })
    // create a new vue component using imported component
    this.vueComponent = new Vue(
      {
        render: h => h(this.config.component, {
          props: {
            value: this.data.comments,
            show
          },
          on: {
            sendComment: ({ content }) => {
              // callback is promise will return the commentId or boolean
              // if return string|number, then update the commentId
              // if return true, then add the comment
              // if return false, then do nothing
              this.config.callback.sendComment({ content, blockId: this.block.id })
                .then((commentId) => {
                  if (['boolean', 'string', 'number'].includes(typeof commentId) === false) {
                    throw new Error('commentId must be string, number, boolean')
                  }
                  if (!commentId) { return }
                  const input = Comment.getNewComment({ content, createdBy: this.config.currentUserId })
                  if (['string', 'number'].includes(typeof commentId) === true) {
                    input.id = commentId
                  }
                  this.data.comments.push(input)
                })
                .finally(() => {
                  this.rerenderVueComponent('unresolved')
                })
            },
            resolveComment: ({ commentId, resolved }) => {
              this.config.callback.resolveComment({ commentId, resolved, blockId: this.block.id })
                .then((result) => {
                  if (result === true) {
                    this.data.comments.find(comment => comment.id === commentId).resolved = resolved
                  }
                }
                )
                .finally(() => {
                  if (!this.data.comments.filter(comment => comment.resolved === true).length) {
                    this.rerenderVueComponent('unresolved')
                  } else {
                    this.rerenderVueComponent(resolved ? 'unresolved' : 'resolved')
                  }
                }
                )
            },
            replyComment: ({ commentId, content }) => {
              this.config.callback.replyComment({ commentId, content, blockId: this.block.id })
                .then((replyId) => {
                  if (['boolean', 'string', 'number'].includes(typeof commentId) === false) {
                    throw new Error('replyId must be string, number, boolean')
                  }
                  if (!replyId) { return }
                  const input = Comment.getNewReply({ content, createdBy: this.config.currentUserId })
                  if (['string', 'number'].includes(typeof replyId) === true) {
                    input.id = replyId
                  }
                  this.data.comments.find(comment => comment.id === commentId).reply.push(input)
                }).finally(() => {
                  this.rerenderVueComponent('unresolved')
                })
            },
            editComment: ({ commentId, replyId, content }) => {
              this.config.callback.editComment({ blockId: this.block.id, commentId, replyId })
                .then(result => {
                  if (result) {
                    if (replyId) {
                      const comment = this.data.comments.find(comment => comment.id === commentId)
                      const replyIndex = comment.reply.findIndex(reply => reply.id === replyId)
                      comment.reply[replyIndex].content = content
                    } else {
                      const commentIndex = this.data.comments.findIndex(comment => comment.id === commentId)
                      this.data.comments[commentIndex].content = content
                    }
                  }
                }).finally(() => {
                  this.rerenderVueComponent('unresolved')
                })
            },
            deleteComment: ({ commentId, replyId }) => {
              this.config.callback.deleteComment({ blockId: this.block.id, commentId, replyId })
                .then((result) => {
                  if (result === true) {
                    if (replyId) {
                      const comment = this.data.comments.find(comment => comment.id === commentId)
                      const replyIndex = comment.reply.findIndex(reply => reply.id === replyId)
                      comment.reply.splice(replyIndex, 1)
                    } else {
                      const commentIndex = this.data.comments.findIndex(comment => comment.id === commentId)
                      this.data.comments.splice(commentIndex, 1)
                    }
                  }
                }).finally(() => {
                  this.rerenderVueComponent('unresolved')
                })
            }

          }

        })
      }).$mount()

    this.wrapper.appendChild(this.vueComponent.$el)
  }

  rerenderVueComponent (show) {
    if (this.destroyVueComponent() === false) { return }
    this.addVueComponent(show)
  }

  destroyVueComponent () {
    if (this.vueComponent === null) { return false }
    this.wrapper.removeChild(this.vueComponent.$el)
    this.vueComponent = null
    return true
  }
}

// mode: edit, comment, view
const Permissions = {
  edit: {
    canResolve: false,
    addComment: false
  },
  comment: {
    canResolve: true,
    addComment: true
  }
}
const Comment = {
  getCurrentDateTime: (timezoneOffset = 8) => {
    const newDate = new Date()
    newDate.setHours(newDate.getHours() + timezoneOffset)
    return newDate.toISOString().slice(0, 19).replace('T', ' ')
  },
  genRandomId: () => {
    return Math.random().toString(36).substr(2)
  },
  getNewComment: ({ content, createdBy }) => {
    return {
      id: Comment.genRandomId(),
      content,
      createdAt: Comment.getCurrentDateTime(),
      createdBy,
      resolved: false,
      reply: []
    }
  },
  getNewReply: ({ content, createdBy }) => {
    return {
      id: Comment.genRandomId(),
      content,
      createdAt: Comment.getCurrentDateTime(),
      createdBy
    }
  }
}
