<template>
  <VTextField
    v-bind="finalAttr"
    v-on="$listeners"
  >
    <slot
      v-for="(_, slot) of $slots"
      :slot="slot"
      :name="slot"
    />
    <template
      v-for="(_, slot) of $scopedSlots"
      #[slot]="scope"
    >
      <slot
        :name="slot"
        v-bind="scope"
      />
    </template>
  </VTextField>
</template>

<script>
import DataUtils from '@/utils/DataUtils'
/**
  * Extend VTextField
  * @displayName AtTextField
  */
export default {
  computed: {
    finalAttr () {
      const defaultAttr = {
        outlined: true,
        label: 'Default Label'
      }
      return DataUtils.mergeObjects(defaultAttr, this.$attrs)
    }
  }
}
</script>

<style>

</style>
