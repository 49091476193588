import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    productDetails: {},
    serviceDetails: {},
    applyApplication: {}
  },
  getters: {},
  mutations: {},
  actions: {
    async getProductDetails({ commit, state, dispatch }, payload) {
      state.productDetails = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T2001ServiceService.doRead_productDetails', input: {
              id: payload.id,
              seo: payload.seo
            }
          }
        ]
      })
      state.productDetails = res[0].output

      console.log('state.productDetails', state.productDetails)
      return new Promise((resolve, reject) => {
        resolve(state.productDetails)
      })
    },
    async getServiceDetails({ commit, state, dispatch }, payload) {
      state.serviceDetails = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T2001ServiceService.doRead_serviceDetails', input: {
              id: payload.id,
              seo: payload.seo
            }
          }
        ]
      })
      state.serviceDetails = res[0].output

      console.log('state.serviceDetails', state.serviceDetails)
      return new Promise((resolve, reject) => {
        resolve(state.serviceDetails)
      })
    },
    async customer_applyApplication({ commit, state, dispatch }, payload) {
      state.applyApplication = {}

      const res = await ApiUtils.postData({
        data: [
          { api: 'T2201ServiceApplicationService.customer_applyApplication', input: payload }
        ]
      })
      state.applyApplication = res[0].data

      console.log('state.applyApplication', state.applyApplication)
      return new Promise((resolve, reject) => {
        resolve(state.applyApplication)
      })
    },
  }
}

export default ObjArrUtils.merge(Base, error, module)
