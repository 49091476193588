import i18n from '@/plugins/i18n'

export default [
  /**
   * Public Project Details
   */
  {
    portalGroup: 'collab.public',
    path: 'project/:seo',
    name: 'ProjectDetails',
    component: { render: h => h('router-view') },
    redirect: { name: 'ProjectOverview' },
    children: [
      {
        // with detail layout
        path: 'd',
        component: () => import('./public/ProjectDetails/ProjectDetailsLayout.vue'),
        meta: {},
        children: [
          {
            path: 'layout',
            name: 'ProjectDetailsLayout',
            component: { render: h => h('div', 'ProjectDetailsLayout(Debug).vue') },
            meta: { resetBreadcrumb: false, debug: true }
          },
          {
            path: 'overview',
            name: 'ProjectOverview',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectDetailOverview.vue'),
            meta: { resetBreadcrumb: false }
          },
          // update
          {
            path: 'update',
            name: 'ProjectUpdate',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectUpdate.vue'),
            meta: { resetBreadcrumb: false }
          },
          {
            path: 'update/:id',
            name: 'ProjectUpdateDetail',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectUpdateDetail.vue'),
            meta: { resetBreadcrumb: false }
          },
          {
            path: 'investment',
            name: 'ProjectInvestment',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectInvestment.vue'),
            meta: { resetBreadcrumb: false }
          },
          {
            path: 'partnership',
            name: 'ProjectPartnership',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectPartnership.vue'),
            meta: { resetBreadcrumb: false }
          },
          {
            path: 'partnership/:id',
            name: 'ProjectPartnershipDetail',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectPartnershipDetail.vue'),
            meta: { resetBreadcrumb: false }
          },
          // advise
          {
            path: 'advise',
            name: 'ProjectAdvise',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectAdvise.vue'),
            meta: { resetBreadcrumb: false }
          },
          {
            path: 'advise/:id',
            name: 'ProjectAdviseDetail',
            component: () => import('./public/ProjectDetails/ProjectDetailsLayout/PgProjectAdviseDetail.vue'),
            meta: { resetBreadcrumb: false }
          }
        ]
      },
      {
        path: 'a',
        component: () => import('./public/ProjectDetails/ProjectLayoutA.vue'),
        children: [
          {
            path: 'support',
            name: 'ProjectSupport',
            component: () => import('./public/ProjectDetails/ProjectLayoutA/PgProjectSupport.vue')
          },
          {
            path: 'crowdfund',
            name: 'ProjectCrowdfund',
            component: () => import('./public/ProjectDetails/ProjectLayoutA/PgProjectCrowdfund.vue')
          },
          // Checkout
          {
            path: 'crowdfund/checkout',
            name: 'ProjectCrowdfundCheckout',
            component: () => import('./public/ProjectDetails/ProjectLayoutA/PgProjectCrowdfundCheckout.vue')
          },
          {
            path: 'investment/form',
            name: 'ProjectInvestmentForm',
            component: () => import('./public/ProjectDetails/ProjectLayoutA/PgProjectInvestmentForm.vue')
          },
          {
            path: 'partnership/:id/form',
            name: 'ProjectPartnershipForm',
            component: () => import('./public/ProjectDetails/ProjectLayoutA/PgProjectPartnershipForm.vue')
          }
        ]
      },
      {
        path: '*',
        redirect: { name: 'ProjectDetails' }
      }
    ]
  },
  {
    portalGroup: 'collab.public',
    path: 'advise/:seo',
    name: 'PgAdviseDetailsWithNoProject',
    component: () => import('./public/PgAdviseDetailsWithNoProject.vue'),
  },

  // TVP routes
  {
    portalGroup: 'collab.public',
    path: 'projects',
    component: { render: h => h('router-view') },
    children:[
      // {
      //   path: '',
      //   name: 'ProjectPublicBrowse',
      //   component: () => import('./PgC0101List.vue'),
      //   meta: {}
      // },
      {
        path: ':id',
        name: 'ProjectPublicRead',
        // component: () => import('./PgC0101View.vue'),
        // meta: { title: i18n.t('c0101.read.title') }
        redirect: to => {
          return { name: 'ProjectDetails', params: { seo: 'a__'+to.params.id } }
        }
      },
    ]
  },
  /**
   * For My Projects (Owner)
   */
  {
    portalGroup: 'collab.public',
    path: 'my/projects',
    component: { render: h => h('router-view') },
    redirect: { name: 'ProjectMyBrowse' },
    children:[
      {
        path: '',
        name: 'ProjectMyBrowse',
        component: () => import('./PgC0101MultiPage.vue'),
        meta: { title: i18n.t('c0101.browse.title'), authenticated: true },
      },
      {
        path: ':id/edit',
        name: 'ProjectMyEdit',
        component: () => import('./PgC0101Form.vue'),
        meta: { title: i18n.t('c0101.edit.title'), authenticated: true }
      },
      {
        path: 'dev',
        name: 'ProjectMyEditDev',
        component: () => import('./PgC0101FormDev'),
        meta: { title: i18n.t('c0101.edit.title'), authenticated: true, debug: true }
      },
      {
        path: 'question/:id',
        name: 'ProjestQuestionForm',
        component: () => import('./PgC4001FormEdit.vue'),
        meta: { title: 'Create/ Edit Question', authenticated: true }
      },
      {
        path: ':id/teammember',
        name: 'ProjectMyTeamMember',
        component: () => import('../C0201ProjectMember/PgC0201ProjectMember.vue'),
        meta: { title: i18n.t('c0101.edit.title'), authenticated: true }
      },
      {
        path: ':id/backer',
        name: 'ProjectBackerList',
        component: () => import('./ProjectBackerList/PgProjectBackerList.vue'),
        meta: {
          title: '資助者列表'
        }
      },
      {
        path: ':id/investor',
        name: 'ProjectInvestorList',
        component: () => import('./ProjectInvestorList/PgProjectInvestorList.vue'),
        meta: {
          title: '資助者列表'
        }
      },
      {
        path: ':id/partner',
        name: 'ProjectPartnerList',
        component: () => import('./ProjectPartnerList/PgProjectPartnerList.vue'),
        meta: {
          title: '合作夥伴列表'
        }
      },
      {
        path: '*',
        redirect: { name: 'ProjectMyBrowse' }
      }
    ]
  },
  /**
   * For My Projects (Applicant)
   */
  {
    portalGroup: 'collab.public',
    path: 'my',
    component: { render: h => h('router-view') },
    children:[
      {
        path: 'activity',
        name: 'ProjectMyActivity',
        component: () => import('./My/customer/PgC0101MyCustomerList.vue'),
        meta: { title: '活動列表', authenticated: true },
      },
      {
        path: 'BackedApplication',
        name: 'UserBackList',
        component: () => import('./UserBackList/PgUserBackList.vue'),
        meta: { // my backed project
          title: '支持項目申請',
          authenticated: true
        }
      },
      {
        path: 'investApplication',
        name: 'UserInvestList',
        component: () => import('./UserInvestList/PgUserInvestList.vue'),
        meta: {
          title: '投資項目申請',
          authenticated: true
        }
      },
      {
        path: 'PartnerApplication',
        name: 'UserPartnerList',
        component: () => import('./UserPartnerList/PgUserPartnerList.vue'),
        meta: {
          title: '合作夥伴申請',
          authenticated: true
        }
      },
      // VBP Routes
      {
        path: 'projectCalendar',
        name: 'ProjectCalendar',
        component: () => import('./ProjectCalendar/PgCalendar.vue'),
        meta: { authenticated: true, title: i18n.t('l0101.calendar.title') }
      }
    ]
  },
  {
    portalGroup: 'admin.admin',
    path: 'projects',
    component: { render: h => h('router-view') },
    children:[
      {
        path: '',
        name: 'ProjectAdminBrowse',
        component: () => import('./PgC0101MultiPage.vue'),
        meta: { title: i18n.t('c0101.browse.title'), authenticated: true }
      },
      {
        path: '/admin/project/:id?/review',
        name: 'C0101AdminReview',
        component: () => import('./Admin/PgReview.vue'),
        meta: {},
      },
      {
        path: 'partner',
        name: 'ProjectPartnerApplicationList',
        component: () => import('./AdminPartnerList/AdminPartnerList.vue'),
        meta: {
          title: '合作夥伴申請',
        },
      },
      {
        path: 'investment',
        name: 'AdminProjectInvestmentList',
        component: () => import('./AdminInvestList/PgAdminInvestorList.vue'),
        meta: {
          title: '投資項目申請',
        }
      }
    ]
  }
]
