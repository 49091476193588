// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    items: [],
    VModel: {
      searchInfo: {
      },
      orderBy: {
        order_created_at: 'desc'
      },
      pagination: {
        current_page: 1,
        last_page: 0,
        per_page: 10,
        total: null
      }
    },
    portal: '',
    approvalKeyItems: [],
    rejectCategory: [],
    notification: {}
  },

  getters: {
    getItems (state) {
      return state.items
    },
    getVModel (state) {
      return state.VModel
    },
    getPortal (state) {
      return state.portal
    },
    getApprovalKeyItems (state) {
      return state.approvalKeyItems
    },
    getRejectCategory (state) {
      return state.rejectCategory
    },
    getNotification (state) {
      return state.notification
    }
  },

  mutations: {
    SET_ITEMS (state, payload) {
      state.items = payload
    },
    SET_PAGINATION (state, payload) {
      state.VModel.pagination = payload
    },
    SET_ORDER_BY (state, payload) {
      state.VModel.orderBy = payload
    },
    SET_SEARCH_INFO (state, payload) {
      state.VModel.searchInfo = payload
    },
    SET_VMODEL (state, payload) {
      state.VModel = payload
    },
    SET_PORTAL (state, payload) {
      state.portal = payload
    },
    SET_APPROVAL_KEY_ITEMS (state, payload) {
      state.approvalKeyItems = payload
    },
    SET_REJECT_CATEGORY (state, payload) {
      state.rejectCategory = payload
    },
    SET_NOTIFICATION (state, payload) {
      state.notification = payload
    }
  },

  actions: {
    searchApprovalList: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.searchApprovalList', input }
        ]
      })
        .then((data) => {
          commit('SET_ITEMS', data[0].output.data)
          commit('SET_PAGINATION', data[0].output.pagination)
        })
    },
    updateContentStatus: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.updateContentStatus', input }
        ]
      })
        .then((data) => {
          return data
        })
    },
    retrieveApprovalKeyList: ({ commit }) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.getApprovalKeyList' }
        ]
      })
        .then((data) => {
          commit('SET_APPROVAL_KEY_ITEMS', data[0].output)
        })
    },
    retrieveNotification: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.retrieveNotification', input }
        ]
      })
        .then((data) => {
          commit('SET_NOTIFICATION', data[0].output[0])
        })
    },
    initAdminReview: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.getApprovalData', input },
          { api: 'M2301ApprovalService.getCategories' }
        ]
      })
        .then((data) => {
          commit('SET_REJECT_CATEGORY', data[1].output)
          return data[0].output
        })
    },
    doAddChatroomAdmin: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.doAddChatroomAdmin', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    },
    updateApprovalStatus: ({ commit }, input) => {
      return ApiUtils.postData({
        data: [
          { api: 'M2301ApprovalService.updateApprovalStatus', input }
        ]
      }).then((data) => {
        return data[0].output
      })
    }
  }
}
export default ObjArrUtils.merge(Base, error, BREAD, module)
