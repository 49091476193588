// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const PageConst ={
  FORM_DEF: {
    editDef: [
    ]
  },
  SEARCH_DEF: {
    inputDef: [
      {
        column: 'col-12',
        data: 'title',
        type: 'text',
        label: 'Title',
        props: {
          clearable: true
        }
      }
    ],
    sortDef:[
      {
        text: '最近購買',
        value:{order_m3001_created_at: 'desc'}
      },
      {
        text: '最早購買',
        value: {order_m3001_created_at: 'desc'}
      }
    ],
  },
  LIST_DEF: {
    tableDef: [
    ]
  },
  ACTION: {
    // doAdd: { api: 'T3001FundingService.doAdd_customer'},
    // getEdit: { api: 'T3001FundingService.getEdit_customer'},
    // doEdit: { api: 'T3001FundingService.doEdit_customer'},
    // doRead: { api: 'T3001FundingService.doRead_customer'},
    // doDelete: { api: 'T3001FundingService.doDelete_customer'},
    doBrowse: { api: 'T4001SurveyDataService.salesOrderItem_customer_doBrowse'},
  },
}
const module = {
  state: {
    PageConst,

    searchInfo:{
    },
    orderBy: PageConst.SEARCH_DEF.sortDef[0].value,
  },

  getters: {

  },

  mutations: {

  },
  actions: {


  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
