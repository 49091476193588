import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'admin.admin',
    path: 'projectPromotionTable',
    name: 'ProjectPromotionTable',
    component: () => import('./PgZZ0201PromotionRequestTable.vue'),
    meta: { title: i18n.t('zz0201.adminPageTitle') }
  }
]
