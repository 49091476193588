import store from '@/store'
import AppUtils from '@/utils/AppUtils'
import ApiUtils from '@/utils/api/ApiUtils'
import { PortalUtils } from '@/utils/PortalUtils/index.js'

// TODO: refactor
export const authorizationCheck = async (to, from, next) => {
  console.log('Navigating from:', from.path, 'to:', to.path)

  // Abort all running AJAX API request before navigate to other route to increase performance
  if (to.name !== from.name) ApiUtils.abortAll()

  // Handle localhost access
  if (to.name === 'portalError') return next()
  if (PortalUtils.checkIsLocalhost()) return next({ name: 'portalError' })

  // Handle portal change
  if (to.meta && to.query.portal) {
    if (to.query.portal !== PortalUtils.name) {
      let targetUrl = PortalUtils.getOrigin(to.query.portal).slice(0, -1)
      if (!targetUrl) next({ name: '404' })
      targetUrl += to.fullPath
      // remove portal query in url string, use URL API to remove query
      const url = new URL(targetUrl)
      url.searchParams.delete('portal')
      targetUrl = url.toString()
      // console.log('targetUrl', targetUrl)
      window.location.href = targetUrl
      return
    } else {
      // delete to.query.portal
    }
  }
  // NOTE: check portal is valid in this route to.meta.portal
  if (to.meta && to.meta.portal && !to.meta.portal.includes(PortalUtils.name)) return next({ name: '404' })

  // Initialize user data if it is not initialized
  try {
    if (store.getters['Auth/getInit'] === null) await store.dispatch('Auth/init')
  }
  catch (error) {
    console.warn('Auth/init Error')
  }

  // when non admin user try to access admin portal
  if (PortalUtils.name === 'admin' &&
    !AppUtils.allowAccess(true, ['SA', 'VA', 'PA', 'PAP', 'PAL', 'PAC', 'PAA'])) {
    return next({ name: 'Landing', query: { portal: 'master' } })
  }

  // Authentication and Authorization Checks (combined into a single block)
  if (to.meta) {
    const { authenticated, roles, permissions, notLogged, debug } = to.meta
    // when user wants to access development only page in production mode
    if (debug && !AppUtils.isDebugMode()) return next({ name: 'Home' })

    // when user wants to access development only page in development mode
    if (debug && from.name !== 'AllCompsExample') {
      console.warn('Development only page!!\n if this page is production page, please remove `debug: true` in meta')
    }

    // when user is not logged in and going to a logged-in page
    if (authenticated && !AppUtils.isLoggedIn()) {
      return next({ name: 'Login', query: { next: to.fullPath } })
    }

    // when user is logged in and going to a not-logged-in page
    if (notLogged && AppUtils.isLoggedIn()) {
      return next({ name: 'Home' })
    }

    // when user is open a page that requires roles or permissions that user does not have
    if ((roles || permissions) && !AppUtils.allowAccess(authenticated, roles, permissions)) {
      return next({ name: 'Home' })
    }
  }

  // If all checks pass, allow navigation
  next()
}
