import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'C4001ProjectAdvise',
  serviceName: 'C4001ProjectAdviseService',
  storeConst: {
    ACTION: {
      getHint: {
        store: 'M3901Guide/getHint'
      },
      getAlert: {
        api: 'C4001ProjectAdviseService.getAlert'
      }
    },
    PROPERTY: {
      init: {
        get: 'C4001ProjectAdvise/getInit'
      },
      alert: {
        get: 'C4001ProjectAdvise/getAlert',
        set: 'C4001ProjectAdvise/SET_ALERT'
      }
    }
  },
  componentDef: {
    form: {
      viewDef: [
        // {
        //   column: 'col-12',
        //   data: 'm0801_id',
        //   type: 'select',
        //   label: i18n.t('c4001.filter.m0801_id')
        // },
        {
          column: 'col-12',
          data: 'project_advise_question_title',
          type: 'text',
          label: i18n.t('c4001.filter.project_advise_question_title')
        },
        {
          column: 'col-12',
          data: 'project_advise_question_desc',
          custom: 'MoBlockEditor',
          label: i18n.t('c4001.filter.project_advise_question_desc')
        },
        {
          column: 'col-12',
          data: 'm0601_id',
          type: 'ttc_category'
        },
        {
          column: 'col-12',
          data: 'project_advise_tag',
          type: 'ttc_tag'
        },
        {
          column: 'col-12',
          data: 'project_advise_policy',
          type: 'ttc_policy',
          props: {
            policyCode: 'collab.projectAdvise'
          }
        }
      ]
    }
  }
}
const C4001Const = new BaseDataStore(BaseConst)
export default C4001Const
