import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'all.public',
    path: '/home',
    name: 'Home',
    component: () => import('./Home.vue'),
    meta: { title: i18n.t('dashboard.home') }
  }
]
