// import Vue from 'vue'
import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// import i18n from '@/plugins/i18n'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    searchFAQ: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M5001FAQService.searchFAQ', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getAllCategory: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M5001FAQService.searchCategory', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getAllPortal: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M5001FAQService.showPortal', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getFAQDetail: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M5001FAQService.GetFAQDetail', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getStaffPick: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M4601StaffPicksService.getStaffPickFAQ' }]
      }).then(data => {
        return data[0].output
      })
    },
    getFAQList: ({commit},payload) => {
      return ApiUtils.postData({
        data: [{api:'M5001FAQService.getFAQList'}]
      }).then(data => {
        return data[0].output
      })
    },
    getFAQ: ({commit},payload) => {
      return ApiUtils.postData({
        data: [{api:'M5001FAQService.getEditFAQ'}]
      }).then(data => {
        return data[0].output
      })
    },
    getFAQCategory: ({commit},payload) => {
      return ApiUtils.postData({
        data: [{api:'M5001FAQService.getFAQCategoryList'}]
      }).then(data => {
        return data[0].output
      })
    },
    getEditFAQ: ({commit},payload) => {
      return ApiUtils.postData({
        data: [{api:'M5001FAQService.getEditFAQ',input: payload}]
      }).then(data => {
        return data[0].output
      })
    },
    addFAQ: ({commit},payload) => {
      return ApiUtils.postData({
        data: [{api:'M5001FAQService.admin_doAdd',input: payload}]
      }).then(data => {
        return data[0].output
      })
    },
    doSave: ({commit},payload) => {
      return ApiUtils.postData({
        data: [{api:'M5001FAQService.doSave',input: payload}]
      }).then(data => {
        return data[0].output
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
