import ObjArrUtils from '@/utils/ObjArrUtils'
import ApiUtils from '@/utils/api/ApiUtils'
import { mapGetters, mapMutations } from 'vuex'

/**
 * Class that serves as a base for creating extended data stores in Vue.js.
 * It defines a set of methods and computed properties that can be used by child components
 * 
 * @method getStore  Returns a store object that can be used to create a Vuex store.
 * @property Component  Returns a Vue.js component object that can be used to create a Vue.js component.
 * @property 
 */
class BaseDataStoreV1 {
  /**
   * 
   * @param {Object} data 
   * @param {int}   data.version Version of the data store // TODO: support diff format?
   * @param {String} data.storeName Name of the Vuex store
   * @param {String} data.serviceName Name of the service
   * @param {Object} data.componentDef Component definition object
   * @param {Object} data.componentDef.[componentName] Component definition object
   * @param {Object} data.storeConst
   * @param {Object} data.storeConst.ACTION
   * @param {String} data.storeConst.ACTION.[actionName].store
   * @param {String} data.storeConst.ACTION.[actionName].api
   * @param {String} data.storeConst.PROPERTY 
   * @param {String} data.storeConst.PROPERTY.[state].get
   * @param {String} data.storeConst.PROPERTY.[state].set
   * @param {String} data.storeConst.PAGE_NAME // TODO: no uses cases
   * 
   */
  constructor (data) {
    // TODO: Validation here
    if (!data.storeName) throw new Error('storeName is required')
    if (!data.serviceName) throw new Error('serviceName is required')

    this.data = data
    this.storeName = data.storeName
    this.serviceName = data.serviceName
    this.componentDef = data.componentDef
    /**
     * @property {Object} storeConst
     * @property {Object} storeConst.ACTION
     * @property {String} storeConst.ACTION.[actionName].store
     * @property {String} storeConst.ACTION.[actionName].api
     * @property {String} storeConst.PROPERTY
     * @property {String} storeConst.PROPERTY.[state].get
     * @property {String} storeConst.PROPERTY.[state].set
     */
    this.storeConst = this.getStoreConst(data.storeConst)
  };

  get storePath () { return this.storeName + '/' }

  get servicePath () { return this.serviceName + '.' }

  /**
 * Class that serves as a base for creating extended data stores in Vue.js. 
 * It defines a set of methods and computed properties that can be used by child components 
 * to interact with Vuex store mutations and getters.
 * 
 * focuses on defining the shared functionality related to target Vuex store interactions
 */
  get Component () {
    return {
      methods: {
        ...mapMutations({
          setRow: this.storeConst.PROPERTY.row.set,
          setRows: this.storeConst.PROPERTY.rows.set,
          setPagination: this.storeConst.PROPERTY.pagination.set,
          setSearchInfo: this.storeConst.PROPERTY.searchInfo.set,
          setOrderBy: this.storeConst.PROPERTY.orderBy.set
        })
        // ! for override use, `<>` is the Const
        // setRow (row) {
        //   // logic here
        //   this.$store.commit(<>.storeConst.PROPERTY.row.set, row)
        // },
        // setSearchInfo (val) {
        //   // logic here
        //   this.$store.commit(<>.storeConst.PROPERTY.searchInfo.set, val)
        // },
        // setOrderBy (val) {
        //   // logic here
        //   this.$store.commit(<>.storeConst.PROPERTY.orderBy.set, val)
        // },
        // setPagination (val) {
        //   // logic here
        //   this.$store.commit(<>.storeConst.PROPERTY.pagination.set, val)
        // }
      },
      mounted () {
        // console.log('BaseDataStoreExtend mounted')
      },
      computed: {
        ...mapGetters({
          row: this.storeConst.PROPERTY.row.get,
          rows: this.storeConst.PROPERTY.rows.get,
          pagination: this.storeConst.PROPERTY.pagination.get,
          searchInfo: this.storeConst.PROPERTY.searchInfo.get,
          orderBy: this.storeConst.PROPERTY.orderBy.get
        })
        // ! for override use, `<>` is the Const
        // row () {
        //   const val = this.$store.getters[<>.storeConst.PROPERTY.row.get]
        //   // logic here
        //   return val
        // },
        // rows () {
        //   const val = this.$store.getters[<>.storeConst.PROPERTY.rows.get]
        //   // logic here
        //   return val
        // },
        // pagination () {
        //   const val = this.$store.getters[<>.storeConst.PROPERTY.pagination.get]
        //   // logic here
        //   return val
        // },
        // searchInfo () {
        //   const val = this.$store.getters[<>.storeConst.PROPERTY.searchInfo.get]
        //   // logic here
        //   return val
        // },
        // orderBy () {
        //   const val = this.$store.getters[<>.storeConst.PROPERTY.orderBy.get]
        //   // logic here
        //   return val
        // }
      }
    }
  };

  /**
   * Returns a store object that can be used to create a Vuex store.
   * @param {Object} overrideStore Store object that will be merged with the default store object
   * @returns {Object} Store object
   */
  getStore (newStore) {
    const state = {
      rows: [],
      row: {},
      pagination: {
        current_page: 1,
        last_page: 0,
        per_page: 10,
        total: null
      },
      searchInfo: {},
      orderBy: [],
      tableOptions: {
        itemsPerPageOptions: [10, 15, 25, 50]
      },
      validations: {},
      errors: {}
    }

    const getters = {
      getRows: (state) => state.rows,
      getRow: (state) => state.row,
      getPagination: (state) => state.pagination,
      getSearchInfo: (state) => state.searchInfo,
      getOrderBy: (state) => state.orderBy,
      getTableOptions: (state) => state.tableOptions,
      getValidations: (state) => state.validations,
      getErrors: (state) => state.errors
    }
    const mutations = {
      SET_ROWS (state, payload) {
        state.rows = payload
      },
      SET_ROW (state, payload) {
        state.row = payload
      },
      REMOVE_ROWS (state) {
        state.rows = []
      },
      REMOVE_ROW (state) {
        state.row = {}
      },
      DELETE_ROW (state, payload) {
        state.rows = state.rows.filter(row => row.id !== payload.id)
      },
      SET_PAGINATION (state, payload) {
        state.pagination = {
          total: payload.total ?? state.pagination.total,
          last_page: payload.last_page ?? state.pagination.last_page,
          current_page: payload.current_page ?? state.pagination.current_page,
          per_page: payload.per_page ?? state.pagination.per_page
        }
      },
      SET_SEARCH_INFO (state, payload) {
        state.searchInfo = payload
      },
      SET_ORDER_BY (state, payload) {
        state.orderBy = payload
      },
      SET_ITEM_PER_PAGE (state, payload) {
        state.tableOptions = {
          itemsPerPageOptions: payload
        }
      },
      SET_ERRORS (state, payload) {
        state.errors = payload
      },
      REMOVE_ERRORS (state) {
        state.errors = {}
      },
      SET_SEARCH_STATE (state, payload) {
        state.searchState = payload
      }
    }
    const storeConst = this.storeConst
    const actions = {
      async doAdd ({ commit, state }, payload) {
        return await ApiUtils.postData({
          data: [{ api: storeConst.ACTION.doAdd.api, input: payload }]
        })
      },
      async getEdit ({ commit, state }, payload) {
        const res = await ApiUtils.postData({
          data: [{ api: storeConst.ACTION.getEdit.api, input: payload }]
        })
        commit('SET_ROW', res[0].output)
        return res
      },
      async doEdit ({ commit, state }, payload) {
        return await ApiUtils.postData({
          data: [{ api: storeConst.ACTION.doEdit.api, input: payload }]
        })
      },
      async doRead ({ commit, state }, payload) {
        const res = await ApiUtils.postData({
          data: [{ api: storeConst.ACTION.doRead.api, input: payload }]
        })
        commit('SET_ROW', res[0].output)
        return res
      },
      async doBrowse ({ commit, state }, payload) {
        const val = {
          searchInfo: state.searchInfo,
          orderBy: state.orderBy,
          pagination: state.pagination
        }
        const res = await ApiUtils.postData({
          data: [{ api: storeConst.ACTION.doBrowse.api, input: val }]
        })
        commit('SET_ROWS', res[0].output.data)
        commit('SET_PAGINATION', res[0].output.pagination)
        return res
      },
      async doDelete ({ commit, state }, payload) {
        return await ApiUtils.postData({
          data: [{ api: storeConst.ACTION.doDelete.api, input: payload }]
        })
      }
    }
    const baseStore = {
      namespaced: true,
      state,
      getters,
      mutations,
      actions,
      strict: (import.meta.env.NODE_ENV !== 'production' && import.meta.env.NODE_ENV !== 'production-dev' && import.meta.env.NODE_ENV !== 'production-staging')
    }
    return ObjArrUtils.merge(baseStore, newStore)
  };

  /**
   * Returns a page constant object with the default values.
   * @returns {Object} Page constant object
   */
  getStoreConst (storeConst) {
    const BaseStoreConst = {
      ACTION: {
        doAdd: {
          store: this.storePath + 'doAdd',
          api: this.servicePath + 'doAdd'
        },
        doEdit: {
          store: this.storePath + 'doEdit',
          api: this.servicePath + 'doEdit'
        },
        getEdit: {
          store: this.storePath + 'getEdit',
          api: this.servicePath + 'getEdit'
        },
        doBrowse: {
          store: this.storePath + 'doBrowse',
          api: this.servicePath + 'doBrowse'
        },
        doDelete: {
          store: this.storePath + 'doDelete',
          api: this.servicePath + 'doDelete'
        },
        doRead: {
          store: this.storePath + 'doRead',
          api: this.servicePath + 'doRead'
        }
      },
      PROPERTY: {
        row: {
          get: this.storePath + 'getRow',
          set: this.storePath + 'SET_ROW'
        },
        rows: {
          get: this.storePath + 'getRows',
          set: this.storePath + 'SET_ROWS'
        },
        pagination: {
          get: this.storePath + 'getPagination',
          set: this.storePath + 'SET_PAGINATION'
        },
        searchInfo: {
          get: this.storePath + 'getSearchInfo',
          set: this.storePath + 'SET_SEARCH_INFO'
        },
        orderBy: {
          get: this.storePath + 'getOrderBy',
          set: this.storePath + 'SET_ORDER_BY'
        },
        itemPerPage: {
          get: this.storePath + 'getItemPerPage',
          set: this.storePath + 'SET_ITEM_PER_PAGE'
        },
        errors: {
          get: this.storePath + 'getErrors',
          set: this.storePath + 'SET_ERRORS'
        }
      }
    }
    const output = ObjArrUtils.merge(BaseStoreConst, storeConst)
    Object.freeze(output)
    return output
  }
}

export default BaseDataStoreV1
