// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// import i18n from '@/plugins/i18n'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
  },

  getters: {
  },

  mutations: {
  },

  actions: {
    async searchComment (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.searchComment', input: payload }] })
      return data[0].output
    },
    async searchCommentByRelOwner (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.searchCommentByRelOwner', input: payload }] })
      return data[0].output
    },
    async addComment (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.addComment', input: payload }] })
      return data[0].output
    },
    async reactionComment (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.doReact', input: payload }] })
      return data[0].output
    },
    async editComment (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.editComment', input: payload }] })
      return data[0].output
    },
    async removeComment (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.removeComment', input: payload }] })
      return data[0].output
    },
    async hideComment (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.hideComment', input: payload }] })
      return data[0].output
    },
    async checkIfMadeRating (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.checkIfMadeRating', input: payload }] })
      return data[0].output
    },
    async getCommentRel (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M1501CommentService.getCommentRel', input: payload }] })
      return data[0].output
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
