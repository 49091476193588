import ApiUtils from '@/utils/api/ApiUtils'
import C3101Const from './C3101Const'

// TODO: can i know what storeConst properties are available? (using intelisense)

const store = {
  state: {
    // custom state
    partnerRequirementInfo: []
  },
  getters: {
    // custom getter
    getPartnerRequirementInfo: (state) => state.partnerRequirementInfo
  },
  mutations: {
    // custom mutation
    SET_PARTNER_REQUIREMENT_INFO (state, payload) {
      state.partnerRequirementInfo = payload
    }
  },
  actions: {
    async getPartnerRequirementInfo ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          { api: 'C3001ProjectPartnerRequirementService.getPartnerRequirementInfo', input: payload }
        ]
      })
      commit('SET_PARTNER_REQUIREMENT_INFO', res[0].output)
      return res
    },
    createPartnershipRequest ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [
          { api: 'C3101ProjectPartnerRequirementApplicationService.createPartnershipRequest', input: payload }
        ]
      })
    }
  }
}

export default C3101Const.getStore(store)
