import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    targetModel: 'M2701ShoppingCart',
    selected_item: [],
    cartItemList: [],
    wallet: [],
    useToken: false,
    promo_code: '',
    sales_order_key: '',
    cartType: null, // used by cartItemlist.vue to determine which cart type to use
    // below is used for subscription
    overwrite: '',
    meta: {
      shipping_info: {
      }
    },
    targetRelModel: null,
    metaCheck: false

  },
  getters: {
    isInCart: (state) => (relId) => {
      const a = state.cartItemList.find(
        (item) =>
          item.relModel === state.targetModel &&
          item.relId === relId
      )
      return !!a
    }
  },
  mutations: {
    SELECT_ITEM(state, payload) {
      const check = state.selected_item.find((item) => item.relId === payload.relId)
      if (!!check === false) {
        state.selected_item.push(payload)
      }
    },
    DESELECT_ITEM(state, payload) {
      state.selected_item = state.selected_item.filter(item => item.id !== payload.id)
    },
    TOGGLE_USE_TOKEN(state, payload) {
      state.useToken = payload
    },
    APPLY_PROMO_CODE(state, payload) {
      state.promo_code = payload
    },
    SET_SALES_ORDER_KEY(state, payload) {
      state.sales_order_key = payload
    },
    SET_CART_TYPE(state, payload) {
      state.cartType = payload
    },
    SET_TARGET_MODEL(state, payload) {
      state.targetModel = payload
    },
    SET_OVERWRITE(state, payload) {
      state.overwrite = payload
    },
    SET_CARTITEM_LIST(state, payload) {
      state.cartItemList = payload
    },
    SET_META(state, payload) {
      state.meta[payload.key] = payload.value
    },
    SET_TARGET_REL_MODEL(state, payload) {
      state.targetRelModel = payload
    },
  },
  actions: {
    async initWallet({ commit, state }, payload) {
      await ApiUtils.postData({
        data: [
          {
            api: 'M2001CoinsService.initWallet'
          }

        ]
      }).then((res) => {
        state.wallet = res[0].output
      })
    },

    async doAdd({ commit, state }, payload) {
      // console.log(payload)
      await ApiUtils.postData({
        data: [
          {
            api: 'M2701ShoppingCartService.doAdd',
            input: {
              rel_model: payload.relModel,
              rel_id: payload.rel_id
            }
          }
        ]
      }).then((res) => {
        state.cartItemList = res[0].output
        if (state.cartType === 'donation') {
          state.selected_item = res[0].output
        }
      })
    },

    async initCart({ commit, state }, payload) {
      console.log(payload)
      await ApiUtils.postData({
        data: [
          {
            api: 'M2701ShoppingCartService.doBrowse',
            input: { shopping_cart_status: 'A', rel_model: payload }
          }
        ]
      }).then((res) => {
        state.cartItemList = res[0].output

        if (['donation', 'subscription'].includes(state.cartType)) {
          state.selected_item = state.cartItemList
        }
      })
    },

    async doDelete({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [
          {
            api: 'M2701ShoppingCartService.doDelete',
            input: {
              rel_model: payload.rel_model,
              rel_id: payload.rel_id
            }
          }
        ]
      }).then(data => {
        state.cartItemList = data[0].output
      })
    },
    async doApply({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: 'M3101PromoCodeService.doApply', input: payload }]
      })
      state.cartItemList = res[0].output
      return res
    },
    async checkout({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{
          api: 'M2701ShoppingCartService.Checkout',
          input: {
            promo_code: state.promo_code,
            selected_item: state.cartItemList,
            sales_order_key: state.sales_order_key,
            useToken: state.useToken,
            meta: state.meta
          }
        }]
      })
      state.cartItemList = []
      state.selected_item = []
      state.useToken = false
      state.sales_order_key = ''

      return res
    },
    async doBrowse2({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M2701ShoppingCartService.doBrowse2',
            input: payload
          }
        ]
      })

      return res[0].output

      // state.cartItemList = res[0].output
      // state.selected_item = res[0].output
    },
    async doCheckPrice({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M2701ShoppingCartService.comparePrice',
            input: payload
          }
        ]
      })

      return res[0].output
    },

    // subscription only
    async doCheck({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M4201SubscriptionService.doCheck',
            input: payload
          }
        ]
      })

      return res[0].output
    },
    async getPolicy({commit, state},payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0901PolicyService.doReadCheckbox',
            input: payload
          }
        ]
      })

      return res[0].output
    },
    async getInvoiceinfo({ commit, state }, payload) {

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M2901SalesOrderService.getInvoiceinfo',
            input: payload
          }
        ]
      })

      return res[0].output

    },
    async getCheckoutInfo({ commit, state }, payload) {

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'M2901SalesOrderService.getCheckoutInfo',
            input: payload
          }
        ]
      })

      return res[0].output

    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
