import i18n from '@/plugins/i18n'

export default [
  {
    path: 'Reports',
    name: 'UserReport',
    component: () => import('@/app/M1101UserReport/UserReport/PgUserReport.vue'),
    portalGroup: 'all.my',
    meta: { title: i18n.t('m1101.UserReport.PgUserReport.title'), authenticated: true }
  }
]
