// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'admin.admin',
    path: 'P0101AdminArticle/index',
    name: 'TTC PRESS Admin Home',
    component: () => import('./PgP0101AdminPage.vue'),
    meta: {
      roles: ['SA', 'PA', 'VA', 'PAP', 'PAL', 'PAC', 'PAA'],
      title: '讀物管理',
    }
  }
]
