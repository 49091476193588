import BaseDataStore from '@/components/base/store/BaseDataStoreV2'
import i18n from '@/plugins/i18n'
// import moment from 'moment'
const storeName = 'P0101Author'
const serviceName = 'P0101ArticleService'

/*
    {
      column: 'col-6', // column size
      data: 'status', // data name
      type: 'select', // component type : text,textarea,number,checkbox,switch,radio,file,date,time,dateRange,select,autoComplete,combobox,rangeSlider,slider,ttc_category,ttc_tag,ttc_price,ttc_policy
      label: 'status', // label
      props: any // component props
    }
*/
const storeDef = {
  ACTION: {
    // base actions : doAdd, doEdit, getEdit, doBrowse, doDelete, doRead
    // custom actions
    // TODO: accept string without storeName/serviceName
    init: {
      store: storeName + '/init'
    },
    doBrowse: {
      store: storeName + '/doBrowse',
      api: serviceName + '.doBrowseWithDetailForAuthor'
    },
    doBrowseComments: {
      store: storeName + '/doBrowseComments',
      api: serviceName + '.doBrowseComments'
    },
    doBrowseVersion: {
      store: storeName + '/doBrowseVersion',
      api: serviceName + '.doBrowseVersion'
    },
    doBrowseIncomeStatistic: {
      store: storeName + '/doBrowseIncomeStatistic',
      api: serviceName + '.doBrowseIncomeStatistic'
    },
    doPending: {
      store: storeName + '/doPending',
      api: serviceName + '.doPending'
    },
    doReject: {
      store: storeName + '/doReject',
      api: serviceName + '.doReject'
    },
    getAdd: {
      store: storeName + '/getAdd'
      // api: serviceName + '.getAdd'
    },
    getEdit: {
      store: storeName + '/getEdit',
      api: serviceName + '.getEditDetailByVersionId'
    },
    getRead: {
      store: storeName + '/getRead',
      api: serviceName + '.getReadDetailByVersionId'
    },
    doEdit: {
      store: storeName + '/doEdit',
      api: serviceName + '.doSaveDetail'
    },
    doDelete: {
      store: storeName + '/doDelete',
      api: serviceName + '.doDeleteByVersionId'
    },
    getStats: {
      store: storeName + '/getStats',
      api: serviceName + '.getMyActiclsStats'
    }

  },
  PROPERTY: {
    // base properties : row, rows, pagination, searchInfo, orderBy
    // custom properties
    myProp: {
      get: storeName + '/getMyProp',
      set: storeName + '/SET_MY_PROP'
    },
    row: {
      get: storeName + '/getRow',
      set: storeName + '/SET_ROW'
    },
    stats: {
      get: storeName + '/getStats',
      set: storeName + '/SET_STATS'
    }
  }
}

const searchDef = {
  viewDef: [
    {
      column: 'col-3',
      data: 'status',
      type: 'select',
      label: 'status',
      props: {
        items: [
          { id: 'DRAFT', name: 'DRAFT' },
          { id: 'PUBLISHED', name: 'PUBLISHED' },
          { id: 'ARCHIVED', name: 'ARCHIVED' }
        ],
        itemText: 'name',
        itemValue: 'id'
      }
    },
    {
      column: 'col-sm-3',
      data: 'category',
      type: 'ttc_category',
      props: {
        label: i18n.t('components.TTCCategoryField.category'),
        outlined: true
      }
    },
    {
      column: 'col-sm-3',
      data: 'tag',
      type: 'ttc_tag',
      props: {
        label: i18n.t('components.TTCTagField.tags'),
        outlined: true
      }
    }
  ],
  optionDef: {}
}

const contentDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'article_version_title',
      type: 'text',
      rules: 'required',
      label: i18n.t('p0101.authorConst.title'),
      labelHint: i18n.t('p0101.authorConst.title')
    },
    {
      column: 'col-12',
      data: 'article_version_subtitle',
      type: 'text',
      rules: 'required',
      label: i18n.t('p0101.authorConst.subtitle'),
      labelHint: i18n.t('p0101.authorConst.subtitle')
    },
    {
      column: 'col-4',
      data: 'article_version_cover',
      type: 'file',
      rules: 'required',
      label: i18n.t('p0101.authorConst.image'),
      labelHint: i18n.t('p0101.authorConst.image'),
      props: {
        accept: 'image/*',
        maxFiles: 1
      }
    },
    {
      column: 'col-12',
      data: 'article_version_content',
      custom: 'MoBlockEditor',
      label: i18n.t('p0101.authorConst.articleContent'),
      labelHint: i18n.t('p0101.authorConst.content')
    }
  ],
  optionDef: {}
}
const commentsDef = {
  viewDef: [],
  optionDef: {}
}
const seoDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'seo_url',
      type: 'text',
      label: i18n.t('p0101.authorConst.article') + ' Seo Url',
      labelHint: i18n.t('p0101.authorConst.article') + ' Seo Url'
    },
    {
      column: 'col-12',
      data: 'seo_title',
      type: 'text',
      label: i18n.t('p0101.authorConst.article') + ' Seo ' + i18n.t('p0101.authorConst.title'),
      labelHint: i18n.t('p0101.authorConst.article') + ' Seo ' + i18n.t('p0101.authorConst.title')
    },
    {
      column: 'col-12',
      data: 'seo_desc',
      type: 'text',
      label: i18n.t('p0101.authorConst.article') + ' Seo ' + i18n.t('p0101.authorConst.content'),
      labelHint: i18n.t('p0101.authorConst.article') + ' Seo ' + i18n.t('p0101.authorConst.content')
    },
    {
      column: 'col-12',
      data: 'seo_keyword',
      type: 'text',
      label: i18n.t('p0101.authorConst.article') + ' Seo ' + i18n.t('p0101.authorConst.keyword'),
      labelHint: i18n.t('p0101.authorConst.article') + ' Seo ' + i18n.t('p0101.authorConst.keyword')
    }
  ],
  optionDef: {}
}
const promotionDef = {
  viewDef: [],
  optionDef: {}
}
const statisticsDef = {
  viewDef: [],
  optionDef: {}
}
const revisionsDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'article_version_title',
      type: 'text',
      label: i18n.t('p0101.authorConst.articleVersionTitle'),
      labelHint: i18n.t('p0101.authorConst.articleVersionTitle')
    },
    {
      column: 'col-12',
      data: 'article_version_subtitle',
      type: 'text',
      label: i18n.t('p0101.authorConst.articleVersionSubtitle'),
      labelHint: i18n.t('p0101.authorConst.articleVersionSubtitle')
    },
    {
      column: 'col-12',
      data: 'article_version_status',
      type: 'text',
      label: i18n.t('p0101.authorConst.articleVersionTitle'),
      labelHint: i18n.t('p0101.authorConst.articleVersionTitle')
    },
    {
      column: 'col-12',
      data: 'updated_at',
      type: 'text',
      label: i18n.t('p0101.authorConst.updatedAt'),
      labelHint: i18n.t('p0101.authorConst.updatedAt')
    }
  ],
  optionDef: {}
}
const settingDef = {
  viewDef: [
    {
      column: 'col-12',
      data: 'm0601_id',
      type: 'ttc_category',
      props: {
        label: i18n.t('components.TTCCategoryField.category'),
        outlined: true
      }
    },
    {
      column: 'col-12',
      data: 'tags',
      type: 'ttc_tag',
      props: {
        label: i18n.t('components.TTCTagField.tags'),
        outlined: true
      }
    },
    {
      column: 'col-6',
      data: 'article_schedule_datetime',
      type: 'date',
      label: i18n.t('p0101.authorConst.publishSchedule')
    },
    {
      column: 'col-6',
      data: 'article_is_paid',
      type: 'checkbox',
      label: '會員内容'
    }
  ],
  optionDef: {}
}

const listDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('samples.layout4.title'), value: 'title' },
    // use type: 'date' to format date
    { text: i18n.t('samples.layout4.created_at'), value: 'created_at', type: 'relativeDate' },
    // use type: 'enum' to format enum
    {
      text: 'Gender',
      value: 'gender',
      type: 'chip',
      items: [
        { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
        { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
        { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
      ]
    },
    { text: i18n.t('p0101.authorConst.comments'), value: 'comment_count' },
    { text: 'post_type', value: 'post_type' },
    { text: i18n.t('p0101.authorConst.views'), value: 'views' },
    { text: i18n.t('p0101.authorConst.custom'), value: 'cus' },
    { text: i18n.t('p0101.authorConst.action'), value: 'actions', sortable: false }
  ],
  optionDef: {}
}
const incomeDef = {
  viewDef: [
    { text: i18n.t('p0101.authorConst.user') + ' Id', value: 'user_id' },
    { text: i18n.t('p0101.authorConst.username'), value: 'user_name' },
    { text: i18n.t('p0101.authorConst.incomeMonth'), value: 'sale_month' },
    { text: i18n.t('p0101.authorConst.incomeType'), value: 'income_type' },
    { text: i18n.t('p0101.authorConst.amount'), value: 'amount' }
  ],
  optionDef: {}
}
const tableDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('p0101.authorConst.title'), value: 'article_version_title' },
    { text: i18n.t('p0101.authorConst.subtitle'), value: 'article_version_subtitle' },
    { text: i18n.t('p0101.authorConst.status'), value: 'article_version_status' },
    { text: i18n.t('p0101.authorConst.tags'), value: 'tags' },
    { text: i18n.t('p0101.authorConst.action'), value: 'actions', sortable: false }

  ],
  optionDef: {}
}

const readDef = {
  viewDef: [
    { text: 'id', value: 'id' },
    { text: i18n.t('p0101.authorConst.title'), value: 'article_version_title' },
    { text: i18n.t('p0101.authorConst.subtitle'), value: 'article_version_subtitle' },
    { text: i18n.t('p0101.authorConst.status'), value: 'article_version_status' },
    { text: i18n.t('p0101.authorConst.tags'), value: 'tags' }
  ],
  optionDef: {}
}

const BaseConst = {
  version: 1,
  storeName,
  serviceName,
  storeConst: storeDef,
  // target def for './component'
  //  if search -> search component
  componentDef: {
    search: searchDef,
    table: tableDef,
    list: listDef,
    read: readDef,
    income: incomeDef,
    edit: {
      content: contentDef,
      comments: commentsDef,
      seo: seoDef,
      promotion: promotionDef,
      statistics: statisticsDef,
      revisions: revisionsDef,
      setting: settingDef
    }

  }
}
const FnConst = new BaseDataStore(BaseConst)
export default FnConst
