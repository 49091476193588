import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'collab.public',
    path: 'projects/:projectId/advise/:adviseId',
    name: 'ProjectPublicBrowseAdvise',
    component: () => import('./PgC4001View.vue'),
    meta: { title: i18n.t('c4001.browse.title') }
  }
]
