<template>
  <v-combobox
    v-model="vModel"
    v-bind="$attrs"
    :items="schoolList"
    :menu-props="{
      closeOnContentClick: true,
      value: input !== ''
    }"
    @keydown="searchTag"
  >
    <template
      v-if="isSearching"
      #append
    >
      <v-progress-circular indeterminate />
    </template>
    <template
      v-if="schoolList.length && isSearching === false && !schoolList.includes(input)"
      #append-item
    >
      <v-divider />
      <v-list-item
        link
        @click="pushTag"
      >
        {{ $t('components.TTCTagField.propose', { tag: input }) }} || {{ value }}
      </v-list-item>
    </template>
    <template
      v-else
      #no-data
    >
      <v-list-item
        v-if="isSearching === false && input !== ''"
        link
        @click="pushTag"
      >
        {{ $t('components.TTCTagField.propose', { tag: input }) }}
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import { debounce } from 'lodash'
import { mapGetters } from 'vuex'

export default {
  name: 'TTCSchoolTagField',

  props: {
    value: {
      type: String,
      default: () => ''
    }
  },

  data () {
    return {
      input: '',
      isSearching: false,
      schoolList: []
    }
  },

  computed: {
    ...mapGetters('M0701Tag', {
      rows: 'getRows'
    }),
    vModel: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },

  mounted () {
    // this.searchTag()
  },

  methods: {
    searchTag: debounce(function (event) {
      this.isSearching = true
      this.input = event ? event.target.value : ''
      this.storeApiDispatch('IndividualProfile/getSchool', { searchEnterprise: this.input }, { loading: false }).then((data) => {
        this.schoolList = data.map(item => item.name)
        this.isSearching = false
      })
    }, 500),
    pushTag () {
    // this.vModel.push(this.input)
      this.vModel = this.input
    }
  }
}
</script>
