import StoreUtils from '@/utils/StoreUtils'

export default {
  methods: {
    /**
     * Mixin for dispatching store action
     *
     * @param {String} dispatchAction Store Action to be called on
     * @param {Object} data Data to be passed to action
     * @param {Object} options Options to be passed to action
     * - loading {Boolean}: [Default: `true`] Whether to show loading animation
     * - callback {Function}: [Default: Empty Function] Callback function
     * - errorDialog {Boolean}: [Default: `true`] Whether to show dialog when error happens
     * - errorTitle {String}: [Default: `common.messages.errorTitle`] Default error title
     * - errorMessage {String}: [Default: `common.messages.errorMessage`] Default error message
     * @returns {Promise} Store action returned promise
     *
     * @example this.storeApiDispatch('{module}/{action}', { data }, { options })
     * @example this.storeApiDispatch('Auth/login', { username: 'admin', password: 'admin' })
     */
    storeApiDispatch (dispatchAction, data = {}, options = {}) {
      return StoreUtils.storeApiDispatch(dispatchAction, data, options,this)
    },

    apiDispatch (input, options = {}) {
      return StoreUtils.apiDispatch(input, options,this)
    },
    storeCommit(...args) {
      return this.$store.commit(...args)
    }
  }
}
