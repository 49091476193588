import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'learn.my',
    path: 'applyLecture',
    name: 'ApplyLecture',
    component: () => import('@/app/zz0101LecturerApplication/PgLecturerApplication.vue'),
    meta: { title: i18n.t('zz0101.becomeLecturer'), authenticated: true, permissions: ['lecturerApplication.apply'] }
  },
  {
    portalGroup: 'admin.admin',
    path: 'LecturerApplication',
    name: 'LecturerApplication',
    component: () => import('@/app/zz0101LecturerApplication/PgManageLecturerApplication.vue'),
    meta: { title: i18n.t('zz0101.manageLecturerApplication'), authenticated: true, permissions: ['lecturerApplication.manage'] }
  }
]
