import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    surveyDatas: {}
  },
  getters: {},
  mutations: {},
  actions: {
    async getSurveyData({ commit, state, dispatch }, payload) {
      state.surveyData = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T4001SurveyDataService.doRead_surveyData', input: {
              id: payload.id,
              seo: payload.seo
            }
          }
        ]
      })
      state.surveyDatas = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.surveyData)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
