import i18n from '@/plugins/i18n'

/**
 * Tools to do color related operations
 * @see https://css-tricks.com/converting-color-spaces-in-javascript/
 */
const colorTools = {
  /**
   * Convert color format from HSL / HSLA to Hex / HEXA
   * @param hsl (Array[Number]) HSL color in [h, s, l] | [h, s, l, a]
   * - h: `0 - 360`
   * - s: `0 -100`
   * - l: `0 - 100`
   * - a: `0 - 1`
   * @return (String) HEX formated color code: #rrggbb | #rrggbbaa
   */
  hslToHex (hsl) {
    const h = hsl[0]
    const s = hsl[1]
    let l = hsl[2]
    l /= 100
    const a = s * Math.min(l, 1 - l) / 100
    const f = n => {
      const k = (n + h / 30) % 12
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
      return Math.round(255 * color).toString(16).padStart(2, '0') // Convert to Hex and prefix "0" if needed
    }

    // Alpha channel processing
    let al = ''
    if (hsl.length === 4) {
      al = Math.round(hsl[3] * 255).toString(16).padStart(2, '0')
    }

    return `#${f(0)}${f(8)}${f(4)}${al}`
  },

  /**
   * Convert color format from RGB / RGBA to HEX / HEXA
   * @param hsl (Array[Number]) RGB color in [r, g, b] | [r, g, b, a]
   * - r: `0 - 255`
   * - g: `0 - 255`
   * - b: `0 - 255`
   * - a: `0 - 1`
   * @return (String) HEX formated color code: #rrggbb | #rrggbbaa
   */
  rgbToHex (rgb) {
    let r = rgb[0]
    let g = rgb[1]
    let b = rgb[2]
    r = r.toString(16)
    g = g.toString(16)
    b = b.toString(16)

    if (r.length === 1) { r = '0' + r }
    if (g.length === 1) { g = '0' + g }
    if (b.length === 1) { b = '0' + b }

    // Alpha channel processing
    let a = ''
    if (rgb.length === 4) {
      a = Math.round(rgb[3] * 255).toString(16).padStart(2, '0')
    }
    return `#${r}${g}${b}${a}`
  },

  /**
   * Convert color format from HEX / HEXA to RGB / RGBA
   * @param hsl (String) HEX color in #rrggbb | #rrggbbaa
   * @return (Array[Number]) RGB color array: [r, g, b] | [r, g, b, a]
   */
  hexToRgb (color) {
    if (color.length === 9) {
      return [
        parseInt('0x' + color.substring(1, 3)),
        parseInt('0x' + color.substring(3, 5)),
        parseInt('0x' + color.substring(5, 7)),
        parseInt('0x' + color.substring(7, 9)) / 255
      ]
    }
    return [parseInt('0x' + color.substring(1, 3)),
      parseInt('0x' + color.substring(3, 5)),
      parseInt('0x' + color.substring(5, 7))]
  },

  /**
   * Convert color format from HEX / HEXA to HSL / HSLA
   * @param hsl (String) HEX color in #rrggbb | #rrggbbaa
   * @return (Array[Number]) HSL color array: [h, s, l] | [h, s, l, a]
   */
  hexToHsl (color) {
    let r = 0
    let g = 0
    let b = 0
    let a = 1

    // Convert HEX to RGB first
    const rgb = this.hexToRgb(color)
    if (rgb.length === 3) {
      [r, g, b] = rgb
    } else {
      [r, g, b, a] = rgb
    }

    // Convert RGB to HSL
    r /= 255
    g /= 255
    b /= 255
    const cmin = Math.min(r, g, b)
    const cmax = Math.max(r, g, b)
    const delta = cmax - cmin
    let h = 0
    let s = 0
    let l = 0

    if (delta === 0) { h = 0 } else if (cmax === r) { h = ((g - b) / delta) % 6 } else if (cmax === g) { h = (b - r) / delta + 2 } else { h = (r - g) / delta + 4 }

    h = Math.round(h * 60)

    if (h < 0) { h += 360 }

    l = (cmax + cmin) / 2
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    a = +(a).toFixed(3)

    if (rgb.length === 3) {
      return [h, s, l]
    } else {
      return [h, s, l, a]
    }
  },

  /**
   * Check if passed in color value is valid with specified type / format
   * @param input (String | Array[Number]) Color value to check against
   * @param type (String) Type of color format
   * - Available values: ['hex', 'hexa', 'rgb', 'rgba', 'hsl', 'hsla']
   * @param throwError (Boolean) Whether to throw error if validation failed
   * - Default: `true`
   * @return (Boolean) Validation / Check result
   */
  checkFormat (input, type, throwError = true) {
    const hexRegex = /^#[0-9a-f]{6}$/i
    const hexaRegex = /^#[0-9a-f]{8}$/is
    switch (type) {
      case 'hex':
        // Regex check
        if (!hexRegex.test(input)) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'HEX' }))
          return false
        }
        break
      case 'hexa':
        // Regex check
        if (!hexaRegex.test(input)) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'HEXA' }))
          return false
        }
        break
      case 'rgb':
        // Array check
        if (!Array.isArray(input)) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidArray', { type: 'RGB' }))
          return false
        }
        // Array length check (3)
        if (input.length !== 3) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'RGB' }))
          return false
        }
        // Check if each element is a number and between `0 - 255`
        for (let i = 0; i < input.length; i++) {
          if (typeof input[i] !== 'number' || input[i] < 0 || input[i] > 255) {
            if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: i }))
            return false
          }
        }
        break
      case 'rgba':
        // Array check
        if (!Array.isArray(input)) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidArray', { type: 'RGBA' }))
          return false
        }
        // Array length check (4)
        if (input.length !== 4) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'RGBA' }))
          return false
        }
        // Check if each element is a number and between `0 - 255`
        for (let i = 0; i < input.length - 1; i++) {
          if (typeof input[i] !== 'number' || input[i] < 0 || input[i] > 255) {
            if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: i }))
            return false
          }
        }
        // Check if 'a' value is between `0 - 1`
        if (input[3] < 0 || input[3] > 1) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 3 }))
          return false
        }
        break
      case 'hsl':
        // Array check
        if (!Array.isArray(input)) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidArray', { type: 'HSL' }))
          return false
        }
        // Array length check (3)
        if (input.length !== 3) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'HSL' }))
          return false
        }
        // Check if each element is a number
        for (let i = 0; i < input.length; i++) {
          if (typeof input[i] !== 'number') {
            if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'HSL' }))
            return false
          }
        }
        // Check if 'h' value is between `0 - 360`
        if (input[0] < 0 || input[0] > 360) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 0 }))
          return false
        }
        // Check if 's' value is between `0 - 100`
        if (input[1] < 0 || input[1] > 100) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 1 }))
          return false
        }
        // Check if 'l' value is between `0 - 100`
        if (input[2] < 0 || input[2] > 100) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 2 }))
          return false
        }
        break
      case 'hsla':
        // Array check
        if (!Array.isArray(input)) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidArray', { type: 'HSLA' }))
          return false
        }
        // Array length check (4)
        if (input.length !== 4) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'HSLA' }))
          return false
        }
        // Check if each element is a number
        for (let i = 0; i < input.length; i++) {
          if (typeof input[i] !== 'number') {
            if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidFormat', { type: 'HSLA' }))
            return false
          }
        }
        // Check if 'h' value is between `0 - 360`
        if (input[0] < 0 || input[0] > 360) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 0 }))
          return false
        }
        // Check if 's' value is between `0 - 100`
        if (input[1] < 0 || input[1] > 100) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 1 }))
          return false
        }
        // Check if 'l' value is between `0 - 100`
        if (input[2] < 0 || input[2] > 100) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 2 }))
          return false
        }
        // Check if 'a' value is between `0 - 1`
        if (input[3] < 0 || input[3] > 1) {
          if (throwError) throw new Error(i18n.t('utils.colorTools.errors.invalidRange', { index: 3 }))
          return false
        }
        break
      default:
        throw new Error(i18n.t('utils.colorTools.errors.invalidType', { type }))
    }
    return true
  }
}

export default colorTools
