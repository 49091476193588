import ApiUtils from '@/utils/api/ApiUtils'
import M3901Const from './M3901Const'

const StoreConst = M3901Const.storeConst
const store = {
  state: {
    hints: {}
  },
  getters: {
    getHints: state => state.hints
  },
  mutations: {
    SET_HINTS (state, payload) {
      state.hints = { ...state.hints, ...payload }
    }
  },
  actions: {
    async getHint ({ commit }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.getHint.api, input: payload }]
      })
      commit('SET_HINTS', res[0].output)
      return res
    }
  }
}

export default M3901Const.getStore(store)
