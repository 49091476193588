<template>
  <v-container>
    <v-row>
      <v-col cols="9">
        <AtTextField
          v-model.number="_price"
          type="number"
          placeholder="0.00"
          :label="$t('components.TTCPriceField.price')"
          :hint="'1 ' + selectedCurrencyCode + ' ≈ ' + parseInt(1/unitPrice) + ' ' + $t('components.TTCPriceField.coins')"
          :rules="priceRules"
          :disabled="disabled"
          persistent-hint
        />
      </v-col>
      <v-col cols="3">
        <VSelect
          v-model="selectedCurrencyCode"
          outlined
          :items="coinACurrencyList"
          item-text="code"
          item-value="code"
          :disabled="disabled"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        align="center"
        style="padding: 0; margin: -24px 0"
      >
        <v-icon>mdi-swap-vertical</v-icon>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <AtTextField
          v-model.number="_coins"
          type="number"
          placeholder="0"
          label=""
          :suffix="$t('components.TTCPriceField.coinLabel')"
          :hint="'1 ' + $t('components.TTCPriceField.coin') + ' ≈ ' + parseFloat(unitPrice) + ' ' + selectedCurrencyCode"
          :rules="coinsRules"
          persistent-hint
          :disabled="disabled"
        />
      </v-col>
    </v-row>
    <v-row v-if="!topUpMode">
      <v-col>
        <label>{{ $t('components.TTCPriceField.percentage') }}</label>
        <VSlider
          v-model.number="percentage"
          :disabled="_coins <= 0 || disabled"
          class="mt-10"
          thumb-label="always"
          :min="minTokenPercentage"
          :max="maxTokenPercentage"
        >
          <template #prepend>
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  mdi-help-circle-outline
                </v-icon>
              </template>
              <span>
                {{ $t('components.TTCPriceField.percentageHint', { min: minTokenPercentage, max: maxTokenPercentage }) }}
              </span>
            </v-tooltip>
          </template>

          <!-- eslint-disable-next-line vue/no-template-shadow -->
          <template #thumb-label="{ value }">
            {{ value + '%' }}
          </template>

          <template #append>
            <div
              class="d-flex align-center"
              style="width: 120px"
            >
              <!-- <AtTextField
                v-model.number="_tokens"
                type="number"
                placeholder="0"
                label=""
                hide-details
                class="mt-0 pt-0"
                single-line
                :outlined="false"
                style="width: 32px"
                @blur="() => { if (percentageWarningMessage) $dialog.notify.warning(percentageWarningMessage) }"
              /> -->
              <p class="mb-0 mr-2">
                {{ _tokens }}
              </p>
              {{ $t('components.TTCPriceField.tokenLabel') }}
            </div>
          </template>
        </VSlider>
        <!-- TODO: [Adrian] link to percentageLearnMore page -->
        <router-link
          to="404"
          class="text-caption"
        >
          <u>{{ $t('components.TTCPriceField.percentageLearnMore') }}</u>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AtTextField from '../AtTextField/AtTextField.vue'

export default {
  name: 'TTCPriceField',

  components: { AtTextField },

  props: {
    value: {
      type: [Object,Array],
      default: () => ({
        coins: 0,
        tokens: 0,
        percentage: 0,
        o0301_id: 2
      })
    },
    // list from `M1902CoinsTypeCurrencyService.GetACoinCurrency`
    coinACurrencyList: {
      type: Array,
      default: () => []
    },
    topUpMode: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selectedCurrencyCode: 'USD',
      coins: this.value.coins,
      percentage: parseInt(this.value.tokens * 100 / this.value.coins),
      tokens: this.value.tokens,
      minTokenPercentage: 20,
      maxTokenPercentage: 70,
      percentageWarningMessage: null
    }
  },
  computed: {
    selectedCurrency () {
      // find array of object by key
      return this.coinACurrencyList.find(x => x.code === this.selectedCurrencyCode)
    },
    unitPrice () {
      if (!this.coinACurrencyList.length) return 0
      return parseFloat(this.selectedCurrency.coins_type_currency_unit_price)
    },
    minPrice () {
      if (!this.coinACurrencyList.length) return 0
      return parseFloat(this.selectedCurrency.coins_type_currency_minimum_currency)
    },
    priceRules () {
      if (this.topUpMode) {
        return [
          v => !!v || this.$t('components.TTCPriceField.priceRequired'),
          // v => (v * 100) % 1 === 0 || this.$t('components.TTCPriceField.priceDecimal'),
          v => v >= this.minPrice || this.$t('components.TTCPriceField.priceMinimum', { minPrice: this.minPrice })
        ]
      }
      return []
    },
    coinsRules () {
      if (this.topUpMode) {
        return [
          v => !!v || this.$t('components.TTCPriceField.coinsRequired'),
          // v => v % 1 === 0 || this.$t('components.TTCPriceField.coinsDecimal'),
          v => (v * this.unitPrice) >= this.minPrice || this.$t('components.TTCPriceField.coinsMinimum', { minCoin: this.minPrice / this.unitPrice })
        ]
      }
      return []
    },
    _price: {
      get () {
        return this.value.coins * this.unitPrice
      },
      set (v) {
        const curCoins = parseInt(v / this.unitPrice)
        this._coins = curCoins
        this._tokens = parseInt(curCoins * this.percentage / 100)
      }
    },
    _coins: {
      get () {
        return this.value.coins
      },
      set (v, oldV) {
        if (v === oldV) return
        this.$emit('input', { coins: v, tokens: parseInt(v * this.percentage / 100), percentage: this.percentage,
          currency: this.selectedCurrency })
      }
    },
    _tokens: {
      get () {
        return this.value.tokens
      },
      set (v, oldV) {
        if (v === oldV) return
        this.$emit('input', { tokens: v, coins: parseInt(v * 100 / this.percentage), percentage: this.percentage,
          currency: this.selectedCurrency })
        this.$nextTick(() => {
          this.percentageWarningMessage = oldV === v
            ? null
            : this.$t('components.TTCPriceField.percentageWarningMin', { min: this.minTokenPercentage, token: this.tokens })
        })
      }
    }
  },

  watch: {
    percentage (v) {
      this.$emit('input', { ...{ ...this.value },
        tokens: parseInt(this.value.coins * v / 100),
        percentage: v,
        currency: this.selectedCurrency
      })
    },
    selectedCurrencyCode (v) {
      const curCoins = parseInt(v / this.unitPrice)
      this._coins = curCoins
      this._tokens = parseInt(curCoins * this.percentage / 100)
    }
  }
}
</script>
