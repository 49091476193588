import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
const BaseConst = {
  version: 1,
  storeName: 'UserBackList',
  serviceName: 'C1301ProjectCrowdFundPledgeService',
  componentDef: {
    table: {
      viewDef: [
        { text: '項目', value: 'c0101_title' },
        // use type: 'date' to format date
        { text: '質押金額', value: 'custom_pledgeAmount' },
        // use type: 'enum' to format enum
        {
          text: '狀態',
          value: 'c1301_status',
          type: 'chip',
          // 'PAYMENT','FAILED','PLEDGED','PENDING','SHIP_OUT','FULLFILLED','WITHDRAW','REFUNDED','CANCELLED'
          items: [
            { value: 'PAYMENT', label: '已付款', color: 'green' },
            { value: 'FAILED', label: '付款失敗', color: 'red' },
            { value: 'PLEDGED', label: '已質押', color: 'blue' },
            { value: 'PENDING', label: '待處理', color: 'yellow' },
            { value: 'SHIP_OUT', label: '已出貨', color: 'green' },
            { value: 'FULLFILLED', label: '已完成', color: 'green' },
            { value: 'WITHDRAW', label: '已提領', color: 'green' },
            { value: 'REFUNDED', label: '已退款', color: 'red' },
            { value: 'CANCELLED', label: '已取消', color: 'red' }
          ]
        },
        { text: '質押日期', value: 'c1301_created_at', type: 'date' },
        { text: '', value: 'actions' }
      ],
      optionDef: {}
    }
  }
}
const C1001Const = new BaseDataStore(BaseConst)
export default C1001Const
