import TwoWayBindingMixin from './TwoWayBindingMixin'

/**
 * This mixin is for the AtFormComp
 * 
 * Override list:
 * 1. props.value - for the value of the form component
 * 2. data().defaultValue - for resetting the value to its default value
 */

// TODO: rules, validate
export default {
  mixins: [TwoWayBindingMixin],
  data () {
    return {
      /**
       * Override this, for clearing the value
       * for example, if the value is an object, then the clearValue should be an empty object
       * 
       * use cases:
       * 1. for AtFormv2 clear event
       */
      defaultValue: undefined
    }
  },
  mounted () {
    if (this.defaultValue !== undefined && !this.value) {
      this.dValue = this.defaultValue
    }
  },
  methods: {
    clear (val) {
      this.dValue = val || this.defaultValue
    }
  }
}
