<template>
  <div
    v-if="editor"
    :id="holder"
  />
</template>

<script>
import EditorJS from '@editorjs/editorjs'
import Paragraph from '@editorjs/paragraph'
import Header from '@editorjs/header'
import List from '@editorjs/list'
import CodeTool from '@editorjs/code'
import Embed from '@editorjs/embed'
import Table from '@editorjs/table'
import Checklist from '@editorjs/checklist'
import Marker from '@editorjs/marker'
import Warning from '@editorjs/warning'
import RawTool from '@editorjs/raw'
import Quote from '@editorjs/quote'
import InlineCode from '@editorjs/inline-code'
import Delimiter from '@editorjs/delimiter'
import Image from '@editorjs/image'
import InlineImage from 'editorjs-inline-image'
import InlineComment from './InlineCommentPlugin/InlineComment'
import Link from '@editorjs/link'
import Undo from 'editorjs-undo'
import DragDrop from 'editorjs-drag-drop'
// TODO: [Adrian] https://github.com/editor-js/nested-list
// import NestedList from @editorjs/nested-list

import CommentSection from './InlineCommentPlugin/CommentSection.vue'

import DataUtils from '@/utils/DataUtils'

export default {
  name: 'MoBlockEditor',

  props: {
    value: {
      type: Object,
      default: () => {}
    },
    mode: {
      type: String,
      default: 'edit',
      validator (value) {
        return ['edit', 'comment', 'view'].indexOf(value) !== -1
      }
    },
    config: {
      type: Object,
      default: () => {}
    },
    commentPluginCallback: {
      type: Object,
      default: () => {
        return {
          sendComment: async ({ content, blockId }) => {
            // true means success
            return true
          },
          resolveComment: async ({ commentId, resolved, blockId }) => {
            return true
          },
          replyComment: async ({ commentId, content, blockId }) => {
            return true
          },
          editComment: async ({ commentId, replyId, blockId }) => {
            return true
          },
          deleteComment: async ({ commentId, replyId, blockId }) => {
            return true
          }
        }
      },
      validator (value) {
        // check keys contains ['sendComment', 'resolveComment'],
        // and value is function
        return Object.keys(value).every((key) =>
          ['sendComment', 'resolveComment', 'replyComment', 'editComment', 'deleteComment']
            .includes(key) &&
        typeof value[key] === 'function'
        )
      }
    },
    /**
     * {
        currentUserId: 1,
        usersData: [
          { id: 1, name: 'user1', imageUrl: 'https://cdn.vuetifyjs.com/images/lists/1.jpg' },
          { id: 'user', name: 'Admin', imageUrl: 'https://cdn.vuetifyjs.com/images/lists/2.jpg' }
        ]
      }
     */
    commentPluginData: {
      type: Object,
      default: () => {},
      validator (value) {
        // check keys must contains ['currentUserId', 'usersData'],
        return Object.keys(value).every((key) =>
          ['currentUserId', 'usersData', 'commentPlaceHolder', 'replyPlaceHolder']
            .includes(key)
        )
      }
    },
    overrideBaseToolsConfig: {
      type: Object,
      default: () => {}
    },
    holder: {
      type: String,
      default: 'editorjs'
    }
  },

  data () {
    return {
      editor: null
    }
  },

  computed: {
    editorConfig () {
      const baseConfig = {
        holder: this.holder,
        logLevel: 'ERROR',
        readOnly: false,
        tunes: ['commentSection'],
        tools: {
          commentSection: {
            class: InlineComment,
            config: {
              callback: this.commentPluginCallback,
              component: CommentSection,
              mode: this.mode,
              ...this.commentPluginData
            }
          },
          paragraph: {
            class: Paragraph,
            inlineToolbar: true,
            config: DataUtils.mergeObjects({
              placeholder: this.$t('components.MoBlockEditor.paragraphPlaceholder')
            }, this.config?.paragraph)
          },
          header: {
            class: Header,
            config: DataUtils.mergeObjects({
              placeholder: 'Title'
            }, this.config?.header)
          },
          list: List,
          code: CodeTool,
          embed: Embed,
          table: Table,
          checklist: Checklist,
          marker: Marker,
          warning: Warning,
          raw: RawTool,
          quote: Quote,
          inlineCode: InlineCode,
          delimiter: Delimiter,
          image: {
            class: Image,
            config: DataUtils.mergeObjects({
              uploader: {
                uploadByFile: this.uploadByFile,
                uploadByUrl: this.uploadByUrl
              }
            }, this.config?.image)
          },
          inlineImage: {
            class: InlineImage,
            inlineToolbar: true,
            config: DataUtils.mergeObjects({
              embed: {
                display: true
              },
              unsplash: {
                appName: import.meta.env.VITE_APP_UNSPLASH_APP_NAME,
                clientId: import.meta.env.VITE_APP_UNSPLASH_CLIENT_ID
              }
            }, this.config?.inlineImage)
          },
          link: {
            class: Link,
            config: DataUtils.mergeObjects({
              endpoint: window.API_URL + 'fetchUrl'
            }, this.config?.link)
          }
        },
        data: this.value,

        /**
         * onReady callback
         */
        onReady: () => {
          const editor = this.editor
          /* eslint-disable-next-line no-new */
          new Undo({ editor })
          /* eslint-disable-next-line no-new */
          new DragDrop(editor)
        },

        /**
         * onChange callback
         */
        onChange: api => {
          api.saver.save().then(outputData => {
            this.$emit('input', outputData)
          })
        }
      }

      if (this.mode === 'view') {
        delete baseConfig.tunes
        delete baseConfig.tools.commentSection
        baseConfig.readOnly = true
        baseConfig.minHeight = 0
      }
      if (this.mode === 'comment') {
        baseConfig.readOnly = true
      }
      let output = {}
      output = { ...baseConfig, ...this.overrideBaseToolsConfig }
      return output
    }
  },
  watch: {
    mode (value) {
      this.editor?.destroy()

      if (value !== 'html') this.editor = new EditorJS(this.editorConfig)
      else this.editor = null
    }
  },

  mounted () {
    this.editor = new EditorJS(this.editorConfig)
  },
  methods: {
    uploadByFile (file) {
      const formData = new FormData()
      formData.append('file', file)
      return new Promise((resolve, reject) => {
        fetch(window.API_URL + 'upload.api', {
          method: 'POST',
          body: formData
        })
          .then((res) => {
            if (res.status !== 200) {
              this.$dialog.notify.error('Error, Please try again')
              reject(new Error('Upload failed'))
            }
            res.json().then(res => {
              res.url = window.API_URL + 'download.api?name=' + res.name + '&tmp=' + res.tmp
              resolve({ success: 1, file: res })
            })
          })
      })
    },
    uploadByUrl (url) {
      return new Promise((resolve, reject) => {
        fetch(window.API_URL + 'upload.api', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ url })
        })
          .then(res => {
            if (res.status !== 200) {
              this.$dialog.notify.error('Error, Please try again')
              reject(new Error('Upload failed'))
            }
            res.json().then(res => {
              res.url = window.API_URL + 'download.api?name=' + res.name + '&tmp=' + res.tmp
              resolve({ success: 1, file: res })
            })
          })
      })
    }
  }
}
</script>
