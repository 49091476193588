<template>
  <VRadioGroup
    v-model="selected"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-row
      v-for="(row, i) in radios"
      :key="i"
    >
      <v-col
        v-for="col in row"
        :key="col[itemValue]"
      >
        <VRadio
          :label="typeof col === 'object' ? col[itemText] : col"
          :value="typeof col === 'object' ? col[itemValue]: col"
        />
      </v-col>
    </v-row>
  </VRadioGroup>
</template>

<script>
/**
  * Extend VRadio
  * @displayName AtRadio
  */
export default {
  props: {
    /**
     * Can be an array of objects or array of strings.
     * When using objects, will look for a text, and value.
     * This can be changed using the **item-text**, and **item-value** props.
     */
    items: {
      type: Array,
      default: () => []
    },
    /**
     * Set property of **items**'s text value
     */
    itemText: {
      type: String,
      default: 'text'
    },
    /**
     * Set property of **item**'s value - **must be primitive**.
     */
    itemValue: {
      type: String,
      default: 'value'
    },
    /**
     * The input's value
     */
    value: {
      type: [String, Number],
      default: ''
    },
    /**
     * Number of items per row.
     * Can be a number or an object with breakpoint keys that return boolean. 
     * {@link https://vuetifyjs.com/en/features/breakpoints/#breakpoint-service-object}
     * @example
     * ```js
     * 1 // 1 item per row
     * { sm: 2, md: 3, lg: 4 } // 2 items per row on sm, 3 on md, 4 on lg,  other breakpoints will use 1
     * { smAndUp: 2 } // 1 item per row on xs, 2 items per row on sm and up
     * ```
     */
    itemsPerRow: {
      type: [Number, Object],
      default: 1
    }
  },
  computed: {
    selected: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    radios () {
      return this.items.reduce((resultArray, item, index) => {
        function getPerChunk (ctx) {
          if (typeof ctx.itemsPerRow === 'number') return ctx.itemsPerRow
          for (const key in ctx.itemsPerRow) {
            if (ctx.$vuetify.breakpoint[key] === undefined) {
              console.error(`Invalid breakpoint key: ${key}`)
            }
            if (typeof ctx.$vuetify.breakpoint[key] !== 'boolean') {
              console.error(`Breakpoint key must return a boolean: ${key}`)
            }
            if (ctx.$vuetify.breakpoint[key]) return ctx.itemsPerRow[key]
          }
          return 1
        }
        const perChunk = getPerChunk(this)

        const chunkIndex = Math.floor(index / perChunk)

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = [] // start a new chunk
        }

        resultArray[chunkIndex].push(item)

        return resultArray
      }, [])
    }
  }
}
</script>
