// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// import i18n from '@/plugins/i18n'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    enrollerAttendance: [],
    row: {}
  },

  getters: {
    getEnrollerAttendance: state => state.enrollerAttendance,
    getRow: state => state.row
  },

  mutations: {
    SET_ENROLLER_ATTENDANCE (state, payload) {
      state.enrollerAttendance = payload
    },
    SET_ROW (state, payload) {
      state.row = payload
    }
  },

  actions: {
    async initContentClassSession ({ commit, state }, input) {
      return await ApiUtils.postData({
        data: [
          { api: 'L0601ContentSectionContentService.getContentSectionContentManageInfo', input },
          { api: 'L0301ContentClassService.getContentClassEnrollerAttendance', input }
        ]
      })
        .then(data => {
          commit('SET_ROW', data[0].output)
          commit('SET_ENROLLER_ATTENDANCE', data[1].output)
        })
        .catch((e) => {
          return null
        })
    },
    async manuallyMarkAttendance ({ commit }, input) {
      return await ApiUtils.postData({ data: [{ api: 'L0801ContentSectionContentProgressService.manuallyMarkAttendance', input }] })
        .catch((e) => {
          return null
        })
    },
    async attendanceCheckIn ({ commit }, input) {
      return await ApiUtils.postData({ data: [{ api: 'L0801ContentSectionContentProgressService.attendanceCheckIn', input }] })
        .then((data) => {
          return data[0].output
        })
        .catch((e) => {
          return null
        })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
