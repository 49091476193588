<template>
  <v-combobox
    v-model="vModel"
    v-permissions="['tag.search']"
    v-bind="$attrs"
    :items="rows"
    :menu-props="{
      closeOnContentClick: true,
      value: input !== ''
    }"
    @keydown="searchTag"
  >
    <template
      v-if="isSearching"
      #append
    >
      <v-progress-circular indeterminate />
    </template>

    <template #append-item>
      <v-divider />
      <v-list-item
        link
        @click="pushTag"
      >
        {{ $t('components.TTCTagField.propose', { tag: input }) }}
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script>
import lodash from 'lodash'
import { mapState } from 'vuex'

export default {
  name: 'TTCTagField',

  props: {
    value: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      input: '',
      isSearching: false
    }
  },

  computed: {
    ...mapState('M0701Tag', {
      rows: 'rows'
    }),
    vModel: {
      get () {
        return this.value
      },
      set (value) {
        this.input = ''
        this.$emit('input', value)
      }
    }
  },

  mounted () {
    this.searchTag()
  },

  methods: {
    searchTag: lodash.debounce(function (event) {
      this.isSearching = true
      this.input = event ? event.target.value : ''
      this.storeApiDispatch('M0701Tag/searchTag', { tagName: this.input }, { loading: false })
        .then(() => {
          this.isSearching = false
        })
    }, 500),

    pushTag () {
      if (!this.vModel.includes(this.input)) {
        this.$emit('input', [...this.vModel, this.input])
      }
    }
  }
}
</script>
