import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'collab.public',
    path: 'PgC1301ProjectCrowdFundTierSelected/tier/:tierInfo',
    name: 'PgC1301ProjectCrowdFundTierSelected',
    component: () => import('./PgC1301ProjectCrowdFundTierSelected.vue'),
    meta: { title: i18n.t('c0101.partnership.partnershipPage') }
  }
]
