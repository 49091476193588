<template>
  <v-container v-if="editOnFullscreen && (form.show || [addFormRouteName, editFormRouteName].includes($route.name))">
    <v-row :key="form.key">
      <v-col cols="12">
        <VCard>
          <VCardTitle>
            <slot name="edit-form-title">
              {{ $route.params.action || formInfo.id ? "Edit" : "Add" }} Form
            </slot>
          </VCardTitle>
          <VCardText>
            <AtForm
              v-if="formDefData"
              v-model="formInfo"
              :def="formDefData"
              v-bind="childComputed.form.props"
              @onCancel="onFormCancel"
              @onSubmit="onFormSubmit"
            />
            <div v-else>
              formDefData Not defined
              <VBtn @click="onFormCancel">
                Close
              </VBtn>
            </div>
          </VCardText>
        </VCard>
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <v-row>
      <slot name="title" />
    </v-row>
    <v-dialog
      :key="form.key"
      v-model="form.show"
      :fullscreen="$vuetify.breakpoint.xs"
      v-bind="childComputed.dialog.props"
    >
      <VCard>
        <VCardTitle>
          <slot
            name="dialog-title"
            :form-info="formInfo"
          >
            {{ formInfo.id?"Edit":"Add" }} Form
          </slot>
        </VCardTitle>
        <VCardText>
          <AtForm
            v-if="formDefData"
            v-model="formInfo"
            :def="formDefData"
            v-bind="childComputed.form.props"
            @onCancel="onFormCancel"
            @onSubmit="onFormSubmit"
          />
          <div v-else>
            formDefData Not defined
            <VBtn @click="onFormCancel">
              Close
            </VBtn>
          </div>
        </VCardText>
      </VCard>
    </v-dialog>
    <v-row>
      <slot name="title" />
    </v-row>
    <v-row>
      <slot
        name="top"
        v-bind="{
          showForm,
          toggleShowFilter,
          activeFilterInputsNumber,
          topActionList
        }"
      >
        <v-spacer />
        <v-col
          v-if="topActionList.includes('showFilter')"
          cols="auto"
        >
          <ActionButton
            :preset="showFilter?'hideFilter':'showFilter'"
            text
            :dark="false"
            :badge-content="activeFilterInputsNumber.toString()"
            @click="toggleShowFilter()"
          />
        </v-col>
        <v-col
          v-if="topActionList.includes('add')"
          cols="auto"
        >
          <ActionButton
            v-if="editOnFullscreen && addFormRouteName"
            v-bind="childComputed.addBtn.props"
            @click="$router.push({ name: addFormRouteName })"
          />
          <ActionButton
            v-else
            v-bind="childComputed.addBtn.props"
            @click="showForm(null)"
          />
        </v-col>
      </slot>
    </v-row>
    <v-row v-show="showFilter && !isSearchDefEmpty">
      <v-col>
        <v-card class="px-3 py-5">
          <AtForm
            v-model="searchInfo"
            v-bind="childComputed.search.props"
            :def="searchDefData"
            undefined-on-empty
            @onSubmit="onSubmit"
            @activeInputsNumber="activeFilterInputsNumber = $event"
          />
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <AtDataTable
          v-model="options"
          :cols="tableDefData"
          :rows="rows"
          :loading="loading"
          v-bind="childComputed.dataTable.props"
          v-on="childComputed.dataTable.on"
          @search="dataTableEvent"
        >
          <template
            v-for="header in tableDefData"
            #[`header_${header.value}`]
          >
            <slot
              :name="'table_header_'+header.value"
              :header="header"
            >
              {{ header.text }}
            </slot>
          </template>
          <template
            v-for="header in tableDefData"
            #[`item_${header.value}`]="{ item }"
          >
            <slot
              :name="'table_item_'+header.value"
              :item="item"
              :show-form="showForm"
            >
              {{ item[header.value] }}
            </slot>
          </template>
          <template #expanded_item="data">
            <slot
              name="table_row_expanded"
              v-bind="data"
            />
          </template>
        </AtDataTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {AtForm} from '@/components/base/atoms/AtForm'
import {AtDataTable} from '@/components/base/atoms/AtDataTable'
import ActionButton from '@/components/base/molecules/ActionButton/ActionButton.vue'
import DataUtils from '@/utils/DataUtils'
import A0mixin from '../Template1/Template1A0'
/**
 * Search field and DataTable component
 * @prop {Object} value - The value of the search field and DataTable, including searchInfo, orderBy, and pagination data
 * @see value
 *
 * @prop {Array} rows - The rows of data for the DataTable
 * @see rows
 *
 * @prop {Boolean} loading - Whether the DataTable is loading data
 * @see loading
 *
 * @prop {Object} child -  Allows customization of child components, such as the add button, form, search, dataTable, and dialog
 * @see child
 *
 * @function toggleShowFilter
 * @arg {Boolean} [show] - The value to set the showFilter property to
 * - If show is null (default), the showFilter property will be toggled
 * - If show is `true`, the showFilter property will be set to true
 * - If show is `false`, the showFilter property will be set to false
 *
 * @emit input - Emits input event with the updated value object
 * @emit search - Emits search event with the current searchInfo object
 * @emit onEdit - Emits onEdit event with the current formInfo object
 * @emit onAdd - Emits onAdd event with the current formInfo object
 *
 * @mixin A0mixin - {@link A0mixin}
 */
export default {
  components: {
    AtForm, AtDataTable, ActionButton
  },
  mixins: [A0mixin],
  props: {
    /**
     * The value of the search field and DataTable
     * @typedef {Object} value
     * @property {Object} searchInfo - The search field data
     * @property {Object} orderBy - The orderBy data
     * @property {Object} pagination - The pagination data
     * @property {number} pagination.current_page - The current page number
     * @property {number} pagination.last_page - The last page number
     * @property {number} pagination.per_page - The number of items per page
     * @property {number} pagination.total - The total number of items
     */
    value: {
      type: Object,
      default: () => {
        return {
          searchInfo: {},
          orderBy: {},
          pagination: {
            current_page: 1,
            last_page: 1,
            per_page: 10,
            total: null
          }
        }
      }
    },
    /**
     * The rows of data for the DataTable
     * @typedef {Array} rows
     */
    rows: {
      type: Array,
      default: () => { return [] }
    },
    /**
     * Whether the DataTable is loading data
     * @typedef {Boolean} loading
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * for customize childComponent, merge with [default option]{@link defaultChild} in {@link childComputed}
     * @typedef {Object} child
     * @property {Object} addBtn - Add Button
     * @property {Object} form - add/edit Form
     * @property {Object} search -  search form
     * @property {Object} dataTable - dataTable
     * @property {Object} dialog - Dialog for showing Add/Edit Form
     */
    child: {
      type: Object,
      default: () => { return {} }
    },
    editOnFullscreen: {
      type: Boolean,
      default: false
    },
    addFormRouteName: {
      type: String,
      default: null
    },
    editFormRouteName: {
      type: String,
      default: null
    },
    // ['add','showFilter']
    topActionList: {
      type: Array,
      default: () => { return ['add'] }
    }
  },
  data () {
    return {
      options: {},
      searchInfo: {},
      showFilter: !this.topActionList.includes('showFilter'),
      activeFilterInputsNumber: 0,
      formInfo: { id: null },
      form: {
        show: false,
        key: 0
      },
      tableDefData: [],
      searchDefData: [],
      formDefData: []
    }
  },
  computed: {
    /**
     * merged child from {@link child} with {@link defaultChild}
     * @typedef {object} childComputed
     */
    childComputed () {
      /**
       * @typedef {object} defaultChild
       * @property {Object} addBtn - Add Button
       * @property {Object} form - add/edit Form
       * @property {Object} search -  search form
       * @property {Object} dataTable - dataTable
       * @property {Object} dialog - Dialog for showing Add/Edit Form
       */
      const defaultChild = {
        addBtn: { props: { preset: 'add' } },
        form: { props: { cancelBtn: true, def: 'Ignore By default' } },
        search: { props: { def: 'Ignore By default' } },
        dataTable: {
          props: {
            cols: 'Ignore By default',
            rows: 'Ignore By default',
            loading: 'Ignore By default'
          },
          on: {}
        },
        dialog: {
          props: {
            width: '500px',
            persistent: true
          }
        }
      }
      return DataUtils.mergeObjects(defaultChild, this.child)
    }
  },
  watch: {
    value: {
      handler () {
        const { orderBy, pagination, searchInfo } = this.value
        this.searchInfo = searchInfo
        this.options = { orderBy, pagination }
      },
      deep: true,
      immediate: true
    },
    searchInfo: {
      handler (val, oldVal) {
        if (DataUtils.isSameObject(val, oldVal)) return
        this.$emit('input', { ...this.value, searchInfo: val })
      },
      deep: true
    },
    options: {
      handler (val, oldVal) {
        if (DataUtils.isSameObject(val, oldVal)) return
        this.$emit('input', { ...this.value, ...val })
      },
      deep: true
    },
    '$route.name': {
      handler (val, oldVal) {
        if (val === this.editFormRouteName) {
          this.$emit('form:edit', this.showForm)
        } else if (val === this.addFormRouteName) {
          this.showForm()
        }
      },
      immediate: true
    }
  },
  methods: {
    /**
     * Handles the search event from the AtForm Search component
     * @param {Object} searchInfo - The search criteria from the form
     */
    onSubmit (searchInfo) {
      /**
       * Emits an event when the search form is submitted
       * @event search
       * @type {value}
       */
      this.$emit('search', DataUtils.jsonCopy({ ...this.value, searchInfo }))
    },
    /**
     * Handles the search event from the AtDataTable component
     * @param {Object} options - The options for the DataTable
     * @param {Object} self - pass context to the event
     */
    dataTableEvent (options, self) {
      /**
       * @event search
       * Emits an event when the dataTable `search` Event is emited
       * @type {Object} options - value format
       */
      this.$emit('search', DataUtils.jsonCopy({ ...this.value, ...options }))
    },
    showForm (modal = null) {
      this.form = { show: true, key: this.form.key + 1 }
      this.formInfo = { id: null }
      if (modal === null) return
      this.formInfo = DataUtils.jsonCopy(modal)
    },
    /**
     * @method toggleShowFilter Toggles the showFilter property
     * @param {Boolean} val - The value to set the showFilter property to
     * - If null, the showFilter property will be toggled
     * - If true, the showFilter property will be set to true
     * - If false, the showFilter property will be set to false
     */
    toggleShowFilter (val = null) {
      if (val !== null) {
        this.showFilter = val
        return
      }
      this.showFilter = !this.showFilter
    },
    onFormCancel () {
      this.form.show = false
      this.$emit('form:cancel')
    },
    onFormSubmit (data) {
      if ('id' in data && data.id !== null) {
        /**
         * @event onEdit
         * Emits an event when the Edit form is submitted
         * @type {Object}
         * @property {Object} formData - The data from the form
         */
        this.$emit('onEdit', DataUtils.jsonCopy(data))
      } else {
        /**
         * @event onAdd
         * Emits an event when the add form is submitted
         * @type {Object}
         * @property {Object} formData - The data from the form
         */
        this.$emit('onAdd', DataUtils.jsonCopy(data))
      }
      this.form.show = false
      // TODO: success dialog
    }
  }
}

</script>

<style>

</style>
