import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    surveyDatas: {},
    surveyQuestion: {}
  },
  getters: {},
  mutations: {},
  actions: {
    async getSurveyData({ commit, state, dispatch }, payload) {
      state.surveyData = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T5001SurveyService.doRead_surveyData', input: {
              seo: payload.seo,
              policy: payload.policy
            }
          }
        ]
      })
      state.surveyDatas = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.surveyData)
      })
    },
    async getSurveyAnswer({ commit, state}, payload) {

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T5101SurveyAnswerService.getSurveyAnswer', input: payload
          }
        ]
      })
      state.surveyQuestion = res[0].output
      return new Promise((resolve, reject) => {
        resolve(state.surveyQuestion)
      })
    },
    async updateAnswer({ commit, state}, payload) {

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'T5101SurveyAnswerService.updateAnswer', input: payload
          }
        ]
      })
      return res[0].output
    },
  }
}

export default ObjArrUtils.merge(Base, error, module)
