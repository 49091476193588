import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    unreadNotification: 0
  },
  getters: {
    unreadNotification: state => state.unreadNotification
  },
  mutations: {
    SET_UNREAD_NOTIFICATION (state, payload) { state.unreadNotification = payload },
  },
  actions: {
    getUnreadNotificationCount: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getUnreadNotificationCount', input: payload }]
      }).then(data => {
        state.unreadNotification = data[0].output
        return data[0].output
      })
    },
    getNotification: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.getNotification', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    readNotification: ({ state }, payload) => {
      if (state.unreadNotification > 0) {
        if (payload?.notiID) {
          state.unreadNotification--
        } else {
          state.unreadNotification = 0
        }
      }
      return ApiUtils.postData({
        data: [{ api: 'M0001UserService.readNotification', input: payload }]
      }).then(data => {
        return data[0].output
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
