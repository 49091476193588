import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'admin.admin',
    path: 'settings',
    name: 'Settings',
    component: () => import('@/app/S0801Setting/PgS0801Settings.vue'),
    meta: { title: i18n.t('s0801.pageName'), authenticated: true, permissions: ['setting.manage'] }
  }
]
