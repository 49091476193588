import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    dialog: {
      confirm: [],
      info: [],
      warning: [],
      error: [],
      prompt: []
    },
    notify: {
      success: [],
      info: [],
      warning: [],
      error: []
    },
    toast: {
      success: [],
      info: [],
      warning: [],
      error: []
    },
    // if is string, then it is the text to display in the prompt
    // TODO: if is function, then it is the function to call to determine if the user should be prompted
    // if undefined or null, then no prompt is displayed
    promptOnRouteChange: undefined
  },

  getters: {
    getDialogConfirm (state) {
      if (state.dialog.confirm.length) return state.dialog.confirm[0]
      return null
    },

    getDialogInfo (state) {
      if (state.dialog.info.length) return state.dialog.info[0]
      return null
    },

    getDialogWarning (state) {
      if (state.dialog.warning.length) return state.dialog.warning[0]
      return null
    },

    getDialogError (state) {
      if (state.dialog.error.length) return state.dialog.error[0]
      return null
    },

    getDialogPrompt (state) {
      if (state.dialog.prompt.length) return state.dialog.prompt[0]
      return null
    },

    getNotifySuccess (state) {
      if (state.notify.success.length) return state.notify.success[0]
      return null
    },

    getNotifyInfo (state) {
      if (state.notify.info.length) return state.notify.info[0]
      return null
    },

    getNotifyWarning (state) {
      if (state.notify.warning.length) return state.notify.warning[0]
      return null
    },

    getNotifyError (state) {
      if (state.notify.error.length) return state.notify.error[0]
      return null
    },

    getToastSuccess (state) {
      if (state.toast.success.length) return state.toast.success[0]
      return null
    },

    getToastInfo (state) {
      if (state.toast.info.length) return state.toast.info[0]
      return null
    },

    getToastWarning (state) {
      if (state.toast.warning.length) return state.toast.warning[0]
      return null
    },

    getToastError (state) {
      if (state.toast.error.length) return state.toast.error[0]
      return null
    }
  },

  mutations: {
    SET_DIALOG_CONFIRM (state, payload) {
      if (payload) state.dialog.confirm.push(payload)
      if (!payload && state.dialog.confirm.length) state.dialog.confirm.shift()
    },
    SET_DIALOG_INFO (state, payload) {
      if (payload) state.dialog.info.push(payload)
      if (!payload && state.dialog.info.length) state.dialog.info.shift()
    },
    SET_DIALOG_WARNING (state, payload) {
      if (payload) state.dialog.warning.push(payload)
      if (!payload && state.dialog.warning.length) state.dialog.warning.shift()
    },
    SET_DIALOG_ERROR (state, payload) {
      if (payload) state.dialog.error.push(payload)
      if (!payload && state.dialog.error.length) state.dialog.error.shift()
    },
    SET_DIALOG_PROMPT (state, payload) {
      if (payload) state.dialog.prompt.push(payload)
      if (!payload && state.dialog.prompt.length) state.dialog.prompt.shift()
    },
    SET_NOTIFY_SUCCESS (state, payload) {
      if (payload) state.notify.success.push(payload)
      if (!payload && state.notify.success.length) state.notify.success.shift()
    },
    SET_NOTIFY_INFO (state, payload) {
      if (payload) state.notify.info.push(payload)
      if (!payload && state.notify.info.length) state.notify.info.shift()
    },
    SET_NOTIFY_WARNING (state, payload) {
      if (payload) state.notify.warning.push(payload)
      if (!payload && state.notify.warning.length) state.notify.warning.shift()
    },
    SET_NOTIFY_ERROR (state, payload) {
      if (payload) state.notify.error.push(payload)
      if (!payload && state.notify.error.length) state.notify.error.shift()
    },
    SET_TOAST_SUCCESS (state, payload) {
      if (payload) state.toast.success.push(payload)
      if (!payload && state.toast.success.length) state.toast.success.shift()
    },
    SET_TOAST_INFO (state, payload) {
      if (payload) state.toast.info.push(payload)
      if (!payload && state.toast.info.length) state.toast.info.shift()
    },
    SET_TOAST_WARNING (state, payload) {
      if (payload) state.toast.warning.push(payload)
      if (!payload && state.toast.warning.length) state.toast.warning.shift()
    },
    SET_TOAST_ERROR (state, payload) {
      if (payload) state.toast.error.push(payload)
      if (!payload && state.toast.error.length) state.toast.error.shift()
    }
  },

  actions: {
  }
}

export default ObjArrUtils.merge(Base, module)
