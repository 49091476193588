<template>
  <v-row>
    <v-col cols="6">
      <VSelect
        v-model="dValue.o0301_id"
        outlined
        :items="currencyList"
        item-text="code"
        :label="$t('currency')"
        item-value="o0301_id"
        :rules="[
          v => !!v || $t('currencyRequired')
        ]"
      />
    </v-col>
    <v-col cols="6">
      <AtTextField
        type="number"
        :disabled="!dValue.o0301_id"
        placeholder="0.00"
        :label="$t('amount')"
        :hint="'1 ' + code + ' ≈ ' + parseInt(1/unitPrice) + ' ' + $t('components.TTCPriceField.coins')"
        :rules="[
          v => !!v || $t('amountRequired'),
          v => v >= minPrice || $t('amountMin', { minPrice: minPrice }),
          // two decimal
          v => /^-?\d+(\.\d{1,2})?$/.test(v) || $t('amountDecimal', { minPrice: minPrice })
        ]"
        persistent-hint
        :value="parseFloat(dValue.amount)"
        @input="v => dValue.amount = parseFloat(v)"
      />
    </v-col>
  </v-row>
</template>
<script>
import AtTextField from '../AtTextField/AtTextField.vue'
export default {
  components: { AtTextField },
  i18n:{
    messages:{
      "en":{
        "amount": "Amount",
        "amountRequired": "Amount is required",
        "amountMin": "Amount must be greater than or equal to {minPrice}",
        "currency" : "Currency",
        "currencyRequired": "Currency is required",
        "amountDecimal":"Amount must be two decimal"
      },
      "zhHant":{
        "amount": "價錢",
        "amountRequired": "價錢為必填",
        "amountMin": "價錢必須大於等於 {minPrice}",
        "currency" : "貨幣",
        "currencyRequired": "貨幣為必填",
        "amountDecimal":"價錢必須為兩位小數"
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        amount: null,
        o0301_id: null
      })
    },
    currencyList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      dValue: this.value
    }
  },
  computed: {
    selectedCurrency () {
      return this.currencyList.find(item => item.o0301_id === this.dValue.o0301_id) || {}
    },
    code () {
      return this.selectedCurrency.code
    },
    unitPrice () {
      if (!this.currencyList.length) return 0
      return parseFloat(this.selectedCurrency.coins_type_currency_unit_price)
    },
    minPrice () {
      if (!this.currencyList.length) return 0
      return parseFloat(this.selectedCurrency.coins_type_currency_minimum_currency)
    }
  },
  watch: {
    value: {
      handler (val) {
        this.dValue = val
      },
      deep: true
    },
    dValue: {
      handler (val) {
        val.o0301_code = this.code
        this.$emit('input', val)
      },
      deep: true
    }
  },
  mounted () {
    if (!this.dValue.o0301_id) {
      this.dValue.o0301_id = this.currencyList[0]?.o0301_id || null
    }
  }
}
</script>

<style>

</style>
