import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'
const store = {
  state: {
    view: {
      row: {},
      s0902_rows: [],
      related_s0901: []
    },
    footerPolicy: {
      about: '',
      tac: '',
      privacy: ''
    }
  },
  getters: {
    lastestVersion: state => {
      return state.view.s0902_rows[0]
    }
  },
  mutations: {
    SET_FOOTER_POLICY (state, payload) { state.footerPolicy = payload },
  },
  actions: {
    async doReadCheckbox({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        // data: payload.codes.map(codes => ({ api: 'S0901PolicyService.doReadCheckbox', input: payload }))
        data: [{api: 'S0901PolicyService.doReadCheckbox', input: payload}]
      });
      // Assuming the response is an array of outputs corresponding to the array of inputs
      commit('SET_ROW', res.map(r => r.output));
      return res.map(r => r.output);
    },
    readVersion: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'S0901PolicyService.getVersion', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    view_doRead: ({ state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'S0901PolicyService.view_doRead', input: payload }]
      }).then(data => {
        state.view = data[0].output
      })
    },
    searchPolicyVersion: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'S0901PolicyService.searchPolicyVersion', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    policyPortal: () => {
      return ApiUtils.postData({
        data: [{ api: 'S0901PolicyService.policyPortal' }]
      }).then(data => {
        return data[0].output
      })
    },
    getPolicyName: () => {
      return ApiUtils.postData({
        data: [{ api: 'S0901PolicyService.doGetPolicyName' }]
      }).then(data => {
        return data[0].output
      })
    },
    addPolicyVersion: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'S0901PolicyService.debug_doSave', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    getEditPolicy: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'S0901PolicyService.getEditPolicy', input: payload }]
      }).then(data => {
        return data[0].output
      })
    }
  }
}
export default ObjArrUtils.merge(Base, BREAD,error, store)
