<template>
  <div
    ref="lottieContainer"
    :style="style"
  />
</template>

<script>
// @ts-check

/**
 * Official Lottie player by airbnb
 * @see https://github.com/airbnb/lottie-web
 */
import lottie from 'lottie-web'

/**
 * Lottie extension to enable changing colors easily from code
 * @see https://github.com/xxmuaddib/lottie-colorify
 */
import { colorify, replaceColor, flatten } from 'lottie-colorify'

import StringUtils from '@/utils/StringUtils'

/**
 * This is a wrapper component to display lottie animation with options   
 * - `lottie-colorify` extension added for more customization
 * - Emits `animControl` event (Lottie Animation Object)
 * 
 * @see https://github.com/airbnb/lottie-web
 * @see https://github.com/xxmuaddib/lottie-colorify
 * 
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/20
 */
export default {
  name: 'Lottie',
  props: {
    /**
     * Sets the animation name for future reference
     * @default lottie-{random_num} 
     */
    name: {
      type: String,
      default: () => 'lottie-' + Math.random()
    },
    /**
     * Sets the width of the animation
     * @default 200px
     */
    width: {
      type: [String, Number],
      default: () => '200px'
    },
    /**
     * Sets the height of the animation
     * @default 200px
     */
    height: {
      type: [String, Number],
      default: () => '200px'
    },
    /**
     * Sets the background color of the animation
     * @default transparent
     */
    backgroundColor: {
      type: String,
      default: () => 'transparent'
    },
    /**
     * Sets to loop the animation indefinitely (`Boolean`) or
     * sets to loop fixed number of times (`Number`)
     * @default true
     */
    loop: {
      type: [Boolean, Number],
      default: () => true
    },
    /**
     * Sets whether to autoplay the animation. 
     * It will starts to play as soon as it is ready
     * @default true
     */
    autoplay: {
      type: Boolean,
      default: () => true
    },
    /**
     * Sets the renderer for the animation
     * - Available values: **['svg', 'canvas', 'html']**
     * @default svg
     * @see https://github.com/airbnb/lottie-web/wiki/Features
     */
    renderer: {
      type: String,
      default: () => 'svg'
    },
    /**
     * Pass the path to a external lottie json file  
     * [This is mutually exclusive with the `animationData` props]
     * @default https://assets1.lottiefiles.com/packages/lf20_mbgkn5h6.json
     * @see https://lottiefiles.com/
     */
    path: {
      type: String,
      default: () => 'https://assets1.lottiefiles.com/packages/lf20_mbgkn5h6.json'
    },
    /**
     * Pass the json object of lottie animation  
     * [This is mutually exclusive with the `path` props]
     * @type Object
     * @default null
     * @see https://lottiefiles.com/
     */
    animationData: {
      type: Object,
      default: () => null
    },
    /**
     * Sets the animation speed
     * - min: `0`
     * @default 1
     */
    animationSpeed: {
      type: Number,
      default: () => 1
    },
    /**
     * Some specific settings that can be passed to the renderer instance  
     * If `null` default settings will be used
     * @type Object
     * @default null
     * @see https://github.com/airbnb/lottie-web/wiki/Renderer-Settings
     */
    rendererSettings: {
      type: Object,
      default: () => null
    },
    /**
     * Sets the animation frame range  
     * Expects `Array` with the length of `2`  
     * First value is the initial frame, second value is the final frame
     * @example [initialFrame, finalFrame]
     * @default null
     */
    initialSegment: {
      type: Array,
      default: () => null
    },
    /**
     * If set to `true`, elements will get added instantiated 
     * and added to the DOM only when the play cursor reaches that frame. 
     * It might not work correctly if track matte masks are 
     * not layer aligned with the masked elements.
     * @default false
     */
    progressiveLoad: {
      type: Boolean,
      default: () => false
    },
    /**
     * Change the colors of a Lottie JSON, one by one (takes 2 argument)  
     * - Argument 1: Colors array (a color can be hex with and without #, 
     * an array of RGB values or undefined for using original color in the animation)  
     * - Argument 2: Lottie animation (only works with `animationData` props)
     * @example [[colorArray], lottieAnimation]
     * @default null
     * @see https://github.com/xxmuaddib/lottie-colorify#usage
     */
    colorify: {
      type: Array,
      default: () => null
    },
    /**
     * Change a single color of a Lottie JSON (takes 3 argument)  
     * - Argument 1: Source color (the color to look for in a Lottie JSON)  
     * - Argument 2: Target color (the replacement color)  
     * - Argument 3: Lottie animation (only works with `animationData` props)
     * @example [sourceColor, targetColor, LottieAnimation]
     * @default null
     * @see https://github.com/xxmuaddib/lottie-colorify#usage
     */
    replaceColor: {
      type: Array,
      default: () => null
    },
    /**
     * To flatten (change all color) a Lottie JSON and use only one color (takes 2 argument)  
     * - Argument 1: Target color (the replacement color)  
     * - Argument 2: Lottie animation (only works with `animationData` props)
     * @example [targetColor, LottieAnimation]
     * @default null
     * @see https://github.com/xxmuaddib/lottie-colorify#usage
     */
    flattenColor: {
      type: [String, Array],
      default: () => null
    }
  },
  data: () => ({
    anim: null,
    options: {},
    style: {}
  }),
  mounted () {
    // Starts animation on mounted
    this.loadAnimation()
  },
  methods: {
    /**
     * Starts loading the animation with supplied options  
     * Will emit a `animControl` event passing animation object
     */
    loadAnimation () {
      // Set options
      this.options = {
        container: this.$refs.lottieContainer,
        name: this.name,
        renderer: this.renderer,
        loop: this.loop,
        autoplay: this.autoplay,
        width: StringUtils.getPixelSize(this.width),
        height: StringUtils.getPixelSize(this.height),
        path: this.path,
        animationData: this.animationData
      }

      // Set styles
      this.style = {
        width: StringUtils.getPixelSize(this.width),
        height: StringUtils.getPixelSize(this.height),
        backgroundColor: this.backgroundColor
      }

      // Other optional settings
      if (this.rendererSettings) {
        this.options.rendererSettings = this.rendererSettings
      }

      if (this.initialSegment) {
        this.options.initialSegment = this.initialSegment
      }

      if (this.progressiveLoad) {
        this.options.progressiveLoad = this.progressiveLoad
      }

      // Change animation colors
      if (this.colorify && this.animationData) {
        this.options.animationData = colorify(this.colorify, this.animationData)
      }

      if (this.replaceColor && this.animationData) {
        this.options.animationData = replaceColor(this.replaceColor[0], this.replaceColor[1], this.animationData)
      }

      if (this.flattenColor && this.animationData) {
        this.options.animationData = flatten(this.flattenColor, this.animationData)
      }

      // Releases resources before generating a new one. The DOM element will be emptied.
      if (this.anim) {
        this.anim.destroy()
      }

      // Starts animation with options
      // @ts-ignore
      this.anim = lottie.loadAnimation(this.options)

      // Sets animation speed
      this.anim.setSpeed(this.animationSpeed)

      /**
       * Emits animation object for further control
       * @return (Object) Lottie Animation object
       * @see http://airbnb.io/lottie/#/web?id=usage-1
       */
      this.$emit('animControl', this.anim)
    }
  }
}
</script>
