<template>
  <v-autocomplete
    v-if="init"
    v-model="vModel"
    v-bind="$attrs"
    :items="listitem"
    item-text="category_name"
    item-value="id"
  >
    <template #item="{item}">
      <v-list-item-content
        v-if="item.m0601_id === null"
        class="font-weight-bold"
      >
        {{ item.category_name }}
      </v-list-item-content>
      <v-list-item-content
        v-else
        class="ml-3"
      >
        <v-list-item-title>{{ item.category_name }}</v-list-item-title>
        <v-list-item-subtitle>{{ listitem.find(category => category.id === item.m0601_id).category_name }}</v-list-item-subtitle>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    value: {
      type: Number,
      default: null
    },
    items: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      init: false
    }
  },
  computed: {
    vModel: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    ...mapState("M0601Category", [
      "category",
    ]),
    listitem(){
      // if this.items is set, use it
      if (this.items && this.items.length > 0)
        return this.items

      // if not, use the category
      return this.category
    }
  },

  async mounted () {
    this.storeApiDispatch("M0601Category/init_category");

    this.init = true
  }
}
</script>
