import listingObj from '@/app/C0101Project/comps/C0101Item/C0101ListDemoJson.js'

const cfProposal = [
  {
    id: 1,
    title: 'AI錄音機 x1',
    desc: '當您訂購我們的AI錄音機時，您將享受到超值的優惠。 我們將會提供一台高質量的AI錄音機，並且為了讓您的體驗更加無憂無慮，我們承諾包括免費運費。',
    imgSrc: '../assets/images/details-cf-tier-placeholder-1.jpg',
    supporters: 12,
    coin: 690,
    token: 20,
    currency: 'US$ 6.90'
  },
  {
    id: 2,
    title: 'AI錄音機 x1',
    desc: '當您訂購我們的AI錄音機時，您將享受到超值的優惠。 我們將會提供一台高質量的AI錄音機，並且為了讓您的體驗更加無憂無慮，我們承諾包括免費運費。',
    imgSrc: '../assets/images/details-cf-tier-placeholder-1.jpg',
    supporters: 12,
    coin: 690,
    token: 20,
    currency: 'US$ 6.90'
  },
  {
    id: 3,
    title: 'AI錄音機 x1',
    desc: '當您訂購我們的AI錄音機時，您將享受到超值的優惠。 我們將會提供一台高質量的AI錄音機，並且為了讓您的體驗更加無憂無慮，我們承諾包括免費運費。',
    imgSrc: '../assets/images/details-cf-tier-placeholder-1.jpg',
    supporters: 12,
    coin: 690,
    token: 20,
    currency: 'US$ 6.90'
  }
]

export default {
  row: {
    ...listingObj.crowdfund,

    comments: 6,
    tags: ['音訊技術', '設計', '民生'],
    intention: ['crowdfund', 'investment', 'partnership', 'advise']
  },
  overview: {
    project_desc: 'MoBlockViewDummyData',
    project_market_analyze: 'MoBlockViewDummyData',
    project_risk_challenge: 'MoBlockViewDummyData',
    project_financial_forecast: 'MoBlockViewDummyData',
    project_timeline: 'MoBlockViewDummyData',
    team: [
      {
        name: '華田 Watin',
        role: '設計師',
        imgSrc: '../assets/images/ttc-press-member-watin.png',
        role_name: '思想家'
      },
      {
        name: '林森 Tommy',
        role: '設計師',
        imgSrc: '../assets/images/details-member-placeholder-2.jpg',
        role_name: '探險家'
      },
      {
        name: '柯里斯',
        role: '銷售經理',
        imgSrc: '../assets/images/ttc-press-member-watin.png',
        role_name: '思想家'
      },
      {
        name: '薛寇弟',
        role: '前端開發者',
        imgSrc: '../assets/images/details-member-placeholder-3.jpg',
        role_name: '探險家'
      },
      {
        name: 'Yuen',
        role: '前端開發者',
        imgSrc: '../assets/images/details-member-placeholder-4.jpg',
        role_name: '探險家'
      }
    ],
    certification: [
      {
        title: '認證證書',
        desc: '符合品質保證',
        imgSrc: '../assets/images/details-certificate-placeholder-1.jpg'
      },
      {
        title: '合規證書',
        desc: '符合品質保證',
        imgSrc: '../assets/images/details-certificate-placeholder-2.jpg'
      }
    ],
    partnership: [
      {
        title: 'Interswitch',
        desc: '資金公司',
        imgSrc: '../assets/images/details-partner-placeholder-1.jpg'
      },
      {
        title: 'BINDO LABS',
        desc: '科技公司',
        imgSrc: '../assets/images/details-partner-placeholder-2.jpg'
      }
    ],
    cfProposal,
    faq: [
      {
        question: '如何使用 P.Note 進行錄音？',
        answer: 'PLAUD NOTE有兩種錄音模式 - 電話和普通 - 可以透過切換開關的上下選擇。 該設備配有三個麥克風，其中兩個是空氣傳導感測器，用於捕捉周圍的聲音，而第三個是振動傳導感測器，當啟動時可以捕捉到內部電話聲音。'
      },
      {
        question: '我能上傳一個音訊檔案並使用 P.NOTE AI 進行處理嗎？',
        answer: '是的，如果您擁有 P.NOTE AI 會員資格。 轉錄分鐘將從您的轉錄分鐘餘額中扣除。'
      },
      {
        question: '電話錄音是如何運作的？',
        answer: 'P.NOTE電話錄音模式支援：1. 無論揚聲器是開啟或關閉，都能記錄呼入和呼出的電話。 2. 它記錄普通電話以及Facetime、WhatsApp、Zoom、Skype和任何其他類型的通話。'
      }
    ]
  },
  // no crowdfund list
  crowdfundDetails: cfProposal[0],
  partnerseeking: [
    {
      id: 1,
      title: '高級工程設計師',
      subtitle: '為我們的產品尋找優秀且專業的工程師，能夠協助開發和製作真實的原型，以確保我們的產品具有最高的品質。 除此之外，能夠引領整個流程，工程出我們...',
      imgSrc: '../assets/images/details-partner-placeholder-1.jpg',
      earn: 'RM 14,000 / 每月',
      slotLeft: 2,
      daysLeft: 10
    },
    {
      id: 2,
      title: '共同創辦人',
      subtitle: '我希望能有一位熟練且專業的共同創辦人，能夠幫助這家新創公司成功，並取得卓越的績效。 協助管理整個團隊，實現更好的工作管理流程。',
      imgSrc: '../assets/images/details-partner-placeholder-2.jpg',
      earn: '10% 公司股份',
      slotLeft: 5,
      daysLeft: 5
    }
  ],
  partnerseekingDetails: {
    id: 1,
    status: 'PUBLISHED',
    title: '高級工程設計師',
    subtitle: '為我們的產品尋找優秀且專業的工程師，能夠協助開發和製作真實的原型，以確保我們的產品具有最高的品質。 除此之外，能夠引領整個流程，工程出我們...',
    imgSrc: '../assets/images/details-partner-placeholder-1.jpg',
    earn: 'RM 14,000 / 每月',
    slotLeft: 2,
    daysLeft: 10,
    requestReceived: 15,
    interviewed: 8,
    scope: `作為人工智慧錄音機的高級工程設計師,您將在我們尖端錄音技術的開發和增強中發揮至關重要的作用。您的職責包
    括,

    系統架構與設計:
    領導人工智慧驅動的錄音系統的架構設計。
    與跨職能團隊合作定義系統需求和規範。

    演算法開發:
    開發和優化語音訊號處理、降噪和語音辨識的演算法。
    實施機器學習模型以提高錄音的準確性和效率。

    使用者介面設計:
    為錄音應用程式設計直覺且使用者友好的介面。
    確保跨各種平台和裝置的無縫用戶體驗。`,
    requirement: `要成功擔任此職位,您應具備以下資格和技能,

    學歴:
    電機工程、電腦科學或相關領域的學士或碩士學位。

    經驗:
    在設計和開發基於人工智慧的語音記錄系統方面擁有豐富的經驗(X年)。

    技術能力:
    精通訊號處理、機器學習和人工智慧。
    強大的Python、C++或Java等語言程式設計能力。

    使用者介面設計:
    具有設計應用程式使用者介面的經驗,重點關注可用性和可訪問性。`,
    files: [
      {
        name: '高級工程設計師申請表.pdf',
        src: 'http://localhost:8000/download.api?path=test/sample.pdf'
      },
      {
        name: '公司概況.pdf',
        src: 'http://localhost:8000/download.api?path=test/sample.pdf'
      }
    ],
    skill: ['Python', 'C++', 'Java', '機器學習', '人工智慧', '訊號處理']
  },
  adviseList: {
    data: [
      {
        id: 1,
        title: '這台AI錄音機支持哪些語言的語音識別?',
        subtitle: '關於這台AI錄音機的語音識別功能，我想了解它支持哪些具體的語言。由於在不同的工作和生活場景中，可能會接觸到多種語言，所以我特別關心這台錄音機是否能夠識別多語言，包括一些主流的語言（如英語、普通話、西班牙語等）...',
        bestAnswer: true,
        answerCount: 4,
        seen: 86,
        upvoteCount: 12,
        created_by: listingObj.user,
        date: '2023-06-13 12:30PM',
        upvotedAnswer: [
          {
            id: 1,
            created_by: {
              name: '林森 Tommy',
              role_name: '探險家',
              imgSrc: '../assets/images/details-member-placeholder-2.jpg'
            },
            upvoteCount: 12,
            bestAnswer: true,
            date: '2023-06-13 12:30PM',
            content: '該AI錄音機支持多國語音識別，包括但不限於中文、英文、西班牙文等'
          },
          {
            id: 2,
            created_by: {
              name: '柯里斯',
              role_name: '思想家',
              imgSrc: '../assets/images/ttc-press-member-watin.png'
            },
            upvoteCount: 2,
            date: '2023-06-13 12:30PM',
            content: '此AI錄音機支持多種語音識別語言，包括中文、英文。'
          }
        ]
      },
      {
        id: 2,
        title: '您認為哪些功能或功能將使我們的產品成為您語音記錄需求的顛覆者？',
        subtitle: '我們的產品 P.NOTE 旨在為您提供高品質的語音記錄體驗。我們想知道您認為哪些功能或功能將使我們的產品成為您語音記錄需求的顛覆者？',
        bestAnswer: false,
        answerCount: 2,
        seen: 86,
        upvoteCount: 12,
        created_by: listingObj.user,
        date: '2023-06-13 12:30PM',
        upvotedAnswer: [
          {
            id: 1,
            created_by: {
              name: '柯里斯',
              role_name: '思想家',
              imgSrc: '../assets/images/ttc-press-member-watin.png'
            },
            upvoteCount: 10,
            date: '2023-06-13 12:30PM',
            content: '我認為 P.NOTE 的 AI 語音識別功能將是其最大的優勢。 這將使用戶能夠更快地將錄音轉換為文字，並且可以幫助用戶更快地獲取信息。'
          }
        ]
      }
    ],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 2,
      has_next_page: 0
    }
  },
  adviseDetails:{
    title: '這台AI錄音機支持哪些語言的語音識別?',
    subtitle: '關於這台AI錄音機的語音識別功能，我想了解它支持哪些具體的語言。由於在不同的工作和生活場景中，可能會接觸到多種語言，所以我特別關心這台錄音機是否能夠識別多語言，包括一些主流的語言（如英語、普通話、西班牙語等）...',
    bestAnswer: true,
    answerCount: 4,
    seen: 86,
    upvoteCount: 12,
    created_by: listingObj.user,
    date: '2023-06-13 12:30PM',
    category: '科技',
    tags: ['音訊技術','設計','民生'],
    items:{
      data : [
        {
          id: 1,
          created_by: {
            name: '林森 Tommy',
            role_name: '探險家',
            imgSrc: '../assets/images/details-member-placeholder-2.jpg'
          },
          upvoteCount: 12,
          bestAnswer: true,
          date: '2023-06-13 12:30PM',
          _replyInput: false,  // use to toggle show for reply input for the comment
          _commentList: false,  // use to toggle show for comments
          content: '該AI錄音機支持多國語音識別，包括但不限於中文、英文、西班牙文等',
          commentNum: 1,
          comments: [
            {
              comment_by: {
                name: 'Jasper Ngai',
                role_name: '探險家',
                imgSrc: '../assets/images/details-member-placeholder-2.jpg',
                date: '2023-06-13 12:30PM'
              },
              comment: "很高興瞭解這一點，並感謝您的幫助。",
              _replyInput: false,  // use to toggle show for reply input for the reply,
              like: 0,
              dislike: 1
            }
          ]
        },
        {
          id: 2,
          created_by: {
            name: '柯里斯',
            role_name: '思想家',
            imgSrc: '../assets/images/ttc-press-member-watin.png'
          },
          upvoteCount: 2,
          date: '2023-06-13 12:30PM',
          _replyInput: false,
          content: '此AI錄音機支持多種語音識別語言，包括中文、英文。'
        },
        {
          id: 3,
          created_by: {
            name: '柯里斯',
            role_name: '思想家',
            imgSrc: '../assets/images/ttc-press-member-watin.png'
          },
          upvoteCount: 2,
          date: '2023-06-13 12:30PM',
          _replyInput: false,
          content: '此AI錄音機支持多種語音識別語言，包括中文、英文。'
        }
      ],
      pagination: {
        current_page: 1,
        last_page: 1,
        per_page: 10,
        total: 2,
        has_next_page: 0
      }
    }
  },
  updateList: {
    data: [
      {
        id: 1,
        created_by: listingObj.user,
        date: '2023-06-13 12:30PM',
        status: '步入正軌',
        likes: 26,
        comments: 3,
        content: {
          html: '<p>我們很高興地宣佈，我們的黑色 P.NOTE 新生產線已在我們的工廠投入使用，這大大加快了我們的交貨時間。 第一批客戶（包括 279 名支持者）已經收到了他們的 P.NOTE。 我們邀請更多已經收到 P.Notes 的支持者嘗試這個令人驚嘆的產品，並在這個社區分享他們的經驗。</p><p>對於所有尚未收到追蹤訊息的支持者，我們懇請您耐心等待。 10月之前支援的包裹我們會在下週出貨，10月和11月下的訂單會在12月初出貨。 我們將按照貢獻ID的順序將它們發送給您。 如支持者需要修改地址，請盡快聯絡客服，避免直接出貨。</p>'
        }
      },
      {
        id: 2,
        created_by: listingObj.user,
        date: '2023-06-13 12:30PM',
        status: '延遲',
        likes: 18,
        comments: 2,
        content: {
          html: '<p>我們深深感謝他的支持和慷慨。 意識到我們先前的説明不夠全面，我們提供了以下詳細步驟，以幫助您更好地監控貨件狀態。</p><p>分步追蹤説明：<br/>1. 尋找您的追蹤電子郵件：在您的收件匣中搜尋“P.NOTE: AI Voice Recorder is on the way!” 並打開電子郵件。 如果您找不到，請放心，我們的倉庫目前正在包裝您的訂單，所有 12 月 30 日之前下的訂單將於下週發貨。</p><p>2. 追蹤您的包裹：如果您的追蹤號碼以YTXXXXXXXXXXX開頭，請造訪ZYX Express的官方網站進行追蹤。 輸入您的追蹤號碼以查看即時更新，包括目的地國家/地區本地配送的「最後一哩追蹤」號碼。</p><p>2650 訂單即將到貨。 另有 2500 個訂單即將發送！温暖的問候。</p>'
        }
      },
      {
        id: 3,
        created_by: listingObj.user,
        date: '2023-06-13 12:30PM',
        status: '嚮前',
        likes: 32,
        comments: 10,
        content: {
          html: '<p>親愛的 P.NOTE 支持者，</p><p>感謝您對我們專案的持續支持以及您在我們努力準備 P.NOTE 實現時的耐心等待。 我們感謝您在過程中的理解和耐心。</p><p>我們的團隊充分意識到客戶不斷增長的期望，並相應地加大了生產力度。</p><p>為了確保生產過程有組織性，我們工廠採用批量生產方式，根據顏色對產品進行分類。 這種方法使我們能夠有效管理 P.NOTE 的製造和運輸。 第一批包括星光P.NOTE，計劃於10月下旬發貨...</p>'
        }
      }
    ],
    pagination: {
      current_page: 1,
      last_page: 1,
      per_page: 10,
      total: 3,
      has_next_page: 0
    }
  },
  updateDetails: {
    id: 3,
    created_by: listingObj.user,
    date: '2023-06-13 12:30PM',
    status: '嚮前',
    content: {
      html: '<p>親愛的 P.NOTE 支持者，</p><p>感謝您對我們專案的持續支持以及您在我們努力準備 P.NOTE 實現時的耐心等待。 我們感謝您在過程中的理解和耐心。</p><p>我們的團隊充分意識到客戶不斷增長的期望，並相應地加大了生產力度。</p><p>為了確保生產過程有組織性，我們工廠採用批量生產方式，根據顏色對產品進行分類。 這種方法使我們能夠有效管理 P.NOTE 的製造和運輸。 第一批包括星光P.NOTE，計劃於10月下旬發貨...</p>'
    }
  },
  dummyData: {
    data: [
      {
        title: '我是標題',
        subtitle: '我是副標題'
      },
      {
        title: '我是標題',
        subtitle: '我是副標題'
      },
      {
        title: '我是標題',
        subtitle: '我是副標題'
      },
      {
        title: '我是標題',
        subtitle: '我是副標題'
      },
      {
        title: '我是標題',
        subtitle: '我是副標題'
      }
    ]
  }

}
