import ApiUtils from '@/utils/api/ApiUtils'
import C3001Const from './C3001Const'

const StoreConst = C3001Const.storeConst
const store = {
  actions: {
    async doBrowse ({ commit }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.doBrowse.api, input: payload }]
      })
      commit('SET_ROWS', res[0].output)
      return res
    },
    async doEdit ({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: StoreConst.ACTION.doEdit.api,
          input: { ...state.row, ...payload }
        }]
      })
    }
  }
}

export default C3001Const.getStore(store)
