import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    rows: null
  },

  getters: {

  },

  mutations: {

  },

  actions: {
    onSearch: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0701TagService.onSearch', input: payload }]
      }).then(data => {
        commit('SET_ROWS', data[0].output.data)
        commit('SET_PAGINATION', data[0].output.pagination)
      })
    },
    searchTag: ({ commit,state }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0701TagService.searchTag', input: payload }]
      }).then(data => {
        state.rows = data[0].output
        // commit('SET_ROWS', data[0].output)
      })
    },
    add: (ctx, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0701TagService.addTag', input: payload }]
      })
    },
    searchDegreeTag: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0701TagService.searchDegreeTag', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    searchFieldTag: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0701TagService.searchFieldTag', input: payload }]
      }).then(data => {
        return data[0].output
      })
    },
    searchSkillTag: ({ commit }, payload) => {
      return ApiUtils.postData({
        data: [{ api: 'M0701TagService.searchSkillTag', input: payload }]
      }).then(data => {
        return data[0].output
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
