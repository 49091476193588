import BaseDataStore from '@/components/base/store/BaseDataStoreV1'

const BaseConst = {
  version: 1,
  storeName: 'C0301ProjectVote',
  serviceName: 'C0301ProjectVoteService',
  componentDef: {
    form: {
      /*
       type: div, row, col, form, text,file,select, button,table
       component:  div,VRow,VForm AtTextField, AtSelect, AtBtn,AtDataTable
       rules :
          string.alpha , string.alpha_dash, string.alpha_num,string.alpha_spaces,string.length
          number.double, number.digits, number.integer, number.numeric,
          regular.email, regular.regex
          range.min , range.max, range.min_value, range.max_value , range.between , range.oneOf, range.excluded
          file.size , file.image , file.dimensions, file.mimes
          logic.confirmed, logic.is_not, logic.required, logic.required_if
      */
      viewDef: [
        {
          column: 'col-12',
          custom: 'VoteTitle'
        },
        {
          column: 'col-12',
          custom: 'FundingGoal',
          data: 'is_fund_success'
        },
        {
          column: 'col-12',
          custom: 'ProjectOutcome',
          data: 'is_project_success'
        },
        {
          column: 'col-12',
          custom: 'ProjectOnTime',
          data: 'is_complete_on_time'
        }
      ],
      optionDef: {}
    }
  }
}
const C0301Const = new BaseDataStore(BaseConst)
export default C0301Const
