import i18n from '@/plugins/i18n'

export default [
  {
    path: 'Reports',
    name: 'ReportCaseList',
    component: () => import('@/app/M1201ReportCase/ReportCaseList/PgReportCaseList.vue'),
    portalGroup: 'admin.admin',
    meta: { title: i18n.t('m1201.ReportCaseList.PgReportCaseList.title'), authenticated: true, permissions: ['reportCase.manage'] }
  },
  {
    path: 'Reports/:m1201_id(\\d+)',
    name: 'ReportCaseDetail',
    component: () => import('@/app/M1201ReportCase/ReportCaseDetail/PgReportCaseDetail.vue'),
    portalGroup: 'admin.admin',
    meta: { title: i18n.t('m1201.ReportCaseDetail.PgReportCaseDetail.header'), authenticated: true, permissions: ['reportCase.viewDetailUser', 'reportCase.viewDetailPress', 'reportCase.viewDetailLearning', 'reportCase.viewDetailCollab', 'reportCase.viewDetailAdvisory'] }
  }
]
