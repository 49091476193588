// Mixin to add debug prop to components
export default {
  props: {
    // use this prop to show debug info, debug button, etc
    debug: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    // use this method to show debug info
    emit (name, arg) {
      if (this.debug) {
        this.$dialog.notify.info(JSON.stringify({
          name,
          arg: JSON.stringify(arg, null, 2)
        }))
      }
      this.$emit(name, arg)
    }
  }
}
