<template>
  <div :class="$attrs.class">
    <label style="display: flex;">
      <h3>{{ label }}</h3>
    </label>
    <p>{{ hint }}</p>
    <AtTextField
      v-for="(textField, i) in vModel"
      :key="i"
      v-model="vModel[i]"
      label=""
      v-bind="$attrs"
    >
      <template #append-outer>
        <v-btn
          v-if="!$attrs.readonly"
          :disabled="$attrs.readonly"
          icon
          color="error"
          @click="vModel.splice(i, 1)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
      </template>
    </AtTextField>
    <v-btn
      v-if="!$attrs.readonly"
      text
      color="primary"
      class="mb-3"
      :disabled="vModel ? vModel.includes('') : true"
      @click="vModel.push('')"
    >
      {{ $t('components.AtTextFieldGroup.add_more') }}
    </v-btn>
  </div>
</template>

<script>
import AtTextField from '../AtTextField/AtTextField.vue'

export default {
  name: 'AtTextFieldGroup',

  components: { AtTextField },

  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: null
    },
    hint: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      vModel: this.value
    }
  },
  watch: {
    value (val) {
      if (JSON.stringify(val) === JSON.stringify(this.vModel)) return
      this.vModel = val
    },
    vModel (val) {
      this.$emit('input', val)
    }
  }
}
</script>
