import ApiUtils from '@/utils/api/ApiUtils'
import C0301Const from './C0301Const'

// TODO: can i know what storeConst properties are available? (using intelisense)
const store = {
  state: {

  },
  getters: {

  },
  mutations: {

  },
  actions: {
    createVote ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [
          { api: 'C0301ProjectVoteService.createVote', input: payload }
        ]
      })
    },
    async checkIfUserVoted ({ commit, state }) {
      const res = await ApiUtils.postData({
        data: [
          { api: 'C0301ProjectVoteService.checkIfUserVoted' }
        ]
      })
      return res[0].output
    }
  }
}

export default C0301Const.getStore(store)
