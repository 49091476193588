<template>
  <div class="comment-container">
    <div class="comment-header">
      <div class="comment-left-wrapper">
        <img
          :src="imageUrl"
          class="comment-user-image"
        >
      </div>
      <div class="comment-center-wrapper">
        <span class="comment-name">{{ name }}</span>
        <span class="comment-createdAt">{{ comment.createdAt |date }}</span>
      </div>
      <div class="comment-right-wrapper">
        <!-- action -->
        <button
          v-if="showActions.includes('resolve') && permission.canResolve && !editMode"
          class="comment-action-button"
          :title="comment.resolved ? 'Reopen' : 'Resolve'"
          @click="$emit('resolveComment')"
        >
          <i
            v-if="!comment.resolved"
            class="v-icon mdi mdi-check"
          />
          <i
            v-else
            class="v-icon mdi mdi-refresh"
          />
        </button>

        <template
          v-if="showActions.includes('edit') &&
            comment.createdBy === currentUserId &&
            !editMode &&
            (!comment.resolved || comment.resolved === undefined)"
        >
          <button
            class="comment-action-button"
            title="Edit"
            @click="editMode = true"
          >
            <i class="v-icon mdi mdi-pencil" />
          </button>
        </template>

        <template
          v-if="showActions.includes('delete') &&
            comment.createdBy === currentUserId &&
            !editMode &&
            (!comment.resolved || comment.resolved === undefined)"
        >
          <button
            class="comment-action-button"
            title="Delete"
            @click="$emit('deleteComment')"
          >
            <i class="v-icon mdi mdi-delete" />
          </button>
        </template>
      </div>
    </div>

    <div
      v-if="editMode"
      style="width: 100%;"
    >
      <input
        v-model="content"
        class="reply-input col"
        @keydown="enterToEdit"
      >
      <button
        class="comment-action-button"
        style="position: absolute; left: auto; right: 3rem; bottom: 0.75rem;"
        title="Confirm change(s)"
        @click="$emit('editComment', content)"
      >
        <i class="v-icon mdi mdi-check" />
      </button>
      <button
        class="comment-action-button"
        style="position: absolute; left: auto; right: 1rem; bottom: 0.75rem;"
        title="Discard change(s)"
        @click="editMode = false"
      >
        <i class="v-icon mdi mdi-close" />
      </button>
    </div>
    <div
      v-else
      class="comment-content"
    >
      {{ comment.content }}
    </div>
  </div>
</template>

<script>
/**
 * name
 * imageUrl
 * time
 * action: edit,delete,resolve
 * 
 */
import moment from 'moment'

export default {
  filters: {
    date (value) {
      return moment(value).fromNow()
    }
  },
  props: {
    comment: {
      type: Object,
      default: () => {}
    },
    showActions: {
      type: Array,
      default: () => ['resolve', 'delete', 'edit']
    }
  },
  data () {
    return {
      content: this.comment.content,
      editMode: false
    }
  },
  computed: {
    name () {
      return this.usersData.find(user => user.id === this.comment.createdBy)?.name ?? 'UnknownUser'
    },
    imageUrl () {
      return this.usersData.find(user => user.id === this.comment.createdBy)?.imageUrl ?? null
    }
  },
  methods: {
    enterToEdit (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
        event.stopPropagation()
        this.$emit('editComment', event.target.value)
      }
    }
  }
}
</script>

<style>
.comment-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
}
.comment-left-wrapper {
  display: flex;
  padding: 0px 5px;
  position: relative;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.comment-center-wrapper {
  display: flex;
  padding: 0px 5px;
  position: relative;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  margin-right: 10px;
}
.comment-right-wrapper {
  display: flex;
  padding: 0px 5px;
  position: relative;
  align-self: stretch;
  align-items: center;
  justify-content: center;
}
.comment-header {
  width: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  justify-content: center;
}

.comment-user-image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
</style>
