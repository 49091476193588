import ObjArrUtils from '@/utils/ObjArrUtils'
import { PortalUtils } from '@/utils/PortalUtils/index.js'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  // `app` prefix is required to avoid name conflicts with other modules'
  // and to make it easier to identify the module in the store.
  state: {
    appLoading: 0,
    appHeaderTitle: null,
    appHeaderColor: null,
    appDocumentTitle: import.meta.env.APP_TITLE,
    appFullScreen: false,
    appBreadcrumbs: [
      // {
      //   text: 'Dashboard', // text to display
      //   to: '/dashboard' // route to navigate to.
      // }
    ],
    // Give string name to display sub header
    appSubHeader: undefined
  },

  getters: {
    getAppLoading (state) {
      return state.appLoading
    },

    getAppHeaderTitle (state) {
      return state.appHeaderTitle
    },

    getAppHeaderColor (state) {
      return state.appHeaderColor
    },

    getAppDocumentTitle (state) {
      return state.appDocumentTitle
    },

    getAppFullScreen (state) {
      return state.appFullScreen
    }
  },

  mutations: {
    ADJUST_APP_LOADING (state, payload) {
      state.appLoading += payload
    },
    SET_APP_HEADER_TITLE (state, payload) {
      state.appHeaderTitle = payload
    },
    SET_APP_HEADER_COLOR (state, payload) {
      state.appHeaderColor = payload
    },
    SET_APP_DOCUMENT_TITLE (state, payload) {
      const portalTitle = PortalUtils.portalTitleMapping[PortalUtils.name]
      if (payload !== portalTitle) payload += ' | ' + portalTitle
      document.title = payload
      state.appDocumentTitle = payload
    },
    SET_APP_FULL_SCREEN (state, payload) {
      state.appFullScreen = payload
    },
    SET_APP_BREADCRUMBS (state, payload) {
      state.appBreadcrumbs = payload
    },
    SET_APP_SUB_HEADER (state, payload) { state.appSubHeader = payload }
  },
  actions: {
  }
}

export default ObjArrUtils.merge(Base, module)
