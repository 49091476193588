<template>
  <v-menu
    open-on-hover
    offset-y
    bottom
  >
    <template #activator="{ on, attrs }">
      <v-btn
        text
        class="pl-1 pr-0"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-translate</v-icon>
        <span class="d-none d-md-block ml-2">{{ availableLocales[selectedLocaleIndex].text }}</span>
        <v-icon>mdi-menu-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item-group
        v-model="selectedLocaleIndex"
        color="primary"
      >
        <v-list-item
          v-for="(locale, i) in availableLocales"
          :key="locale.value"
          @click="selectedLocaleIndex = i"
        >
          <v-list-item-title>{{ locale.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  computed: {
    selectedLocaleIndex: {
      get: function () {
        return this.availableLocales.findIndex((locale) => locale.value === this.currentLocale)
      },
      set: function (index) {
        this.storeApiDispatch('Auth/setUserLocale', { id: this.availableLocales[index].id })
        this.changeLocale(this.availableLocales[index].value)
      }
    }
  }
}
</script>

<style>

</style>
