import BaseDataStore from '@/components/base/store/BaseDataStoreV2'
// import moment from 'moment'
const storeName = 'M4401SubscriptionUser'
const serviceName = 'M4401SubscriptionUserService'

/*
    {
      column: 'col-6', // column size
      data: 'status', // data name
      type: 'select', // component type : text,textarea,number,checkbox,switch,radio,file,date,time,dateRange,select,autoComplete,combobox,rangeSlider,slider,ttc_category,ttc_tag,ttc_price,ttc_policy
      label: 'status', // label
      props: any // component props
    }
*/
const storeDef = {
  ACTION: {
    // base actions : doAdd, doEdit, getEdit, doBrowse, doDelete, getRead
    // custom actions
    // TODO: accept string without storeName/serviceName
    doBrowse: {
      store: storeName + '/doBrowse',
      api: serviceName + '.doBrowseForPress'
    },
    doAdd: {
      store: storeName + '/doAdd',
      api: serviceName + '.doAddForPress'
    }
  },
  PROPERTY: {
    // base properties : row, rows, pagination, searchInfo, orderBy
    // custom properties

  }
}

const tableDef = {
  viewDef: [
    { text: 'ID', value: 'id' },
    { text: 'Key', value: 'subscription_key' },
    { text: 'Code', value: 'subscription_code' },
    { text: 'Value', value: 'created_at' }
  ],
  optionDef: {}
}

const editDef = {

  viewDef: [
    {
      column: 'col-12',
      data: 'setting_key',
      type: 'text',
      rules: 'required',
      label: 'Key'
    },
    {
      column: 'col-12',
      data: 'setting_code',
      type: 'text',
      rules: 'required',
      label: 'Code'
    },
    {
      column: 'col-12',
      data: 'setting_value',
      type: 'text',
      rules: 'required',
      label: 'Date'
    }
  ],
  optionDef: {}
}

const BaseConst = {
  version: 1,
  storeName,
  serviceName,
  storeConst: storeDef,
  componentDef: {
    table: tableDef,
    edit: editDef

  }
}
const FnConst = new BaseDataStore(BaseConst)
export default FnConst
