import ApiUtils from '@/utils/api/ApiUtils'
import S0801Const from './S0801Const'

const store = {
  state: {
    // custom state
    settingValues: {},
    links: null
  },
  getters: {
    // custom getter
    getSettingValues: (state) => state.settingValues
  },
  mutations: {
    // custom mutation
    SET_SETTING_VALUES (state, payload) {
      state.settingValues = payload
    },
    SET_LINKS (state, payload) { state.links = payload },
  },
  actions: {
    async updateSettings ({ commit, state }, payload) {
      return await ApiUtils.postData({
        data: [{ api: S0801Const.serviceName + '.updateSetting', input: payload }]
      })
    },
    async getSettingValuesFrontend ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: S0801Const.serviceName + '.getSettingValuesFrontend', input: payload }]
      })
      commit('SET_SETTING_VALUES', res[0].output)
    },
    async getCategoriesSitemap ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: S0801Const.serviceName + '.getCategoriesSitemap', input: payload }]
      })
      return res[0].output
    },
    async doSave ({commit, state}, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: 'S0801SettingService.doSave', input: payload}]
      })
      return res
    },
    async getLinks ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: S0801Const.serviceName + '.getLinks', input: payload }]
      })
      state.links = res[0].output
    },
  },
}

export default S0801Const.getStore(store)
