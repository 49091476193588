import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'L0101Content',
  serviceName: 'L0101ContentService',
  storeConst: {
    ACTION: {
      getReview: {
        store: 'L0101Content/getReview',
        api: 'L0101ContentService.getReview'
      },
      doReview: {
        store: 'L0101Content/doReview',
        api: 'L0101ContentService.doReview'
      },
      doSubmit: {
        store: 'L0101Content/doSubmit',
        api: 'L0101ContentService.doSubmit'
      },
      doBrowse_Discussion: {
        store: 'L0101Content/doBrowse_Discussion',
        api: 'L0101ContentService.doBrowse_Discussion'
      },
      doBrowse_Rating: {
        store: 'L0101Content/doBrowse_Rating',
        api: 'L0101ContentService.doBrowse_Rating'
      }
    }
  },
  componentDef: {
    form: {
      viewDef: [
        // L0101FormStep1
        [
          {
            column: 'col-12',
            data: 'content_title',
            type: 'text',
            props: {
              counter: true,
              maxlength: 150,
              class: 'required'
            },
            label: i18n.t('l0101.filters.content_title'),
            labelHint: null,
            rules: 'required',
            async: [
              {
                api: 'M3901GuideService.doGet_atFormHint',
                input: 'learn.contentTitle',
                mutateTarget: 'labelHint'
              }
            ]
          },
          {
            column: 'col-12',
            data: 'content_subtitle',
            type: 'textarea',
            label: i18n.t('l0101.filters.content_subtitle'),
            labelHint: 'null',
            rules: 'required',
            async: [
              {
                api: 'M3901GuideService.doGet_atFormHint',
                input: 'learn.contentSubtitle',
                mutateTarget: 'labelHint'
              }
            ],
            props: {
              outlined: true,
              class: 'required'
            }
          },
          {
            column: 'col-12',
            data: 'content_desc',
            custom: 'MoBlockEditor',
            rules: 'required',
            props: {
              data: 'content_desc',
              class: 'required'
            },
            label: i18n.t('l0101.filters.content_desc'),
            labelHint: null,
            async: [
              {
                api: 'M3901GuideService.doGet_atFormHint',
                input: 'learn.contentDesc',
                mutateTarget: 'labelHint'
              }
            ]
          },
          // objective, prerequisite, target audience
          {
            data: 'content_objective',
            column: 'col-md-12',
            component: 'AtTextFieldGroup',
            rules: 'required',
            props: {
              label: i18n.t('l0101.filters.objective'),
              hint: i18n.t('l0101.filters.objectiveHint'),
              placeholder: i18n.t('l0101.filters.objectivePlaceholder'),
              counter: true,
              maxlength: 160,
              outlined: true,
              class: 'required'
            }
          },
          {
            data: 'content_prerequisite',
            column: 'col-md-12',
            component: 'AtTextFieldGroup',
            rules: 'required',
            props: {
              label: i18n.t('l0101.filters.prerequisite'),
              hint: i18n.t('l0101.filters.prerequisiteHint'),
              placeholder: i18n.t('l0101.filters.prerequisitePlaceholder'),
              outlined: true,
              class: 'required'
            }
          },
          {
            data: 'content_target_audience',
            column: 'col-md-12',
            component: 'AtTextFieldGroup',
            rules: 'required',
            props: {
              label: i18n.t('l0101.filters.targetAudience'),
              hint: i18n.t('l0101.filters.targetAudienceHint'),
              placeholder: i18n.t('l0101.filters.targetAudiencePlaceholder'),
              outlined: true,
              class: 'required'
            }
          },

          // language, course level, category
          {
            data: 'm0801_id',
            column: 'col-md-4',
            type: 'select',
            label: 'Course Level',
            rules: 'required',
            labelHint: null,
            props: {
              outlined: true,
              items: [
                {
                  text: i18n.t('l0101.CourseLevel.all'),
                  value: 'All'
                },
                {
                  text: i18n.t('l0101.CourseLevel.beginner'),
                  value: 'Beginner'
                },
                {
                  text: i18n.t('l0101.CourseLevel.intermediate'),
                  value: 'Intermediate'
                },
                {
                  text: i18n.t('l0101.CourseLevel.expert'),
                  value: 'Expert'
                }
              ],
              class: 'required'
            },
            async: [
              {
                api: 'M0801LabelService.doBrowse_input',
                input: {
                  label_type: 'CONTENT_LEVEL'
                },
                mutateTarget: 'props.items'
              }
            ]
          },
          {
            data: 'o0201_id',
            column: 'col-md-4',
            type: 'select',
            label: 'Langauge',
            rules: 'required',
            props: {
              outlined: true,
              items: [
                { text: 'English', value: 'English' },
                { text: 'Japanese', value: 'Japanese' }
              ],
              class: 'required'
            },
            async: [
              {
                api: 'O0201LocalesService.getLangauges',
                mutateTarget: 'props.items'
              }
            ]
          },

          {
            column: 'col-4',
            data: 'm0601_id',
            type: 'ttc_category',
            label: i18n.t('l0101.filters.m0601_id'),
            labelHint: null
          },
          {
            column: 'col-12',
            data: 'content_tag',
            type: 'ttc_tag',
            label: i18n.t('l0102.filters.content_tag'),
            labelHint: null,
            async: [
              {
                api: 'M3901GuideService.doGet_atFormHint',
                input: 'learn.contentTag',
                mutateTarget: 'labelHint'
              }
            ]
          },
          {
            column: 'col-6',
            data: 'content_cover',
            type: 'file',
            props: {
              accept: 'image/*',
              maxFiles: 1,
              class: 'required'
            },
            rules: 'required',
            label: i18n.t('l0101.filters.content_cover'),
            labelHint: null,
            async: [
              {
                api: 'M3901GuideService.doGet_atFormHint',
                input: 'learn.contentCover',
                mutateTarget: 'labelHint'
              }
            ]
          },
          {
            column: 'col-6',
            data: 'content_cover_video',
            type: 'file',
            props: {
              accept: 'video/*',
              maxFiles: 1,
              class: 'required'
            },
            rules: 'required',
            label: i18n.t('l0101.filters.content_video'),
            labelHint: null,
            async: [
              {
                api: 'M3901GuideService.doGet_atFormHint',
                input: 'learn.contentCover',
                mutateTarget: 'labelHint'
              }
            ]
          }
        ],
        // L0101FormStep2
        [
        ],
        // L0101FormStep3
        [
          {
            data: 'content_class_name',
            column: 'col-12',
            component: 'AtTextField',
            labelHint: 'asad',
            rules: 'required',
            props: {
              label: i18n.t('l0101.filters.className'),
              dense: true,
              rules: 'required'
            }
          }, {
            data: 'content_class_description',
            column: 'col-12',
            custom: 'MoBlockEditor',
            props: {
              label: i18n.t('l0101.filters.classDescription'),
              style: 'border: solid gray 1px;',
              overrideBaseToolsConfig: { minHeight: 90 },
              rules: 'required'
            }
          },
          {
            data: {
              set (val, input) {
                val.content_class_enrollment_start = input.start
                val.content_class_enrollment_end = input.end
                return val
              },
              get (val) {
                return {
                  start: val.content_class_enrollment_start,
                  end: val.content_class_enrollment_end
                }
              }
            },
            column: 'col-12 ml-1 mt-5',
            component: 'AtDatetimeRangePicker',
            props: {
              backgroundColor: '#bebebe"',
              label: i18n.t('l0101.filters.enrollment')
            },
            labelHint: 'Enrollment Date'
          },
          {
            data: {
              set (val, input) {
                val.content_class_access_start = input.start
                val.content_class_access_end = input.end
                return val
              },
              get (val) {
                return {
                  start: val.content_class_access_start,
                  end: val.content_class_access_end
                }
              }
            },
            column: 'col-12 ml-1 mt-3',
            component: 'AtDatetimeRangePicker',
            props: {
              backgroundColor: '#bebebe"',
              label: i18n.t('l0101.filters.AccessDate')
            },
            labelHint: 'Access Date'
          },
          {
            data: {
              set (val, input) {
                val.content_class_start = input.start
                val.content_class_end = input.end
                return val
              },
              get (val) {
                return {
                  start: val.content_class_start,
                  end: val.content_class_end
                }
              }
            },
            column: 'col-12 ml-1 mt-2',
            component: 'AtDatetimeRangePicker',
            props: {
              backgroundColor: '#bebebe"',
              label: i18n.t('l0101.filters.ClassStartEnd')
            },
            labelHint: 'Class Start and End'
          },
          {
            data: 'meeting_venue_text',
            column: 'col-12',
            component: 'AtTextField',
            labelHint: 'asad',
            rules: 'required',
            props: {
              label: i18n.t('l0101.meeting.meeting_venue_text'),
              dense: true,
              rules: 'required'
            }
          },
          {
            column: 'col-12',
            data: 'meeting_venue_link',
            type: 'textarea',
            label: i18n.t('l0101.meeting.meeting_venue_link'),
            labelHint: 'null',
            rules: 'required',
            class: 'required',
            props: {
              outlined: true,

              label: i18n.t('l0101.meeting.meeting_venue_link'),
              dense: true,
              rules: 'required'
            }
          },
          {
            data: 'content_class_student_max',
            column: 'col-md-4',
            component: 'CheckboxTextField',
            props: {
              label: i18n.t('l0101.filters.classStudentMax'),
              checkboxLabel: i18n.t('l0101.filters.noClassStudentMax'),
              textFieldLabel: ''

            }
          },
          // {
          //   data: 'MeetingCompForL0301',
          //   column: 'col-12',
          //   component: 'MeetingCompForL0301',
          //   props: {
          //     label: i18n.t('l0101.filters.Meeting'),
          //     textFieldLabel: '',
          //     backgroundColor: '#bebebe"'

          //   }
          // },
          {
            data: 'ProgramContent',
            column: 'col-md-12',
            custom: 'ProgramContent',
            props: {
              label: i18n.t('l0101.filters.session')

            }
          }
        ],
        // L0101FormStep4
        [
          {
            column: 'col-12',
            data: 'content_section_content_media',
            type: 'file',
            props: {
              accept: 'video/*',
              maxFiles: 1
            },
            label: i18n.t('l0101.filters.content_section_content_media'),
            labelHint: null
          }
        ],
        // L0101FormStep5
        [
          {
            column: 'col-12',
            data: 'content_section_content_resource_document',
            type: 'file',
            props: {
              accept: 'video/*',
              maxFiles: 1
            },
            label: i18n.t('l0101.filters.content_section_content_resource_document'),
            labelHint: null
          }
        ],
        // L0101FormStep6
        [],
        // L0101FormStep7
        []
      ]
    }
  }
}

const L0101Const = new BaseDataStore(BaseConst)
export default L0101Const
