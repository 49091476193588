<template>
  <v-container>
    <slot name="title" />
    <v-row>
      <slot name="header">
        <v-spacer />
        <v-btn-toggle v-model="dataValue.layout">
          <v-btn
            value="grid"
            @click="dataValue.layout = 'grid'"
          >
            <v-icon>mdi-view-grid</v-icon>
          </v-btn>
          <v-btn
            value="list"
            @click="dataValue.layout = 'list'"
          >
            <v-icon>mdi-view-list</v-icon>
          </v-btn>
        </v-btn-toggle>
      </slot>
    </v-row>

    <v-row>
      <template v-for="(item, index) of rows">
        <slot
          v-if="dataValue.layout==='list'"
          name="list"
          :data="item"
          :index="index"
        >
          <div
            :key="index"
            class="col-md-12"
          >
            <v-card>
              <v-card-title>this is list slot</v-card-title>
              <v-card-text>{{ item }}</v-card-text>
              <v-card-actions>
                <v-spacer /><V-btn>Testing</V-btn>
              </v-card-actions>
            </v-card>
          </div>
        </slot>
        <slot
          v-if="dataValue.layout==='grid'"
          name="grid"
          :data="item"
          :index="index"
        >
          <div
            :key="index"
            class="col-md-4"
          >
            <v-card>
              <v-card-title>this is Grid slot</v-card-title>
              <v-card-text>{{ item }}</v-card-text>
              <v-card-actions>
                <v-spacer /><V-btn>Testing</V-btn>
              </v-card-actions>
            </v-card>
          </div>
        </slot>
      </template>
      <!-- <div v-if="empty">
        <slot name="empty">
          NoData
        </slot>
      </div>
      <div v-if="loading">
        <slot name="loading">
          Loading
        </slot>
      </div> -->
    </v-row>
    <v-row>
      <slot name="footer">
        <v-spacer />
        <v-pagination
          v-model="dataValue.pagination.current_page"
          class="col-md-4"
          :length="dataValue.pagination.last_page"
        />
        <v-spacer />
      </slot>
    </v-row>
  </v-container>
</template>

<script>
import DataUtils from '@/utils/DataUtils'
export default {
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          searchInfo: {},
          orderBy: {},
          layout: 'grid',
          pagination: {
            current_page: 1,
            last_page: 1,
            per_page: 10,
            total: null
          }
        }
      }
    },
    /**
     * The rows of data for the DataTable
     * @type {Array}
     */
    rows: {
      type: Array,
      default: () => { return [] }
    },
    /**
     * The definition for the search field
     * @type {Array}
     */
    // searchDef: {
    //   type: Array,
    //   required: true
    // },
    /**
     * The definition for the form
     * @type {Array}
     */
    // formDef: {
    //   type: Array,
    //   default: () => { return null }
    // },
    /**
     * The definition for the DataTable headers
     * @type {Array}
     */
    // headerDef: {
    //   type: Array,
    //   required: true
    // },
    /**
     * Whether the DataTable is loading data
     * @type {Boolean}
     */
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dataValue: {
        // searchInfo: {},
        // orderBy: {},
        layout: 'grid',
        pagination: {
          current_page: 1,
          last_page: 1,
          per_page: 10,
          total: null
        }
      }
    }
  },
  watch: {
    dataValue: {
      handler (val, oldVal) {
        // if (this.isSameObject(val, oldVal)) return
        this.$emit('input', val)
        this.$emit('onChange', val)
      },
      deep: true
    }
  },
  mounted () {
    this.dataValue = DataUtils.mergeObjects(this.dataValue, this.value)
  }
}
</script>

<style>

</style>
