import $ from 'jquery'
import dot from 'dot-object'
const ObjArrUtils = {
  merge (...args) {
    let final = {}
    args.forEach((arg) => {
      final = $.extend(true, final, arg)
    })

    return final
  },
  clone (source) {
    let target

    if (source instanceof Array) {
      target = []
      $.extend(true, target, source)
    } else if (source instanceof Object) {
      target = {}
      $.extend(true, target, source)
    } else {
      target = source
    }

    return target
  },
  keyExists (obj, key) {
    if (obj instanceof Object) {
      return (key in obj)
    } else {
      return false
    }
  },
  isEmpty: function (value) {
    if (value === undefined || value === null || value === '') {
      return (true)
    }
    if (value instanceof Array) {
      return (value.length === 0)
    }
    if (value instanceof Object) {
      return (Object.keys(value).length === 0)
    }
  },
  objectEquals (a, b) {
    if (a === b) {
      return true
    }
    return JSON.stringify(a) === JSON.stringify(b)
  },
  flattenObject (obj) {
    const dotOb = dot.dot(obj)
    const keyArr = Object.keys(dotOb)
    keyArr.forEach(key => {
      const newKey = key.split('.')[key.split('.').length - 1]
      dotOb[newKey] = dotOb[key]
      if (newKey !== key) { delete dotOb[key] }
    })
    return dotOb
  },
  shuffleArray (arr, randomness = 0.5) {
    if (arr instanceof Array) {
      arr.sort(() => Math.random() > randomness)
    }
    return arr
  },
  arrayAverage (arr) {
    if (arr instanceof Array) {
      return arr.reduce((a, b) => a + b) / arr.length
    }
    return arr
  },
  isJsonString (value) {
    if (typeof value !== 'string') {
      return false
    }
    try {
      const json = JSON.parse(value)
      return (typeof json === 'object')
    } catch (error) {
      return false
    }
  }
}
export default ObjArrUtils
