export default [
  {
    portalGroup: 'all.public',
    path: '/notifications',
    name: 'UserNotification',
    component: () => import('./PgUserNotification.vue'),
    meta: { title: '通知' }
  },
  {
    path: '/notifications/redirect',
    name: 'UserNotificationRedirect',
    component: () => import('./comp/UserNotificationRedirect.vue'),
  }
]
