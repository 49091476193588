// @ts-check

/** @module ColorUtils */

import ntc from '@/utils/base/nameThatColor'
import colorTools from '@/utils/base/colorTools'

import i18n from '@/plugins/i18n'

let _hexaValue = null
let _colorName = null

/**
 * Utilities to perform color format conversion and related operations
 * @method {Function} hex Get / Set color value in HEX format [#rrggbb]
 * @method hexa(hexa=null) Get / Set color value in HEXA format [#rrggbbaa]
 * @method rgb(rgb=null) Get / Set color value in RGB format [r, b, g]
 * @method rgba(rgba=null) Get / Set color value in RGBA format [r, g, b, a]
 * @method hsl(hsl=null) Get / Set color value in HSL format [h, s, l]
 * @method hsla(hsla=null) Get / Set color value in HSLA format [h, s, l, a]
 * @method name() Get color name of color
 * @method contrast() Get contrast for the color in black or white
 * @method contrastColor() Get contrast for the color
 * @method checkColorFormat(input,type,throwError=true) Check if passed in color value is valid with specified type / format
 * 
 * @author Low Yee Huat <yeehuat.low@orbitmy.com>
 * @since 2022/07/20
 */
const ColorUtils = {
  /**
   * Get / Set color value in HEX format [#rrggbb]
   * @param {String | null} hex HEX value color format
   * - Default: `null`, will act as a getter and gets the HEX value
   * - When supplied with `String` will act as a setter and sets the HEX value
   * @return {String | ColorUtils} 
   * - Getter: Returns `String`, color in HEX value
   * - Setter: Returns `ColorUtils` instance object
   */
  hex (hex = null) {
    // If input is not set, act as a getter
    if (hex === null) return _hexaValue.substring(0, 7)

    colorTools.checkFormat(hex, 'hex')
    this.hexa(hex + 'ff')

    return this
  },
  /**
   * Get / Set color value in HEXA format [#rrggbbaa]
   * @param {String | null} hexa HEXA value color format
   * - Default: `null`, will act as a getter and gets the HEXA value
   * - When supplied with `String` will act as a setter and sets the HEXA value
   * @return {String | ColorUtils} 
   * - Getter: Returns `String`, color in HEXA value
   * - Setter: Returns `ColorUtils` instance object
   */
  hexa (hexa = null) {
    // If input is not set, act as a getter
    if (hexa === null) return _hexaValue

    colorTools.checkFormat(hexa, 'hexa')
    _hexaValue = hexa
    _colorName = ntc.name(hexa)[1].toString().toLowerCase()

    return this
  },
  /**
   * Get / Set color value in RGB format [r, g, b]
   * @param {Array<Number> | null} rgb RGB value color format
   * - Default: `null`, will act as a getter and gets the RGB value
   * - When supplied with `Array<Number>` will act as a setter and sets the RGB value
   * - [r, g, b]: All number values are between `0 - 255`
   * @return {Array<Number> | ColorUtils}
   * - Getter: Returns `Array<Number>`, color in RGB value
   * - Setter: Returns `ColorUtils` instance object
   */
  rgb (rgb = null) {
    // If input is not set, act as a getter
    if (rgb === null) return colorTools.hexToRgb(this.hex())

    colorTools.checkFormat(rgb, 'rgb')
    const hex = colorTools.rgbToHex(rgb)
    this.hexa(hex + 'ff')

    return this
  },
  /**
   * Get / Set color value in RGBA format [r, g, b, a]
   * @param {Array<Number> | null} rgba RGBA value color format
   * - Default: `null`, will act as a getter and gets the RGBA value
   * - When supplied with `Array<Number>` will act as a setter and sets the RGBA value
   * - [r, g, b]: All number values are between `0 - 255`
   * - [a]: Number value between `0 - 1`
   * @return {Array<Number> | ColorUtils}
   * - Getter: Returns `Array<Number>`, color in RGBA value
   * - Setter: Returns `ColorUtils` instance object
   */
  rgba (rgba = null) {
    // If input is not set, act as a getter
    if (rgba === null) return colorTools.hexToRgb(this.hexa())

    colorTools.checkFormat(rgba, 'rgba')
    const hexa = colorTools.rgbToHex(rgba)
    this.hexa(hexa)

    return this
  },
  /**
   * Get / Set color value in HSL format [h, s, l]
   * @param {Array<Number> | null} hsl HSL value color format
   * - Default: `null`, will act as a getter and gets the HSL value
   * - When supplied with `Array<Number>` will act as a setter and sets the HSL value
   * - [h]: Number value between `0 - 360`
   * - [s]: Number value between `0 - 100`
   * - [l]: Number value between `0 - 100`
   * @return {Array<Number> | ColorUtils}
   * - Getter: Returns `Array<Number>`, color in HSL value
   * - Setter: Returns `ColorUtils` instance object
   */
  hsl (hsl = null) {
    // If input is not set, act as a getter
    if (hsl === null) return colorTools.hexToHsl(this.hex())

    colorTools.checkFormat(hsl, 'hsl')
    const hex = colorTools.hslToHex(hsl)
    this.hexa(hex + 'ff')

    return this
  },
  /**
   * Get / Set color value in HSLA format [h, s, l, a]
   * @param {Array<Number> | null} hsla HSLA value color format
   * - Default: `null`, will act as a getter and gets the HSLA value
   * - When supplied with `Array<Number>` will act as a setter and sets the HSLA value
   * - [h]: Number value between `0 - 360`
   * - [s]: Number value between `0 - 100`
   * - [l]: Number value between `0 - 100`
   * - [a]: Number value between `0 - 1`
   * @return {Array<Number> | ColorUtils}
   * - Getter: Returns `Array<Number>`, color in HSLA value
   * - Setter: Returns `ColorUtils` instance object
   */
  hsla (hsla = null) {
    /// If input is not set, act as a getter
    if (hsla === null) return colorTools.hexToHsl(this.hexa())

    colorTools.checkFormat(hsla, 'hsla')
    const hexa = colorTools.hslToHex(hsla)
    this.hexa(hexa)

    return this
  },
  /**
   * Get color name of color
   * @return {String} Name of the color
   */
  name () {
    return _colorName
  },
  /**
   * Get contrast for the color in black or white
   * @return {typeof ColorUtils} Object Instance
   */
  contrast () {
    // If color is not initialized, throw error
    if (this.hexa() === null) throw new Error(i18n.t('utils.ColorUtils.errors.notInitialized').toString())

    // hex to rgb
    const rgb = this.rgb()
    const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                        (parseInt(rgb[1]) * 587) +
                        (parseInt(rgb[2]) * 114)) / 1000)

    // If brightness is greater than 125, return black, else return white
    this.hex(((brightness > 125) ? '#000000' : '#ffffff'))
    return this
  },
  /**
   * Get contrast for the color
   * @return {typeof ColorUtils} Object Instance
   */
  contrastColor () {
    if (this.hexa() === null) throw new Error(i18n.t('utils.ColorUtils.errors.notInitialized').toString())

    // hex to hsl
    const hsl = this.hsl()
    hsl[0] = (hsl[0] / 360 + 0.5) % 1 // Hue
    hsl[1] = (hsl[1] / 100 + 0.5) % 1 // Saturation
    hsl[2] = (hsl[2] / 100 + 0.5) % 1 // Luminocity

    const outputHsl = [(hsl[0] * 360), (hsl[1] * 100), (hsl[2] * 100)]
    this.hsl(outputHsl)

    return this
  },
  /**
   * Check if passed in color value is valid with specified type / format
   * @see checkFormat [@/utils/base/colorTools]
   */
  checkColorFormat: colorTools.checkFormat
}
export default ColorUtils
