<!-- <template>
  <v-btn
    class="px-2"
    :height="dataHeight"
    :width="dataWidth"
    dark
    color="#0077B5"
    @click="loginWithLinkedIn"
  >
    <div class="full-width d-flex justify-md-space-between">
      <img
        class="v-icon"
        :style="iconStyle"
        :src="require('@/assets/icon/socialmedia/linkedin/linkedin-tile.svg')"
      >
      <div class="flex-grow-1 d-flex justify-center align-center">
        <span class="font-weight-bold text-subtitle-1">
          {{ $t('components.AtLinkedInLoginAtom.text') }}
        </span>
      </div>
    </div>
  </v-btn>
</template> -->

<template>
  <button
    class="membership-login-method"
    @click.prevent="loginWithLinkedIn"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="22"
      height="22"
      x="0"
      y="0"
      viewBox="0 0 382 382"
      style="enable-background:new 0 0 512 512; margin-right: 10px;"
      xml:space="preserve"
      class=""
    ><g><path
      d="M347.445 0H34.555C15.471 0 0 15.471 0 34.555v312.889C0 366.529 15.471 382 34.555 382h312.889C366.529 382 382 366.529 382 347.444V34.555C382 15.471 366.529 0 347.445 0zM118.207 329.844c0 5.554-4.502 10.056-10.056 10.056H65.345c-5.554 0-10.056-4.502-10.056-10.056V150.403c0-5.554 4.502-10.056 10.056-10.056h42.806c5.554 0 10.056 4.502 10.056 10.056v179.441zM86.748 123.432c-22.459 0-40.666-18.207-40.666-40.666S64.289 42.1 86.748 42.1s40.666 18.207 40.666 40.666-18.206 40.666-40.666 40.666zM341.91 330.654a9.247 9.247 0 0 1-9.246 9.246H286.73a9.247 9.247 0 0 1-9.246-9.246v-84.168c0-12.556 3.683-55.021-32.813-55.021-28.309 0-34.051 29.066-35.204 42.11v97.079a9.246 9.246 0 0 1-9.246 9.246h-44.426a9.247 9.247 0 0 1-9.246-9.246V149.593a9.247 9.247 0 0 1 9.246-9.246h44.426a9.247 9.247 0 0 1 9.246 9.246v15.655c10.497-15.753 26.097-27.912 59.312-27.912 73.552 0 73.131 68.716 73.131 106.472v86.846z"
      style=""
      fill="#0077b7"
      data-original="#0077b7"
      class=""
    /></g></svg>
    使用<span class="en fw-600">Linkedin</span>登入
  </button>
</template>

<script>
export default {
  name: 'AtLinkedInLoginAtom',
  props: {
    redirectUri: {
      type: String,
      default: window.location.origin + '/Login/LINKEDIN'
    },
    dataHeight: {
      type: String,
      default: '40'
    },
    dataWidth: {
      type: String,
      default: '300'
    },
    iconStyle: {
      type: Object,
      default: () => ({
        height: '34px',
        width: '34px'
      })
    }
  },
  methods: {
    loginWithLinkedIn () {
      const api = import.meta.env.VITE_APP_LINKEDIN_API_URL
      const url = new URL(this.redirectUri)
      if (this.$route.query.next) {
        url.set('next', this.$route.query.next)
      }
      const query = new URLSearchParams({
        response_type: 'code',
        client_id: import.meta.env.VITE_APP_LINKEDIN_CLIENT_ID,
        redirect_uri: url.toString(),
        scope: 'openid profile email'
      })
      // localStorage.setItem('redirect', window.location.pathname + window.location.search)
      window.location = api + '?' + query
    }
  }
}
</script>
