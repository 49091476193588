<template>
  <v-row>
    <v-col
      v-if="mode !== 'hidden'"
      cols="12"
      class="pb-0"
    >
      <!-- Author Review Infomation -->
      <ReviewNotification
        v-if="mode === 'notification'"
        :value="value"
      />
    </v-col>
    <v-col cols="12">
      <slot />
    </v-col>
    <v-col
      v-if="mode !== 'hidden'"
      cols="12"
      class="footer"
    >
      <!-- Admin Review Action Panel -->
      <AdminReview
        v-if="mode === 'review'"
        :value="value"
        :hide-amendment="$attrs['hide-amendment']"
        v-on="$listeners"
      />
    </v-col>
  </v-row>
</template>

<script>
import ReviewNotification from '@/app/M2301Approval/reviewNotification.vue'
import AdminReview from '@/app/M2301Approval/adminReview.vue'
export default {
  components: {
    AdminReview,
    ReviewNotification
  },
  props: {
    mode: {
      type: String,
      default: 'review',
      validator (value) {
        return ['notification', 'review', 'hidden'].indexOf(value) !== -1
      }
    },
    /**
     * @param {Object} value
     * @param {String} value.rel_model
     * @param {String} value.rel_id
     * @param {String} value.key
     */
    value: {
      type: Object,
      default: null
    }
  },
  mounted () {
    if (this.value === null || this.value.rel_model === undefined || this.value.rel_id === undefined || this.value.key === undefined) {
      throw new Error('TTCApprovalWrapper: value.rel_model, value.rel_id, value.key are required')
    }
  }
}
</script>

<style scoped>
.footer {
  bottom: 0;
  position: sticky;
  height: auto;
  z-index: 2;
}
</style>
