// eslint-disable-next-line no-unused-vars
import ApiUtils from '@/utils/api/ApiUtils'
import Const from './Const'

// eslint-disable-next-line no-unused-vars
const StoreConst = Const.storeConst

const store = {
  state: {
    teamMemberList: []
  },
  getters: {

  },
  mutations: {

  },
  actions: {
    async browseInvitation({ state }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'C0201ProjectMemberService.browseInvitation',
          input: {
            'c0101_id': payload.c0101_id
          }
        }]
      })

      state.teamMemberList = data[0].output
    }
  }
}

export default Const.getStore(store)