import i18n from '@/plugins/i18n'

export default [
  // TODO: [Adrian] confirm whether this is a TVP only route
  {
    portalGroup: 'all.public',
    path: 'my/content/refund/salesOrderId/:m2901_id(\\d+)/:rel_model(\\w+)/:rel_id(\\d+)',
    name: 'RefundContentDetails',
    component: () => import('@/app/M3003Refund/PgRefund.vue'),
    meta: { title: i18n.t('m3003.refundPage.label'), authenticated: true }
  },
  {
    portalGroup: 'all.public',
    path: 'my/userRefundManagement',
    name: 'UserRefundManagement',
    component: () => import('@/app/M3003Refund/PgUserRefundManagement.vue'),
    meta: { title: i18n.t('m3003.userRefundManagementPage.label'), authenticated: true }
  },
  {
    portalGroup: 'all.public',
    path: 'my/refundDetails/salesOrderId/:m2901_id(\\d+)/:rel_model(\\w+)/:rel_id(\\d+)/refundUserId/:created_by',
    name: 'RefundDetails',
    component: () => import('@/app/M3003Refund/PgRefundDetails.vue'),
    meta: { title: i18n.t('m3003.refundDetailsPage.label'), authenticated: true }
  },
  {
    portalGroup: 'all.public',
    path: 'my/vendorRefundManagement',
    name: 'VendorRefundManagement',
    component: () => import('@/app/M3003Refund/PgVendorRefundManagement.vue'),
    meta: { title: i18n.t('m3003.vendorRefundManagementPage.label'), authenticated: true }
  },
  {
    portalGroup: 'all.public',
    path: 'my/refund/approvalRelModel/:approval_rel_model(\\w+)/approvalRelId/:approval_rel_id(\\d+)/key/:key(\\w.+)/salesOrderId/:m2901_id(\\d+)/:rel_model(\\w+)/:rel_id(\\d+)/refundUserId/:created_by',
    name: 'VendorReviewRefund',
    component: () => import('@/app/M3003Refund/PgRefundReview.vue'),
    meta: { title: i18n.t('m3003.refundReviewPage.label.vendor'), authenticated: true, permissions: ['approval.review'] }
  },
  {
    // TODO: dirty fix
    portalGroup: 'admin.admin',
    path: 'RefundManagement',
    name: 'AdminRefundManagement',
    component: () => import('@/app/M3003Refund/PgVendorRefundManagement.vue'),
    meta: { title: '退款管理頁面' }
  },
  {
    portalGroup: 'admin.admin',
    path: 'refund/:id(\\d+)/admin/review',
    name: 'AdminReviewRefund',
    component: () => import('@/app/M3003Refund/PgRefundReview.vue'),
    meta: { title: i18n.t('m3003.refundReviewPage.label.admin'), authenticated: true, permissions: ['approval.review'] }
  }
]
