<template>
  <div>
    <span>{{ text | showMoreFilter(showMoreBtn(text), showMore) }}</span>
    <button
      v-if="showMoreBtn(text)"
      type="button"
      class="show-more-text-button"
      @click="showMore = !showMore"
    >
      <v-icon>
        {{ showMore ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
      </v-icon>
    </button>
  </div>
</template>
<script>
export default {
  filters: {
    showMoreFilter (value, enable, trigger) {
      if (!value) return value
      if (!enable) return value
      if (trigger) return value

      const line1 = value.split('\n').length > 3
      let text = value.slice(0, 150)
      if (line1) {
        text = text.split('\n').slice(0, 3).join('\n')
      }
      return text + '...'
    }
  },
  props: {
    text: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      currentText: '',
      showMore: false
    }
  },
  watch: {
    text () {
      this.currentText = this.text
    }
  },
  methods: {
    showMoreBtn (text) {
      if (!text) return false
      const line1 = text.split('\n').length > 3
      const length1 = text.length > 150

      return line1 || length1
    }
  }
}
</script>
