// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import ApiUtils from '@/utils/api/ApiUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'
// import demo from './P0101DemoJson'

const module = {
  state: {
    // article: demo.article,
    // donateList: demo.donateList,
    // author: demo.author,
    article: null,
    author: null,
    doBrowse_recommend: [],
    doBrowse_recommend_author: [],
    doBrowse_recommend_sameCategory: []
  },
  getters: {},
  mutations: {},
  actions: {
    async init ({ commit, state, dispatch }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'P0101ArticleService.doRead_article',
            input: { seo_url: payload.seo_url }
          },
          {
            api: 'P0101ArticleService.doRead_author',
            input: { seo_url: payload.seo_url }
          }
        ]
      })
      state.article = res[0].output.article
      state.doBrowse_recommend = res[0].output.doBrowse_recommend
      state.doBrowse_recommend_author = res[0].output.doBrowse_recommend_author
      state.doBrowse_recommend_sameCategory = res[0].output.doBrowse_recommend_sameCategory

      state.author = res[1].output
    },
    async fullContent ({ state }, payload) {
      // state.article = demo.fullArticle
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'P0101ArticleService.doRead_articlefull',
            input: { seo_url: payload.seo_url }
          }
        ]
      })
      state.article = res[0].output
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
