import ApiUtils from '@/utils/api/ApiUtils'
import Const from './Const'

// eslint-disable-next-line no-unused-vars
const StoreConst = Const.storeConst
const store = {
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async userBack_doBrowse ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [{ api: 'C1301ProjectCrowdFundPledgeService.userBack_doBrowse', input: val }]
      })
      commit('SET_ROWS', res[0].output.data)
      commit('SET_PAGINATION', res[0].output.pagination)
    },
    // custom action
    async userBack_getShippingInfo ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'C1301ProjectCrowdFundPledgeService.userBack_getShippingInfo',
            input: payload
          }]
      })
      return res[0].output
    },
    async userBack_goToChatroom ({ commit, state }, payload) {
      return ApiUtils.postData({
        data: [{ api: 'C1301ProjectCrowdFundPledgeService.userBack_goToChatroom', input: payload }]
      }).then((res) => {
        return res[0].output
      })
    }
  }
}

export default Const.getStore(store)
