// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// import i18n from '@/plugins/i18n'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    data: null,
    list: null,
    row: null
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    init: ({ state }) => {
      return ApiUtils.postData({
        data: [{
          api: 'P0101ArticleService.doBrowse_home'
        }]
      }).then(data => {
        state.data = data[0].output
      })
    },
    getArticleList: ({ state }) => {
      return ApiUtils.postData({
        data: [{
          api: 'P0101ArticleService.getArticleList'
        }]
      }).then(data => {
        state.list = data[0].output.data
      })
    },
    async getEdit({ commit, state, dispatch }, payload) {
      state.row = {}

      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.getEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output[0]

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    },
    async doEdit({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'S0801SettingService.doEdit_master',
            input: payload
          }
        ]
      })
      state.row = res[0].output

      return new Promise((resolve, reject) => {
        resolve(state.row)
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
