import ApiUtils from '@/utils/api/ApiUtils'
import C1001Const from './C1001Const'

const StoreConst = C1001Const.storeConst
const store = {
  getters: {
    getRow (state, getters, rootState, rootGetters) {
      const C0101row = rootGetters['C0101Project/getRow']
      if (!C0101row) return null

      return {
        ...state.row,
        project_funding_target: {
          coins: C0101row.project_main_funding_target,
          tokens: C0101row.project_alternative_funding_target_max
        }
      }
    }
  },

  mutations: {
    SET_ROW (state, payload) {
      if (payload?.project_funding_target) {
        this.commit('C0101Project/SET_ROW_MERGE', {
          m1901_main_id: 1,
          project_main_funding_target: payload.project_funding_target.coins,
          m1901_alternative_id: 2,
          project_alternative_funding_target_max: payload.project_funding_target.tokens
        })
        delete payload.project_funding_target
      } else {
        state.row = payload
      }
    }
  },

  actions: {
    async doEdit ({ state }, payload) {
      return await ApiUtils.postData({
        data: [{
          api: StoreConst.ACTION.doEdit.api,
          input: { ...state.row, ...payload }
        }]
      })
    }
  }
}

export default C1001Const.getStore(store)
