import BaseDataStore from '@/components/base/store/BaseDataStoreV1'
import i18n from '@/plugins/i18n'

const BaseConst = {
  version: 1,
  storeName: 'QuizScorePage',
  serviceName: 'L0301ContentClassService',
  componentDef: {
    form: {
      l0301Filter: [
        {
          column: 'col-6',
          data: 'content_class_name',
          label: i18n.t('l0301.scorePage.className'),
          type: 'text',
          topLabel: true
        },
        {
          column: 'col-6',
          data: 'content_class_status',
          label: i18n.t('l0301.scorePage.classStatus.title'),
          type: 'select',
          topLabel: true,
          props: {
            items: [
              { id: 'ACTIVE', name: 'Active' },
              { id: 'HIDDEN', name: 'Hidden' },
              { id: 'DELETED', name: 'Deleted' },
              { id: 'REMOVED', name: 'Removed' }
            ],
            itemText: 'name',
            itemValue: 'id'
          }
        }
      ],
      l0601Filter: [
        {
          column: 'col-4',
          data: 'content_section_content_name',
          label: i18n.t('l0601.scorePage.quizName'),
          type: 'text',
          topLabel: true
        },
        {
          column: 'col-4',
          data: 'content_section_content_type',
          label: i18n.t('l0601.scorePage.quizType.title'),
          type: 'select',
          topLabel: true,
          props: {
            items: [
              { id: 'QUIZ_ONLINE', name: i18n.t('l0601.scorePage.quizType.online') },
              { id: 'QUIZ_OFFLINE', name: i18n.t('l0601.scorePage.quizType.offline') }
            ],
            itemText: 'name',
            itemValue: 'id'
          }
        },
        {
          column: 'col-4',
          data: 'completion_status',
          label: i18n.t('l0601.scorePage.completionStatus.title'),
          type: 'select',
          topLabel: true,
          props: {
            items: [
              { id: 'complete', name: i18n.t('l0601.scorePage.completionStatus.complete') },
              { id: 'incomplete', name: i18n.t('l0601.scorePage.completionStatus.incomplete') }
            ],
            itemText: 'name',
            itemValue: 'id'
          }
        }
      ],
      l0802Filter: [
        {
          column: 'col-6',
          data: 'student_name',
          label: i18n.t('l0802.scorePage.studentName'),
          type: 'text',
          topLabel: true
        },
        {
          column: 'col-6',
          data: 'content_section_content_progress_quiz_status',
          label: i18n.t('l0802.scorePage.status.title'),
          type: 'select',
          topLabel: true,
          props: {
            items: [
              { id: 'PASS', name: i18n.t('l0802.scorePage.status.pass') },
              { id: 'FAIL', name: i18n.t('l0802.scorePage.status.fail') },
              { id: 'IN_PROGRESS', name: i18n.t('l0802.scorePage.status.inProgress') },
              { id: 'PENDING', name: i18n.t('l0802.scorePage.status.pending') },
              { id: 'VOID', name: i18n.t('l0802.scorePage.status.void') }
            ],
            itemText: 'name',
            itemValue: 'id'
          }
        }
      ]
    }
  }
}
const quizScoreConst = new BaseDataStore(BaseConst)
export default quizScoreConst
