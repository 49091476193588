// import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import { Base, BREAD, error } from '@/store/base/module'
import ApiUtils from '@/utils/api/ApiUtils'
import i18n from '@/plugins/i18n'

const statusMap={
  PENDING: {
    text: i18n.t('t2201.status.PENDING'),
    color: 'grey',
    bottomBtnList: ['reportUser', 'spacer', 'reject', 'goToChatroom'],
  },
  IN_PROGRESS: {
    text: i18n.t('t2201.status.IN_PROGRESS'),
    color: 'orange',
    bottomBtnList: ['reportUser', 'spacer', 'goToChatroom'],
  },
  PAYMENT: {
    text: i18n.t('t2201.status.PAYMENT'),
    color: 'purple',
    bottomBtnList: ['reportUser', 'spacer', 'payment', 'goToChatroom'],
  },
  FAILED: {
    text: i18n.t('t2201.status.FAILED'),
    color: 'red',
    bottomBtnList: ['reportUser'],
  },
  ACTIVE: {
    text: i18n.t('t2201.status.ACTIVE'),
    color: 'green',
    bottomBtnList: ['reportUser', 'spacer', 'markComplete', 'goToChatroom'],
  },
  INACTIVE: {
    text: i18n.t('t2201.status.INACTIVE'),
    color: 'grey',
    bottomBtnList: ['reportUser'],
  },
  REJECTED: {
    text: i18n.t('t2201.status.REJECTED'),
    color: 'red',
    bottomBtnList: ['reportUser'],
  },
  CLOSED: {
    text: i18n.t('t2201.status.CLOSED'),
    color: 'red',
    bottomBtnList: ['reportUser'],
  },
  DELETED: {
    text: i18n.t('t2201.status.DELETED'),
    color: 'red',
    bottomBtnList: ['reportUser'],
  },
  REMOVED: {
    text: i18n.t('t2201.status.REMOVED'),
    color: 'red',
    bottomBtnList: ['reportUser'],
  }
}


function getMapArray(obj){
  return Object.keys(obj).map(key => {
    return {
      value: key,
      ...obj[key],
    }
  })
}

const PageConst =  {
  // FORM_DEF: {
  //   editDef: [
  //     { name: 'name', label: 'Name', type: 'text', required: true },
  //     { name: 'description', label: 'Description', type: 'textarea', required: false }
  //   ]
  // },
  // SEARCH_DEF: {
  //   inputDef: [
  //   ],
  // },
  // LIST_DEF: {
  //   tableDef:[
  //   ]
  // },
  ACTION: {
    doAdd: { api: 'T2201ServiceApplicationService.customer_doAdd'},
    getEdit: { api: 'T2201ServiceApplicationService.customer_getEdit'},
    doEdit: { api: 'T2201ServiceApplicationService.customer_doEdit'},
    doRead: { api: 'T2201ServiceApplicationService.customer_doRead'},
    doDelete: { api: 'T2201ServiceApplicationService.customer_doDelete'},
    doBrowse: { api: 'T2201ServiceApplicationService.customer_doBrowse'},
  },
}

const module = {
  state: {
    PageConst,
    statusMap,
    statusMapArray: getMapArray(statusMap),
    searchInfo:{},
    orderBy:{},
    showDialog: false,
    paymentDetail: []
  },

  getters: {
    getShowDialog: state => state.showDialog
  },

  mutations: {

  },
  actions: {
    async doGet_application ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2201ServiceApplicationService.doGet_application',
          input: payload
        }] })
      state.row = res[0].output
      state.showDialog = true
      return res[0].output
    },
    async closeDialog ({ commit, state }, payload) {
      state.showDialog = false
      state.row = {}
      return true
    },
    async creator_acceptNDA ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2201ServiceApplicationService.creator_acceptNDA',
          input: payload
        }] })
      state.row = res[0].output
      state.showDialog = true
      return res[0].output
    },
    async creator_acceptApplication ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2201ServiceApplicationService.creator_acceptApplication',
          input: payload
        }] })
      state.row = res[0].output
      state.showDialog = true
      return res[0].output
    },
    async creator_rejectApplication ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2201ServiceApplicationService.creator_rejectApplication',
          input: payload
        }] })
      state.row = res[0].output
      state.showDialog = true
      return res[0].output
    },
    async creator_completeApplication ({ commit, state }, payload) {
      const res = await ApiUtils.postData({ data: [
        { api: 'T2201ServiceApplicationService.creator_completeApplication',
          input: payload
        }] })
      state.row = res[0].output
      state.showDialog = true
      return res[0].output
    },
    async customer_initPayment ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{
          api: 'T2201ServiceApplicationService.doBrowse',
          input: {
            t2201_id: payload.id
          }
        }]
      })
      state.paymentDetail = res[0].output
      return res[0].output
    },
  }
}

export default ObjArrUtils.merge(Base, error, BREAD, module)
