<template>
  <div
    v-if="init"
    class="ads-image-container"
    :style="`${$store.getters['Auth/getUser'].m0101_id !== 1 && Object.keys(row[0]).length === 0 ? 'display: none' : null}`"
  >
    <div class="" style="position: absolute; right: 5px; top: 5px;">
      <v-dialog v-if="$store.getters['Auth/getUser'].m0101_id === 1" v-model="dialog" max-width="750px">
        <template #activator="{ on, attrs }">
          <ActionButton
            v-bind="attrs"
            large
            color="success"
            preset="edit"
            class="d-flex justify-content-end p-0"
            v-on="on"
            @click="getEdit"
          />
        </template>
        <div :key="updateKey">
          <v-tabs v-model="tab">
            <v-tab v-for="(n, i) in row" :key="i + '_tabLength'">
              廣告 {{ i + 1 }}
            </v-tab>
            <div class="d-flex align-center">
              <v-btn plain color="primary" @click="row.push({})"><v-icon>mdi-plus</v-icon></v-btn>
            </div>
            <div class="ms-auto d-flex align-center">
              <v-btn plain @click="dialog = false"><v-icon>mdi-close</v-icon></v-btn>
            </div>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="(n, j) in row" :key="j + '_form'">
              <v-card class="p-5">
                <AtFormV2
                  v-model="row[j]"
                  :def="form"
                  :reset-btn="false"
                  @onSubmit="doEdit"
                />
                <v-card-actions>
                  <ActionButton
                    preset="delete"
                    @click="deleteTab(j)"
                  />
                </v-card-actions>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-dialog>
    </div>
    <slot>
      <div v-if="$store.getters['Auth/getUser'].m0101_id === 1 && row.length === 1 && Object.keys(row[0]).length === 0">
        {{ $t('components.AtAds.title') }}
      </div>
      <div v-else>
        <img
          v-if="randomAds !== null"
          :src="row[randomAds]?.image[0]?.src ?? imagePlaceholder"
          class="w-100 h-100"
          style="object-fit: content;"
          @click="openNewTab(row[randomAds].link)"
        >
      </div>
    </slot>
  </div>
</template>

<script>
import ActionButton from '../../molecules/ActionButton/ActionButton.vue';
import { mapState } from 'vuex'
import AtFormV2 from '../AtForm/AtFormV2.vue';

export default {
  components: {
    ActionButton,
    AtFormV2
  },
  data () {
    return {
      dialog: false,
      tab: null,
      randomAds: null,
      updateKey: 0,
      init: false
    }
  },
  computed: {
    ...mapState('LandingMaster', [
      'row'
    ]),
    row:{
      get(){
        return this.$store.getters['LandingMaster/getRow']
      },
      set(val){
        this.$store.commit('LandingMaster/SET_ROW',val)
      }
    },
    form () {
      return [
        {
          column: 'col-12',
          data: 'link',
          type: 'text',
          // rules: 'required',
          rules: 'url',
          props: {
            persistentHint: true,
            hint: "例如: https://www.google.com"
          },
          label: '鏈接'
        },
        {
          column: 'col-12',
          data: 'image',
          type: 'file',
          // rules: 'required',
          props: {
            accept: 'image/*',
            maxFiles: 1,
            hideDetails: true
          },
          label: '圖片'
        }
      ]
    }
  },
  async mounted(){
    await this.storeApiDispatch("LandingMaster/getEdit", { setting_key: 'member.landing.ads', setting_code: 'ads' })
    this.init = true

    function getRandomIndex(arr) {
      // Generate a random index between 0 and arr.length - 1
      const randomIndex = Math.floor(Math.random() * arr.length);

      return randomIndex;
    }
    if (Object.keys(this.row[0]).length !== 0){
      this.randomAds = getRandomIndex(this.row)
    }
  },
  methods: {
    getEdit () {
      this.storeApiDispatch("LandingMaster/getEdit", { setting_key: 'member.landing.ads', setting_code: 'ads' })
    },
    doEdit () {
      this.storeApiDispatch("LandingMaster/doEdit", { setting_key: 'member.landing.ads', setting_code: 'ads', setting_value: this.row})
        .then(() => {
          this.$dialog.notify.success('提交成功')
        })
        .then(() => {
          this.refreshPage()
        })
    },
    deleteTab (val) {
      if(this.row.length === 1) {
        this.row = [{}]
        this.doEdit()
      } else {
        this.row.splice(val, 1)
      }
      this.updateKey--
    },
    refreshPage () {
      location.reload()
    }
  }
}
</script>

<style scoped>
.ads-image-container{
  width: 100%;
  height: 200px;
  min-width: 50px;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #757575;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  color: white;
  font-size : 20px;
  font-weight: 700;
}
</style>

<style scoped src="bootstrap/dist/css/bootstrap.min.css"></style>
<style scoped src="@/assets/css/style.css"></style>
