import ApiUtils from '@/utils/api/ApiUtils'
import FnConst from './P0101AuthorConst'

// TODO: can i know what storeConst properties are available? (using intelisense)
const StoreConst = FnConst.storeConst
const store = {
  state: {
    // custom state
    searchInfo: { status: 'DRAFT' },
    stats: {}

  },
  getters: {
    // custom getter
    getStats: (state) => state.stats
  },
  mutations: {
    // custom mutation

    SET_STATS (state, payload) {
      const stats = {}
      payload.forEach(row => {
        stats[row.name] = row.count
      })
      state.stats = stats
    }
  },
  actions: {
    // override action  
    async doBrowse ({ commit, state }, payload) {
      console.debug('doBrowse', payload)

      let searchApi = {}
      if (state.searchInfo.status === 'COMMENT') {
        const val = {
          searchInfo: {},
          orderBy: [],
          pagination: state.pagination
        }
        searchApi = { api: StoreConst.ACTION.doBrowseComments.api, input: val }
      } else if (state.searchInfo.status === 'INCOME') {
        const val = {
          searchInfo: state.searchInfo,
          orderBy: [],
          pagination: state.pagination
        }
        searchApi = { api: StoreConst.ACTION.doBrowseIncomeStatistic.api, input: val }
      } else {
        const val = {
          searchInfo: state.searchInfo,
          orderBy: state.orderBy,
          pagination: state.pagination
        }
        searchApi = { api: StoreConst.ACTION.doBrowse.api, input: val }
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.getStats.api, input: null },
          searchApi
        ]
      })
      commit('SET_STATS', res[0].output)
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
    },
    async getStats ({ commit, state }, payload) {
      const res = await ApiUtils.postData({
        data: [{ api: StoreConst.ACTION.getStats.api, input: null }]
      })
      commit('SET_STATS', res[0].output)
    },
    async doPending ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.doPending.api, input: { id: state.row.id } },
          { api: StoreConst.ACTION.doBrowse.api, input: val }
        ]
      })
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
    },
    async doEdit ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.doEdit.api, input: payload },
          { api: StoreConst.ACTION.doBrowse.api, input: val }
        ]
      })
      commit('SET_ROW', null)
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
    },
    async doDelete ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.doDelete.api, input: payload },
          { api: StoreConst.ACTION.doBrowse.api, input: val }
        ]
      })
      commit('SET_ROW', null)
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
    },
    async doReject ({ commit, state }, payload) {
      const val = {
        searchInfo: state.searchInfo,
        orderBy: state.orderBy,
        pagination: state.pagination
      }
      const res = await ApiUtils.postData({
        data: [
          { api: StoreConst.ACTION.doReject.api, input: { id: state.row.id } },
          { api: StoreConst.ACTION.doBrowse.api, input: val }
        ]
      })
      commit('SET_ROWS', res[1].output.data)
      commit('SET_PAGINATION', res[1].output.pagination)
    },
    getAdd ({ commit, state }, payload) {
      commit('SET_ROW', { article_version_content: {} })
    }
  }
}
export default FnConst.getStore(store)
