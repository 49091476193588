import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    l0101: {},
    l0301List: [],
    l0301: {},
    l0201: [],
    l0701: [],
    l0801: [],
    refund: null,
    selectedL0301Index: 0,
    doBrowse_recommend_sameType: [],
    doBrowse_recommend_sameCategory: []
  },
  getters: {
    // get l0301List that l0301.is_enrolled === true
    enrolledL0301List (state) {
      return state.l0301List.filter(l0301 => l0301.is_enrolled)
    },
    notEnrolledL0301List (state) {
      return state.l0301List.filter(l0301 => !l0301.is_enrolled)
    }
  },
  mutations: {
  },
  actions: {
    async init ({ commit, state, dispatch }, payload) {
      const res = await ApiUtils.postData({
        data: [
          {
            api: 'L0101ContentService.doRead_contentDetails',
            input: { seo_url: payload.seo_url, preview: !!payload.preview ?? false }
          }
        ]
      })
      state.l0101 = res[0].output.l0101_content
      state.l0301List = res[0].output.l0301_content_class
      dispatch('selectbyL0301Index', 0)
      state.l0201 = res[0].output.l0201_content_lecturer
      state.l0701 = res[0].output.l0701_content_class_enrollment
      state.l0801 = res[0].output.l0801_content_section_content_progress
      state.refund = res[0].output.refund

      state.doBrowse_recommend_sameType = res[0].output.doBrowse_recommend_sameType
      state.doBrowse_recommend_sameCategory = res[0].output.doBrowse_recommend_sameCategory
    },
    // dreprecated
    setSelectedL0301Index ({ state }, index) {
      state.selectedL0301Index = index
      state.l0301 = state.l0301List[index]
    },
    selectbyL0301Index ({ state }, payload) {
      state.l0301 = state.l0301List[payload]
    },
    selectbyL0301Id ({ state }, payload) {
      state.l0301 = state.l0301List.find(l0301 => l0301.id === payload)
      state.selectedL0301Index = state.l0301List.findIndex(l0301 => l0301.id === payload)
      if (state.l0301 === undefined) {
        throw new Error('l0301 not found')
      }
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
