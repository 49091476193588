// @ts-check

import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
import i18n from '@/plugins/i18n'
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    col: {
      email: { label: i18n.t('m0001.col.email'), type: 'email', icon: 'mdi-email' },
      first_name_en: { label: i18n.t('m0001.col.first_name_en'), icon: 'mdi-account' },
      last_name_en: { label: i18n.t('m0001.col.last_name_en'), icon: 'mdi-account' },
      first_name_zh: { label: i18n.t('m0001.col.first_name_zh'), icon: 'mdi-account' },
      last_name_zh: { label: i18n.t('m0001.col.last_name_zh') },
      nickname: { label: i18n.t('m0001.col.nickname') },
      user_profile_pic: { label: i18n.t('m0001.col.user_profile_pic'), type: 'image', attrs: { width: '32px', height: '32px' } },
      date_of_birth: { label: i18n.t('m0001.col.date_of_birth'), type: 'date', icon: 'mdi-cake' },
      mobile_no: { label: i18n.t('m0001.col.mobile_no'), icon: 'mdi-phone' },
      gender: {
        label: i18n.t('m0001.col.gender._'),
        type: 'chip',
        icon: 'mdi-gender-male-female',
        items: [
          { value: 'M', label: i18n.t('m0001.col.gender.M'), color: 'blue' },
          { value: 'F', label: i18n.t('m0001.col.gender.F'), color: 'pink' },
          { value: 'O', label: i18n.t('m0001.col.gender.O'), color: 'grey' }
        ]
      },
      status: {
        label: i18n.t('m0001.col.status._'),
        type: 'chip',
        items: [
          { value: 'A', label: i18n.t('m0001.col.status.A'), color: 'green' },
          { value: 'F', label: i18n.t('m0001.col.status.F'), color: 'orange' },
          { value: 'B', label: i18n.t('m0001.col.status.B'), color: 'red' },
          { value: 'N', label: i18n.t('m0001.col.status.N'), color: 'grey' },
          { value: 'C', label: i18n.t('m0001.col.status.C'), color: 'grey' }
        ]
      },
      alias: { label: i18n.t('m0001.col.alias'), icon: 'mdi-account-circle' },
      created_at: { label: i18n.t('m0001.col.created_at'), icon: 'mdi-calendar', type: 'relativeDate' },
      role_code: {
        label: i18n.t('m0001.col.role'),
        icon: 'mdi-account-tie-outline',
        type: 'chip',
        items: []
      }
    },
    userStatus: null,
    approvalStatus: {}
  },
  getters: {
    getCol (state) {
      return state.col
    }
  },
  mutations: {
    SET_ROLE_ITEMS (state, payload) {
      state.col.role_code.items = payload
    }
  },
  actions: {
    async getUserByAlias ({ state }, payload) {
      const data = await ApiUtils.postData({
        data:
        [
          { api: 'M0001UserService.getUserByAlias', input: payload }
        ]
      })
      return data[0].output
    },
    async doSearch ({ state }, payload) {
      const data = await ApiUtils.postData({
        data:
        [
          { api: 'M0001UserService.doSearch', input: payload },
          // TODO: remove getRoles After init
          { api: 'M0101RoleService.getRolesWithNoAdmin' }
        ]
      })
      state.col.role_code.items = data[1].output
      return data[0].output
    },
    async doRead (_, payload) {
      const data = await ApiUtils.postData({
        data: [{ api: 'M0001UserService.doRead', input: payload }]
      })
      return data[0].output
    },
    async getUserProfilePicPath (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M0001UserService.getUserProfilePicPath', input: payload }] })
      return data[0].output
    },
    async unbanUser (_, payload) {
      const data = await ApiUtils.postData({ data: [{ api: 'M0001UserService.unbanUser', input: { id: payload } }] })
      return data[0].output
    },
    async userUpgradeInit ({ state }, payload) {
      const data = await ApiUtils.postData({
        data:
        [
          { api: 'M0001UserService.userUpgradeInit' }
        ]
      })
      state.userStatus = data[0].output
    },
    async sendApproval ({ state }, payload) {
      const data = await ApiUtils.postData({
        data:
        [
          {
            api: 'M0001UserService.sendApproval',
            input: payload
          }
        ]
      })
      state.userStatus = data[0].output
    },
    async getRecommendUser ({ state }, payload) {
      const data = await ApiUtils.postData({
        data: [{
          api: 'M0001UserService.fullSearch',
          input: payload
        }]
      })
      return data[0].output
    },

  }
}
export default ObjArrUtils.merge(Base, error, BREAD, module)
