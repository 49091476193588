// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'all.public',
    path: 'search',
    name: 'Search',
    component: () => import('./PgSearch.vue'),
    meta: { title: 'Search' }
  },
  {
    portalGroup: 'collab.public',
    path: 'search',
    name: 'CollabTVPSearch',
    component: () => import('./TVP/PgSearchTVP.vue'),
    meta: { debug: true, title: 'Search' }
  }
]
