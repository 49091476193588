<template>
  <div>
    <v-card
      v-if="status === null"
    >
      <v-card-title>
        {{ $t('m2301.detailButtons.title') }}
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            class="ma-4"
          >
            <v-btn
              color="primary"
              class="py-8"
              :width="200"
              block
              @click="status='APPROVED'"
            >
              {{ $t('m2301.detailButtons.approved') }}
            </v-btn>
          </v-col>
          <v-col
            v-if="!$attrs['hide-amendment']?? notRefund"
            class="ma-4"
          >
            <v-btn
              color="warning"
              class="py-8"
              :width="200"
              block
              @click="status='AMENDMENT'"
            >
              {{ $t('m2301.detailButtons.amendment') }}
            </v-btn>
          </v-col>
          <v-col
            class="ma-4"
          >
            <v-btn
              color="error"
              class="py-8"
              :width="200"
              block
              @click="status='REJECTED'"
            >
              {{ $t('m2301.detailButtons.rejected') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card v-if="status !== null">
      <v-card-title>
        <h2>
          {{ $t('m2301.detailButtons.title') }}
        </h2>
        <v-spacer />
      </v-card-title>
      <v-card-text class="px-0">
        <v-row
          class="mx-4 mt-4"
        >
          <v-autocomplete
            v-model="status"
            class="col-md-2"
            :label="$t('m2301.detailButtons.status')"
            :items="notRefund ? statusItemsDefault : statusItemsRefund"
            item-text="label"
            item-value="value"
            outlined
          />
        </v-row>
        <v-row
          class="mx-4"
        >
          <h3>
            {{ noticeText[status] }}
          </h3>
        </v-row>
        <v-row class="ma-4">
          <v-form
            ref="form"
            v-model="formIsValid"
            class="full-width-form"
            @submit.prevent="updateApprovalStatus()"
          >
            <v-col>
              <v-row>
                <v-autocomplete
                  v-if="status === 'REJECTED'"
                  v-model="selectedIndex"
                  class="required"
                  :label="$t('m2301.detailButtons.category')"
                  :items="rejectCategory"
                  item-text="translated_category_name"
                  :rules="[
                    v => !!selectedIndex || $t('m2301.rules.categoryRequired')
                  ]"
                  item-value="id"
                  outlined
                >
                  <template #item="{ item }">
                    <div class="item-container">
                      <div>{{ item.translated_category_name }}</div>
                      <div class="caption">
                        {{ item.translated_category_desc }}
                      </div>
                    </div>
                  </template>
                </v-autocomplete>
              </v-row>
              <v-row>
                <v-textarea
                  v-model="verdict"
                  :class="status !== 'APPROVED' ? 'required' : null"
                  :label="$t('m2301.detailButtons.verdict')"
                  :rules="[
                    v => !(v === '' &&(status === 'REJECTED' || status === 'AMENDMENT')) || $t('m2301.rules.verdictRequired')
                  ]"
                  outlined
                />
              </v-row>
            </v-col>
            <v-col
              cols="auto"
              class="buttonContainer pt-0 pr-0"
            >
              <v-btn
                color="primary"
                :width="200"
                block
                type="submit"
              >
                {{ $t('m2301.detailButtons.submit') }}
              </v-btn>
            </v-col>
          </v-form>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import i18n from '@/plugins/i18n'
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      verdict: '',
      status: null,
      selectedIndex: null,
      approvalId: null,
      noticeText: {
        APPROVED: i18n.t('m2301.detailButtons.noticeForApprove'),
        AMENDMENT: i18n.t('m2301.detailButtons.noticeForAmendment'),
        REJECTED: i18n.t('m2301.detailButtons.noticeForReject')
      },
      statusItemsDefault: [
        { label: i18n.t('m2301.status.approved'), value: 'APPROVED' },
        { label: i18n.t('m2301.status.amendment'), value: 'AMENDMENT' },
        { label: i18n.t('m2301.status.rejected'), value: 'REJECTED' }
      ],
      statusItemsRefund: [
        { label: i18n.t('m2301.status.approved'), value: 'APPROVED' },
        { label: i18n.t('m2301.status.rejected'), value: 'REJECTED' }
      ],
      formIsValid: false,
      notRefund: true
    }
  },
  computed: {
    ...mapGetters('M2301Approval', {
      rejectCategory: 'getRejectCategory'
    })
  },
  mounted () {
    if (this.value.key.includes('refund')) this.notRefund = false
    this.storeApiDispatch('M2301Approval/initAdminReview', this.value).then((res) => {
      this.approvalId = res
    })

    if (this.value.key.includes('preliminary')) this.notRefund = false
  },
  methods: {
    async updateApprovalStatus () {
      const input = {
        m2301_id: this.approvalId,
        approval_status: this.status,
        approval_verdict: this.verdict,
        m0601_id: this.selectedIndex
      }

      this.$refs.form.validate()

      if (!this.formIsValid) {
        this.$dialog.notify.error(this.$t('components.AtForm.formInvalid'))
        return
      }
      const result = await this.storeApiDispatch('M2301Approval/updateApprovalStatus', input)

      // const result = await this.storeApiDispatch('L0101Content/updateStatusAdmin', input)

      result ? this.$dialog.notify.success('submitSuccess') : this.$dialog.notify.error('submitFailed')

      this.$router.push({ name: 'ApprovalList' })
      // const result = await this.storeApiDispatch('M2301Approval/updateContentStatus', input)
      // result ? this.$emit('submitSuccess', this.status) : this.$emit('submitFailed', this.status)
    }
  }
}
</script>
<style scoped>
.buttonContainer{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.item-container{
  display: flex;
  flex-direction: column;
}

.caption {
  font-size: 12px;
  color: grey;
}

.full-width-form{
  width: 100%;
  display: flex;
  flex-direction: row;
}

h3{
  color: black;
}
</style>
