// eslint-disable-next-line no-unused-vars
import i18n from '@/plugins/i18n'
import { PortalUtils } from '@/utils/PortalUtils/index.js'
import exampleRoutes from '@/components/examples/routes'
/**
DEF
{
  path: '/example',
  name: 'Example',
  component: () => import('@/components/examples/Example.vue'),
  portalGroup: 'all.my',
  meta: {
    authenticated: true, // Whether to allow only logged in user (Default: false)
    roles: [], // Array of permitted roles
    permissions: [], // Array of permitted permissions
    notLogged: false, // Whether to allow only non-logged user (Default: false)
    debug: false, // Whether to allow only on debug mode (Default: false)
    headerTitle: undefined, // Sets app-bar title for route
    documentTitle: undefined, // Sets tab title for route
    title: undefined // Sets both app-bar & tab title for route
  }
}
 */

/**
:to
{
  path: '/example',
  name: 'Example',
  portal: undefined, // if set, will redirect to target subdomin
}
 */

/**
 * Auto import routes from all modules
 * @example @/app/Example/routes.js
 * @example @/app/../routes.js - nested dir is allowed
 * ! multi nested is allowed
 * ? limit to only @/app/./routes.js file ?
 */
const req = import.meta.globEager('../app/**/routes.js', { eager: true, import: 'default' })
const portalName = PortalUtils.name

const portalGroupMapping = {
  public: {
    path: '/',
    component: () => import(`../router/template/${portalName}/PublicTemplate.vue`),
    children: []
  },
  my: {
    path: '/my',
    component: () => import(`../router/template/${portalName}/MyTemplate.vue`),
    children: []
  },
  admin: {
    path: '/admin',
    component: () => import(`../router/template/${portalName}/AdminTemplate.vue`),
    children: []
  }
}

const autoImportRoutes = []

Object.keys(req).forEach((file) => {
  const data = req[file]

  if (!data) { throw new Error(`No default export in '${file}'`) }
  // must array
  if (!Array.isArray(data)) { throw new Error(`Default export must be an array in '${file}'`) }
  // every item must be object
  if (!data.every((item) => typeof item === 'object')) { throw new Error(`Every item must be an object in '${file}'`) }

  // loop every item
  for (let i = 0; i < data.length; i++) {
    const e = data[i]
    // check e has portalGroup
    if (!e.portalGroup) {
      // throw new Error(`Missing portalGroup in '${file}' index ${i}`)
      // if no portalGroup, just add to autoImportRoutes
      autoImportRoutes.push(e)
      continue
    }

    // if contain `all.public` -> [home.public, learn.public, press.public, collab.public, advisory.public]
    if (typeof e.portalGroup === 'string' && e.portalGroup.split('.')[0] === 'all') {
      const u = e.portalGroup.split('.')[1]
      const tmpportalGroup = []
      // const allList = []
      // looop PortalUtils.portals
      for (let j = 0; j < PortalUtils.portals.length; j++) {
        const portal = PortalUtils.portals[j]
        tmpportalGroup.push(`${portal}.${u}`)
      }
      e.portalGroup = tmpportalGroup
    }
    // So that all portal can use the same page like for example now cart page is learn.my only but what if collab.my want to use the same template then thats where we need to do for e.portalGroup is array
    if (!Array.isArray(e.portalGroup)) {
      e.portalGroup = [e.portalGroup]
    }

    const u = e.portalGroup[0].split('.')[1]
    for (let j = 0; j < e.portalGroup.length; j++) {
      const ele = e.portalGroup[j]
      const _portal = ele.split('.')[0]
      const _role = ele.split('.')[1]

      if (typeof ele !== 'string') { throw new Error(`portalGroup must be a string in '${file}'index ${i}`) }
      // asd = e.portalGroup
      // if asd = ['learn.my', 'admin.admin']
      // throw error
      // if asd = ['learn.my', 'collab.my']
      // then pass
      if (_role !== u) { throw new Error(`portalGroup must for same user only in '${file}'index ${i}`) }
      if (!portalGroupMapping[_role]) { throw new Error(`Unknown portalGroup: '${ele}' in '${file}'index ${i}`) }
      // check portal is valid
      if (!PortalUtils.portals.includes(_portal)) { throw new Error(`Unknown portal: '${_portal}' in '${file}'index ${i}`) }

      // extract portal list
      e.meta = e.meta || {}
      e.meta.portal ? e.meta.portal.push(_portal) : e.meta.portal = [_portal]
    }
    portalGroupMapping[u].children.push(e)
  }
})

Object.keys(portalGroupMapping).forEach((key) => {
  // if (!key.includes(PortalUtils.name)) return
  const route = portalGroupMapping[key]
  if (route.children.length > 0) {
    autoImportRoutes.push(route)
  }
})

// console.log('autoImportRoutes', autoImportRoutes)
const routes = [
  ...autoImportRoutes,
  ...exampleRoutes,
  {
    path: '*',
    redirect: '/404'
  }
]
export default routes
