<template>
  <v-menu
    ref="wrapper"
    v-model="modal"
    :disabled="$attrs.readonly"
    :return-value.sync="time"
    v-bind="{
      ...{
        closeOnContentClick : false,
        transition: 'scale-transition',
        offsetY: true,
        maxWidth: '290px',
        minWidth: '290px'
      },
      ...wrapperProps
    }"
  >
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="customTextFormat(time)"
        :label="label"
        v-bind="{
          ...attrs,
          clearable: true,
          prependIcon: 'mdi-clock-time-four-outline',
          readonly: true,
          ...$attrs
        }"
        v-on="on"
        @click:clear="clear"
      />
    </template>
    <v-time-picker
      v-model="time"
      v-bind="{
        ...{
          fullWidth: true,
          scrollable: true,
          locale: convertLocale(currentLocale, 'bcp47'),
        },
        ...timePickerProps
      }"
    >
      <v-btn
        text
        color="primary"
        @click="modal = false"
      >
        {{ $t('common.cancel') }}
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="clear"
      >
        {{ $t('common.clear') }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="primary"
        @click="$refs.wrapper.save(time)"
      >
        {{ $t('common.ok') }}
      </v-btn>
    </v-time-picker>
  </v-menu>
</template>

<script>
import moment from 'moment'
export default {
  components: {
  },
  props: {
    value: {
      validator: prop => typeof prop === 'string' || prop === null || prop === undefined,
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    wrapperProps: {
      type: Object,
      default: () => {}
    },
    timePickerProps: {
      type: Object,
      default: () => {}
    },
    customTextFormat: {
      type: Function,
      default: (time) => {
        if (moment(time, 'HH:mm').isValid()) return moment(time, 'HH:mm').format('hh:mm A')
        else return ''
      }
    },
    clearValue: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      modal: false,
      time: this.value,
      wrapperPropsMapping: {
        VDialog: {
          persistent: true,
          width: '300px'
        }
      }

    }
  },
  watch: {
    value: {
      handler (val) {
        this.time = val
      },
      immediate: true
    },
    time (val) {
      this.$emit('input', val)
    }
  },
  methods: {
    clear () {
      this.time = this.clearValue
    }
  }
}
</script>
