<template>
  <div>
    <v-row
      v-for="(row, index) in chunkedItems"
      :key="'AtGrid'+ row + index"
    >
      <v-col
        v-for="(item, colIndex) in row"
        :key="'AtGrid'+ row + index + colIndex"
        :class="`col-${12 / itemsPerRow}`"
      >
        <slot
          v-if="item"
          :item="item"
        >
          {{ item }}
        </slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true
    },
    itemsPerRow: {
      type: Number,
      default: 4
    }
  },
  computed: {
    chunkedItems () {
      return this.chunkArray(this.items, this.itemsPerRow)
    }
  },
  mounted () {
  },
  methods: {
    chunkArray (array, size) {
      const result = []
      for (let i = 0; i < array.length; i += size) {
        const chunk = array.slice(i, i + size)
        if (chunk.length < size) {
          for (let j = chunk.length; j < size; j++) {
            chunk.push(null)
          }
        }
        result.push(chunk)
      }
      return result
    }
  }
}
</script>
