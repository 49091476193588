// import i18n from '@/plugins/i18n'

export default [
  {
    portalGroup: 'press.public',
    component: { render: h => h('router-view') },
    path: '',
    children: [
      {
        path: 'article/:seo',
        name: 'ArticleDetail',
        component: () => import('./public/ArticleDetail/PgArticleDetail.vue')
      },
      {
        // ! Duplicated use case with Search
        path: 'P0101Article/list',
        name: 'PgP0101List',
        component: () => import('./PgP0101List.vue')
      },
      {
        // !
        path: 'P0101UserArticle/index',
        name: 'TTC PRESS HOME',
        component: () => import('../P0101UserArticle/PgP0101MainPage.vue')
      },
      {
        // !
        path: 'P0101AuthorList/index',
        name: 'TTC PRESS Article Author',
        component: () => import('../P0101AuthorList/PgMainPage.vue')
      },
    ]
  },
  {
    // ! Deprecated route: use `my/article` instead
    portalGroup: 'press.public',
    component: { render: h => h('router-view') },
    path: 'my',
    children:[
      {
        path: 'P0101AuthorArticle/index',
        name: 'Author Home',
        redirect: { name: 'PressMyDashboard' }
      },
    ]
  },
  {
    portalGroup: 'press.public',
    component: { render: h => h('router-view') },
    path: 'my/article',
    children:[
      {
        path: 'index',
        name: 'PressMyDashboard',
        component: () => import('../P0101AuthorArticle/PgP0101AuthorPage.vue')
      },
      {
        path: ':id/edit',
        name: 'PressMyArticleEdit',
        component: () => import('../P0101AuthorArticle/comps/P0101AuthorEdit.vue')
      },
      {
        // ! deprecated
        path: 'P0101ArticleMy/index',
        name: 'PgP0101MultiPageMy',
        component: () => import('./PgP0101MyPage.vue')
      },
      {
        // ! deprecated
        path: 'P0101Article/view/:id',
        name: 'PgP0101View',
        component: () => import('./PgP0101View.vue')
      },
      {
        // ! deprecated
        path: 'P0101Article/form/:id?',
        name: 'PgP0101Form',
        component: () => import('./PgP0101Form.vue')
      },
    ]
  },
  {
    portalGroup: 'admin.admin',
    component: { render: h => h('router-view') },
    path: 'press',
    meta: {
      roles: ['SA', 'PA', 'VA', 'PAP', 'PAL', 'PAC', 'PAA']
    },
    children: [
      {
        // ! deprecated
        path: 'P0101Article/index',
        name: 'PgP0101MultiPage',
        component: () => import('./PgP0101MultiPage.vue')
      },
      {
        // ! deprecated
        path: 'P0101Article/single',
        name: 'PgP0101SinglePage',
        component: () => import('./PgP0101SinglePage.vue')
      },
      {
        // ! deprecated
        path: 'P0101ChoiceArticle/index',
        name: 'TTC PRESS Featured List',
        component: () => import('../P0101ChoiceArticle/PgMainPage.vue'),
      },
      {
        // ! deprecated
        path: 'P0101ArticleSns/index',
        name: 'TTC PRESS SNS Schedule',
        component: () => import('../P0101ArticleSns/PgMainPage.vue'),
      }
    ]
  },
]
