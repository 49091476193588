import BaseDataStore from '@/components/base/store/BaseDataStoreV1'

const BaseConst = {
  version: 1,
  storeName: 'M3801Meeting',
  serviceName: 'M3801MeetingService'
}

const M3801Const = new BaseDataStore(BaseConst)
export default M3801Const
