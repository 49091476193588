import ApiUtils from '@/utils/api/ApiUtils'
import ObjArrUtils from '@/utils/ObjArrUtils'
// eslint-disable-next-line no-unused-vars
import { Base, BREAD, error } from '@/store/base/module'

const module = {
  state: {
    order: 0,
    status: 'ALL',
    medium: 'ALL',
    list: [],
    dialog: false,
    contentId: 0,
    pagelimit: 10,
    allamount: [],
    loadingPage: true,
    contentCategory: 'ALL',
    mylearn: 'BoughtContent',
    wishOrder: 0,
    loadingLearn: false,
    loadingMore: false,
    bookmarkall: [],
    getrating: null,
    getfeedback: null,
    ratingStatus: null,
    comment_id: null
  },
  getters: {
    getsortList (state) {
      return state.sortList
    }

  },
  mutations: {
    SET_ORDER (state, payload) {
      state.order = payload
      // state.loadingPage = false
    },
    SET_STATUS (state, payload) {
      state.status = payload
      // state.loadingPage = false
    },
    SET_MEDIUM (state, payload) {
      state.medium = payload
      // state.loadingPage = false
    },
    SET_LIST (state, payload) {
      state.list = payload
      state.contentId = 0
      state.loadingPage = false
    },
    SET_RATING (state, payload) {
      state.contentId = payload
      state.getrating = 0
      state.getfeedback = null
      state.ratingStatus = 'add'
      state.dialog = true
    },
    CLOSE_RATING (state) {
      state.dialog = false
    },
    SET_DIALOG (state, payload) {
      state.dialog = payload
    },
    SET_PAGELIMIT (state, payload) {
      state.pagelimit = payload
    },
    SET_SHOW_ALL_AMOUNT (state, payload) {
      state.allamount = payload
      // state.loadingLearn = false
    },
    SET_LOADING_PAGE (state) {
      state.loadingPage = true
    },
    SET_CONTENT_CATEGORY (state, payload) {
      state.contentCategory = payload
      state.loadingPage = false
    },
    SET_MY_LEARN (state, payload) {
      state.mylearn = payload
      state.loadingLearn = false
    },
    SET_WISH_SORT (state, payload) {
      state.wishsort = payload
    },
    SET_WISH_MEDIUM (state, payload) {
      state.wishMedium = payload
    },
    SET_WISH_ORDER (state, payload) {
      state.wishOrder = payload
      state.loadingPage = false
    },
    SET_LOADING_LEARN (state, payload) {
      state.loadingLearn = payload
    },
    SET_LOADING_MORE (state, payload) {
      state.loadingMore = payload
    },
    SET_BOOKMARK_ALL (state, payload) {
      // console.log(payload.map((item) => item.bookmarks_rel_id))
      state.bookmarkall = payload.map((item) => item.bookmarks_rel_id)
    }
  },
  actions: {
    // async init ({ commit, state }, payload) {
    //   const res = await ApiUtils.postData({
    //     data: [
    //       {
    //         api: ''
    //       }
    //     ]
    //   })
    // }
    async setAllAmountWish ({ state, commit }) {
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent_AllWish' }
        ]
      }).then(data => {
        commit('SET_SHOW_ALL_AMOUNT', data[0].output)
        return data
      })
    },
    async setAllAmount ({ state, commit }) {
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent_AllAmount', input: { status: state.status, medium: state.medium, contentCategory: state.contentCategory } }
        ]
      }).then(data => {
        commit('SET_SHOW_ALL_AMOUNT', data[0].output)
        return data
      })
    },
    async moreList ({ state, commit }, payload) {
      commit('SET_LOADING_MORE', true)
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent', input: { order: state.order, status: state.status, medium: state.medium, pagelimit: payload, contentCategory: state.contentCategory, mylearn: state.mylearn } }
        ]
      }).then(data => {
        commit('SET_PAGELIMIT', payload)
        commit('SET_LIST', data[0].output)
        commit('SET_LOADING_MORE', false)
        return data
      })
    },
    async setOrder ({ state, commit }, payload) {
      commit('SET_LOADING_PAGE')
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent', input: { order: payload, status: state.status, medium: state.medium, pagelimit: state.pagelimit, contentCategory: state.contentCategory, mylearn: state.mylearn } }
        ]
      }).then(data => {
        commit('SET_ORDER', payload)
        commit('SET_LIST', data[0].output)
        return data
      })
    },
    async setStatus ({ state, commit }, payload) {
      commit('SET_LOADING_PAGE')
      commit('SET_PAGELIMIT', 10)
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent', input: { status: payload, order: state.order, medium: state.medium, pagelimit: state.pagelimit, contentCategory: state.contentCategory, mylearn: state.mylearn } }
        ]
      }).then(data => {
        commit('SET_STATUS', payload)
        commit('SET_LIST', data[0].output)
        return data
      })
    },
    async setMedium ({ state, commit }, payload) {
      commit('SET_LOADING_PAGE')
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent', input: { medium: payload, status: state.status, order: state.order, pagelimit: state.pagelimit, contentCategory: state.contentCategory, mylearn: state.mylearn, wishOrder: state.wishOrder } }
        ]
      }).then(data => {
        commit('SET_MEDIUM', payload)
        commit('SET_LIST', data[0].output)
        return data
      })
    },
    async setBookmark ({ state, commit }, payload) {
      commit('SET_LOADING_PAGE')
      commit('SET_PAGELIMIT', 10)
      commit('SET_ORDER', 0)
      // commit('SET_STATUS', 'ALL')
      commit('SET_MEDIUM', 'ALL')
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent', input: { medium: state.medium, status: state.status, order: state.order, pagelimit: state.pagelimit, contentCategory: payload, mylearn: state.mylearn } }
        ]
      }).then(data => {
        commit('SET_CONTENT_CATEGORY', payload)
        commit('SET_LIST', data[0].output)
        return data
      })
    },
    setRating: ({ commit }, payload) => {
      commit('SET_RATING', payload)
    },
    giveRatingComment: ({ commit }, payload) => {
      commit('CLOSE_RATING', payload)
    },
    closeRating: ({ commit, state }, payload) => {
      commit('CLOSE_RATING', payload)
    },
    setDialog: ({ commit, state }, payload) => {
      commit('SET_DIALOG', payload)
    },
    setLoadingPage: ({ commit }) => {
      commit('SET_LOADING_PAGE')
    },
    setGetbackRatingDetail: ({ state }, payload) => {
      state.contentId = payload.id
      state.getrating = payload.rating
      state.getfeedback = payload.feedback
      state.ratingStatus = 'edit'
      state.comment_id = payload.comment_id
      state.dialog = true
    },
    async setMyLearn ({ commit, state }, payload) {
      commit('SET_LOADING_LEARN', true)
      commit('SET_PAGELIMIT', 10)
      commit('SET_ORDER', 0)
      // commit('SET_STATUS', 'ALL')
      commit('SET_MEDIUM', 'ALL')
      commit('SET_CONTENT_CATEGORY', 'ALL')
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent', input: { medium: state.medium, status: state.status, order: state.order,wishOrder: state.wishOrder, pagelimit: state.pagelimit, contentCategory: state.contentCategory, mylearn: payload } }
        ]
      }).then(data => {
        commit('SET_MY_LEARN', payload)
        commit('SET_LIST', data[0].output)
        // commit('SET_LOADING_LEARN', false)
        return data
      })
    },
    async setWishOrder ({ commit, state }, payload) {
      commit('SET_LOADING_PAGE')
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doBrowse_userBoughtContent', input: { status: state.status, medium: state.medium, wishOrder: payload, pagelimit: state.pagelimit, mylearn: state.mylearn } }
        ]
      }).then(data => {
        commit('SET_WISH_ORDER', payload)
        commit('SET_LIST', data[0].output)
        return data
      })
    },
    async setAllBookmark ({ state, commit }) {
      return await ApiUtils.postData({
        data: [
          { api: 'L0701ContentClassEnrollmentService.doGetBookmark' }
        ]
      }).then(data => {
        commit('SET_BOOKMARK_ALL', data[0].output)
        return data
      })
    },
    async updateCardRating ({ state }, payload) {
      state.list = state.list.map((item) => {
        if (item.l0301_id !== state.contentId) return item
        const newItem = item
        newItem.content_rating = payload.rating
        return newItem
      })
    }
  }
}

export default ObjArrUtils.merge(Base, error, module)
