<template>
  <div>
    <!-- group by languages -->
    <v-tabs
      v-if="!groupByKey"
      v-model="tab"
    >
      <template
        v-for="l in languages"
      >
        <v-tab
          :key="l.code"
        >
          {{ l.name }}
        </v-tab>
        <v-tab-item
          :key="l.code"
          class="pt-2"
        >
          <template v-for="(v, k) in value">
            <component
              :is="defs[k].component"
              v-if="defs[k] !== undefined"
              :key="k+'_'+l.code"
              v-model="v[l.code]"
              :label="k"
              outlined
            />
            <v-text-field
              v-else
              :key="k+'_'+l.code"
              v-model="v[l.code]"
              :label="k"
              outlined
            />
          </template>
        </v-tab-item>
      </template>
    </v-tabs>
    <!-- group by key -->
    <v-tabs
      v-else
      v-model="tab"
    >
      <template v-for="(v, k) in value">
        <v-tab
          :key="k"
        >
          {{ k }}
        </v-tab>
        <v-tab-item
          :key="k"
          class="pt-2"
        >
          <template v-for="l in languages">
            <component
              :is="defs[k].component"
              v-if="defs[k] !== undefined"
              :key="k+'_'+l.code"
              v-model="v[l.code]"
              :label="k"
              outlined
            />
            <v-text-field
              v-else
              :key="k+'_'+l.code"
              v-model="v[l.code]"
              :label="k"
              outlined
            />
          </template>
        </v-tab-item>
      </template>
    </v-tabs>
  </div>
</template>

<script>
/**
 * Translation component
 * 
 * @prop {Object} value - The translation object
 * @prop {Array} languages - The languages array
 * @prop {Boolean} groupByLanguage - Group by language or key
 * 
 * @example
 * <AtTranslation
 *  v-model="value"
 * :languages="languages"
 * />
 */
export default {
  props: {
    label: {
      type: String,
      default: 'Translate'
    },
    /**
     * {
          name: {
            en: 'Category 1',
            'zh-cn': '分类 1',
            'zh-tw': '類別 1'
          },
          description: {
            en: 'This is the description for category 1',
            'zh-cn': '这是分类 1 的描述',
            'zh-tw': '這是類別 1 的描述'
          }
        }
     */
    value: {
      type: Object,
      default: () => {
        return {}
      }
    },
    /**
     * [
        {
          id: 1,
          name: 'English',
          code: 'en'
        },
        {
          id: 2,
          name: 'Chinese Simplified',
          code: 'zh-cn'
        },
        {
          id: 3,
          name: 'Chinese Traditional',
          code: 'zh-tw'
        }
      ]
     */
    languages: {
      type: Array,
      default: () => []
    },
    /**
     * The definition of the translation object using the key as the label
     * component to use for the field
     * {
     * name: {
     *  component: 'TextField'
     * },
     * description: {
     * component: 'AtTextField'
     * }
     */
    defs: {
      type: Array,
      default: () => []
    },
    /**
     * Group by language or key
     */
    groupByKey: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tab: null
    }
  },
  watch: {
    value: {
      handler: function (val, oldVal) {
        // if value is empty, set to init object
        if (Object.keys(val).length === 0) this.$emit('input', this.clearVal(oldVal))
        else { this.$emit('input', val) }
      },
      deep: true
    }
  },
  methods: {
    checkEmpty (val) {
      return val === '' || val === null || val === undefined
    },
    clearVal (val) {
      const obj = {}
      for (const k in val) {
        obj[k] = {}
        for (const l in val[k]) { obj[k][l] = '' }
      }
      return obj
    }
  }
}
</script>

<style scoped>
</style>
