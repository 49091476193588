/** @module CookieUtils */

/**
 * Utilities for cookie (storage) related operations
 * @property {Function} getCookie Get cookie value with supplied key
 * @property {Function} setCookie Set cookie with key and value
 * 
 * @author Tin Ley Ter <leyter.tin@orbitmy.com>
 * @since 2022/07/20
 */
const CookieUtils = {
  /**
   * Get cookie value with supplied key
   * @param {String} key Name of cookie
   * @return {String | null}
   * - Returns `String` when cookie match is found
   * - Returns `null` if no matches is found
   */
  getCookie (key) {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${key}=`)
    if (parts.length === 2) return parts.pop().split(';').shift()
    return null
  },
  /**
   * Set cookie with key and value
   * @param {String} key Name of cookie to set
   * @param {String} value Value of cookie to set
   */
  setCookie (key, value) {
    // BUG: This will not work on current TTC implementation
    document.cookie = key + '=' + value + ';domain=' + import.meta.env.VITE_APP_API_DOMAIN + ';path=/'
  }
}
export default CookieUtils
